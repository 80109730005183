.end09_mybody{
	width: 95%;
	margin: 0 auto;
	margin-top: 20px;
}
.end09_mybody p{
	font-family: "宋体";
	margin-bottom: 10px;
}
.end09_mybody p:nth-of-type(1){
	font-size: 40px;
	color: #002F36;
}
.end09_mybody p:nth-of-type(2){
	width: 600px;
	font-size: 13px;
}
.end09_mybody p:nth-of-type(3){
	font-size: 14px;
	margin-top: 10px;
}
.foot-button{
	width: 98%;
	margin: 0 auto;
}
.foot-button .fk{
	background-color: #008296;
	color: white;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.1);
	padding: 2px 7px;
	position: fixed;
	bottom: -2px;
}

.end09_mybody_tab{
	width: 136px;
	background: #ddd;
}
.end09_mybody_tab .ant-tabs-tabpane{
	background: #fff;
}

.end09_mybody_tab .ant-tabs-tab-active{
	border-bottom: 2px solid #A6C8FF !important;
	border-top: 0 !important;
}
.end09_mybody_tab .ant-tabs-tab-active{
	border: 2px solid #A6C8FF !important;
	box-shadow: 0px 0px 0px 0px #A6C8FF, 0px 0 0px 0px #A6C8FF !important;
	margin: 0 !important;
}
.end09_mybody_tab .ant-tabs-tab-btn{
	padding-bottom: 6px;
	margin: 0 !important;
}
.end09_mybody_tab .ant-tabs-tab{
	margin: 0;
}

.end09_mybody_tab  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: #000000 !important;
}

.end09_mybody_tab_btm{
	border-bottom: qpx solid #008296;
}