




.Unshipped_head{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}
.Unshipped_head div{
    margin-right: 20px;
}
.UnshippedBox_navBox{
    width: 100%;
    display: flex;
    margin-top: 30px;
    padding-bottom: 300px;
}
.ausste_left_titel{
    border-bottom: none;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.ausste_left_dateTitel{
    padding-bottom: 7px;
}
.ausste_left_datenav{
    border-bottom: 1px solid #dddddd;
    margin-top:  7px;
    padding-bottom: 7px;
}
.Unshipped_head span{
    font-weight: bold;
}
.UnshippedBox .ausste_right{
    display: block;
}
.UnshippedBox .ausste_right >div{
    /* display: flex; */
    justify-content: space-between;
}
.UnshippedBox table{
    box-shadow: none;
    width: 100%;
}
.UnshippedBox table img{
    width: 60px;
    height: 60px;
}
.UnshippedBox table th{
    text-align: center;
}
.UnshippedBox table td{
    vertical-align:top;
    padding: 10px;
    text-align: center;
}
.UnshippedBox table td:nth-child(2){
    width: 11%;
}

.UnshippedBox table td:nth-child(3),
.UnshippedBox table td:last-child{
    text-align: right;
}
.UnshippedBox table td p{
    margin-bottom: 0;
}

.UnshippedBox table td:nth-child(5){
    width: 40%;
}
.UnshippedBox table td:nth-child(6){
    min-width: 100px;
}
.UnshippedBox .sueess{
    background-color: red;
    color: #fff;
    padding: 5px;
}
.UnshippedBox .lanCol{
    margin-bottom: 5px;
    font-weight: bold;
    color: rgb(102, 0, 153);
    cursor: pointer;
}
.UnshippedBox .col102{
    color: rgb(102, 0, 153);

}
.UnshippedBox table .operation button{
    width: 200px;
    height: 30px;
    border: 1px solid #ccc;
    margin: 5px 0;
    background-color: rgb(240, 241, 244);
    color: #000;
}
.UnshippedBox table .operation button:nth-child(1),
.UnshippedBox table .operation button:nth-child(2){
    background-color: rgb(242, 204, 110);

}
.UnshippedBox table .operation button:last-child{
    background-color: #fff;
    margin-bottom: 0;
}
.UnshippedBox .ausste_right_headII{
    text-align: right;
    margin-bottom: 10px;
    height: 35px;
}
.UnshippedBox .ausste_right_headII button{
    height: 32px;
}

.UnshippedBox .ausste_right_headII .addAll button{
    background-color: #f9f3e5;
}
.UnshippedBox .shopupDL-rBox-warnnav{
    margin-top: 15px;
}




