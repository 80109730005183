


.Lending_head{
    width: 100%;
}
.Lending_head img{
    width: 100%;
}

.Lending_head_font{
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

.Lending_head_font div{
    font-size: 15px;
    font-weight: bold;
}
.Lending_message{
    width: 100%;
    background: #F1F6F0;
    padding: 0 20px;
}
.Lending_message_titel{
    padding: 20px 0;
    font-size: 20px;
}
.Lending_message_content{
    font-size: 16px;
    font-weight: bold;
}
.Lending_message_image{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
}
.Lending_message_image div{
    width: 70px;
    height: 83px;
}
.Lending_message_image div img{
    width: 100%;
    height: 100%;
}


.Lending_List{
    padding: 0 40px;
}

.Lending_List_titel{
    font-size: 24px;
}
.Lending_List_content{
    padding-left: 6%;
}
.Lending_List_contentBox{
    display: flex;
    align-items: center;
    margin: 25px 0;
  
}

.Lending_List_contentBox div:nth-child(2){
    font-size: 15px;
    font-weight: bold;
}
.Lending_List_img{
    width: 45px;
    height: 45px;
    margin-right: 5%;
}

.Lending_List_img img{
    width: 100%;
    height: 100%;
}