
.qd01_content{
    padding-left: 25px;
    margin-top: 25px;
    padding-bottom: 300px;

}
.qd01_content .qd01_navigation{
    font-size: 16px;
}
.qd01_content .qd01_navigation span:nth-of-type(1){
    color: rgb(13, 116, 194);
}
.qd01_content .qd01_navigation span:nth-of-type(2){
    color: #c45500;
}
.qd01_content>div:nth-of-type(1){
    display: flex;
	margin: 15px 0;
}
.qd01_content>div:nth-of-type(1)>span{
    font-size: 24px;
}
.qd01_content>div:nth-of-type(1)>p{
    margin-left: 15px;
}
.qd01_content>div:nth-of-type(1)>p>span{
    color: rgb(13, 116, 194);
    padding: 0 3px;
}
.qd01_content>h2{
    font-size: 17px;
    font-weight: bold;
	margin: 8px 0;
}
.qd01_content>div:nth-of-type(2){
    display: flex;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(1){
    width: 15%;
    margin-right: 25px;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2){
    width: 58%;
    border: 1px solid #aaaaaa;
    height: 200px;
    border-radius: 5px;
	display:flex;
	flex-flow:column; 
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(3){
    width: 15%;
    padding: 0 35px;
    margin-left: 25px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    height: 285px;
	display:flex;
	flex-flow:column; 
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(3)>ul{
    list-style: none;
	padding: 0px;
    background-size:15px 15px;
    font-size: 12px;
    color: rgb(13, 116, 194);
	margin-top: 15px;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(3)>ul p img{
    vertical-align: middle;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(3)>p{
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(1)>h2{
    color: #c45500;
    font-size: 16px;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(1)>p{
    color: #665f5f;
    font-size: 12px;
    width: 71%;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1){
    font-size: 14px;
    font-weight: bold;
    margin-left: 35px;
	margin-top: 15px;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2){
    font-size: 14px;
    margin-left: 35px;
}

.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3){
    background: #f6f6f6;
    width: 100%;
    height: 52px;
    margin-top: 30px;
    border-top: 1px solid #d6d6d6;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3)>button{
    width: 105px;
    height: 28px;
    margin: 10px 10px 10px 35px;
    font-size: 12px;
    border-radius: 5px;
    background: #f1c454;
    background-image: linear-gradient(#F6Dc9c, #f1c454);
    border: 1px solid #999999;

}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2)>input{
    width: 356px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #999999;
}
.qd01_content>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1)>.qd01_select{
    background: #f4f5f7;
    border-radius: 4px;
    border: 1px solid #999999;
}
.qd01_content>.qd01_shopping{
    width: 75%;
    font-weight: bold;
    color: #665f5f;
    font-size: 16px;
    border-top: 2px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    padding: 18px;
}
.qd01_content>.qd01_rule{
    width: 75%;
    font-weight: bold;
    color: #665f5f;
    font-size: 16px;
    padding: 18px;
}
.qd01_feedback{
    width: 80px;
    height: 26px;
    color: #ffff;
    line-height: 26px;
    padding: 4px 7px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #008296;
    position: absolute;
    bottom: 0;
    left: 35px;
}
.qd01_feedback img{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
}
.qd01_rightBox{

}
.qd01_rightBox_titel{
    font-size: 19px;
    font-weight: bold;
}
.qd01_rightBox ul li span{
    color: #000000;
}
.qd01_select_middle{
    font-weight: bold;
    margin-top: 10px;
}