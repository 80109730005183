.return_settings{
  padding: 0 88px;
}

.return_settings_header{
  height: 40px;
  margin-top: 30px;
}
.return_settings_header strong {
  font-size: 26px;
  display: inline-block;
  margin-right: 20px;
}
.return_settings_header span {
  color: rgb(0,102,192);
}
.return_settings_header_tips{
  background: rgb(246,246,246);
  border: 1px solid rgb(187,211,222);
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
  margin: 20px 0;
}

.return_settings .ant-tabs{
  margin: 20px auto;
  border: 1px solid rgb(221,221,221);
}
.return_settings .ant-tabs-nav{
  background: rgb(243,243,243);
}
.return_settings .ant-tabs-tab-active{
  background: #fff;
  color: rgb(196,85,0);
  border-bottom: 2px solid rgb(196,85,0);
}
.return_settings .ant-tabs-tab-active .ant-tabs-tab-btn{
  color: rgb(196,85,0) !important;
}
.return_settings .ant-tabs-tab{
  padding: 12px 20px;
}