.a-tableform__container {
}

.a-tableform__container.wrapper-border {
  border: 1px solid #ccc;
}
.a-tableform__container.row-bottom-border .a-tableform__table tr {
  border-color: #ccc;
}

.a-tableform__header {
  background-color: #f5f5f5;
  height: 40px;
}
.a-tableform__row-item {
  box-sizing: border-box;
  padding: 18px 8px;
}

/* .a-tableform__body-row-item {
  padding-bottom: 0;
} */

.a-tableform__row-item.left {
  text-align: left;
}
.a-tableform__row-item.center {
  text-align: center;
}
.a-tableform__row-item.right {
  text-align: right;
}

.a-tableform__container table {
  width: 100%;
  box-shadow: none;
}

.a-tableform__table tr {
  height: auto;
}

.a-tableform__table tr:not(:last-child) {
  border-bottom: 1px solid transparent;
}

.a-tableform__table th {
  font-weight: normal;
  word-break: break-word;
}
.a-tableform__table td {
  font-weight: normal;
  word-break: break-word;
}

.a-tableform__table .ant-form-item {
  margin-bottom: 0;
}

.a-tableform__table .ant-form-item-control {
  position: relative;
}
.a-tableform__table .ant-form-item-explain {
  position: absolute;
  bottom: -16px;
}

.a-tableform__container .empty {
  max-height: 254px;
}
