

.A_body{
    width: 100%;
    padding: 0 20px;
}



.A_head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
}

.A_head_left{
    width: 50%;
    display: flex;
    align-items: center;
}
.A_head_left span{
    margin-left: 20px;
    color: #008296;
}
.A_right{
    width: 78%;
    display:flex;
    justify-content: center;
    align-items: center;
}


.A_right_inp{
    width: 65%;
    height: 36px;
    display: flex;
    border: 1px solid #008296;
    line-height: 36px;
}
.A_right_inp span{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.A_right_inp svg{
    width: 2em;
    height: 2em;
}
.A_right_inp input{
    width: 100%;
    outline-style: none;
    border: 0;
}

.A_right_or{
    margin: 0  20px;
    font-weight: bold;
    color: #dddddd;
}
.A_right_btn button{
    width: 116%;
    height: 36px;
    line-height: 36px;
    background-color: #008296;
    color: #ffffff;
    font-weight: bold;
}

.A_right_btn button a{
    color: #ffffff;
}
.A_head_bottom{
    margin: 30px 0;
}

.A_nav_box{
    border: 1px solid #dddddd;
}

.A_nav_head{
    width: 100%;
    border: 1px solid  #dddddd;
    background: #FAFAFA;
    padding: 0 20px;
    padding-bottom: 20px;

}

.A_nav_head h2{
    padding: 17px 0 8px 0;
    font-size: 16px;
    font-weight: bold;
}

.A_nav_headII{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.A_nav_inp{
    width: 40%;
    height: 36px;
    display: flex;
    border: 1px solid #008296;
    line-height: 36px;
}
.A_nav_inp input{
    width: 100%;
    outline-style: none;
    border: 0;
}
.A_nav_inp span{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.A_nav_inp span svg{
    width: 2em;
    height: 2em;
}
.A_nav_check{
    width: 25%;
}
.A_nav_check input{
    color: #008296;
    margin: 0 10px;
}
.A_nav_page{
    /* width: 18%; */
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}
/* .A_nav_page ul{
    margin: 0;
}
.A_nav_page ul li {
    display: inline-block;
    margin: 0 20px;
} */
.A_page_left {
    font-size: 20px;
    transform: rotate(-180deg);
    font-weight: bold;
}
.A_page_right {
    font-size: 20px;
    font-weight: bold;
}
.A_nav_name{
    height: 57px;
    background-color: #FAFAFA;
    padding: 0 20px ;
    color: #000000;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.A_nav_nameI{
    width: 50%;
    height: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.A_nav_nameI span svg{
    width: 21px;
    height: 21px;
    font-weight: bold;
}

.A_nav_nameright{
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.A_nav_nameII{
    width: 21%;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left:1px solid #dddddd ;
    padding-left: 20px;
}
.A_nav_ASIN{
    line-height: 37px;
    border-left:1px solid #dddddd ;
    padding-left: 20px;
    display: flex;
    justify-content: center;

}
.A_nav_itemI{
    width: 50%;
    height: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.A_nav_itemI a{
    color:#008296;
}
.A_nav_itemII{
    width: 19%;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}
.A_nav_ASINII{
    width: 10%;
    line-height: 37px;
    padding-left: 20px;
    display: flex;
    justify-content: center;
}
.A_nav_ASINII a{
    color: #008296;

}
.A_list_name{
    height: 57px;
    padding: 0 20px ;
    color: #000000;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}
.ant-tabs-tab-active{
    border-top:0 !important;

} 

.A_body .A_icon_sousuo svg{
    width: 36px;
    height: 36px;
    padding: 5px;
}
.A_body .A_alert_new{
    display: inline-block;
    border-radius: 12px;
    background-color: #008296;
    padding: 3px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
}
.A_body .ant-alert{
    border-color: #008296;
    margin-bottom: 30px;
}