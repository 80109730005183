.left-box {
  border-right: 1px solid #ccc;
}

.keyword-delivery-card .a-tabs .ant-tabs-nav-list {
  padding-left: 24px;
}

.keyword-delivery-card .a-tabs .ant-tabs-tabpane {
  padding: 0;
}
