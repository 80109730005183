.Content_body {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 500px;
}
.Content_box{
    width: 100%;
}
.Content_nav{
    display: flex;
    justify-content: center;
}
.Content_nav a{
    color: #000;
}
.Content_nav li{
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #dddddd;
    background-color: #fafafa;
    padding: 0 4px;
    background: #e8e8e8;
}


.content {
  width: 100%;
  height: 450px;
  border-top: 2px solid #fff;
}

.content1,
.content2 {
  display: none;
  word-wrap: break-word;
  width: 100%;
  height: 600px;
}
.content1:target,
.content2:target {
  display: block;

}
