.a-radio .description {
  margin-left: 24px;
  color: #999;
  font-size: 12px;
}
.a-radio .extra {
  font-size: 12px;
  color: #999;
  max-width: 260px;
  padding-left: 6px;
  border-left: 1px solid #ccc;
}
