.end06_mybody .mybody-ul {
    width: 390px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    cursor: pointer;
}

.RegCommentatorBox .mybody-ul li:nth-of-type(3),
.RegCommentatorBox .mybody-ul li:nth-of-type(1) {
    border-bottom: 0 !important;
}

.end06_mybody .mybody-ul li:nth-of-type(2) {
    border-bottom: 3px solid #FFBA53;
}

.a-size-large {
    font-size: 21px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.text-justify {
    text-align: justify;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.RegCommentatorBox .program-overview-values {
    width: 100%;
    padding: 20px;
    margin: auto;
    background: #f4f5f5;
}

.a-grid-vertical-align.a-row {
    width: 100%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
}

.a-grid-vertical-align.a-row>div.a-span4 {
    width: 34.93%;
}

.a-grid-vertical-align.a-row>div.a-column {
    float: none !important;
    display: table-cell !important;
    zoom: 1;
    padding-right: 14px;
}

.a-ws div.a-column,
div.a-column {
    margin-right: 2%;
    float: left;
    min-height: 1px;
    overflow: visible;
}

.a-row .a-span4,
.a-ws .a-row .a-ws-span4 {
    width: 31.948%;
}

.value-box {
    padding: 35px !important;
}

.a-text-center {
    text-align: center !important;
}

.reviews-increase-icon {
    height: 100px;
    background: url('~@/assets/images/regConnents1.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.reviews-faster-icon {
    height: 100px;
    background: url('~@/assets/images/regConnents2.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-easy-icon {
    height: 100px;
    background: url('~@/assets/images/regConnents3.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.a-section:last-child {
    margin-bottom: 0;
}

.program-overview-footer {
    padding: 45px;
}

.alternate-bg,
.program-overview-footer {
    background-color: #f4f5f5;
}

.RegCommentatorFooter {
    background-color: #fff;
}

.a-button-primary:hover {
    color: #000;
}

.a-section {
    margin-bottom: 22px;
}

.a-section h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    padding: 14px 18px 18px;
}

.a-text-beside-button {
    display: inline-block;
    position: relative;
    top: 1px;
    padding: 4px 0 0 6px;
    margin-right: 10px;
    font-size: 14px;
}

.SKUinput {
    background-color: #fff;
    height: 31px;
    padding: 3px 7px;
    line-height: normal;
}

.a-box .a-box-inner {
    border-radius: 4px;
    position: relative;
    padding: 14px 18px;
}

.a-padding-extra-large {
    padding: 20px 26px !important;
}

.a-text-center {
    text-align: center !important;
}

.a-spacing-extra-large,
.a-ws .a-ws-spacing-extra-large {
    margin-bottom: 32px !important;
}

.a-box {
    display: block;
    border-radius: 4px;
    border: 1px #ddd solid;
    background-color: #fff;
}

.zigeClass {
    margin-left: 15px;
    min-width: 145px;
}

.a-box .SKUinput {
    height: 30px;
    width: 250px;
    border-radius: 5px;
    padding-left: 10px;
    box-shadow: none;
    border-width: 1px;
}