.container {
  background-color: #f3f3f3;
}

.container .groupItem {
  cursor: pointer;
  border-left: 4px solid transparent;
  display: inherit;
}

.groupItem:global(.active) {
  border-left-color: rgb(50, 98, 149);
}

.groupItem:hover {
  background-color: #fff;
}

.groupList {
  width: 150px;
}
