
.information_preview-box{
    text-align: center;
    margin: 30px auto;
    width: 50%;
}
.information_preview{
    display: flex;
}
.information_preview p{
    text-align: left;
}
.information_preview > div {
    width: 220px;
    margin: 0 10px;
}
.information_preview_tabs{
    background-color: rgb(243, 243, 243);
    height: 24px;
    line-height: 24px;
    text-align: left;
}
.information_preview_tabs > span{
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #000;
    margin: 0 20px;
}
