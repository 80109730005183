.account_info h2{
  color: rgb(196,85,90);
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
}
.account_info_body{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.account_info .account_info_left{
  width: 400px;
}
.account_info .account_info_center{
  width: 600px;
}
.account_info .account_info_right{
  width: 400px;
}
.account_info_item{
  border: 1px solid rgb(221,221,221);
  margin-bottom: 20px;
}
.account_info_left_title{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding: 0 10px;
  border: 1px solid rgb(221,221,221);
  margin-bottom: 20px;
}
.account_info_left_title span {
  color: rgb(0,102,192);
}
.account_info_left_title div:nth-child(2), .account_info_item_title div:nth-child(2){
  color: rgb(0,102,192);
}
.account_info_item_title{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding: 0 10px;
  border: 1px solid rgb(221,221,221);
  background: rgb(242,242,242);
  height: 33px;
  line-height: 33px;
}
.account_info_left_text{
  display: flex;
  border-bottom: 1px solid rgb(221,221,221);
  padding: 10px 0;
}
.account_info_left_text div:nth-child(1){
  width: 150px;
  text-align: right;
  padding-right: 20px;
}
.account_info_center_text{
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.account_info_center_text div{
  width: 50%;
  margin-bottom: 10px;
}
.account_info_right_text{
  padding: 20px;
}
.account_info_right_text a{
  font-size: 12px;
  height: 34px;
  line-height: 34px;
  white-space:nowrap;
}
.account_info_right .account_info_item:nth-child(1) a{
  border-bottom: 1px solid rgb(0,102,192);
  font-weight: 700;
}
