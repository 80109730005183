/** *************************** 文字修饰 ***************************** **/

.wb-ba {
  word-break: break-all;
}

.wb-ka {
  word-break: keep-all;
}

.lh-12 {
  line-height: 12px;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-32 {
  line-height: 32px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

button[data=hovers-7]:hover {
  /* background-color: #ccc !important; */
  opacity: 0.7;
  color: #ffffff;
}

button[data=hovers-f]:hover {
  background-color: #E8F6F5 !important;
  border: 1px solid #28BBAC;
  color: #28BBAC !important;
}

button[data=hovers-c]:hover {
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc;
  color: #999999 !important;
}

.text-ellipsis-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis-2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis-3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

/** *************************** 文字修饰 ***************************** **/

/** *************************** 布局 ***************************** **/

/** ********** 溢出 ********** **/
.overflow-auto {
  overflow: auto;
}

a,
a:active,
a:link,
a:visited {
  text-decoration: none;
  color: #0066c0;
}
.link_a {
  text-decoration: none;
  color: #0066c0;
}

.register_style a:hover,
.link_a:hover {
  text-decoration: underline;
  cursor: pointer ;
  color: #c45500 ;
}
.register_style .ant-btn a:hover{
  text-decoration: none;
}

/** ********** 溢出 ********** **/

/* display */

.d-ib {
  display: inline-block;
}

.d-b {
  display: block;
}

.d-n {
  display: none;
}

/** ********** flex ********** **/
.df {
  display: flex;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-fe {
  align-items: flex-end;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.fd-c {
  flex-direction: column;
}

.f-1 {
  flex: 1;
}

.f-2 {
  flex: 2;
}

.f-3 {
  flex: 3;
}

.f-4 {
  flex: 4;
}

.f-5 {
  flex: 5;
}

.f-6 {
  flex: 6;
}

.f-7 {
  flex: 7;
}

.f-8 {
  flex: 8;
}

.f-9 {
  flex: 9;
}

.f-10 {
  flex: 10;
}

.f-11 {
  flex: 11;
}

.f-12 {
  flex: 12;
}

.f-13 {
  flex: 13;
}

.f-14 {
  flex: 14;
}

.f-15 {
  flex: 15;
}

.f-16 {
  flex: 16;
}

.f-17 {
  flex: 17;
}

.f-18 {
  flex: 18;
}

.f-19 {
  flex: 19;
}

.f-20 {
  flex: 20;
}

.f-21 {
  flex: 21;
}

.f-22 {
  flex: 22;
}

.f-23 {
  flex: 23;
}

.f-24 {
  flex: 24;
}

.fw-n {
  flex-wrap: nowrap;
}

.fw-w {
  flex-wrap: wrap;
}

/** ********** flex ********** **/

/** ********** 定位 ********** **/
.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}

.p-f {
  position: fixed;
}

.p-s {
  position: sticky;
}

.l-0 {
  left: 0;
}

.t-0 {
  top: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

/** ********** 定位 ********** **/

/** *************************** 布局 ***************************** **/

/** *************************** 边距和宽高 ***************************** **/

.p-0 {
  padding: 0;
}

.p-12 {
  padding: 12px;
}

.p-18 {
  padding: 18px;
}

.p-24 {
  padding: 24px;
}

/* horizontal */
.ph-6 {
  padding: 0 6px;
}

.ph-12 {
  padding: 0 12px;
}

.ph-24 {
  padding: 0 24px;
}

/* vertical */
.pv-12 {
  padding: 12px 0;
}

/* vertical */
.pv-24 {
  padding: 24px 0;
}

.pl-6 {
  padding-left: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.pl-12 {
  padding-left: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pr-24 {
  padding-right: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.m-0 {
  margin: 0;
}

.mh-auto {
  margin: 0 auto;
}

.mh-12 {
  margin: 0 12px;
}

.mh-24 {
  margin: 0 24px;
}

.ml-6 {
  margin-left: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.ml-12 {
  margin-left: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.w-100vw {
  width: 100vw;
}

.h-100vh {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.h-12 {
  height: 12px;
}

.h-24 {
  height: 24px;
}

.h-44 {
  height: 44px;
}

.h-254 {
  height: 254px;
}

.w-100percent {
  width: 100%;
}

.h-100percent {
  height: 100%;
}

/** *************************** 边距和宽高 ***************************** **/

/** *************************** 颜色 ***************************** **/

.c-primary {
  color: #c45500;
}

.c-ccc {
  color: #ccc;
}

.c-999 {
  color: #999;
}

.c-666 {
  color: #666;
}

.c-333 {
  color: #333;
}

.c-000 {
  color: #000;
}

.bg-primary {
  background-color: #c45500;
}

.bg-white {
  background-color: #fff;
}

.bg-f8 {
  background-color: #f8f8f8;
}

.bg-f7 {
  background-color: #f7f7f7;
}

.bg-fa {
  background-color: #fafafa;
}

.bg-fb {
  background-color: #fbfbfb;
}

/** *************************** 颜色 ***************************** **/

/** *************************** 边框 ***************************** **/

.border-radius-4 {
  border-radius: 4px;
}

/* f0f0f0 */
.border-f0 {
  border: 1px solid #f0f0f0;
}

.border-b-f0 {
  border-bottom: 1px solid #f0f0f0;
}

.border-l-f0 {
  border-left: 1px solid #f0f0f0;
}

.border-t-f0 {
  border-top: 1px solid #f0f0f0;
}

.border-r-f0 {
  border-right: 1px solid #f0f0f0;
}

/* #ccc */
.border-ccc {
  border: 1px solid #ccc;
}

.border-b-ccc {
  border-bottom: 1px solid #ccc;
}

.border-l-ccc {
  border-left: 1px solid #ccc;
}

.border-t-ccc {
  border-top: 1px solid #ccc;
}

.border-r-ccc {
  border-right: 1px solid #ccc;
}

/** *************************** 边框 ***************************** **/

/** *************************** 投影 ***************************** **/
.box-shadow-cd {
  /* box-shadow: 2px 2px 4px 2px #cdcdcd; */
  box-shadow: 1px 1px 4px 1px #cdcdcd;
}

/** *************************** 投影 ***************************** **/
.ant-message {
  z-index: 2070;
}