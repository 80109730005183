.tourBox{
  border-radius: 5px;
  /* animation-name: fadeInRightBig; */
  /* animation-duration: .3s; */
  /* top: 180px;
  right: 100px; */
}

.tourBox .jixURJ{
  top: 10px;
  right: 10px;
}
.tourBox .ftZxKx{
  justify-content: center;
}
.animationTour .dQPfSq {
  width: 50px;
  height:50px;
  z-index: 9;
  top: 204px;
  right: 125px;
  left: auto;
}
.animationTour .dQPfSq svg,
.animationTour .dQPfSq svg rect{
  width: 50px;
  height:50px;
  z-index: 9;
  top: 180px;
  right: 100px;
}

  .animationBox{
    width: 30px;
    height: 30px;
    min-width:auto;
    overflow: hidden;
    border-radius: 11%;
    top: 62px;
    z-index: 9;
    left: 649px;
  }
  .dQPfSq,
  .dQPfSq svg,
  .dQPfSq svg rect{
    animation-duration: .3s;
    animation-fill-mode: both;
  }
  @keyframes fadeInRightBig {
    0%{
      opacity: 0;
      width: 0;
      height: 0;
      transform: translate3d(1800px,100px,0);
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes fadeInRightBig1 {
    0%{
      opacity: 0;
      width: 0 !important;
      height: 0 !important;
      transform: translate3d(1800px,100px,0);
    }
    100%{
      /* opacity: 0.7; */
    }
  }
    @keyframes fadeInRightBig2 {
    100%{
      opacity: 0;
      transform: translate3d(1800px,100px,0);
    }
  }
