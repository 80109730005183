.AdvertisingPageLayout-box {
  display: flex;
}
.AdvertisingPageLayout-box-left {
  width: 35px;
  line-height: normal;
  background-color: #000;
  color: #fff;
  padding: 10px;
}
.AdvertisingPageLayout-box-right {
  width: 100%;
  height: 100%;
  padding-left: 2%;
}
.box-right-head {
  width: 100%;
  height: 55px;
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  background-color: #005a95;
  position: fixed;
  z-index: 9;
}
.box-right-head-right {
  display: flex;
}
.box-right-head-left {
  color: #fff;
  font-size: 16px;
  padding: 0 20px;
}
.box-right-head-right div {
  margin-right: 22px;
}
.icons-list {
  color: #fff;
}
.AdvertisingPageLayout-Navigation {
  width: 100%;
  background-color: #f8f8f8;
}
.main-box {
  display: flex;
  padding-top: 55px;
}

.AdvertisingPageLayout-Navigation-box {
  width: 100%;
}
.AdvertisingPageLayout-Navigation-box .ant-tabs-tabpane:nth-child(1) {
  padding: 0 !important;
}
