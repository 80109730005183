
.n_o_notice{
  padding: 0 20px;
}

.n_o_notice .ant-alert{
  background: rgb(243,243,243);
  border: 1px solid rgb(187,211,222);
}
.n_o_notice_panel_title{
  padding-left: 20px;
  font-size: 12px;
}
.n_o_notice_item{
  border: 1px solid rgb(221,221,221);
  margin-top: 20px;
}
.n_o_notice_item_header{
  display: flex;
  justify-content: space-between;
  background: rgb(243,243,243);
  border: 1px solid rgb(221,221,221);
  padding: 10px 20px;
  
}
.n_o_notice_item_header button{
  margin-right: 20px;
}
.n_o_notice_item_body{
  padding: 10px 20px;
}