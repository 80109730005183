.selectedGoodsList {
  height: 100%;
}

.operaBar {
  padding: 0 12px;
}

.goodsList {
  overflow: auto;
  height: 462px;
  padding: 0 12px;
}

.goodsList > div.goods-item {
  border-bottom: none;
}
