@import "~antd/dist/antd.css";
@import "./animate.css";

.App {
  text-align: center;
}

.purposeTipsBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EEB95D;
  color: #FFF;
  position: fixed;
  z-index: 1060;
}

.purposeTipsBox img {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.purposeTipsBox+div {
  padding-top: 40px;
}

#root .operationBtn {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: center;
  margin-top: 32px;
  height: 150px;
}

#root .operationBtn .previousPage,
.ymx-modal .operationBtn .previousPage {
  background: linear-gradient(180deg, #f7f8fa, #e7e9ec);
  border: 1px solid #adb1b8 !important;
  margin-right: 2%;
  width: 185px;
  height: 35px;
  box-shadow: 0 1px 2px 0 #b5b5b5;
  color: #333;
  border-radius: 2px;
}

.ymx-modal .operationBtn .previousPage {
  width: 118px;
  background-color: #e7e9ec;
  height: 31px;
}

#root .operationBtn .previousPage:hover,
.ymx-modal .operationBtn .previousPage:hover {
  background: #e1e4e9;
}

#root .operationBtn .next,
.ymx-modal .operationBtn .next {
  background: linear-gradient(180deg, #f7dfa5, #f0c14b);
  border: 1px solid #a88734;
  margin-right: 2%;
  width: 185px;
  height: 35px;
  margin-top: 0;
  color: #333;
  box-shadow: 0 1px 2px 0 #b5b5b5;
  border-radius: 2px;
}

#root .operationBtn .next:hover,
.ymx-modal .operationBtn .next:hover {
  background: linear-gradient(to bottom, #f0c14b, #f0c14b);
}

#root .operationBtn .next.disabled,
.ymx-modal .operationBtn .next.disabled {
  background: #f9f3e5;
  border-color: #e8e0cd;
}

.ymx-modal .operationBtn .next {
  width: 169px;
  height: 31px;
}

.ymx-modal .operationBtn {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: center;
  margin-top: 0px;
}

.ymx-modal {
  border-radius: 4px;
}

.ymx-modal .ant-modal-header,
.ymx-modal .ant-modal-footer {
  background-color: #f3f3f3 !important;
  border-radius: 4px;
  border: 1px #ddd solid;
}

.register_tooltip .ant-tooltip-inner {
  color: #333;
}

.a-icon-alert {
  height: 27px;
  width: 30px;
  position: absolute;
  left: 18px;
  top: 11px;
  background-image: url('~@assets/images/Amazon.png');
  -webkit-background-size: 400px 670px;
  background-size: 400px 670px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
  background-position: -248px -35px;
}

.anticon-question-circle {
  color: rgb(204, 204, 204);
  margin-left: 4px;
}