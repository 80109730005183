* {
	margin: 0;
	padding: 0;
}

ul li {
	list-style: none;
}
.kc03_cont{
	width: 95%;
}
.kc03_myhead{
	background: #131921;
	color: #fff;
}
.kc03_myhead ul{
	display: flex;
}
.kc03_myhead ul:first-of-type{
	padding-left: 20px;
}
.kc03_myhead ul:first-of-type li{
	margin-right: 30px;
}
.kc03_myhead ul li>div{
	font-size: 14px;
	font-weight: bold;
}
.kc03_myhead ul li>div:first-of-type{
	font-size: 12px;
	margin-top: 10px;
	font-weight:400;
}
.kc03_head_search{
	border-radius: 8px;
	overflow: hidden;
}
.kc03_sel{
	padding:12px 20px 12px 10px;
	font-size: 12px;
	color: #505050;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	/* background: url(../img/下拉.png) center no-repeat #e6e6e6; */
	background-position: 99% 50%;
	background-size: 16px;
	border-right: 1px solid #999999 ;
	vertical-align: middle;
}
.kc03_head_search input{
	width: 820px;
	height: 38px;
	border: none;
	vertical-align: middle;
}
.kc03_head_search span{
	display: inline-block;
	width: 45px;
	height: 38px;
	background-color: #febd69;
	vertical-align: middle;
}
.kc03_head_search img{
	margin: 2px 0 0 5px;
}
.kc03_us{
	margin-top: 20px;
}
.kc03_myhead ul:last-of-type{
	background-color: #232f3e;
	font-size: 14px;
	padding: 5px 0;
}
.kc03_myhead ul:last-of-type li{
	height: 20px;
	text-align: center;
	padding: 5px 10px;
	line-height: 20px;
}
.kc03_myhead ul:last-of-type li img{
	vertical-align: middle;
}
.kc03_border{
	border: 1px solid #fff ;
}
.kc03_nav{
	display: flex;
	background: #fdfdfd;
	font-size: 12px;
	padding: 10px 10px;
	justify-content: space-between;
	line-height: 40px;
}
.kc03_nav li:first-of-type{
	font-size: 20px;
	font-weight: bold;
	vertical-align: middle;
}
.kc03_nav li:last-of-type{
	font-size: 16px;
	font-weight: 600;
	color: #1cc6f5;
}
.kc03_nav li:last-of-type span{
	color: #000;
}
.kc03_nav li:last-of-type div{
	line-height: 26px;
}
.kc03_nav li:last-of-type div:last-of-type{
	color: #999999;
	font-size: 12px;
	text-align: center;
	line-height: 0px;
	font-weight: 400;
}
.kc03_banner{
	height: 57px;
	background: #f0f0f0;
	display: flex;
	line-height: 57px;
}
.kc03_banner >div{
	margin-right: 50px;
}
.kc03_banner >div:first-of-type{
	margin-left: 400px;
}
.kc03_star{
	display: flex;
	line-height: 10px;
}
.kc03_star>div img{
	margin-top: 5px;
	width: 26px;
}
.kc03_star>div{
	margin-right: -10px;
}
.kc03_comment{
	line-height: 20px;
	font-size: 14px;
}
.kc03_banner_text{
	color: #7a0000;
}
.kc03_star_num{
	color: #2b2b2b;
	font-size: 13px;
	margin: 13px 0 0 12px;
}
.kc03_comment span{
	font-size: 12px;
	color: #1CC6F5;
	font-weight: bold;
}
.kc03_comment span img{
	vertical-align: middle;
}
.kc03_banner_btm{
	padding: 10px 0 20px 0;
	font-size: 12px;
	color: #505050;
	margin-left: 150px;
}
.kc03_showlist{
	display: flex;
	margin: 0 163px 0 163px;
	padding-bottom: 40px;
	border-bottom: 1px solid #8b8b8b;
}
.kc03_showlist>div:first-of-type{
	margin-top: 50px;
}
.kc03_showlist>div:nth-of-type(2){
	margin: 100px 30px 0 10px;
}
.kc03_showlist_text{
	font-size: 14px;
}
.kc03_showlist_text>div:first-of-type{
	color: #008e95;
}
.kc03_showlist_text>div:nth-of-type(2){
	font-size: 24px;
	color: #505050;
	padding-bottom: 20px;
	border-bottom: 1px solid #bcbcbc;
}
.kc03_showlist_text>div:nth-of-type(3){
	margin-top: 10px;
	font-size: 18px;
	color: #a60000;
}
.kc03_showlist_text>ul li{
	margin-left: 20px;
	width: 400px;
	list-style-type:disc;
/* 	list-style-position: inside; */
	line-height: 20px;
}
.kc03_showlist_text>div:nth-of-type(5){
	margin-top: 20px;
	color: #008e95;
	padding-bottom: 10px;
	border-bottom: 1px solid #bcbcbc;
}
.kc03_showlist_text div:nth-of-type(5) img{
	vertical-align: middle;
	margin-right: 5px;
}
.kc03_showlist_text>div:nth-of-type(6){
	padding: 10px 10px 0;
	margin-top: 20px;
	width: 415px;
	display: flex;
	border: 1px solid #8c8c8c;
}
.kc03_showlist_right{
	margin-left: 30px;
	font-size: 14px;
}
.kc03_showlist_right>div:first-of-type{
	border-radius: 10px;
	border: 1px solid #8c8c8c;
	text-align: center;
	padding: 20px 20px 15px;
}
.kc03_showlist_right>div:first-of-type>div:first-of-type{
	color: #7A0000;
	font-weight: 600;
}
.kc03_showlist_right>div:first-of-type>div:nth-of-type(3){
	color: #008e95;
	font-size: 12px;
	text-align: left;
	position: relative;
	margin-left: 20px;
	padding: 10px 0;
}
.kc03_showlist_right>div:first-of-type>div:nth-of-type(3) img{
	position: absolute;
	left: -20px;
}
.kc03_showlist_right>div:first-of-type>div:nth-of-type(4){
	border-top: 1px solid #7a7a7a;
	padding-top: 15px;
	position: relative;
}
.kc03_sel_1{
	font-weight: 600;
	padding: 8px 10px;
	width: 200px;
	/* background: url(../img/下拉箭头.png) center no-repeat #eff1f3; */
	background-position: 96% 50%;
}
.kc03_showlist_right>div:first-of-type>div:nth-of-type(4)::before{
	content: "";
	width: 1px;
	height: 30px;
	position: absolute;
	right: 40px;
	background-color: #9c9c9c;
}
.kc03_sel_2{
	margin-top: 10px;
	border-radius: 10px;
	border-top: 1px solid #cfcfcf;
}
.kc03_showlist_right>div:nth-of-type(2){
	text-align: center;
	color: #006367;
	font-size: 12px;
	margin: 10px 0;
}
.kc03_showlist_right>div:nth-of-type(2) img{
	vertical-align: middle;
	margin-right: 5px;
}
.kc03_showlist_right>div:nth-of-type(3){
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #4f4f4f;
}
.kc03_showlist_right>div:nth-of-type(3)>div:first-of-type{
	text-align: center;
}
.kc03_text_list{
	margin: 0 163px;
	padding: 20px 5px;
	border-bottom: 1px solid #4f4f4f;
}
.kc03_text_list>div:first-of-type{
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 10px;
}
.kc03_text_list>ul li{
	list-style-type:disc;
	list-style-position: inside;
	font-size: 14px;
}
.kc03_text_list span{
	font-weight: 600;
	color: #009095;
}
.kc03_text_list>div:nth-of-type(2){
	font-size: 12px;
	margin-bottom: 10px;
}
.kc03_text_list>div:nth-of-type(3){
	width: 800px;
	padding: 5px;
	font-size: 12px;
	color: #989898;
	border: 1px solid #222222;
}
.kc03_text_list div:nth-of-type(3) img{
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
.kc03_text_list_2{
	padding: 35px 0;
}
.kc03_text_list_3>div:nth-of-type(2){
	font-size: 14px;
	padding-left: 40px;
}
.kc03_text_list_3>div:first-of-type{
	font-size: 20px;
}
.kc03_text_list_4>div:nth-of-type(2){
	padding-left: 20px;
	font-size: 14px;
	line-height: 24px;
}
.kc03_text_list_4{
	border: none;
}
.kc03_text_list_4 span{
	color: #000000;
}
.kc03_text_list_5>div>div{
	border: none;
	color: #000000;
	font-size: 18px;
	font-weight: 600;
	padding: 0;
}
.kc03_text_list_5>div:nth-of-type(2){
	margin: 0;
}
.kc03_text_list_5>div:nth-of-type(3){
	border: none;
	color: #000000;
	font-size: 14px;
	padding: 0;
}
.kc03_text_list_5 span{
	margin-left: 10px;
	font-size: 14px;
	font-weight: 400;
}
.kc03_text_list_6{
	margin-top: 20px;
	border-top: 1px solid #4f4f4f;
}
.kc03_text_list_6>div:nth-of-type(4){
	margin:30px 0 0 60px;
	font-size: 14;
	color: #353535;
}
.kc03_text_list7{
	border: none;
}
.kc03_text_list7 div:nth-of-type(2){
	font-size: 14px;
}
.kc03_text_list7 div:nth-of-type(2)>div{
	margin-bottom: 10px;
	color: #4f4f4f;
}
.kc03_text_list7 div:nth-of-type(2)>div>div{
	width: 180px;
	height: 20px;
	background: #f0f2f2;
	display: inline-block;
	margin: 0 20px;
	border: 1px solid #999999;
	border-radius: 5px;
	line-height: 20px;
	vertical-align: text-bottom;
}
.kc03_text_last{
	text-align: left;
	color: #008E95 !important;
}
.kc03_text_last img{
	vertical-align: middle;
	width: 8px;
	margin-right: 2px;
}
.kc03_text_list7>div>span{
	font-size: 14px;
	color: #000000;
	margin-left: 240px;
}
.kc03_text_list8{
	width: 300px;
	border-top:1px solid #999999;
	padding: 40px 0;
}
.kc03_text_list8>div:first-of-type{
	font-size: 18px;
}
.kc03_text_list8>div:nth-of-type(3){
	color: #000000;
	width: 300px;
	text-align: center;
	padding: 8px 0;
	background: #edeef1;
	margin-top: 20px;
}
.kc03_text_list_img{
	width: 300px;
	margin-left: 163px;
	border: 1px solid #9C9C9C;
	margin-top: 40px;
	padding: 10px;
	box-sizing: border-box;
	position: relative;
}
.kc03_text_list_img>div:first-of-type{
	text-align: center;
}
.kc03_star_btn{
	font-size: 12px;
	padding: 3px 20px;
	position: absolute;
	border: 1px solid #986500;
	background: #fdcb56;
	right: 10px;
	bottom: 15px;
}
.kc03_text_list_last{
	margin-top: 20px;
	width: 300px;
}
.kc03_bottom_bar{
	margin-top: 80px;
	text-align: center;
	padding-bottom:60px;
	border-bottom: 1px solid #9C9C9C;
}
.kc03_myfoot{
	text-align: center;
	
}
.kc03_myfoot>div:first-of-type{
	font-size: 14px;
	color: #FFFFFF;
	padding: 15px 0;
	background: #37475a;
}
.kc03_myfoot>div:nth-of-type(2){
	background:#232f3e;
}
.kc03_foot_list{
	width: 980px;
	margin: 0 auto;
	text-align: left;
	font-size: 14px;
	display: flex;
	color: #d9d9d9;
	padding: 50px 0;
	
}
.kc03_foot_list ul{
	margin-right: 120px;
}
.kc03_foot_list ul li:first-of-type{
	color: #FFFFFF;
	font-weight: 600;
}
.kc03_foot_list ul li{
	margin-bottom: 10px;
}
.kc03_foot_mid{
	border-top: 1px solid #7f7f7f;
	padding: 30px 0 45px;
}
.kc03_foot_mid>img{
	vertical-align: middle;
	margin-right: 80px;
}
.kc03_foot_mid>div{
	padding:5px 10px;
	display: inline-block;
	color: #cacaca;
	font-size: 13px;
	border: 1px solid #d9d9d9;
}
.kc03_foot_mid>div img{
	vertical-align: middle;
}
.kc03_foot_mid>div span{
	margin:0 20px 0 5px;
}
.kc03_myfoot>div:nth-of-type(3){
	padding: 30px 0;
	background: #131A22;
}
.kc03_foot_list2{
	width: 980px;
	margin: 0 auto 20px;
	text-align: left;
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	color: #b1b1b1;
}
.kc03_foot_list2>div>ul{
	margin-bottom: 20px;
}
.kc03_foot_list2>div>ul li:first-of-type{
	color: #FFFFFF;
}
.kc03_bq{
	font-size: 12px;
	color: #a8a8a8;
}
.kc03_bq span{
	color: #dedede;
	margin-left: 10px;
}
	