.atAglancetab3Box table tr td,
.atAglancetab3Box table tr th{
    text-align: center;
    padding: 5px;
}
.atAglancetab3Box table tbody tr:first-child{
    background-color: #dfdfdf;
}
.atAglancetab3Box table tr td:nth-child(2){
    width: 200px;
}