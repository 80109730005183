.BuyerMessage_box{
    width: 100%;
    
}
.BuyerMessage_box>div{
    display: flex;
}
.BuyerMessage_box .bolds{
    font-weight: bold;
}
.BuyerMessage_box .fz15{
    font-size: 15px;
}
.BuyerMessage_left,
.BuyerMessage_right{
    border: 1px solid #dddddd;
    width: 18% ;
}
/* titleBolds */
.BuyerMessage_box .BuyerMessage_content{
    width: 64% ;
}
.BuyerMessage_left_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background: #008296;
}

.BuyerMessage_left_titel{
    color: #ffffff;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
}
.BuyerMessage_box .BuyerMessage_left .titleBolds span{
    font-weight: bold;
}

.BuyerMessage_left_select select option{
    background: #000;
}
.BuyerMessage_box .BuyerMessage_left .ant-list-items,
.BuyerMessage_box .BuyerMessage_left .minHeighg520{
    height: 520px;
    margin-bottom: 30px;
    overflow-y: scroll;
}
.BuyerMessage_box .BuyerMessage_left .ant-list-items .ant-list-item{
    padding: 15px;
    cursor: pointer;
}
.BuyerMessage_box .BuyerMessage_left .listMsg{
    background-color: #e3eced
}
.BuyerMessage_box .BuyerMessage_left .listSearch .ant-input-group{
    padding: 10px;
}
.BuyerMessage_box .BuyerMessage_left .listSearch .ant-btn-icon-only{
    width: 60px;
}
.BuyerMessage_box .BuyerMessage_content .BuyerMessage_content_title{
    padding: 15px;
    border: 1px solid #dddddd;
}
.BuyerMessage_box .BuyerMessage_content .BuyerMessage_content_title p{
    font-size: 14px;
}
.BuyerMessage_box .BuyerMessage_content_text{
    padding: 20px;
    border: 1px solid #dddddd;
    height: 370px;
    overflow-y: scroll;
}
.BuyerMessage_content_text .content_text_left,
.BuyerMessage_content_text .content_text_right{
    display: flex;
    padding-bottom: 30px;
}
.BuyerMessage_content_text .content_text_right{
    justify-content: flex-end;
}
.BuyerMessage_content_text .content_text_right p{
    text-align: right;
}
.BuyerMessage_content_text .content_text_left>div,
.BuyerMessage_content_text .content_text_right>div{
    max-width: 48%;
}
.BuyerMessage_content_text .content_text_left>div div,
.BuyerMessage_content_text .content_text_right>div div{
    border: 1px solid #dddddd;
    padding: 5px 10px;
}
.BuyerMessage_content .text_operation{
    padding: 10px;
}
.BuyerMessage_content .text_operation_select{
    margin-bottom: 15px;
}
.BuyerMessage_content .text_operation .text_operation_textArea textarea{
    min-height: 120px;
}
.BuyerMessage_content .text_operation_button{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.BuyerMessage_content .text_operation_button div:last-child button{
    margin-left: 10px;
}
.BuyerMessage_right .linkBox p{
    color: #008296;
    cursor: pointer;
}
.BuyerMessage_right .linkBox .testBox{
    margin-left: 10px;
    background-color: red;
    color: #ffffff;
    border-radius: 20px;
    font-weight: bold;
    padding: 5px 10px;
}
.BuyerMessage_right .BuyerMessage_right_title{
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    font-weight: bold;
}
.BuyerMessage_right .BuyerMessage_right_title span{
    font-size: 15px;
}
.BuyerMessage_box .BuyerMessage_right{
    padding: 10px;
}
.BuyerMessage_box .BuyerMessage_right .orderBox div{
    margin-bottom: 5px;
    color: #667575;
}
.BuyerMessage_box .noMsgBox{
    padding-top: 50px;
    font-size: 24px;
    /* color: #c6b0d2; */
    text-align: center;
}


