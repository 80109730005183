.All_box{
    padding:  0 12px;
    margin-bottom: 40px;
}

.All_head{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    height: 37px;
}

.All_head_btn{
    width: 60%;
}
.All_head_btn button{
    margin-right: 5px;
    width: 90px ;
    height: 30px;
}
.All_head_right{
    width: 50%;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.All_head_right_divI{
    width: 45%;
}
.All_head_right_divI span{
    margin-left: 3px;
}
.All_content{
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    height: 98px;
}
.All_content div{
    font-size: 14px;

}

.All_bottom{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.All_bottom_page{
    display: flex;
    align-items: center;
    width: 95%;
    padding: 0 0 0 45%;
}
.All_bottom_page div{
    margin: 0 10px;
    
}
.All_bottom_btn{
    position: relative;
}
.All_bottom_btn button{
    width: 114px;
    height: 26px;
}
.All_bottom_btn button:focus {
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
.All_bottom_btn_select{
    width: 114px;
    height: 62px;
    display: none;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 0px;
    z-index: 13;
    background: #fff;
}
.All_bottom_btn_select div:hover{
    border: 1px solid #dddddd;
    background: #dddddd;
}
.All_bottom_btn:hover .All_bottom_btn_select{
    display: block;

}