.register-collapse {
  background-color: #fff;
  border-left: 1px solid #d5dbdb;
}

.register-collapse a {
  color: #0066c0;
  cursor: pointer;
  font-size: 14px;
}
.register-collapse .ant-collapse-borderless > .ant-collapse-item{
  border-bottom: 1px solid #d5dbdb;
  
}
.register-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: #0066c0;
  padding-bottom: 0;
  padding-top: 18px;
}
.register-collapse .ant-collapse-content > .ant-collapse-content-box{
  padding-left: 40px;
  padding-bottom: 0px;
}
.register-collapse.ant-collapse-borderless > .ant-collapse-item{
  padding-bottom: 18px;
}