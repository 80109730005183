.kc33_tab{
    min-width: 1650px;
    padding: 0px 20px;
    margin: 0 20px;
}
.kc33_tab>.kc33_tab_list{
    margin-top: 15px;
    display: flex;
    list-style: none;
    border-bottom: 3px solid rgba(0, 0,.0, 0.3);
    font-size: 18px;
    height: 35px;
}
.kc33_tab_list>li{
    margin-right: 30px;
    position: relative;
}
.kc33_tab_list>li:nth-of-type(1){
    display: flex;
    color: #CC6600;
    height: 35px;
}
.kc33_tab_list>li:nth-of-type(1) img{
    width: 10px;
    position:absolute;
    bottom: 0;
    left: 150px;
}
.kc33_tab_list>li:nth-of-type(1) div:nth-of-type(1){
    margin-right: 30px;
}
.kc33_tab_list>li:not(:nth-last-of-type(1)){
    border-bottom: 4px solid #CC6600;
}
.kc33_sp_text{
    margin-top: 30px;
}
.kc33_tx_1>h3{
    font-size: 18px;
}
.kc33_tx_1>p{
    font-size: 14px;
}
.kc33_tx_1>p:nth-of-type(1){
    margin-top: 30px;
}
.kc33_tx_1>p:nth-of-type(2){
    margin-top: 30px;
}
.kc33_tx_2{
    margin: 30px 40px 0;
}
.kc33_box1 p{
    font-weight: 600;
}
.kc33_box1 input{
    margin-left: 5px;
    margin-right: 5px;
}
.kc33_box2{
    background: #F3F3F3;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
}
.kc33_box2 span{
    color: #1275C6;
}
.kc33_box2>p{
    font-size: 14px;
}
.kc33_list{
    margin: 30px 20px;
    font-size: 14px;
}
.kc33_fenlei{
    margin-top: 20px;
    display: flex;
}
.kc33_fenlei p{
    font-weight: 600;
}
.kc33_fenlei ul{
    margin-right: 300px;
    line-height: 25px;
    padding-left: 20px;
}
.kc33_jiage{
    margin-top: 30px;
}
.kc33_jiage>p{
    margin-top: 30px;
    font-size: 14px;
}
.kc33_jiage>p:nth-of-type(3){
    font-weight: 600;
}
.kc33_jiage_ul{
    margin: 10px 20px 0;
}
.kc33_jiage_ul>ul{
    list-style: none;
    display: flex;
    border: 1px solid #ccc;
}
.kc33_jiage_ul>ul li{
    width: 450px;
    padding: 10px 20px;
    font-size: 14px;
}
.kc33_jiage_ul>ul li:not(:nth-of-type(1)){
    border-left: 1px solid #ccc;
}
.kc33_jiage_ul>ul:nth-of-type(1){
    border-bottom: none;
    background: #F2F2F2;
}
.kc33_jiage_ul>ul:nth-of-type(2) li:nth-of-type(even){
    background: #F2F2F2;
}
.kc33_btn{
    margin-top: 30px;
    position: relative;
    padding-bottom: 300px;
}
.kc33_btn button:nth-of-type(1){
    padding: 7px 13px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0,.0, 0.3);
}
.kc33_btn button:nth-of-type(2){
    padding: 7px 10px;
    position: absolute;
    right: 0;
    background-color: #F1C454;
    border-radius: 3px;
    border: 1px solid rgba(0, 0,.0, 0.3);
}