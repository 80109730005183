



.Archlve_navBox{
    border: 1px solid #dddddd;
    background: #f2f2f2;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 30px;
}
.Archlve_seleBoxII{
    display: flex;
    align-content: center;
}


.Archlve_navBox_inp{
    margin-bottom: 10px;
}
.Archlve_navBox_inp input{
    width: 250px;
    height: 30px;
    margin-right: 10px;
    padding: 0 10px;
}
.Archlve_navBox_inp button{
    width: 65px;
    height: 30px;
}


.Archlve_seleBox{
    height: 40px;
    border-radius: 4px;
    padding-left: 30px;
    background: #E7E7E7;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Archlve_sele{
    border: 0;
    background: #E7E7E7;

}
.Archlve_select{
    padding: 0 20px;
    border-left:1px solid #000000 ;
    border-right:1px solid #000000 ;
}
.Archlve_selectI{
    margin-right:  20px;
}
.Archlve_selectII{
    margin-left: 20px;
}
.Archlve_btn{
    margin-right: 10px;
}
.Archlve_btn button{
    background: #ffffff;
    outline-style: none;
    border: 0;
    height: 27px;
    width: 84px;
}