.stepBar{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 30px auto;

}
.stepBar .ant-steps{
    width: 80%;
}
.addCouponsBox .end05_myBody_header_title button{
    margin-right: 0;
}
.stepBar .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: red;
    border-color: red;
}
.addCouponsBox .end05_con_search_bar{
    display: block;
    border: none;
}
.addCouponsBox .end05_con_search_bar input{
    width: 200px;
    margin-right: 20px;
}
.addCouponsBox .end05_con_search_bar select{
    height: 30px;
    line-height: 30px;
}
.addCouponsBox .end05_con_search_bar button{
    margin-left: 0;
}
.addCouponsBox .couponsConentBox,
.addCouponsBox .addCouponsTwo{
    display: flex;
}
.couponInfoLeft{
    width: 34%;
    min-width: 30%;
    border-right: 1px solid #00000050;
    padding-right: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;

}
.couponInfoLeft .couponTitle{
    font-size: 26px;
}
.couponInfoLeft .couponDesc{
    margin: 25px 0;
}
.couponInfoLeft .searchResultBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #00000050;
}
.searchResultBox .delSearch{
    color: rgb(0, 130, 150);
    font-weight: bold;
    margin-left: 15px;
    cursor: pointer;
}
.buttomPro img{
    width: 70px;
    height: 50px;
    margin-right: 15px;
}
.buttomPro .buttomProItem{
    display: flex;
    border: 1px solid #00000050;
    padding: 10px;
    margin-top: 15px;
}
.buttomPro .addCouponBtn{
    width: 140px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #00000050;
    text-align: center;
    border-radius: 3px;
    background: rgb(221, 224, 229);
    cursor: pointer;
}

.buttomPro .buttomProLift{
    display: flex;
}
.couponInfoRight{
    margin-left: 20px;
    border-right: none;
}
.addCouponsBox .couponInfoRight .end05_con_search_bar input{
    width: 80%;
}
.addCouponsTwo .couponInfoLeft{
    width: 50%;
}
.addCouponsTwo .addCouponsTwoRadio .ant-radio-wrapper{
    display: block;
    margin-bottom: 10px;
}
.addCouponsTwo .addCouponsTwoPrice{
    margin-top: 60px;
    border-bottom: 1px solid #000000;
    padding-bottom: 20px;
}
.addCouponsTwo .addCouponsTwoPrice .ant-input-affix-wrapper{
    width: 10%;

}
.addCouponsTwo .bottomQuestion p{
    font-weight: bold;
    margin: 30px 0;
}
.addCouponsTwo .bottomDesc{
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 60%;
    margin-top: 20px;
}
.addCouponsTwo .bottomDesc p{
    font-weight: bold;
}
.addCouponsTwo .bottomDesc ul li{
    list-style:outside;
    margin-left: 25px;
}
.addCouponsThree .addCouponsTwoPrice .ant-input-affix-wrapper{
    width: 70%;
    margin-right: 30px;
}
.addCouponsThree .couponInfoLeft .bottomDesc{
    width: 100%;
    padding: 15px;
}
.addCouponsThree h2{
    font-size: 20px;
}
.addCouponsThree .bottomQuestion{
    margin-top: 15px;
}
.couponsConentFour .couponInfo .couponInfoItem > div{
    display: flex;
    justify-content: space-between;
}
.couponsConentFour .buttomProItem1{
    width: 150px;
    text-align: center;
}
.couponsConentFour .buttomProItem1 img{
    width: 130px;
    height: 160px;
    margin-right: 0;
    margin-bottom: 15px;
}
.couponsConentFour .buttomProItem1>div{
    width: 100%;
}
.couponsConentFour .buttomProItem1 .receiveCoupons{
    width: 80%;
    margin: 0 auto;
    background: rgb(247, 248, 250);
    border: none;
}
.couponsConentFour .buttomPro1{
    display: flex;
}
.couponsConentFour .buttomPro1 .buttomProItem1{
    margin-right: 15px;
}
