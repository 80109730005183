* {
  margin: 0;
  padding: 0;
}
.kc04_header_04amazon{
    width: 200px;
    height: 46px;
    background-image: url("../../assets/images/kc04_amazon.jpg");
    background-size: 200px;
}
.kc04_header {
  width: 100%;
  height: 300px;
}

.kc04_header-img {
  display: block;
  width: 200px;
  margin-left: 20px;
  float: left;
}

.kc04_myheader {
  width: 1200px;
  height: 300px;
  float: left;
  margin-left: 20%;
}
.kc04_header_04fs{
    width: 200px;
    height: 200px;
    background-image: url("../../assets/images/kc04_fs.jpg");
    background-size: 200px;
}

.kc04_myheader-img {
  width: 200px;
  display: block;
  float: left;
}

.kc04_myheader-box {
  margin-top: 25px;
  float: left;
  margin-left: 10px;
  height: 295px;
}

.kc04_myheader-box div:first-of-type {
  font-weight: bold;
  color: #1b2f36;
}

.kc04_myheader-box-span {
  position: relative;
  margin-top: 20px;
  height: 28px;
  color: #1b2f36;
}
.kc04_qz{
    width: 36px;
    height: 28px;
    background-image: url("../../assets/images/kc04-qz.JPG");
    background-size: 36px;
    display: inline-block;
}
.kc04_qi {
  height: 100%;
}

.kc04_myheader-box-span span {
  font-weight: 900;
  position: absolute;
}

.kc04_myheader-box-span span:first-of-type {
  font-weight: bold;
  height: 22px;
  margin-top: -8px;
}

.kc04_myheader-box-span span:nth-of-type(2) {
  margin-left: 45px;
  font-weight: 900;
  font-size: 18px;
  margin-left: 45px;
  position: relative;
}

.kc04_myheader-box-span span:nth-of-type(3) {
  margin-left: 2px;
  font-size: 15px;
}

.kc04_myheader-box-span span:nth-of-type(4) {
  color: tomato;
  margin-left: 23px;
}

.kc04_myheader-box-span span:nth-of-type(5) {
  color: #5aadd0;
  margin-left: 34px;
}

.kc04_myheader-box > ul {
  list-style: none;
  color: #a5aeae;
  margin-top: 12px;
}

.kc04_myheader-box_ul_span span {
  font-size: 15px;
  font-weight: 700;
}

.kc04_myheader-box_ul_span span:last-of-type {
  font-weight: bold;
}

.kc04_myheader-box_ul li:first-of-type {
  font-weight: bold;
}

.kc04_myheader-box_ull li:last-of-type {
  color: #5aadd0;
}

.kc04_myheader_p {
  font-weight: bold;
  margin-top: 12px;
}

.kc04_myheader_p span {
  color: #5aadd0;
}

/*  中间 */
.kc04_container {
  width: 100%;
  height: 1000px;
  background: #f5f9fa;
  margin-top: 65px;
}

.kc04_container h3 {
  width: 1200px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
}

.kc04_container_box {
  width: 1200px;
  height: 760px;
  margin: 0 auto;
  background: rgb(250, 249, 249);
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  overflow: hidden;
}

.kc04_container_box > div:first-of-type {
  height: 50px;
  line-height: 50px;
  text-align: right;
  box-sizing: border-box;
  border-bottom: 1px solid #d1d1d1;
}
.kc04_container_box > div:last-of-type {
  margin-left: 240px;
}

.kc04_container_box_sku {
  margin-top: 20px;
  overflow: hidden;
}
.kc04_jzc {
  height: 30px;
  margin-top: 18px;
  font-weight: 600;
  float: left;
  overflow: hidden;
}
.kc04_zc {
  text-indent: 2em;
}
.kc04_yu {
  display: block;
  width: 13px;
  height: 13px;
  line-height: 13px;
  margin-top: 20px;
  margin-left: 5px;
  text-align: center;
  float: left;
  border-radius: 50%;
  border: 1px solid #5aadd0;
  color: #5aadd0;
}
.kc04_input {
  display: block;
  height: 39px;
  float: left;
  margin-top: 22px;
  font-size: 15px;
  text-indent: 1em;
}
.kc04_inp {
  width: 780px;
  margin-left: 15px;
}
.kc04_container_box_sku > img {
  width: 20px;
  margin-top: 35px;
}
.kc04_box_inp {
  border: 1px solid red;
}
.kc04_box_p {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  color: #a5aeae;
  margin-left: 100px;
  font-size: 15px;
}

.kc04_container_but {
  width: 60px;
  display: block;
  float: left;
  height: 39px;
  font-weight: 600;
  margin-left: 16px;
  margin-top: 22px;
}

.kc04_container_select {
  width: 777px;
  display: block;
  height: 39px;
  float: left;
  margin-top: 22px;
  font-size: 15px;
  color: #acacac;
  text-indent: 1em;
  margin-left: 38px;
  border: 1px solid red;
}
.kc04_box_bor {
  width: 780px;
  height: 240px;
  margin-left: 110px;
  margin-top: 20px;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #acacac;
}
.kc04_box_bor div:first-of-type {
  height: 120px;
  box-sizing: border-box;
  border-bottom: 1px solid #acacac;
}
.kc04_rad {
  display: block;
  margin-left: 50px;
  float: left;
  margin-top: 50px;
  border: 10px solid #5aadd0;
}
.kc04_box_bor_p {
  width: 300px;
  margin-left: 10px;
  margin-top: 30px;
  float: left;
  color: #021a1f;
}
.kc04_tr {
  margin-top: 2px;
  margin-right: 5px;
  font-size: 12px;
}
.kc04_box_bor_foo {
  margin-top: 20px;
  width: 780px;
  font-size: 18px;
  margin-left: 110px;
  color: #a5aeae;
  font-size: 18px;
}
.kc04_awsx {
  width: 1200px;
  height: 36px;
  margin: 20px auto;
  text-align: right;
}
.kc04_awsx > button {
  height: 36px;
  border: none;
}
.kc04_awsx > button:first-of-type {
  width: 80px;
  background: #e3eced;
}
.kc04_awsx > button:last-of-type {
  width: 100px;
  color: white;
  background: #879596;
}

/* 尾部 */
.kc04_foote {
  height: 80px;
  overflow: hidden;
}
.kc04_foote > span {
  display: block;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  color: rgb(2, 83, 94);
  border-right: 2px solid #acacac;
}
.kc04_foote > span:first-of-type {
  width: 50px;
  text-align: center;
}
.kc04_foote > span:nth-of-type(2) {
  width: 80px;
  text-align: center;
}
.kc04_foote > select {
  width: 100px;
  margin-top: 20px;
  margin-left: 10px;
}
.kc04_foote > span:last-of-type {
  color: #acacac;
  border: none;
  float: right;
  font-size: 13px;
  margin-right: 30px;
}
