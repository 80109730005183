.font-green{
	color: #008296 !important;
}
.end02_myBody{
	padding: 0 20px 0 40px;
}
.end02_myBody_header{
	margin-top: 30px;
}
.end02_myBody_header_title{
	display: flex;
	justify-content: space-between;
}
.end02_myBody_header_title h1{
	font-weight: 100;
	font-size: 30px;
}
.end02_myBody_header_title h1 span{
	font-size: 16px;
	color: #008296;
	margin-left: 20px;
}
.end02_myBody_header_title p{
	color: #008296;
}
.end02_myBody_header>p:nth-of-type(1){
	margin-top: 20px;
}
.end02_myBody_header>button{
	padding: 8px 18px;
	background: #008296;
	color: #FFFFFF;
	border: 0;
	border-radius: 2px;
	margin-top: 40px;
}
.end02_pageing{
	display: flex;
	justify-content: flex-end;
}
.end02_pageing div:first-of-type{
	display: flex;
	margin-right: 40px;
	align-items: flex-start;
	padding-top: 4px;
}
.end02_pageing div p{
	color: #008296;
	padding: 0 6px;
	font-weight: bold;
	line-height: 16px;
}
.end02_pageing div p:first-of-type{
	color: #000;
}
.end02_pageing div:last-of-type{
	position: relative;
}
.end02_icon_bottom{
	position: absolute;
	right: 6px;
	top: 12px;
}
.end02_pageing select{
	height: 40px;
	width: 200px;
	padding-left: 6px;
	color: #1e474e;
	border: 1px solid #aebbbc;
}
.end02_table{
	width: 100%;
	border-left: 1px solid #d5dbdb;
	border-right: 1px solid #d5dbdb;
	border-bottom: 1px solid #d5dbdb;
	margin: 20px 0;
}
.end02_table th,.end02_table td{
	border-bottom: 1px solid #dadfdf;
}
.end02_table_title{
	text-align: left;
	background: #fafafa;
	line-height: 50px;
	padding-left: 20px;
	border: 1px solid #d5dbdb;
	color: #002f36;
	font-weight: bold;
}
.end02_table_header{
	height: 40px;
}
.end02_table_header th{
	line-height: 40px;
	text-align: left;
	padding-left: 10px;
	position: relative;
	color: #002f36;
}
.end02_table_header th div{
	position: absolute;
	top: 4px;
	right: 6px;
	width: 20px;
}
.end02_table_header th div img{
	width: 100%;
}
.end02_icon_top{
	position: absolute;
	top: 10px;
	right: 6px;
	width: 12px;
}
.end02_table_header th:not(:last-of-type)::before{
	content: "";
	width: 1px;
	height: 20px;
	background: gray;
	position: absolute;
	right: 0;
	top: 10px;
}
.end02_discount{
	width: 300px;
}
.end02_start{
	width: 400px;
}
.end02_end{
	width: 400px;
}
.end02_state{
	width: 200px;
}
.end02_change{
	width: 400px;
	background-color: #f5f9fa;
}
.end02_operation{
	width: 200px;
}
.end02_table td{
	height: 50px;
	padding-left: 10px;
	color: #002f36;
}
.end02_table_btn{
	display: flex;
}
.end02_table_btn button{
	background: #008296;
	color: #fff;
	padding: 0 20px;
	border: 0;
	height: 34px;
	line-height: 34px;
	border-radius: 2px 0 0 2px;
}
.end02_table_btn div{
	width: 30px;
	height: 34px;
	line-height: 34px;
	background: #008296;
	border-left: 1px solid #016a7a;
	border-radius: 0 2px 2px 0;
	text-align: center;
}
.end02_table-f{
	background: #fafafa;
}
.end02_table-f td{
	height: 20px;
}
.caption1{
    caption-side: top !important;
}