.billBox .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #C45500;
    border: 1px solid #C45500;
}

.billBox .bottomDesc {
    /* background: rgb(246, 246, 246); */
    /* border: 1px solid rgb(187, 211, 222); */
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-bottom: 0;
}

.billBox .topTitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.billBox .selectBox .billBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}

.billBox .a-spacing-top-large {
    margin-top: 24px !important;
}

.billBox .ant-steps {
    margin: 20px auto 50px;
    width: 70%;
}

.billBox .a-size-large {
    font-size: 13px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: 0;
    width: 70%;
}

.billBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}

.billBox .ng-scope {
    text-align: center;
}

.billBox .a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.billBox .a-size-small {
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.billBox .eCkQDX {
    display: none;
}

.billBox .jXxwsB span.icon,
.billBox .jXxwsB .websit.ant-select,
.billBox .jXxwsB .language.ant-select .ant-select-selector,
.billBox .jXxwsB .ant-select-arrow,
.billBox .jXxwsB .search,
.billBox .jXxwsB .language.ant-select,
.billBox .jXxwsB .ilQBwr button {
    display: none;
}

.billBox .hoQOMo>div {
    display: block;
}

.billBox .jXxwsB .ilQBwr button:last-child {
    display: inline-block;
}

.billBox .jXxwsB .ilQBwr {
    text-align: right;
}

.billBox .CompanyAddress {
    width: 55%;
    margin: 0 auto;
}

.billBox .selectBox {
    width: 49%;
    height: 35px;
}

.billBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;
}

.billBox .rightCollapse {
    width: 30%;
}

.billBox .rightCollapse>p {
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}

.billBox .inpBox {
    display: flex;
}

.billBox .inpBox1 input {
    padding-left: 5px;
    width: 100%;
}

.billBox .inpBox input:nth-child(2) {
    margin-left: 2%;
}

.billBox .addressBox .ant-form-item {
    padding-left: 5px;
    width: 49%;
}

.billBox .inpBox .ant-form-item:nth-child(2) {
    margin-left: 2%;
}

.billBox .billDesc {
    margin-top: 30px;
}

.billBox .selectDetails {
    margin-top: 22px;
}

.billBox .selectDetails .ng-binding {
    color: #767676;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 30px;
}

.billBox .selectDetails .a-section span {
    font-size: 13px;
    color: #000;
    /* margin-bottom: 20px; */
}


.billBox .aCheckbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.billBox .aCheckbox span {
    margin-left: 5px;
}

.billBox .receivingMode {
    margin-top: 20px;
}

.billBox .receivingMode p {
    font-weight: bold;
    margin-bottom: 0;
}

.billBox .yanzPhone {
    display: flex;
    border: 1px solid #d9d9d9;
}

.billBox .yanzPhone select,
.billBox .yanzPhone .ant-input-affix-wrapper {
    border: none;
}

.billBox .inpBox .next {
    width: 49%;
    margin-left: 2%;
    height: 35px;
}

.billBox .representative input+input {
    margin-left: 2%;
}

.billBox .billTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.billBox .billTitle div p {
    font-size: 16px;
    font-weight: bold;
}

.billBox .billTitle div p:first-child {
    font-size: 22px;
}

.billBox .billTitle div:last-child {
    font-size: 14px;
    color: rgb(165, 126, 192);
    cursor: pointer;
}

.billBox .inpBox1 {
    display: flex;
}

.billBox .inpBox1 p {
    margin-bottom: 0;
    font-weight: bold;
}

.billBox .inpBox1 .dates {
    margin-left: 2%;
    width: 66%;
}

.billBox .inpBox1 .dates .ant-form-item {
    width: 45%;
    height: 35px;
    margin-bottom: 0;
}

.billBox .inpBox1 .dates>div>div {
    display: flex;
}

.billBox .inpBox1 .dates .Slash {
    margin: 0 5%;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
}

.billBox .bottomDesc .ant-radio-wrapper {
    word-wrap: break-word !important;
    word-break: normal;
    white-space: pre-wrap
}

.billBox .depositInfo {
    width: 49%;
    margin-bottom: 15px;
}

.billBox .depositInfo>div {
    display: flex;
    align-items: center;
}

.billBox .depositInfo>div span {
    font-weight: 400;
}

.billBox .depositInfo:nth-child(2) {
    margin-left: 2%;
}

.billBox .depositInfo select,
.billBox .depositInfo input {
    width: 100%;
}

.billBox .operationBtn {
    display: flex;
    justify-content: center;
}

.billBox .operationBtn .next {
    width: 30%;
}

.billBox .previousPage {
    background: linear-gradient(180deg, #f7f8fa, #e7e9ec);
    border: 1px solid #adb1b8;
    margin-right: 2%;
    width: 185px;
    height: 35px;

}

.a-section_alert {
    border: 1px solid #f0c14b;
    padding: 15px 30px;
    margin-bottom: 60px;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0px 0px 4px#f0c14b;
    display: flex;
}

.a-section_alert h5 {
    color: #7c6200;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    margin-top: 0px!important;
}

.a-section_alert span {
    color: #1890ff;
}

.a-section_alert .icon {
    margin-right: 20px;
}

.xuandizhi .ant-modal-body {
    padding: 6px 10px !important;
}

.xuandizhi .bottomDesc {
    padding: 10px;
    border-radius: 4px;
}

.xuandizhi .action.bottomDesc {
    border: 1px #ddd solid;
    background-color: #f3f3f3 !important;
}

.billBox h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 48px;
}
.billBox h5 p{
    font-size: 14px;
    color: #767676;
}
.billBox .kat-divider{
    margin-bottom: 30px;
}