/* 头部下面UL */
.kc24_box1 {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bold;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.kc24_box1 a {
    color: #003399;
}

.kc24_box1-ul {
    display: flex;
    align-items: center;
}

.kc24_box1-ul li {
    padding: 0 10px;
    color: #CC6600;
    position: relative;
}

.kc24_box1-ul li::after {
    content: "";
    width: 2px;
    height: 15px;
    background-color: #5d0000;
    position: absolute;
    right: 0;
    top: 8px;
}

.kc24_box1-ul li:last-of-type::after {
    display: none;
}

.kc24_box1-ul li:nth-of-type(2) a {
    color: #000000;
    text-decoration: none;

}

/*提示*/
.kc24_fanhui {
    min-width: 1650px;
    padding: 0px 20px;
    margin: 0 50px 0 40px;
    margin-top: 10px;
    background: #F6F6F6;
    padding: 15px;
    border: 2px solid #C2D7E1;
    border-radius: 5px;
}

.kc24_fanhui>img {
    width: 30px;
    vertical-align: middle;
}

.kc24_fanhui>span {
    margin-left: 20px;
    font-size: 12px;
}

.kc24_fanhui>span>span {
    color: #064F93;
}


.kc24_tab {
    min-width: 1650px;
    padding: 0px 20px;
    margin: 0 20px;
}

.kc24_tabList {
    display: flex;
    justify-content: space-between;
    color: #1E78C7;
    font-weight: 600;
    margin: 10px 0;
    border-bottom: 2px solid rgba(0, 0, .0, 0.3);
}

.kc24_tabList>ul {
    height: 43px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    font-size: 15px;
}

.kc24_tabList>ul li {
    padding: 10px 20px;
    box-sizing: border-box;
}

.kc24_tabList>ul li span {
    font-size: 10px;
    font-weight: 500;
    color: #FFA928 !important;
    margin-left: 5px;
}

.kc24_guanli {
    height: 45px;
    line-height: 35px;
    padding: 0 20px !important;
    color: rgba(0, 0, .0, 0.5);
    border: 2px solid rgba(0, 0, .0, 0.3);
    border-top: 3px solidrgba(0, 0, .0, 0.3);
    border-bottom: 3px solid white;
    position: relative;
    z-index: 99;
}

.kc24_tabList>div {
    font-size: 15px;
    line-height: 43px;
}

.kc24_lllff {
    display: flex;
    justify-content: space-between;
}

.kc24_kc24_rongyutit {
    list-style: none;
    display: flex;
    position: relative;
}

.kc24_rongyutit li>h2 {
    font-size: 28px;
    font-weight: 400;
    color: #DB8800;
}

.kc24_rongyutit li:nth-of-type(2) {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}

.kc24_rongyutit li:nth-of-type(2) span {
    font-size: 10px;
    margin: 0 5px;
    vertical-align: top;
    color: black !important;
}

.kc24_lllff p {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}

.kc24_lllff p>span:nth-of-type(1) {
    padding: 0 5px;
    border: 1px solid rgba(0, 0, .0, 0.5);
    border-radius: 5px;
    background: #E6E6C2;
    color: #000066;
    margin-right: 10px;
    cursor: pointer;
}

.kc24_lllff p>span:nth-of-type(2) {
    padding: 0 5px;
    border: 1px solid rgba(0, 0, .0, 0.5);
    border-radius: 5px;
    background: #FFC53A;
    color: #221A5E;
    margin-right: 10px;
    cursor: pointer;
}

.kc24_rongyu>p {
    color: #AAAAAA;
}

.kc24_rongyu>p>span {
    color: #2E81CB !important;
}

.kc24_tuijie {
    margin: 30px 0 0;
    font-size: 14px;
}

.kc24_tuijie>span {
    font-size: 15px;
    font-weight: 600;
    margin: 0 10px 0 0;
    font-weight: 600;
}

.kc24_tuijie>input {
    padding: 5px 10px;
    margin-left: 10px;
    margin-right: 5px;
    vertical-align: middle;
    font-weight: 600;
}

.kc24_tuijie_r {
    float: right;
    position: relative;

}

.kc24_tuijie_r img {
    width: 40px;
    vertical-align: middle;
}

.kc24_tuijie_r>input {
    padding: 5px 5px;
}

.kc24_tuijie_r p {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
}

.kc24_tuijie_r p>span {
    color: #4E82B2;
}

.kc24_shujuList {
    margin-top: 30px;
}

.kc24_shujuList>ul {
    min-width: 1300px;
    width: 100%;
    list-style: none;
    display: flex;
    font-size: 14px;
    font-size: 13px;
}

.kc24_shujuList>ul>li img {
    width: 10px;
}

.kc24_shujuList>ul>li:nth-of-type(1) {
    width: 300px;
    margin-left: 100px;
}

.kc24_shujuList>ul>li:nth-of-type(2) {
    width: 200px;
    margin: 0 0px 0 0px;
}

.kc24_shujuList>ul>li:nth-of-type(3) {
    width: 200px;
    margin: 0 0 0 0px;
}

.kc24_shujuList>ul>li:nth-of-type(4) {
    width: 200px;
    margin: 0 0 0 0px;
}

.kc24_shujuList>ul>li:nth-of-type(5) {
    width: 50px;
    margin: 0 30px 0 0px;
    text-align: right;
}

.kc24_shujuList>ul>li:nth-of-type(6) {
    width: 50px;
    margin: 0 30px 0 0px;
    text-align: right;
}

/* .kc24_shujuList>ul>li:nth-of-type(7){
    width: 50px;
    margin: 0 30px 0 0px;
    text-align: right;
} */
/* .kc24_shujuList>ul>li:nth-of-type(8){
    width: 100px;
    margin: 0 0 0 0px;
} */
.kc24_headbor:nth-last-of-type(1) {
    border: none !important;
}

.kc24_daosanjiao span {
    transform: rotateX(180deg);
    vertical-align: middle;
    margin-bottom: 5px;
}

.kc24_daosanjiao:active {
    transform: rotate(180deg);
}

.kc24_lan {
    font-size: 14px;
    color: #004B91;
}

.kc24_chu {
    font-weight: 600;
}

.kc24_headbor {
    padding: 10px 0;
    border-bottom: 2px solid rgba(0, 0, .0, 0.5);
}

.kc24_xi {

    border-bottom: 1px solid rgba(0, 0, .0, 0.3);
}

.kc24_zuihou {
    margin-left: 200px;
    position: relative;
}

.kc24_zuihou div {
    /* padding: 0 70px; */
    width: 160px;
    text-align: center;
    background-image: linear-gradient(#F7F7E6, #dfab2f);
    color: #0D0D6B;
    border: 1px solid #8F8F6D;
    border-radius: 5px;
    font-weight: 700;
    height: 23px;
}

.kc24_zuihou {
    color: #CD6A06;
    font-weight: 600;
    cursor: pointer;
}

.kc24_zuihou>p {
    margin: 5px 0;
    color: #0B5396;
    font-size: 12px;
}

.kc24_zuihou:active {
    top: 2px;
}

.kc24_black1 {
    color: black !important;
}

.kc24_que {
    color: #D45252;
}

.kc24_que span {
    color: #3E77AC !important;
    cursor: pointer;
}

.kc24_zhuyi {
    font-size: 14px;
    margin-bottom: 100px;
}

.kc24_zhuyi p:nth-of-type(1) {
    text-align: right;
    margin-bottom: 20px;
}

.kc24_zhuyi p:nth-of-type(1) span {
    color: #5184B4;
}

.kc24_search {
    overflow: hidden;
    padding: 10px 20px;
    background-color: #f4f4f4;
    border-top: 1px solid #b2b8b9;
    border-bottom: 1px solid #b2b8b9;
    font-size: 14px;
    color: #053439;
    margin-top: 20px;
}

.kc24_search select {
    width: 180px;
    font-size: 12px;
    height: 30px;
    line-height: 20px;
    appearance: none;
    /* 去掉默认图标 */
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    /* background: url(../img/下拉1.png) center no-repeat #fff; */
    background-position: 90% 50%;
    background-size: 10px;
    padding: 5px 10px 0 10px;
}

.kc24_search_l {
    float: left;
    font-weight: 600;
}

.kc24_search_l button {
    background-color: #fafafa;
    border: 1px solid #b2b8b9;
    padding: 10px;
    margin-left: 5px;
}

.kc24_search_r {
    float: right;
}

.kc24_search_r div {
    width: 50px;
    display: inline-block;
    font-weight: bold;
}

.kc24_back {
    margin-top: 20px;
}

.kc24_back button {
    border: none;
    padding: 10px 20px;
    font-weight: 600;
    color: #053439;
    background-color: #e3eced;
}

.kc24_data {
    display: flex;
    font-size: 14px;
    color: #686868;
    font-weight: 600;
    border-bottom: 3px solid #b6b6b6;
    padding: 10px 0;
}

.kc24_data li:nth-of-type(1) {
    width: 800px;
    padding: 0 10px 0 20px;
}

.kc24_data li {
    width: 300px;
    padding: 0 10px;

}

.kc24_data li div:nth-of-type(1)::after {
    content: "";
    width: 1px;
    height: 20px;
    background-color: #686868;
    position: absolute;
    right: 0;
}

.kc24_data li div:nth-of-type(1):last-of-type::after {
    display: none;
}

.kc24_data li div:nth-of-type(1) {
    color: #053439;
    width: 100%;
    position: relative;
}

.kc24_data li:nth-of-type(4) {
    text-align: right;
    padding-right: 20px;
}

.kc24_data li:nth-of-type(4) div:nth-of-type(1) {
    padding-right: 20px;
}

.kc24_icon {
    position: relative;
    margin-left: 5px;
    color: #1e9eb1;
}

.kc24_icon::after {
    content: "";
    width: 15px;
    height: 15px;
    border: 2px solid #1e9eb1;
    border-radius: 50%;
    position: absolute;
    left: -5px;
}

input::-webkit-input-placeholder {
    color: #008296;
    font-size: 12px;
}