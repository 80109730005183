.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 18px;
}

/* .ant-form-item-with-help {
  margin-bottom: 0;
} */

.ant-form-item-explain,
.ant-form-item-extra {
  min-height: 12px;
  font-size: 12px;
}