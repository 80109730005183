.qd12_tjmj{
	margin: 30px  0 0 40px;
}
.qd12_daoh{
	font-size: 14px;
}
.qd12_daoh svg{
	vertical-align: middle;
	margin: 0 5px;
}
.qd12_daoh span:nth-of-type(1){
	color: #0099dc;
}
.qd12_daoh span:nth-of-type(2){
	color: #cd5500;
}
.qd12_tjmj h2{
	margin: 10px 0;
	font-size: 28px;
	font-weight: 500;
}
.qd12_bianhao{
	margin-top: 20px;
	font-size: 14px;
}
.qd12_bianhao>p>span{
	margin-left: 10px;
	color: #0099DC;
	font-size: 12px;
}
.qd12_bianhao>p>span svg{
	margin-left: 5px;
	vertical-align: middle;
}
.qd12_bianhao>div>p{
	font-weight: 700;
	margin: 3px 0;
}
.qd12_bianhao>div>input{
	border-radius: 3px;
	border: 1px solid #ccc;
	height: 30px;
	padding-left: 5px;
}
.qd12_bianhao>div>span{
	display: inline-block;
	width: 50px;
	cursor: pointer;
	margin-left: 10px;
	text-align: center;
	border-radius: 5px;
	line-height: 29px;
	height: 30px;
	color: white;
	border: 1px solid black;
	background-image: linear-gradient(#6d737a,#454d56);
}
.qd12_tjmj{
    width: 100%;
    padding-bottom: 300px;
}