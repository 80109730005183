.BrandPromotion2 {
    font-size: 12px;
  }
  .BrandPromotion2-head {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e8e8e8;
  }
  .BrandPromotion2-head-left span {
    font-size: 14px;
    margin-left: 25px;
  }
  .BrandPromotion2-head-right {
    font-size: 12px;
  }
  .BrandPromotion2-head-right span {
    margin: 0px 10px;
  }
  .BrandPromotion2-head-right-button2 {
    background-color: #f6bd43;
    border: 1px solid #f6bd43;
    width: 100px;
    height: 30px;
    margin-left: 15px;
  }
  .BrandPromotion2-body-head h3 {
    padding-left: 0px;
    margin-bottom: 50px;
  }
  .BrandPromotion2-head-right-button1 {
    border: 1px solid #e6e6e6;
    width: 90px;
    height: 30px;
    margin-left: 15px;
  }
  .BrandPromotion2-setUp-content span {
    font-weight: 700;
    margin-top: 15px;
  }
  .BrandPromotion2-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .BrandPromotion2-body-head {
    width: 958px;
    margin: 25px 0;
  }
  .BrandPromotion2-setUp-box {
    border: 1px solid #bfbfbf;
    border-radius: 3px;
  }
  .BrandPromotion2-setUp {
    padding: 20px;
    font-size: 14px;
    border-bottom: 1px solid #bfbfbf;
  }
  .BrandPromotion2-setUp span {
    font-size: 18px;
  }
  .Name-of-advertising-campaign input {
    width: 285px;
    height: 30px;
  }
  .BrandPromotion2-setUp-time-box {
    display: flex;
    justify-content: space-between;
  }
  .Set-date {
    width: 323px;
    line-height: 26px;
    padding-left: 5px;
    border-left: 1px solid #ccc;
  }
  .Daily-budget {
    display: flex;
    justify-content: space-between;
  }
  .Set-date1 {
    width: 323px;
    height: 18px;
    padding-left: 5px;
    margin-top: 45px;
    margin-bottom: 0px;
    border-left: 1px solid #ccc;
  }
  .BrandPromotion2-setUp-content {
    padding: 18px;
    font-size: 12px;
  }
  .BrandPromotion2-setUp-time {
    display: flex;
  }
  .BrandPromotion2-setUp-time input {
    margin-right: 15px;
  }
  
  .SetUp-BudgetSelection2 span {
    margin-top: 0px;
    padding: 0;
  }
  .SetUp-BudgetSelection2 select {
    height: 28px;
  }
  .SetUp-BudgetSelection2 {
    display: flex;
  }
  .BrandPromotion2-setUp-select {
    margin-left: 0px !important;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .Advertising-mix {
    display: flex;
    justify-content: space-between;
  }
  .BrandPromotion2-setUp-select-data {
    border-left: 1px solid #ccc;
    padding-left: 10px;
    margin-bottom: 35px;
  }
  .box-head {
    font-size: 16px;
  }
  .box-head-box {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: solid 1px #ccc;
  }
  .Advertising-format {
    border: solid 1px #ccc;
    margin-top: 25px;
  }
  .Advertising-format-Commodity-collection {
    width: 278px;
    height: 240px;
    border: solid 1px #ccc;
  }
  .Advertising-format-Commodity-collection-box {
    display: flex;
    justify-content: space-evenly;
    padding: 45px 0;
  }
  .Bold-title {
    margin-left: 31px;
    margin-bottom: 20px;
  }
  .Bold-title span {
    font-weight: 700;
  }
  .Landing-page {
    margin-left: 31px;
    margin-bottom: 20px;
  }
  .Landing-page-selection {
    display: flex;
  }
  .Landing-page-selection select {
    width: 220px;
    background-color: #e8e8e8;
  }
  .Landing-page-selection select {
    margin-left: 0px !important;
  }
  .button {
    float: right;
    margin-top: 20px;
    overflow: hidden;
  }
  .button1 {
    width: 165px;
    height: 30px;
    margin-right: 20px;
    border: solid 1px #ccc;
  }
  .button2 {
    width: 240px;
    height: 30px;
    border: solid 1px #f7c454;
    background-color: #f7c454;
  }
  .CommodityPromotion-search {
    position: relative;
    padding: 10px;
    border-bottom: solid 1px #ccc;
  }
  .CommodityPromotion-search input {
    width: 340px;
    height: 30px;
    text-indent: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;

  }
  .CommodityPromotion-search i {
    position: absolute;
    top: 14px;
    left: 15px;
    color: #ccc;
  }
  .CommodityPromotion-search button {
    width: 30px;
    height: 29px;
    margin-right: 5px;
  }
  .Add-promotional-products-box {
    border: solid 1px #ccc;
    margin-top: 25px;
  }
  .Add-promotional-products {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ccc;
  }
  .Add-page {
    border: solid 1px #ccc;
    width: 480px;
    height: 500px;
  }
  .CommodityPromotion-setUp-select-sort {
    margin-left: 0px !important;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .CommodityPromotion-search-sort {
    display: flex;
    justify-content: flex-end;
    padding: 8px 15px;

  }
  .Add-pager {
    padding: 0px 11px;
  }
  .CommodityPromotion-search-sort-span {
    background-color: #0066c0;
    color: #fff;
    margin-left: 5px;
  }
  .CommodityPromotion-search-sort-a {
    line-height: 25px;
  }
  .BudgetSelection2-tianjia{
    height:320px ;
    padding:0px 15px;
  }
  .BudgetSelection2-tianjia-fenye{
    border-top: 1px solid #ccc;
    padding: 5px;
    display: flex;
    justify-content: flex-end; 
  }
  .BudgetSelection2-tianjia-fenye button{
    width: 30px;
    height: 24px;
    border: #e6e6e9;
  }
  .Add-box{
    display: flex;
  }
  .BudgetSelection2-yitianjiashangpin-shuliang{
    padding: 20px;
  }
  .BudgetSelection2-yitianjiashangpin{
    margin: 0px 20px;
    height: 220px;
  }
  .BudgetSelection2-yitianjiashangpin img{
    margin-right:10px;
  }
  .BudgetSelection2-proposal{
    font-size: 12px;
    padding: 15px;
    color: #767676;
  }