.gg04_cen{
    width: 100%;
    height:50px;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    overflow: hidden;
}
.gg04_box{
    width: 200px;
    height: 30px;
    line-height: 30px;
    margin-left: 30px;
    font-size: 20px;
    position: relative;
    color: rgb(34, 33, 32);
}
.gg04_box>span{
    font-size: 12px;
    position: absolute;
    margin-left:12px;
    margin-top: 6px;
    color: #2594A5;
}
.gg04_box2{
    text-align: center;
    width:200px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    color: white;
    margin-right: 30px;
    background: #008296;
    box-shadow: 2px 2px 5px #acacac;
}
.gg04_boxsz{
    height: 36px;
    margin: 0 36px;
    border-bottom:1px solid #acacac;
}
.gg04_boxsz>div{
    width: 90px;
    font-size:12px ;
    margin-left: 10px;
    border-bottom:2px solid #0095A7;
    height: 33px;
    color: #404042;
}
.gg04_flex{
    margin-top: 30px;
   margin-left: 30px;
   height:38px;
   display: flex;
}
.gg04_flex>input{
    text-align: center;
    width:300px;
}
.gg04_flex>img{
    width:38px;
}
.gg04_flex>div{
    width: 80px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid #B5C1C2;
}
.gg04_flex>div>span{
    margin-left: 6px;
    font-weight: bolder;
}
.gg04_flex>div:nth-of-type(1){
    width: 130px;
}
.gg04_flex>div:nth-of-type(2){
    width: 200px;
    border-left: none;
    border-right: none;
}
.gg04_bacac{
    margin-top: 10px;
    width: 100%;
    height:50px;
    border-top: 1px solid #acacac;
    background:#FAFAFA ;
    overflow: hidden;
}
.gg04_bacac>div:first-of-type{
    margin-left: 30px;
    float: left;
    margin-top: 8px;
}
.gg04_bacac>div:last-of-type{
    float: right;
    margin-top: 8px;
    font-size: 12px;
    overflow: hidden;
    color: rgb(3, 98, 109);
    margin-right: 30px;
}
.gg04_bacac>div:nth-of-type(2)>p{
    float: left;
    text-align: right;
    margin-right:10px;
}
.gg04_bacac>div:nth-of-type(2)>div{
    width: 180px;
    height: 36px;
    line-height: 36px;
    float: left;
    
    border: 1px solid #000;
}
.gg04_bacac>div:nth-of-type(2)>div span{
    margin-left:0px;
}
.gg04_bacac_tab{
    width: 100%;
    padding-left: 30px;
    height:60px;
    display: flex;
    color: #00252C;
    font-size: 14px;
    background:#FAFAFA ;
}
.gg04_bacac_tab>div{
    font-size: 12px;
    color: rgb(3, 98, 109);
    font-weight: 800;
    width: 16%;
    text-align: center;

}
.gg04_bacac_tab>div p{
    font-size: 12px;
    color: #acacac;

}
.gg04_bacac_tab>div:nth-of-type(1){
    width: 40%;
    text-align: left;
}
/* .gg04_bacac_tab>div:nth-of-type(2){
    margin-left:500px;
}
.gg04_bacac_tab>div:nth-of-type(3){
    margin-left:300px;
}
.gg04_bacac_tab>div:nth-of-type(4){
    margin-left:130px;
}
.gg04_bacac_tab>div:nth-of-type(5){
    margin-left:130px;
}
.gg04_bacac_tab>div:nth-of-type(6){
    margin-left:130px;
} */
.gg04_box-bor{
    border-bottom: 1px solid #B9C5C6;
    margin-left: 30px;
}
.gg04_table{
    width: 100%;
}
.gg04_table tr{
    width: 100%;
    height: 100px;

}


/* tr{
    border-bottom: 1px solid #acacac;
} */



.gg04_tdas{
    overflow: hidden;
    padding-left: 20px;
    margin-right: 30px;
}
.gg04_table tr td .gg04_sp{
    display: block;
    width: 10px;
    height: 1px;
    background: #acacac;;
}
.gg04_tdas_image{
    width: 83px;
    height: 98px;
    display: block;
    float: left;
}
.gg04_tdas>div>img{
    width: 100%;
    height: 100%;
   
}
.gg04_boxtab{
    float: left;
    margin-right: 156px;
}


.gg04_boxtab>p{
    font-size: 14px;
}
.gg04_boxtab>p:nth-of-type(2){
    font-size: 12px;
    color: #acacac;
    margin-bottom: 8px;
} 
.gg04_sl{
    width: 188px;
}
.gg04_sl>p{
    font-size: 14px;
}
.gg04_sl>p:nth-of-type(2){
    font-size: 12px;
    color: #acacac;
    margin-bottom: 8px;
}
.gg04_sl>span{
    margin-top: 8px;
    color: #fff;
    border-radius:12px;
    font-size: 12px !important;
    padding:0 5px;
    text-align: left;
    background:#667575 ;
}
.gg04_we{
    margin-left:50px;
}
.gg04_but{
    width: 80px;
    height: 20px;
    background:#E3ECED ;
    border: none;
    color: #023241;
    border: 1px solid #acacac;
    overflow: hidden;
}
.gg04_topbox{
    margin-top: 10px;
}
.gg04_sppa{
    font-size: 12px;
}
.gg04_hssd{
    margin-right: 12px;
}
.gg04_spanle{
    display: block;
    float:right;
    height: 20px;
    border-left: 1px solid #008296;
}
.gg04_brouy{
    font-weight: 100 !important;
}

