.BrandAccountSale h3{
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 700;
}
.BrandAccountSale .ant-col-9{
  background: rgb(245,249,250);
  margin-right: 20px;
}
.BrandAccountSale p{
  padding-left: 23px;
}