.end15_content {
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
}

.end15_sidebar {
	width: 240px;
	padding: 30px 0 30px 30px;
	background-color: #F4F8FA;
}

.end15_sidebar ul li {
	margin-bottom: 15px;
}

.end15_sidebar ul li:nth-of-type(1) {
	color: #008296;
	font-weight: 600;
}

.end15_sidebar>p {
	margin-top: 30px;
	font-size: 13px;
	margin-bottom: 20px;
}

.end15_chakanv{
	width: 75%;
	margin-top: 20px;
	border-top: 1px solid #ccc;
	padding: 0 10px;
	font-size: 12px;
}
.end15_chakanv p{
	margin-top: 10px;
}
.end15_chakanv p:nth-of-type(2){
	font-weight: 600;
}
.end15_chakanv p:nth-last-of-type(1){
	color: #008296;
}
.end15_chakanv2{
	width: 75%;
	margin-top: 20px;
	border-top: 1px solid #ccc;
	padding: 0 10px;
	font-size: 12px;
}
.end15_chakanv2 p:nth-of-type(1){
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 15px;
}
.end15_chakanv2 p:nth-last-of-type(1){
	margin-top: 15px;
	margin-bottom: 10px;
	color: #008296;
	font-size: 13px;
}

.end15_maijia{
	width: 100%;
	padding: 30px 15px 0 30px;
}
.end15_pinglun{
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 10px;
}
.end15_pinglun span:nth-of-type(1){
	margin-left: 10px;
	font-size: 14px;
	font-weight: 400;
	color: #000 !important;
	vertical-align: middle;
}

.end15_pinglun .end15_gengd{
	font-size: 13px;
	font-weight: 400;
	color: #00a4b9;
	margin-left:10px;
}
.end15_zhongxian{
	font-size: 10px;
	margin-left: 10px;
}
.end15_qingzy{
	background-color: #E5F2F4;
	font-size: 14px;
	height: 50px;
	line-height: 50px;
	position: relative;
}
.end15_qingzy svg:nth-of-type(1){
	margin-left: 20px;
	margin-right: 10px;
	vertical-align: middle;
}
.end15_qingzy svg:nth-of-type(2){
	position: absolute;
	right: 20px;
	top: 15px;
}
.end15_pinglun2{
	font-size: 17px;
	font-weight: 600;
}
.end15_jiankang{
	margin-top: 10px;
}
.end15_jiankang > ul{
	padding-left: 10px;
	display: flex;
}
.end15_jiankang > ul li{
	border: 1px solid #ccc;
	border-top:4px solid #008296;
	padding: 10px 15px 0;
	width: 330px;
	height: 150px;
	margin-right: 20px;
	background-color: #EDF1F2;
}
.end15_litit{
	position: relative;
	font-weight: 400;
}
.end15_litit svg{
	position: absolute;
	right: 0;
}
.end15_litit_p1{
	font-size: 13px;
	margin: 7px 0 15px;
}
.end15_litit_p2{
	color: #008296;
}
.end15_xjb{
	margin-top: 10px;
	font-size: 13px;
	padding-bottom: 30px;
	border-bottom: 1px solid #ccc;
}
.end15_xjb span{
	color: #008296;
}
.end15_jiankang:nth-last-of-type(1){
	margin-top: 20px;
}
.end15_jkul2{
	margin-top: 20px;
	flex-wrap: wrap;
}
.end15_jkul2 li{
	border: 2px solid #ccc !important;
	border-top: 5px solid #7FC2BB !important;
	height: 130px !important;
	background-color: white !important;
	position: relative;
	margin-bottom: 15px;
}
.end15_jkul2 li:nth-last-of-type(1){
	border: none !important;
}
.end15_jkul2_p1{
	position: absolute;
	font-size: 18px;
	color: rgba(0,0,0,0.5);
}
.end15_jkul2_p2{
	position: absolute;
	top: 45%;
	transform: rotateY(-45%);
	font-size: 13px;
}
.end15_jkul2_p3{
	position: absolute;
	bottom: 0px;
	font-size: 13px;
	color: #008296;
}
.end15_xjb2{
	margin: 20px 0;
	border-bottom: none !important;
}