.enterpriseBox {
    position: relative;
}

.enterpriseBox .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #C45500;
    border: 1px solid #C45500;
}

.enterpriseBox .bottomDesc {
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    padding-left: 60px;
}

.enterpriseBox .selectBox .enterpriseBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}

.enterpriseBox .a-spacing-top-large {
    margin-top: 24px !important;
}

.enterpriseBox .ant-steps {
    margin: 20px auto 50px;
    width: 70%;
}

.enterpriseBox .a-size-large {
    font-size: 13px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.enterpriseBox .program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: 0;
    width: 70%;
}

.enterpriseBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}

.enterpriseBox .ng-scope {
    text-align: center;
}

.enterpriseBox .a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.enterpriseBox .a-size-small {
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.enterpriseBox .eCkQDX {
    display: none;
}

.enterpriseBox .jXxwsB span.icon,
.enterpriseBox .jXxwsB .websit.ant-select,
.enterpriseBox .jXxwsB .language.ant-select .ant-select-selector,
.enterpriseBox .jXxwsB .ant-select-arrow,
.enterpriseBox .jXxwsB .search,
.enterpriseBox .jXxwsB .language.ant-select,
.enterpriseBox .jXxwsB .ilQBwr button {
    display: none;
}

.enterpriseBox .hoQOMo>div {
    display: block;
}

.enterpriseBox .jXxwsB .ilQBwr button:last-child {
    display: inline-block;
}

.enterpriseBox .jXxwsB .ilQBwr {
    text-align: right;
}

.enterpriseBox .CompanyAddress {
    width: 55%;
    margin: 0 auto;
}

.enterpriseBox .selectBox {
    width: 49%;
    height: 35px;
}

.enterpriseBox .next {
    width: 200px;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border: 1px solid #000;
    border-radius: 3px;

}

.enterpriseBox .rightCollapse {
    width: 30%;
}

.enterpriseBox .rightCollapse>p {
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}

.enterpriseBox .inpBox {
    display: flex;
}

.enterpriseBox .inpBox .ant-form-item {
    /* padding-left: 5px; */
    width: 49%;
}

.enterpriseBox .inpBox .ant-form-item:nth-child(2) {
    margin-left: 2%;
}

.enterpriseBox .aCheckbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

/* .enterpriseBox .aCheckbox span {
    margin-left: 5px;
} */

.enterpriseBox .receivingMode {
    margin-top: 20px;
}

.enterpriseBox .receivingMode p {
    font-weight: bold;
    margin-bottom: 0;
}

.enterpriseBox .yanzPhone {
    display: flex;
}

.enterpriseBox .successBox {
    display: flex;
    align-items: center;
    color: #007600;
    margin-left: 0px;
    margin-top: 20px;
    font-size: 12px;
}

.enterpriseBox .ant-form-item-extra {
    color: #767676 !important;
    clear: both;
}

.enterpriseBox .successBox>span {
    margin-left: 4px;
}
.enterpriseBox .successBox>.icon {
    position: relative;
    top: 2px;
}

.enterpriseBox .yanzPhone .ant-form-item {
    width: 200px;
}

.enterpriseBox .yanzPhone select,
.enterpriseBox .yanzPhone .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    /* border-left: 0; */
    height: 32px;
}

.enterpriseBox .inpBox .next {
    width: 100%;
    margin-left: 2%;
    height: 35px;
}

.enterpriseBox .representative {
    margin-bottom: 50px;
}

.enterpriseBox .representative>div {
    display: flex;
    justify-content: space-between;
}

.enterpriseBox .representative>div .ant-form-item {
    width: 32%;
}

.codeModalBox {
    width: 400px !important;
}

.codeModalBox .codeTitle {
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 40px;
}

.codeModalBox .VerifyBtn {
    display: inline-block;
    width: 55px;
    height: 31px;
    margin-left: 10px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border: 1px solid #846a29;
    color: #111;
    margin: 0;
    outline: 0;
    font-size: 13px;
}

.codeModalBox .verificationBox {
    display: flex;
}

.codeModalBox .codeContentBox>p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
}

.codeModalBox .verificationBox .ant-form {
    margin-right: 10px;
}

.enterpriseBox .elailTips {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    z-index: 999999;
}

.enterpriseBox .elailTips img {
    width: 200px;
    height: 200px;
}

.enterpriseBox .meailModalBox .ant-modal-body {
    text-align: center;
}

.yanzPhone_prefix img {
    width: 20px;
    height: 14px;
    margin-bottom: 1px;
}

.yanzPhone_prefix .anticon {
    font-size: 8px;
    margin: 0 5px;
}

.enterpriseBox .anticon-question-circle {
    color: rgb(204, 204, 204);
    margin-left: 4px;
}

.enterpriseBox .a-icon-alert {
    background-position: -350px -35px;
}