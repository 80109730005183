.previewtaxationInfoBox .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #C45500;
    border: 1px solid #C45500;
}
.previewtaxationInfoBox .bottomDesc{
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}
.previewtaxationInfoBox .topTitle{
    font-size: 24px;
}
.previewtaxationInfoBox .selectBox
.previewtaxationInfoBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}
.previewtaxationInfoBox .a-spacing-top-large {
    margin-top: 24px!important;
}
.previewtaxationInfoBox .ant-steps{
    margin: 20px auto 50px;
    width: 70%;
}
.CommentatorBox .mybody-ul li:nth-of-type(3){
    border-bottom: 0;
}
.previewtaxationInfoBox .a-size-large {
    font-size: 13px!important;
    line-height: 1.3!important;
    font-weight: bold;
}
.previewtaxationInfoBox .program-overview-values {
    padding: 20px;
    margin: 0;
    width: 65%;
}
.previewtaxationInfoBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table!important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}
.previewtaxationInfoBox .ng-scope{
    text-align: center;
}
.previewtaxationInfoBox .a-size-medium {
    font-size: 17px!important;
    line-height: 1.255!important;
}
.previewtaxationInfoBox .a-size-small {
    font-size: 12px!important;
    line-height: 1.5!important;
}
.previewtaxationInfoBox .eCkQDX{
    display: none;
}
.previewtaxationInfoBox .jXxwsB span.icon,
.previewtaxationInfoBox .jXxwsB .websit.ant-select,
.previewtaxationInfoBox .jXxwsB .language.ant-select .ant-select-selector,
.previewtaxationInfoBox .jXxwsB .ant-select-arrow,
.previewtaxationInfoBox .jXxwsB .search,
.previewtaxationInfoBox .jXxwsB .language.ant-select,
.previewtaxationInfoBox .jXxwsB .ilQBwr button{
    display: none;
}
.previewtaxationInfoBox .hoQOMo>div{
    display: block;
}
.previewtaxationInfoBox .jXxwsB .ilQBwr button:last-child{
    display: inline-block;
}
.previewtaxationInfoBox .jXxwsB .ilQBwr{
    text-align: right;
}
.previewtaxationInfoBox .CompanyAddress{
    width: 90%;
    margin:0;
}
.previewtaxationInfoBox .selectBox{
    width: 49%;
    height: 35px;
}
.previewtaxationInfoBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;

}
.previewtaxationInfoBox .rightCollapse{
    width: 35%;
}
.previewtaxationInfoBox .rightCollapse>p{
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}
.previewtaxationInfoBox .inpBox{
    margin-top: 15px;
}
.previewtaxationInfoBox .inpBox1 input{
    padding-left: 5px;
    width: 100%;
    height: 35px;
}
.previewtaxationInfoBox .inpBox input:nth-child(2){
    margin-left: 2%;
}
.previewtaxationInfoBox .aCheckbox{
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.previewtaxationInfoBox .aCheckbox span{
    margin-left: 5px;
}
.previewtaxationInfoBox .receivingMode{
    margin-top: 20px;
}
.previewtaxationInfoBox .receivingMode p{
    font-weight: bold;
    margin-bottom: 0;
}
.previewtaxationInfoBox .yanzPhone{
    display: flex;
    border: 1px solid #d9d9d9;
}
.previewtaxationInfoBox .yanzPhone select,
.previewtaxationInfoBox .yanzPhone .ant-input-affix-wrapper{
    border: none;
}
.previewtaxationInfoBox .inpBox .next{
    width: 49%;
    margin-left: 2%;
    height: 35px;
}
.previewtaxationInfoBox .representative input+input{
    margin-left: 2%;
}
.previewtaxationInfoBox .billTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.previewtaxationInfoBox .billTitle div p{
    font-size: 16px;
    font-weight: bold;
}
.previewtaxationInfoBox .billTitle div p:first-child{
    font-size: 22px;
}
.previewtaxationInfoBox .billTitle div:last-child{
    font-size: 14px;
    color: rgb(165, 126, 192);
}
.previewtaxationInfoBox .inpBox1{
    display: flex;
}
.previewtaxationInfoBox .inpBox1 p{
    font-weight: bold;
}
.previewtaxationInfoBox .inpBox1 .dates{
    margin-left: 2%;
    width: 66%;
}
.previewtaxationInfoBox .inpBox1 .dates>div{
    display: flex;
}
.previewtaxationInfoBox .inpBox1 .dates select{
    height: 35px;
    width: 40%;
}
.previewtaxationInfoBox .inpBox1 .dates span{
    margin: 0 10%;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
}
.previewtaxationInfoBox .bottomDesc .ant-radio-wrapper{
    word-wrap: break-word !important;
    word-break: normal;
    white-space:pre-wrap
}
.previewtaxationInfoBox .depositInfo{
    width: 49%;
    margin-bottom: 15px;
}
.previewtaxationInfoBox .depositInfo:nth-child(2){
    margin-left: 2%;
}
.previewtaxationInfoBox .depositInfo select,
.previewtaxationInfoBox .depositInfo input{
    width: 100%;
}
.previewtaxationInfoBox .operationBtn{
    display: flex;
    justify-content: center;
}
.previewtaxationInfoBox .operationBtn .next{
    width: 30%;
}
.previewtaxationInfoBox .previousPage{
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}
.previewtaxationInfoBox .btnClass{
    display: flex;
    margin-top: 20px;
}
.previewtaxationInfoBox .btnClass span{
    display: block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #00000050;
    margin-left: 15px;
    text-align: center;
    cursor: pointer;
}
.previewtaxationInfoBox .nsInfoTitle{
    margin-top: 40px;
}
.previewtaxationInfoBox .nsInfoBox{
    border-radius: 5px;
    border: 1px solid #00000030;
    padding: 30px;
}
.previewtaxationInfoBox .xyTable{
    border-radius: 5px;
    background-color: rgb(246, 246, 246);
    padding: 30px;
}
.previewtaxationInfoBox .xyTable .bolds{
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;

}
.previewtaxationInfoBox .xyTable select{
    width: 100%;
    height: 35px;
}
.previewtaxationInfoBox .CheckboxClass{
    margin: 30px 0;
}
.previewtaxationInfoBox .CheckboxClass p{
    margin-top: 5px;
}
.previewtaxationInfoBox .selectClass{
    background-color: #fef8f2;
    border: 1px solid #e77b09;
}


