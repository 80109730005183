.addDiscountBox table td{
    padding: 7px 14px 6px;
}
.addDiscountBox .cengjiTitles{
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 10px;
}
.addDiscountBox .addCengji{
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    padding: 0 6px 0 7px;
    color: #111;
    background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
    background: #e7e9ec;
    border-radius: 3px;
    border-color: #ADB1B8 #A2A6AC #8D9096;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    text-align: center;
    text-decoration: none!important;
    vertical-align: middle;
    margin-top: 10px;
}
.addDiscountBox .ant-radio-group{
    width: 100%;
}
.addDiscountBox .counpType .ant-radio-wrapper{
    width: 25%;
}
.addDiscountBox .zdytips{
    cursor: pointer;
}
.addDiscountBox .hides{
    display: none;
}