.priceBox{
    width:30%;
    padding:0 11px 0 0;
    height:32px
}
.priceBox .ant-input-prefix{
    width: 50px;
    padding-left: 11px;
    background-color: #fafafa;
    border-right: 1px solid #AAB7B8;
}
.tab1Banner-box .df {
    position: relative;
}
.tab1Banner-box .ant-form-item{
    width: 100%;
}