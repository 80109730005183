.a-tabs .ant-tabs-tab-active {
  border-top: none !important;
}

.a-tabs.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.a-tabs.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.a-tabs.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.a-tabs.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.a-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #999 !important;
}

.a-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #002f36 !important;
}

.a-tabs.ant-tabs-card
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active {
  border-top: 1px solid #f0f0f0 !important;
}
