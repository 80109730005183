
.kc28_content {
	margin:0 15%  0 2%  ;
    padding-bottom: 300px;
}

.kc28_content {
	margin: 1%;
}
.kc28_tab-ul{
    display: flex;
}
.kc28_tab-ul li{
    height: 16px;
    padding: 0 15px;
    color: #0D6AC1;
    font-size: 15px;
    border-left: 1px solid #CCC;
    display: flex;
    align-items: center;
}
.kc28_tab-ul li:nth-child(1){
    padding-left: 0;
    border-left: none;
}
.kc28_tab-ul li span{
    font-size: 13px;
    margin-left: 5px;
     color: #E57912;
}
.kc28_tab{
    margin-bottom: 20px;
}
.kc28_title-p-l{
    font-size: 27px;
}
.kc28_title-p-l span{
    font-size: 14px;
    margin-left: 10px;
    color: #0D6AC1;
}
.kc28_title-box {
    display: flex;
    justify-content: space-between;
}
.kc28_title-box-r button{
   padding:5px 10px;
   margin-right: 10px;
}
.kc28_title-box-r a{
    color: #000;
}
.kc28_warnbox{
    padding: 10px 30px;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: inset 0 0 3px #E57912;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 24px 15px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
}

.kc28_warnbox svg{
    height: 2rem;
    width: 2rem;
    margin-right: 20px;
}

.kc28_inpbox{
    display: flex;
    align-items: flex-start;
}
.kc28_inpbox select{
    height: 30px;
    border-radius: 3px;
    border: 1px solid #CCC;
    padding: 6px;
    background-color: #F7F8FA;
    font-size: 12px;
}
.kc28_inpbox input{
    width: 195px;
    height: 30px;
    padding:0 0 0  22px ;
    margin-left: 20px;
    border-radius: 3px;
    border: 1px solid #000;
    background-image: url(../../assets/images/kc28_sousuo.png);
    background-size: 15px;
    background-position: 5px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    
}
.kc28_inpbox button{
    height: 30px;
    padding: 3px 10px;
    color: #FFF;
    background-color: #70767D;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 3px;
}
.kc28_inpbox span{
    height: 30px;
    font-size: 12px;
    margin-left: 15px;
    margin-top: 5px;
}
.kc28_screen{
    font-size: 12px;
    background-color: #F3F3F3;
    color: #999da1;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 10px;
    min-width: 1642px;
}
.kc28_sn1{
	padding: 0;
	display: flex;
}
.kc28_screen-ul-l{
	width: 45%;
	display: flex;
	padding:0 10px 10px ;
}
.kc28_screen-ul-l li{
	width: 90px;
	font-size: 12px;
	margin-top: 10px;
	color: #0D6AC1;
	font-weight: bold;
}
.kc28_screen-ul-l li:nth-child(1){
	width: 130px;
	
}
.kc28_ct{
	display: flex;
	align-items: center;
}
.kc28_screen-ul-l li input{
	margin-right: 8px;
}
.kc28_gray{
	color: #999da1 !important;
	font-weight: 400 ;
	font-size: 12px;
}
.kc28_screen-ul-r{
	width: 850;
	height: 100%;
	display: flex;
}
.kc28_screen-ul-r li{
	font-size: 12px;
	padding-top: 10px;
	color: #000;
	font-weight: bold;
	padding-right: 40px;
	margin-left: 10px;
}
.kc28_screen-ul-r li:nth-child(1){
	margin-left: 0;
	padding-right: 0;
}
.kc28_screen-ul-r li:nth-child(7){
	margin-left: 0;
	padding-right: 0;
}
.kc28_screen-ul-r li:nth-child(5){
	text-align: right;
	margin-right: 50px;
}
.kc28_screen-ul-r li:nth-child(6){
	margin-right: 50px;
}
.kc28_screen-ul-r li:nth-child(4){
	text-align: center;
}
.kc28_screen-ul-r li:nth-child(3){
	text-align: center;
}
.kc28_screen-ul-l li:nth-child(2){
	margin-left: 20px;
}
.kc28_screen-ul-r li:last-child{
	padding-right: 0;
}
.kc28_orange{

	margin-top: -1px;
	border-top: 1px solid #E57912;
	color: #E57912 !important;
	padding-left: 5px;
    padding-right: 10px;
}
.kc28_orange_xsj{
    width: 10px;
    height: 10px;
    background-image: url(../../assets/images/kc28_xsj.png);
    float:right;
    margin: 4px 10px 0 0;
    background-size: 9px;
}
.kc28_orange img{
	height: 10px;
}
.kc28_blue{
	width: 80px;
	color: #0D6AC1 !important;
}
.kc28_sn1 button{
	width: 120px;
	height: 26px;
	background-color: #F9F3E5;
	border: 1px solid #CCC;
	color: #000;
	font-size: 12px;
	margin: 10px;
}
.kc28_sn2 {
	height: 82px;
	padding: 0;
	display: flex;
	background-color: #FFF;
	position: relative;
}
.kc28_sn2 .kc28_screen-ul-r li , .kc28_sn2 .kc28_screen-ul-l li{
	font-weight: 400 !important;
}
.kc28_sn2 .kc28_screen-ul-l li:nth-child(2){
	width: 90px;
	margin-left: 20px;
}
.kc28_sn2 .kc28_screen-ul-l li:nth-child(3){
	width: auto;
}
.kc28_sn2 .kc28_screen-ul-r li:nth-child(2){
	padding-right: 0px;
}
.kc28_sn2 .kc28_screen-ul-r li:last-of-type{
	width: 60px;
	padding-right: 28px;
	text-align: center;
}
 .kc28_screen-ul-r input{
	text-align: right;
	padding-right: 10px;
	width: 105px;
	height: 28px;
	margin: 10px 10px;
	border: 1px solid rgba(0,0,0,0.5);
	border-radius: 3px;
}
.kc28_screen-ul-r input:first-of-type{
	width: 50px !important;
}

.kc28_sn2 .kc28_screen-ul-r input:nth-child(5){
	margin-right: 10px;
}
.kc28_q{
	background-image: url(../../assets/images/kc28_my.png);
	background-size: 15px;
	background-repeat: no-repeat;
	background-position: 10px center ;
}
.kc28_sn2 .kc28_screen-ul-r select{
	height: 26px;
	margin-top: 10px;
	background-color: #F2F3F6;
	border-radius:0  3px 3px 0;
	border: 1px solid rgba(0,0,0,0.4);
}

.kc28_sele{
	width: 80px;
	height: 26px;
	margin-top: 10px;
	border: 1px solid rgba(0,0,0,0.4);
	border-radius:  3px 0 0 3px ;
	border-right: 0;
	color: #000000;
	display: flex;
	align-items: center;
	padding-left: 12px;
	background-color: #F2F3F6;
}
.kc28_cuowu{
	font-size: 8px;
    color: #000000;
    display: flex;
    align-items: center;
}


.kc28_cuowu_js{
    width: 10px;
    height: 10px;
    background-image: url(../../assets//images/kc28_js.png);
    background-size: 10px;
}
.kc28_position-ul{
	position: absolute;
	bottom: -19%;
	right: 30%;
	display: flex;
}
.kc28_position-ul li{
	width: 130px !important;
	text-align: right;
	margin: 0 22px 0 0;
	font-size: 10px;
}
.kc28_position-ul li:nth-child(2){
    width: 105px !important;
}
.kc28_img_xl{
    width: 18px;
    height: 18px;
    background-image: url(../../assets/images/kc28_xl.png);
    background-size: 17px;
}

.kc28_position-ul li div{
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.kc28_position-ul li p{
	width: auto;
}
.kc28_position-ul li img{
	height: 18px;
	
}
.kc28_hei{
	color: #000000;
}

/* 底部 */
.kc28_myfoot {
	
    border-top: 1px solid #dcdcdc;
    padding: 20px 40px;
    background-color: #FFF;
    position: relative;
    margin-top: 25px;
}

.kc28_myfoot ul {
    width: 100%;
    font-size: 14px;
    color: #06393e;
    overflow: hidden;
}

.kc28_myfoot ul li select {
    height: 25px;
    border: 1px solid #CCC;
    border-radius: 3px;
    padding: 0 50px 0 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url('../../assets/images/kc20_sanjiao.png') center no-repeat #fff;
    background-position: 95% 50%;
    background-size: 10px;
    margin-right: 20px;
}

.kc28_myfoot ul li:last-of-type {
    color: #999999;
    float: right;

}

.kc28_myfoot ul li:nth-of-type(2)::after {
    content: "";
    width: 1px;
    height: 21px;
    background-color: #DCDCDC;
    position: absolute;
    top: 2px;
    right: 0;
    
}

.kc28_myfoot ul li:nth-of-type(2)::before {
    content: "";
    width: 1px;
    height: 21px;
    background-color: #DCDCDC;
    position: absolute;
    top: 2px;
    left: 0;
}

.kc28_myfoot ul li {
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    float: left;
    position: relative;
}
.kc28_page{
    width: 98%;
    padding:0  1%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -20px;
    left: 0;
}
.kc28_page-l{
    display: flex;
    align-items: flex-start;
}
.kc28_page-l span{
    font-size: 13px;
    margin-right: 5px;
    padding-top: 5px;
}
.kc28_page-l span:nth-child(3){
    font-size: 12px;
}
.kc28_page-l input{
    width: 60px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.4);
    margin:0 5px 0 0 ;
    padding-left: 5px;
}
.kc28_page-l button{
    height: 35px;
    padding: 5px 8px;
    background-color: #F0F1F4;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 3px;
}
.kc28_page-r{
    display: flex;
}
.kc28_sele2{
    width: 120px;
    height: 33px;
    border: 1px solid rgba(0,0,0,0.5);
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-right:0 ;
    background-color: #F6F7F9;
    border-radius: 3px 0 0 3px;
}
.kc28_page-r select{
    height: 35px;
    border: 1px solid rgba(0,0,0,0.5);
    border-left:0 ;
    background-color: #F6F7F9;
    border-radius: 0 3px 3px 0;
}