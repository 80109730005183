.qr13_contcent{
    width: 100%;
    display: flex;
}
.qr13_contcent-ul{
    width: 246px;;
  
    margin: 20px 36px;
    height:375px;
    background:#F3F3F3;
    list-style: none;
    font-size: 15px;
    border: 1px solid #DFDFDF;
}
.qr13_contcent-ul>li{
    height:50px;
    line-height:50px;
    font-weight: 700;
    text-indent: 2em;
    border-bottom: 1px solid #DFDFDF;
}

.qr13_contcent-ul>li:first-of-type,.qr13_contcent-ul li:nth-of-type(3){
    height:60px;
    line-height:70px;;
}
.qr13_contcent-ul>li:nth-of-type(2){
    color: #acacac;
    font-weight:initial;
}
.qr13_contcent-ul>li:nth-of-type(4){
   background:#E4E4E4 ;
}
.qr13_contcent-ul>li:last-of-type{
   border: none;
}
.qr13_contcent-box{
    
    margin-top: 20px;
    width: 80%;
    height: 350px;
}
.qr13_contcent-box>div:first-of-type{
    font-size:26px;
    height:33px;
    position: relative;
}
.qr13_contcent-box>div:first-of-type span{
    font-size:12px;
    height:18px;
    text-align: center;
    margin-top:8px;
    position: absolute;
    color: #3083CC;
}
.qr13_contcent-box>div:first-of-type span:first-of-type{
   color: #302EFF;
   margin-left: 10px;
 }
.qr13_contcent-box>div:first-of-type span:nth-of-type(2){
   margin-left:50px;
   width:77px;
   border-left: 1px solid #000;
}
.qr13_contcent-box>div:first-of-type span:nth-of-type(3){
    margin-left:130px;
    width:60px;
    border-left: 1px solid #000;
 }
 .qr13_contcent-box>div:first-of-type span:last-of-type{
   right: 20px;
   color: #3E7ACA;
   font-size: 12px;
   font-weight: 600;
 }
 .qr13_contcent-box>div:nth-of-type(2){
     font-size: 16px;
     font-weight:900;
     margin-top: 30px;
 }
 .qr13_contcent-box>div:nth-of-type(3){
    font-size: 15px;
    color: #565656; 
}
.qr13_contcent-box>div:nth-of-type(4){
    font-size: 13px;
   color:#2C80CB;
}

.qr13_contcent-box>div:nth-of-type(4) span{
    color: #000;
}
.qr13_contcent-box-ul{
    list-style: none;
    margin-top: 20px;
    background: #F3F3F3;
    margin-right: 30px;
    overflow: hidden;
    height:60px;
    border: 2px solid #EFEFEF;
}
.qr13_contcent-box-ul li{
    float: left;
    font-size: 15px;
    margin-top: 6px;
    margin-left:150px;
}
.qr13_contcent-box-ul li:first-of-type{
   margin-left: 10px;
}
.qr13_contcent-box-ul li:nth-of-type(2) p{
    color: #888;
 }
 .qr13_contcent-box-ul li:nth-of-type(3){
    margin-left:500px;
 }
 .qr13_contcent-box-ul li:nth-of-type(5){
   float: right;
   margin-right: 130px;
 }
 .qr13_contcent-box>div:nth-of-type(5){
    height:50px;
    margin-right: 30px;
  border-bottom: 2px solid #EAEAEA;
}
.qr13_boxbac{
    border: 2px solid #C5D9E3;
    height:38px;
    margin:8px;
    border-radius: 5px;
    background: #F6F6F6;
}
.qr13_img{
    display: block;
    float: left;
    margin: 7px 0;
    width: 36px;
   color:#C5D9E3 ;
}
.qr13_boxbac>p{
    margin-top: 7px;
    float: left;
}

