.login_foot {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0 10px 0;
}

.login_foot div {
    margin: 0 25px;
    font-size: 12px;
}

.gvRLFR p {
    padding: 0 !important;
}

.login_line {
    height: 44px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
}

.login_line::after {
    display: block;
    width: 100%;
    height: 44px;
    background-color: transparent;
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
    content: "";
}

.sendEmailBox .fwobuC,
.sendEmailBox .efYuOf {
    padding-top: 0;
}

.sendEmailBox .fwobuC::before,
.sendEmailBox .efYuOf::before {
    background: none;
    margin-bottom: 20px;
}

.sendEmailBox .efYuOf p {
    text-align: center;
}

.sendEmailBox .elailTips {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
}

.sendEmailBox .elailTips img {
    width: 200px;
    height: 200px;
}

.sendEmailBox .stepGuidanceBox0 {
    margin-bottom: 10px;
}

.meailModalBox p {
    font-family: "arial", "sans-serif";
    font-size: 14px;
}

.meailModalBox .otp {
    font-size: 20px;
    font-weight: bold;
}

.meailModalBox .titleLogo {
    margin: 40px 0 20px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.meailModalBox .titleLogo img {
    width: 107px;
    height: 31px;
}

.meailModalBox .titleLogo span {
    font-size: 20px;
    font-family: "arial", "sans-serif";
    color: #E47911;
}

/* .mobileNumber .ant-input-group{

} */
.mobileNumber .ant-input-group .ant-select {
    width: 80px !important;
    margin-right: 10px;
    border-radius: 10px !important;
    padding: 0;
}

.mobileNumber .ant-input-group .ant-select .ant-select-selector {
    border-radius: 3px !important;
    background: #e7e9ec !important;
    border-color: #ADB1B8 #A2A6AC #8D9096 !important;
    padding: 0 6px;
    font-size: 12px;
    /* background-color: #d9d9d9; */
}

.mobileNumber .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ADB1B8 #A2A6AC #8D9096 !important;
}

.mobileNumber .ant-input-group .ant-input {
    width: calc(100% - 100px) !important;
    border-radius: 4px;
}

.mobileNumber .ant-select-arrow {
    right: 6px;
}

.mobileNumber .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 10px;
}

/* .mobileNumber .page1 .ant-form-item-explain{
    padding-left: 86px;
} */
.cxin {
    position: relative;
    padding-left: 22px;
    color: #007600;
    font-size: 13px;
}

.cxin .a-icon-alert {
    background-position: -117px -130px;
    height: 13px;
    width: 14px;
    left: 2px;
    top: 2px;
}