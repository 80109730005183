	
	#qd09_isTjbj{
		width: 100%;
		min-width: 1890px;
	}
	.qd09_titbox1{
		margin: 10px 0 0 20px;
	}
	.qd09_titbox1>p{
		font-size: 22px;
		font-weight: 100;
		color: rgba(0,0,0,0.7);
	}
	.qd09_titbox1>p>span{
		font-size: 13px;
		color: #008296;
		margin-left: 20px;
	}
	.qd09_titbox2{
		margin-top: 20px;
		padding: 20px 0 20px 20px;
		background: #e5f2f4;
		display: flex;
		position: relative;
	}
	.qd09_titbox2>img{
		height: 40px;
		vertical-align: middle;
	}
	.qd09_titbox2>.qd09_box_con{
		margin-left: 10px;
		position: relative;
	}
	.qd09_titbox2>.qd09_box_con>p{
		font-size: 14px;
		font-weight: 700;
	}
	.qd09_titbox2> .qd09_con_x{
		position: absolute;
		right: 20px;
	}
	.qd09_titbox2>.qd09_box_con>span{
		font-size: 14px;
	}
	.qd09_titbox2>.qd09_box_con>span>span{
		color: #008296;
	}
	.qd09_zshou{
		margin-top: 20px;
	}
	.qd09_zshou>.qd09_splist{
		padding-left: 10px;
		display: flex;
		background: #fafafa;
    }
    .qd09_splist a{
        color: #000;
    }
	.qd09_zshou>.qd09_splist>li{
		font-size: 14px;
		margin-right: 30px;
		padding-top: 10px;
		height: 50px;
	}
	.qd09_splist>li:nth-of-type(2){
		border-bottom:2px solid #008296;
	}
	.qd09_gailaii{
		margin: 20px 0 0 10px;
	}
	.qd09_splist2box{
		background: #fafafa;
		padding-bottom: 5px;
	}
	.qd09_splist2{
		width: 300px;
		padding-left: 10px;
		display: flex;
		border-bottom: 1px solid #ccc;
		padding-bottom: 3px;
	}
	.qd09_splist2>li{
		font-size: 14px;
		margin-right: 30px;
		padding-top: 10px;
		height: 50px;
	}
	.qd09_splist2>li:nth-of-type(1){
		border-bottom:2px solid #008296;
	}
	.qd09_flex2{
		display: flex;
	}
	.qd09_gailaii>p{
		margin-left: 20px;
		font-size: 18px;
		font-weight: 500;
	}
	.qd09_glbox{
		width: 600px;
		margin-top: 20px;
		margin-right: 20px;
		border: 1px solid #ccc;
	}
	.qd09_glbox>p{
		font-size: 15px;
		margin: 10px 0 0 20px;
	}
	.qd09_glbox>p:nth-of-type(2){
		margin-top: 0;
		font-size: 12px;
		color: rgba(0,0,0,0.5);
	}
	.qd09_gl_con_lan{
		color: #008296 !important;
	}
	.qd09_glbox>p>svg{
		margin-left: 3px;
		vertical-align: middle;
	}
	.qd09_gl_con{
		width: 350px;
		margin: 0 auto;
		padding: 30px 0;
		text-align: center;
	}
	.qd09_gl_con>p{
		font-size: 26px;
		margin: 10px 0;
	}
	.qd09_gl_con>span{
		display: block;
		font-size: 10px;
		color: rgba(0,0,0,0.6);
	}
	.qd09_tiaojian{
		margin: 30px 0 0;
	}
	.qd09_tj_btn{
		margin-left: 20px;
		margin-bottom: 10px;
		display: flex;
	}
	.qd09_tj_btn>li{
		width: 80px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		font-size: 14px;
	}
	.qd09_tj_btn>li:nth-of-type(1){
		font-size: 14px;
		font-weight: 600;
	}
	.qd09_timer{
		width: 50px;
		border: 1px solid #ccc;
	}
	.qd09_timer:not(:nth-of-type(1)){
		border-left: none;
	}
	#qd09_lfbor{
		border-left: 1px solid #ccc !important;
	}
	.qd09_tj_text{
		margin: 5px 0 0 40px;
		list-style-type:disc;
	}
	.qd09_tj_text>li>img{
		vertical-align: middle;
		margin-right: 7px;
	}
	.qd09_tj_text>li{
		
	}
	.qd09_cuos{
		margin: 30px 20px 0 10px;
	}
	.qd09_cuos>span:nth-of-type(1){
		font-size: 18px;
		font-weight: 100;
		color: rgba(0,0,0,0.6);
	}
	.qd09_cuos>p:nth-of-type(1){
		margin-top: 10px;
		font-size: 13px;
	}
	.qd09_cuos>p:nth-of-type(1)>span{
		color: #008296;
	}
	.qd09_fizs{
		font-size: 18px;
		font-weight: 0;
		color: rgba(0,0,0,0.6);
		margin: 20px 0 10px 10px;
	}
	.qd09_spl3{
		padding-left: 10px;
	}
	.qd09_tuijbox{
		min-width: 1830px;
		border: 1px solid  #008296;
		margin-left: 10px;
	}
	
	.qd09_fzsList{
		display: flex;
		border-top: 1px solid #ccc;
		border-bottom: 3px solid #ccc;
	}
	.qd09_fzsList>li{
		
	}
	.qd09_fzsList>li>.qd09_miaos:nth-of-type(1){
		
	}
	.qd09_fzsList>li>.qd09_miaos{
		height: 40px;
		padding: 10px 0 20px;
		position: relative;
	}
	.qd09_fzsList>li>.qd09_miaos svg{
		margin-left: 3px;
	}
	.qd09_miaos>p:nth-of-type(1){
		margin-top: 10px;
		margin-left:20px;
		font-size: 13px;
		font-weight: 800;
	}
	.qd09_miaos>p:nth-last-of-type(1){
		color: rgba(0,0,0,0.6);
		font-size: 14px;
		margin-left:20px;
	}
	.qd09_miaos2{
		border-top: 1px solid #ccc;
		padding: 30px 20px;
		position: relative;
	}
	#qd09_iskey{
		height: 50px;
	}
	.qd09_flex1{
		display: flex;
		padding-left: 20px;
	}
	.qd09_key_ms{
		font-size: 13px;
	}
	.qd09_key_ms>p:nth-of-type(1){
		color: #008296;
	}
	.qd09_key_ms>p:nth-of-type(2){
		color: #008296;
	}
	.qd09_bufuhe{
		font-size: 13px;
		margin-top: 20px;
	}
	.qd09_bufuhe p{
		font-weight: 800;
	}
	.qd09_bufuhe p>span{
		font-weight: 400;
	}
	.qd09_myjiage{
		margin-top: 50px;
		font-size: 12px;
		text-align: right;
	}
	.qd09_myp_right{
		text-align: right;
	}
	.qd09_myto{
		font-size: 12px;
		margin-top: 65px;
	}
	.qd09_myshez{
		margin-top: 50px;
		font-size: 12px;
	}
	.qd09_iscank{
		padding: 0;
	}
	.qd09_myck{
		text-align: right;
		font-size: 12px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.qd09_gengxin{
		width: 60px;
		height: 60px;
		border-radius: 5px;
		background-color: #008296;
		color: white;
		overflow: hidden;
		position: absolute;
		right: 10px;
	}
	.qd09_gengxin>p{
		width: 10px;
		font-size: 12px;
		margin: 13px auto 0;
	}
	.qd09_fzsList img{
		vertical-align: text-top;
		margin-left: 5px;
	}
	.qd09_mar0{
		width: 470px;
	}
	.qd09_mar1{
		width: 320px;
	}
	.qd09_mar2{
		width:100px;
	}
	.qd09_mar4{
		margin-left: 290px;
		width:100px;
	}
	.qd09_mar5{
		width: 320px;
		text-align: right;
	}
	.qd09_mar5 p:nth-of-type(1){
		padding-right: 20px;
	}
	.qd09_mar7{
		margin-left: 150px;
	}
	.qd09_gengduo{
		padding: 20px 0;
		background: #fafafa;
	}
	.qd09_gengduo>p{
		width: 360px;
		margin: 0 auto;
		font-size: 12px;
		letter-spacing:2px;
		font-weight: 100;
	}