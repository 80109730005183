.kc26_tab{
    min-width: 100%;
    /* padding: 0px 20px; */
    padding-left: 20px;
    padding-right: 20px;
    margin: 20px 20px 0;
}
.kc26_lllff{
    display: flex;
    justify-content:space-between;
}
.kc26_rongyutit{
    list-style: none;
    display: flex;
    position: relative;
}
.kc26_rongyutit li>h2{
    font-size: 28px;
    font-weight: 400;
    color: #585555;
}
.kc26_rongyutit li:nth-of-type(2){
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}
.kc26_rongyutit li:nth-of-type(2) span{
    font-size: 10px;
    margin: 0 5px;
    vertical-align: top;
    color: black !important;
}
.kc26_lllff p{
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}
.kc26_lllff p>span{
    font-size: 10px;
    margin: 0 5px;
    vertical-align: top;
    color: black !important;
}
.kc26_rongyu>p{
    color: #AAAAAA;
    margin-top: 10px;
    font-size: 14px;
}
.kc26_rongyu>p>span{
    color: #41A2B1 !important;
}
.kc26_xinxi{
    border: 1px solid #ccc;
    margin-top: 30px;
    border-radius: 3px;
}
.kc26_xin_box1{
    display: flex;
    justify-content:space-between;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}
.kc26_box1_l{
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
}
.kc26_box1_l span:nth-of-type(1){
    border: 2px solid #008296;
    padding: 10px;
    border-radius: 3px;
}
.kc26_box1_l span:nth-of-type(2){
    border: 2px solid #ccc;
    margin-left: -4px;
    padding: 10px;
    border-radius: 3px;
}
.kc26_box1_r{
    margin-left: 20px;
    margin-right: 40px;
}
.kc26_box1_r input{
    width: 300px;
    padding: 8px;
    border-radius:3px;
    border:1px solid rgba(0, 0,.0, 0.5);
    font-style: italic;
}
.kc26_box1_r img{
   width: 40px;
   vertical-align: middle;
   border-top-right-radius:5px;
   border-bottom-right-radius:5px;
   margin-left: -3px;
}
.kc26_xin_box2{
    padding: 15px 20px;
    color: rgba(0, 0,.0, 0.5);
    font-size: 14px;
    font-weight: 600;
}
.kc26_xin_box3{
    padding: 20px;
    border-top: 1px solid #ccc;
    background-color: #FAFAFA;
}
.kc26_xin_box3 ul{
    list-style: none;
    display: flex;
}
.kc26_xin_box3 ul li{
    font-size: 14px;
    font-weight: 800;
    position: relative;
}
.kc26_xin_box3 ul li:nth-of-type(1){
    
}
.kc26_xin_box3 ul li:nth-of-type(2){
   margin-left: 600px;
}
.kc26_xin_box3 ul li:nth-of-type(3){
    margin-left: 100px;
 }
 .kc26_xin_box3 ul li:nth-of-type(4){
    margin-left: 200px;
 }
.kc26_xin_box3 ul li:not(:nth-of-type(1)){
   border-left: 2px solid #ccc;
   padding-left: 20px;
}
.kc26_xin_box3 ul li>p{
    font-size: 12px !important;
   color: rgba(0, 0,.0, 0.5);
}
.kc26_jianyi{
    padding: 30px 20px;
    border-bottom: 1px solid #ccc;
}
.kc26_jianyi p{
    color: #20494F;
    font-size: 18px;
}
.kc26_jianyi ul{
	margin-top: 10px;
    margin-left: 40px;
    color: rgba(0, 0,.0, 0.8);
    font-size: 14px;
	list-style-type:disc;
}
.kc26_fanye{
    margin: 30px 0 0;
    position: relative;
}
.kc26_box{
    width: 100px;
    margin: 0 auto;
}
.kc26_box img{
    width: 10px;
    margin-left: 10px;
}
.kc26_aboBox{
    position:absolute;
    right:20px;
    top: 0;
}
.kc26_aboBox select{
    height: 40px;
    padding: 0px 30px 0  5px;
    font-weight: 600;
    color: #20494F;
    border: 2px solid #ccc;
}