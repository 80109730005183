.end06_mybody .mybody-ul {
    width: 390px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    cursor: pointer;
}

.AuthenticationTwoBox .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #C45500;
    border: 1px solid #C45500;
}

.AuthenticationTwoBox .bottomDesc {
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.agreementsBox .selectBox .AuthenticationTwoBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}

.AuthenticationTwoBox .a-spacing-top-large {
    margin-top: 24px !important;
}

.AuthenticationTwoBox .ant-steps {
    margin: 20px auto 50px;
    width: 70%;
}

.CommentatorBox .mybody-ul li:nth-of-type(3) {
    border-bottom: 0;
}

.end06_mybody .mybody-ul li:nth-of-type(1) {
    border-bottom: 3px solid #FFBA53;
}

.commentBg {
    height: 300px;
    background: url('~@/assets/images/commentBg.png') no-repeat;
    background-size: cover;
    display: flex;
}

.program-overview-title-box {
    padding: 60px 0 0 80px;
    width: 400px;
    background: rgba(39, 62, 84, .7);
    height: 100%;
    color: #fff;
}

.program-overview-diagonal {
    height: 0;
    width: 0;
    border-bottom: 300px solid rgba(39, 62, 84, .7);
    border-right: 100px solid transparent;
}

.heading-text {
    color: #fff;
    font-size: 40px;
}

.a-spacing-small,
.a-ws .a-ws-spacing-small {
    margin-bottom: 8px !important;
}

.a-spacing-medium,
.a-ws .a-ws-spacing-medium {
    margin-bottom: 16px !important;
}

.agreementsBox .a-size-large {
    font-size: 13px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.text-justify {
    text-align: justify;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.agreementsBox .program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: 0 auto;
    width: 80%;
}

.agreementsBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}

.agreementsBox .ng-scope {
    text-align: center;
}

.agreementsBox .a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.agreementsBox .a-size-small {
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.a-grid-vertical-align.a-row>div.a-span4 {
    width: 34.93%;
}

.a-grid-vertical-align.a-row>div.a-column {
    float: none !important;
    display: table-cell !important;
    zoom: 1;
    padding-right: 14px;
}

.a-ws div.a-column,
div.a-column {
    margin-right: 2%;
    float: left;
    min-height: 1px;
    overflow: visible;
}

.a-row .a-span4,
.a-ws .a-row .a-ws-span4 {
    width: 31.948%;
}

.value-box {
    padding: 35px !important;
}

.a-text-center {
    text-align: center !important;
}

.reviews-increase-icon {
    height: 78px;
    background: url(~@/assets/images/Business_image._V270892899_.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.reviews-faster-icon {
    height: 78px;
    background: url('~@/assets/images/mobile_image._V270892880_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-easy-icon {
    height: 78px;
    background: url('~@/assets/images/credit_card_image._V270892869_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-four-icon {
    height: 78px;
    background: url('~@/assets/images/tax_information_image._V270892852_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.a-section:last-child {
    margin-bottom: 0;
}

.program-overview-footer {
    padding: 45px;
}

.alternate-bg,
.program-overview-footer {
    background-color: #f4f5f5;
}

.a-button-primary:hover {
    color: #000;
}

.agreementsBox .eCkQDX {
    display: none;
}

.agreementsBox .jXxwsB span.icon,
.agreementsBox .jXxwsB .websit.ant-select,
.agreementsBox .jXxwsB .language.ant-select .ant-select-selector,
.agreementsBox .jXxwsB .ant-select-arrow,
.agreementsBox .jXxwsB .search,
.agreementsBox .jXxwsB .language.ant-select,
.agreementsBox .jXxwsB .ilQBwr button {
    display: none;
}

.agreementsBox .hoQOMo>div {
    display: block;
}

.agreementsBox .jXxwsB .ilQBwr button:last-child {
    display: inline-block;
}

.agreementsBox .jXxwsB .ilQBwr {
    text-align: right;
}

.agreementsBox .CompanyAddress {
    width: 55%;
    margin: 0 auto;
}

.AuthenticationTwoBox .selectBox {
    width: 49%;
    height: 35px;
}

.agreementsBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;

}

.agreementsBox .rightCollapse {
    width: 30%;
}

.agreementsBox .rightCollapse>p {
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}

.AuthenticationTwoBox .inpBox {
    display: flex;
}

.AuthenticationTwoBox .inpBox .yanzPhone input {
    width: 100%;
}

.AuthenticationTwoBox .ant-select-selector {
    height: 35px;
}

.AuthenticationTwoBox .addressBox .ant-form-item {
    width: 50%;
}

.AuthenticationTwoBox .inpBox .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 5px;
}

.AuthenticationTwoBox .inpBox1 {
    display: flex;
}

.AuthenticationTwoBox .inpBox1 p {
    margin-bottom: 0;
    font-weight: bold;
}

.AuthenticationTwoBox .inpBox1 .selectBox {
    width: 100%;
}

.AuthenticationTwoBox .inpBox1 .dates>div>div {
    display: flex;
}

.AuthenticationTwoBox .yanzPhone .ant-form-item-control-input,
.AuthenticationTwoBox .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px;
}

.AuthenticationTwoBox .inpBox1 .dates select {
    height: 35px;
}

.AuthenticationTwoBox .inpBox1 .dates .ant-form-item {
    width: 33.33%;
    margin-bottom: 0;
}

.AuthenticationTwoBox .inpBox>div,
.AuthenticationTwoBox .inpBox .ant-radio-group {
    width: 100%;
}

.AuthenticationTwoBox .inpBox .ant-radio-wrapper {
    width: 48%;
}

.AuthenticationTwoBox .inpBox>.proveBox {
    width: 100%;

}

.AuthenticationTwoBox .inpBox input {
    padding-left: 5px;
    /* width: 49%; */
    height: 35px;
}

.AuthenticationTwoBox .inpBox input:nth-child(2) {
    margin-left: 2%;
}

.AuthenticationTwoBox .aCheckbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.AuthenticationTwoBox .aCheckbox span {
    margin-left: 5px;
}

.AuthenticationTwoBox .receivingMode {
    margin-top: 20px;
}

.AuthenticationTwoBox .receivingMode p {
    font-weight: bold;
    margin-bottom: 0;
}

.AuthenticationTwoBox .yanzPhone {
    display: flex;
    /* border: 1px solid #d9d9d9; */
}

.AuthenticationTwoBox .yanzPhone .ant-form-item {
    width: 50%;
}

.AuthenticationTwoBox .yanzPhone select,
.AuthenticationTwoBox .yanzPhone .ant-input-affix-wrapper {
    border: none;
}

.AuthenticationTwoBox .inpBox .next {
    width: 49%;
    margin-left: 2%;
    height: 35px;
}

.AuthenticationTwoBox .representative input+input {
    margin-left: 2%;
}

.AuthenticationTwoBox .previousPage {
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}

.AuthenticationTwoBox .operationBtn {
    display: flex;
    justify-content: center;
}

.AuthenticationTwoBox .operationBtn .next {
    width: 30%;
}

.AuthenticationTwoBox .a-size-medium {
    font-size: 22px !important;
    line-height: 1.255 !important;
    margin-bottom: 15px;
}

.AuthenticationTwoBox .help {
    text-align: right;
    color: rgb(0, 102, 192);
    width: 97%;
    margin: 20px auto;
}

.AuthenticationTwoBox .a-size-small {
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.AuthenticationTwoBox .racioBold {
    margin-top: 30px;
}

.AuthenticationTwoBox .racioBold .ant-radio-wrapper {
    font-weight: bold;
}

.AuthenticationTwoBox .inpBox .birthTimes {
    width: 50%;
}