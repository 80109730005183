* {
	margin: 0;
	padding: 0;
}

.end17_content {
	width: 100%;
    height: 1050px;
    margin-bottom: 5%;
	background: #E3ECED;
}

.end17_con {
	width: 1200px;
	height: 240px;
	margin: 0 auto;
}

.end17_con_right {
	width: 850px;
	height: 240px;
	padding: 25px 0;
	float: right;
}

.end17_con_right table {
	border-collapse: collapse;
	width: 100%;
}

.end17_con_right table tr td {
	height: 45px;
	position: relative;
}

.end17_table_main {
	width: 3%;
	border-top: 1px solid #878787;
}

.end17_table_one {
	width: 16%;
	font-size: 14px;
	padding-left: 15px;
	border-top: 1px solid #878787;
}

.end17_table_none {
	border: none;
}

.end17_table_tow {
	width: 20%;
	border-top: 1px solid #878787;
	border-left: 1px solid #878787;
	border-right: 1px solid #878787;
	background: #FFFFFF;
	font-size: 14px;
	padding-left: 15px;
}

.end17_table_show {
	border-left: 1px solid #878787;
	border-right: 1px solid #878787;
	background: #FFFFFF;
	font-size: 14px;
	padding-left: 15px;
}

.end17_table_four {
	width: 29%;
	border-top: 1px solid #878787;
	border-left: 1px solid #878787;
	border-right: 1px solid #878787;
	background: #FFFFFF;
	font-size: 14px;
	padding-left: 15px;
}

.end17_table_six {
	width: 31%;
	border-top: 1px solid #878787;
	border-left: 1px solid #878787;
	border-right: 1px solid #878787;
	background: #FFFFFF;
	padding-left: 15px;
	font-size: 14px;
	color: #003c46;
}

.end17_table_four_con {
	font-size: 14px;
	font-weight: bold;
	color: #003c46;
}

.end17_table_four_con select {
	width: 125px;
	height: 35px;
	border: 1px solid #7c7c7c;
	margin-left: 15px;
}

.end17_table_tow a {
	color: #008296;
}

.end17_table_btn {
	background: #008296;
	color: #FFFFFF;
	text-align: center;
	line-height: 23px;
}

.end17_table_one,
.end17_table_bold img {
	vertical-align: middle;
}

.end17_table_clr {
	color: #003c46;
}

.end17_table_bold {
	font-size: 14px;
	font-weight: bold;
	color: #003c46;
}

.end17_table_four button {
	width: 105px;
	height: 44px;
	border: none;
	position: absolute;
	background: #F5F9FA;
	left: 105px;
	top: 0;
	border: 1px solid #b0b0b0;
	border-radius: 3px;
}

.end17_table_ban button {
	width: 105px;
	height: 44px;
	border: none;
	position: absolute;
	background: #F5F9FA;
	top: 0;
	left: 105px;
	border: 1px solid #b0b0b0;
	border-radius: 3px;
}

.end17_orders {
	width: 1200px;
	margin: 50px auto 0;
	background: #FFFFFF;
}

.end17_orders ul {
	list-style: none;
	display: flex;
	padding: 25px 0;
	border-bottom: 1px solid #878787;
}

.end17_orders ul li {
	width: 31%;
	padding: 0 1.5%;
}

.end17_orders ul li p {
	font-size: 14px;
	font-weight: bold;
	color: #003c46;
}

.end17_orders ul li div .end17_orders_text {
	font-weight: 400;
}

.end17_orders ul li div select {
	width: 280px;
	height: 35px;
	color: #003c46;
}

.end17_orders ul li div .end17_orders_number {
	width: 240px;
	color: #878787;
	font-style: oblique;
}

.end17_orders ul li:nth-of-type(3) div button {
	width: 65px;
	height: 35px;
	background: #008296;
	color: #FFFFFF;
	border: none;
	border-radius: 2px;
}

.end17_orders_data {
	padding: 0 18px;
	display: flex;
}

.end17_orders_data_left {
	width: 320px;
	margin-top: 25px;
	border: 1px solid #878787;
}

.end17_orders_data_left_con {
	padding: 10px 0;
}

.end17_orders_data_left_con div {
	font-size: 20px;
	text-align: center;
	color: #838383;
}

.end17_orders_data_left_con p:nth-of-type(1) {
	font-size: 14px;
	text-align: center;
	color: #003c46;
	margin: 3px 0;
}

.end17_orders_data_left_con p {
	font-size: 18px;
	text-align: center;
}

.end17_orders_data_left_exp {
	background: #F5F9FA;
	padding: 10px 0;
	border-bottom: 1px solid #bebebe;
}

.end17_orders_data_left_exp div {
	font-size: 16px;
	text-align: center;
	color: #003c46;
}

.end17_orders_data_left_exp p {
	font-size: 14px;
	text-align: center;
	color: #008296;
}

.end17_orders_data_left_xs {
	margin: 0 25px;
	padding: 10px 0;
	border-bottom: 1px solid #bebebe;
}

.end17_orders_data_left_xs div {
	font-size: 16px;
	text-align: center;
	color: #003c46;
}

.end17_orders_data_left_xs p {
	font-size: 14px;
	text-align: center;
	color: #008296;
}

.end17_orders_data_left_zc {
	padding: 10px 0;
	border-bottom: 1px solid #bebebe;
}

.end17_orders_data_left_zc div {
	font-size: 16px;
	text-align: center;
	color: #003c46;
}

.end17_orders_data_left_zc p {
	font-size: 14px;
	text-align: center;
	color: #7e7e7e;
}

.end17_orders_data_left_yl {
	padding: 10px 0;
	border-bottom: 1px solid #bebebe;
	background: #F5F9FA;
}

.end17_orders_data_left_yl div {
	font-size: 16px;
	text-align: center;
	color: #003c46;
}

.end17_orders_data_left_yl p {
	font-size: 14px;
	text-align: center;
	color: #7e7e7e;
}

.end17_orders_data_right {
	width: 100%;
}

.end17_orders_data_right img {
	width: 100%;
	height: 400px;
}

.end17_content_footer p {
	padding-left: 25px;
}

.end17_content_footer div {
	text-align: right;
	margin-right: 25px;
	color: #008296;
}

.end17_content_footer p span {
	color: #008296;
}

.end17_content_footer p:nth-of-type(1) {
	font-weight: bold;
	color: #002e35;
	font-size: 14px;
}

.end17_content_footer p:nth-of-type(2) {
	color: #002e35;
	font-size: 14px;
}
.end17_feedback {
	color: #FFFFFF;
	width: 80px;
	height: 26px;
	color: #ffff;
	line-height: 30px;
	padding: 0 7px;
	padding-left: 11px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background: #008296;
	/* position: absolute;
	bottom: 0;
    left: 35px; */
    position: fixed;
    bottom: 0;
    left: 35px;
}
.end17_header{
	width: 1200px;
	height: 150px;
	margin: 0 auto;
	padding-top: 30px;

}
.end17_header .end17_header_bar{
	color: #05899d;
	margin-top: 15px;
	margin-bottom: 45px;
	height: 35px;
	list-style: none;
	display:flex;
	align-items:flex-end;
}
.end17_header .end17_header_bar li{
	float: left;
	padding: 0 15px;
}
.end17_header .end17_header_bar li:nth-of-type(1){
	font-size: 29px;
	color: #1f1f1f;
}
.end17_header .end17_header_bar li:nth-of-type(2),.end17_header .end17_header_bar li:nth-of-type(3){
	border-right: 1px solid #CCCCCC;
}
.end17_header .end17_header_navigation{
	list-style: none;
}
.end17_header .end17_header_navigation li{
	float: left;
	margin: 0 15px;
	height: 30px;
}
.end17_header .end17_header_navigation li:nth-of-type(2){
	border-bottom: 3px solid #00a0b9;
}