.completeShipmentBox .kc06_center {
    width: 96%;
    margin: 0 auto;
    overflow: hidden;
}
.completeShipmentBox .kc06_box1 {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bold;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.kc06_box1 a {
    color: #003399;
}

.completeShipmentBox .kc06_box1-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.completeShipmentBox .kc06_box1-ul li {
    height: 15px;
    padding: 0 10px;
    color: #CC6600;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
}

.completeShipmentBox .kc06_box1-ul li a {
    display: block;
}

.completeShipmentBox .kc06_box1-ul li:last-of-type {
    border-right: 0;
}

.completeShipmentBox .kc06_mybody {
    width: 100%;
    padding: 10px 0;
}

.completeShipmentBox .kc06_h1 {
    font-size: 22px;
    color: #CC6600;
    font-weight: bold;
    margin-bottom: 5px;
}

.completeShipmentBox .kc06_h1 span {
    margin-left: 10px;
    font-size: 14px;
    color: #1976ac;
}


.completeShipmentBox .kc06_body-ul {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #CCC;
    box-sizing: border-box;
}


.kc06_body-ul li {
    height: 32px;
    color: #0070b1;
    font-size: 15px;
    font-weight: bold;
    padding-right: 30px;
    padding-bottom: 5px;
    border-bottom: 3px solid #E47911;
    display: flex;
    align-items: center;
}

.completeShipmentBox .kc06_body-ul li:nth-child(5) {
    color: #E47911;
    margin-right: 0;
    position: relative;
}


.completeShipmentBox .kc06_body-ul li:nth-child(5) img {
    width: 12px;
    position: absolute;
    left: 20px;
    bottom: -5px;
    transform: translateX(50%);
    z-index: 2;
}

.completeShipmentBox .kc06_body-table {
    border-bottom: none;
    padding: 10px 0;
}
.completeShipmentBox .kc06_table1{
	width: 60%;
	box-shadow: none;
}
.completeShipmentBox .kc06_table2{
    width: 40%0;
}

.completeShipmentBox .kc06_table1 tr{
	height: auto;
}
.completeShipmentBox .kc06_table1 tr:first-child{
    background-color: #ffffff;
}


.completeShipmentBox .kc06_table1 th,
.completeShipmentBox .kc06_table1 td {
    width: 160px;
	padding: 0;
    padding-right: 35px;
    text-align: left;
    font-size: 12px;
    color: #666666;
	border: none;
	height: auto;
}
.completeShipmentBox .jcContent{
    font-size: 18px;
    border-bottom: 1px solid #CCC;
    color: red;
    margin-top: 20px;
}

.kc06_itbox {
    margin-top: 10px;
    display: flex;
}

.kc06_itbox li {
    font-size: 14px;
    color: #666666;
}

.kc06_itbox li:nth-child(1) {
    width: 700px;
    padding-left: 10px;
}

.kc06_itbox li:nth-child(2) {
    width: 220px;
}

.kc06_itbox li:nth-child(3) {
    width: 30px;
    text-align: right;
}

.kc06_itbox li:nth-child(4) {
    width: 80px;
    margin-left: 40px;
    text-align: right;
}

.kc06_itbox li:nth-child(5) {
    margin-left: 20px;
}

.completeShipmentBox .body-box2{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    padding-bottom: 20px;
    margin-top: 30px;
}

.completeShipmentBox .kc06_it-r {
    width: 200px;
}

.completeShipmentBox .kc06_it-r button {
    width: 200px;
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #CECEAD;
    background-image: linear-gradient(#ffde91, #d9a62d);
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
}
.completeShipmentBox .body-box2 {
	height: 150px;
}
.completeShipmentBox .btn-l{
	float: left;
}
.completeShipmentBox .btn-l button{
    margin-right: 5px;
}
.completeShipmentBox .btn-r{
	float: right;
	position: relative;
}
.completeShipmentBox .btn1{
	height: 20px;
	position: absolute;
	left: 2px;
	top: 0px;
}
.completeShipmentBox .body-box2 button {
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #868654;
    background-image: linear-gradient(#F7F7EE, #CECEAD);
    font-weight: bolder;
    border-radius: 5px;
}
.completeShipmentBox .body-box2 .btn-r button {
    background-image: linear-gradient(#ffde91, #d9a62d);
}

.completeShipmentBox .body-box2_button2 {
	font-size: 12px;
	margin-left: 10px;
    padding: 0px 10px;
    color: #000068;
    background-color: #dfdfdf !important;
    border: 2px solid #000068 !important;
    background-image: linear-gradient(#ffde91, #d9a62d) !important;
    font-weight: bolder;
    border-radius: 5px;
    position: relative;
}
.completeShipmentBox .kc06_it-r p {
    font-size: 13px;
    color: #014C91;
    font-weight: bold;
    text-align: left;
}

.completeShipmentBox .kc06_it-r-p1 {
    margin: 10px 0;
    width: 110px;
    height: 13px;
    border-right: 1px solid #014C91;
    padding-right: 5px;
    display: flex;
    align-items: center;
}
.kc06_center .body-tab .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 0 70px;
	border-radius: 5px;
}
.kc06_center .body-tab p:nth-of-type(1) span{
	color: cornflowerblue;
}
.kc06_center .body-tab .body-tab-div .i{
	width: 30px;
	position: absolute;
	top: 10px;
	left: 20px;
}
.kc06_center .body-tab .body-tab-div p{
	display: inline;
}
.kc06_center .body-tab .xs{
	font-size: 13px;
}
.completeShipmentBox .body-tab-div{
	display: flex;
	align-items: flex-start;
}
.completeShipmentBox .body-tab-div .i{
	width: 30px;
}
.completeShipmentBox .body-tab-div p{
	margin-bottom: 0;
	margin-left: 10px;
}
.completeShipmentBox .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 10px 20px;
	border-radius: 5px;
    margin-top: 20px;
}
.completeShipmentBox .psTypeBox{
    display: flex;
    margin-top: 20px;
}
.completeShipmentBox .psType{
    margin-right: 100px;
}

.completeShipmentBox .psType>div{
    margin-top: 10px;
}
.completeShipmentBox .psshang>div select{
    width: 200px;
    margin-right: 20px;
    height: 35px;
    margin-bottom: 20px;
}
.completeShipmentBox .bzBox select{
    width: 200px;
    height: 35px;
}
.completeShipmentBox .wpInfoBox>div{
    display: flex;
}

.completeShipmentBox .bolds{
    font-weight: bold;
    margin: 15px 0;
}
.completeShipmentBox .wpInfoRadioBox>div{
    display: flex;
    width: 100%;
}
.completeShipmentBox .wpInfoRadioBox>div div{
    margin-left: 15px;
}

.completeShipmentBox .wpInfoRadioBox p:first-child{
    display: block;
    font-weight: bold;
}
.completeShipmentBox .submitBox{
    text-align: right;
    width: 100%;
}
.completeShipmentBox .submitBox button{
    margin-top: 0;
    width: 75px;
}

.completeShipmentBox .wpInfoTableBox{
    width: 100%;
    margin-top: 20px;
}
.completeShipmentBox .wpInfoTableBox .ant-form-item{
    margin-bottom: 0;
}
.completeShipmentBox .wpInfoTableBox input{
    width: 90px;
}
.completeShipmentBox .wpInfoTableBox .sizeBox input{
    width: 100%;
}
.completeShipmentBox .wpInfoTableBox tr td:nth-child(1){
    width: 10%;
}
.completeShipmentBox .wpInfoTableBox tr td:nth-child(2),
.completeShipmentBox .wpInfoTableBox tr td:nth-child(3),
.completeShipmentBox .wpInfoTableBox tr td:nth-child(4),
.completeShipmentBox .wpInfoTableBox tr td:nth-child(5),
.completeShipmentBox .wpInfoTableBox tr td:nth-child(6){
    width: 13%;
    text-align: center;
}
.completeShipmentBox .surplus{
    margin: 10px;
}
.completeShipmentBox .kc06_table2{
    width: 40%;
}
.completeShipmentBox .kc06_table3{
    width: 30%;
}
.completeShipmentBox .kc06_table3 select{
    width: 150px;
    height: 35px;
}
.completeShipmentBox .m20{
    margin: 20px 0;
}
.completeShipmentBox .kc_10_btn2{
    top: 24px;
}
.completeShipmentBox .kc06_table1 .bolds{
    font-weight: bold;
}
.completeShipmentBox .ant-form-item-explain.ant-form-item-explain-error{
    display: none;
}
.completeShipmentBox .setGoodsBox table{
    width: 100%;
}
.completeShipmentBox .setGoodsBox th,
.completeShipmentBox .setGoodsBox td{
    text-align: center;
}
.completeShipmentBox .setGoodsBox table tbody tr:first-child{
    background-color: #dfdfdf;
}
.completeShipmentBox .wpInfoRadioBox .ant-radio-wrapper{
    display: flex;
    width: 33%;
}
.completeShipmentBox table tr th,
.completeShipmentBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
}
.completeShipmentBox table tr th,
.completeShipmentBox table tr td{
    border-left: none;
    border-right: none;
}




	
	
	
