body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}

/* 店铺未注册弹框弹框样式 */
.unregisteredCompleteTipsBox {
  width: 27.2% !important;
}

.unregisteredCompleteTipsBox .ant-modal-confirm-btns,
.unregisteredCompleteTipsBox .ant-modal-confirm-body>.anticon {
  display: none;
}

.ant-modal-confirm-centered {
  z-index: 9999999 !important;
}

.unregisteredCompleteTipsBox .ant-modal-content .ant-modal-body,
.unregisteredCompleteTipsBox .ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0;
  margin: 0;
}

.unregisteredCompleteTipsBox .ant-modal-content {
  border-radius: 16px;
}

.unregisteredCompleteTipsBox .expire-modal-content-text .tipsTitle {
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F5F7F7;
  padding: 0 24px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.unregisteredCompleteTipsBox .expire-modal-content-text .tipsContent {
  font-size: 16px;
  margin-top: 10px;
}

.unregisteredCompleteTipsBox .expire-modal-btn {
  padding: 30px 24px;
  text-align: center;
}

.unregisteredCompleteTipsBox .expire-modal-btn button {
  height: 40px;
  min-width: 98px;
  padding: 4px 0;
  border-radius: 6px;
  font-size: 16px;
  background-color: #28bbac;
  border-color: #28bbac;
  margin-bottom: 0;
  margin-left: 8px;
}

.unregisteredCompleteTipsBox .expire-modal-btn button+button {
  margin-left: 16px;
}

.amazonDialogBox {
  width: 23% !important;
}

.amazonDialogBox .scoreTipsText {
  display: flex;
}

.amazonDialogBox .scoreTipsText img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.amazonDialogBox .expire-modal-btn {
  padding: 28px 26px 24px 26px;
  text-align: right;
}

.amazonDialogBox .expire-modal-btn button {
  height: 34px;
  line-height: 0;
  border-radius: 4px;
  font-size: 14px;
}

.amazonDialogBox .expire-modal-btn button.cancelBtn {
  background-color: #fff;
  border-color: #ccc;
  color: #999999;
  font-size: 14px;
}

.amazonDialogBox .p-24 {
  padding-top: 30px;
  padding: 30px 26px 0 26px;
}

.amazonDialogBox .ant-modal-content {
  border-radius: 4px;
}

.amazonDialogBox .expire-modal-content-text .tipsTitle {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ant-message {
  z-index: 2070;
}

.rightCollapse>p {
  border-bottom: 1px solid #e7e7e7;
  border-left: 1px solid #d5dbdb;
}

.kat-divider {
  display: block;
  height: .0625rem;
  margin: .5rem 0;
  background-color: #d5dbdb;
}

.program-overview-values {
  width: 74% !important;
  padding: 20px 10% !important;
  margin: 0 !important;
}

.rightCollapse {
  width: calc(100% - 74%);
}

.sbu.ant-btn-primary {
  background: linear-gradient(to bottom, #f7dfa5, #f0c14b) !important;
  border: 1px solid #a88734 !important;
  margin-right: 2%;
  width: 100%;
  height: 35px;
  margin-top: 0;
  color: #333 !important;
  box-shadow: 0 1px 2px 0 #b5b5b5;
  border-radius: 2px;
}

.sbu.ant-btn-primary:hover {
  background: linear-gradient(to bottom, #f0c14b, #f0c14b) !important;
  border: 1px solid #a88734;
  color: #333;
}

.elailTips {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.elailTips img {
  width: 200px;
  height: 200px;
}

::placeholder,
.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

iframe {
  display: none;
}