.goodsItem {
  
}

.goodsPic {
  width: 70px;
  height: 70px;

  object-fit: contain;
}
