




.Unshipped_head{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}
.Unshipped_head div{
    margin-right: 20px;
}
.Unshipped_navBox{
    width: 100%;
    display: flex;
    margin-top: 30px;
    padding-bottom: 300px;
}
.ausste_left_titel{
    border-bottom: 1px solid #dddddd ;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.ausste_left_dateTitel{
    padding-bottom: 7px;
}
.ausste_left_datenav{
    border-bottom: 1px solid #dddddd;
    margin-top:  7px;
    padding-bottom: 7px;
}
.Unshipped_head span{
    font-weight: bold;
}
.Unshipped .ausste_right{
    display: block;
}
.Unshipped .ausste_right >div{
    display: flex;
    justify-content: space-between;
}
.Unshipped table{
    box-shadow: none;
    width: 100%;
}
.Unshipped table img{
    width: 60px;
    height: 60px;
}
.Unshipped table th{
    text-align: center;
}
.Unshipped table td{
    vertical-align:top;
    padding: 10px;
    padding-bottom: 0;
}
.Unshipped table td:nth-child(2){
    width: 11%;
}

.Unshipped table td:nth-child(3),
.Unshipped table td:last-child{
    text-align: right;
}
.Unshipped table td p{
    margin-bottom: 0;
}

.Unshipped table td:nth-child(4){
    width: 30%;
}
.Unshipped table td:nth-child(5){
    width: 15%;
}
.Unshipped table td:nth-child(6){
    min-width: 100px;
}
.Unshipped .sueess{
    background-color: red;
    color: #fff;
    padding: 5px;
}
.Unshipped .lanCol{
    margin-bottom: 5px;
    font-weight: bold;
    color: rgb(102, 0, 153);
    cursor: pointer;
}
.Unshipped .col102{
    color: rgb(102, 0, 153);

}
.Unshipped table .operation button{
    width: 200px;
    height: 30px;
    border: 1px solid #ccc;
    margin: 5px 0;
    background-color: rgb(240, 241, 244);
    color: #000;
}
.Unshipped table .operation button:nth-child(1),
.Unshipped table .operation button:nth-child(2){
    background-color: rgb(242, 204, 110);

}
.Unshipped table .operation button:last-child{
    background-color: #fff;
    margin-bottom: 0;
}






