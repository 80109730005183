.SetUp-body{
  width: 100%;
  display: flex;
  background-color: #fff;

}

.SetUp-box {
  width: 100%;
  height: 100%;
  display: flex;
}
.SetUp-BudgetCap {
  width: 460px;
  height: 100px;
  background-color: #f3f3f3;
  font-weight: 700;
  font-size: 13px;
  padding: 10px;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: bold;
}
.SetUp-BudgetSelection {
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 20px;
  line-height: 50px;
  padding-left: 10px;
  font-size: 12px;
}
.SetUp-BudgetSelection1 {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 30px;
}
.SetUp-BudgetSelection input{
  margin-right: 3px;
  background: #F3F3F3;
}
.SetUp-BudgetSelection2 {
    width:260px;
    height: 30px;
  }

.SetUp-BudgetSelection2  div{
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    border-radius: 4px 0px 0px 4px; 
    background: #F9F9F9;
}
.SetUp-BudgetSelection-content{
  display: flex;
  align-items: center;
}
.SetUp-BudgetSelection2_button {
  padding-left: 10px;
}


.SetUp-BudgetSelection2_button button{
  padding: 1px 15px;
  margin-right: 10px;
  outline-style: none;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 1px 2px 4px #dddddd;
}
.SetUp-BudgetSelection2_button button:nth-child(1){
  background: #F4B122;
}