/* .Mengk{

} */

.Mengk .Mengk-store {
  margin-top: 20px;
}
.Mengk .Mengk-store > button {
  background-color: rgb(228, 121, 17);
  color: #FFF;
  border-radius: 5px;
}
.Mengk-body{
  text-align: center;
  height: 24px;
  line-height: 24px;
  height: auto;
}
.Mengk-body h3{
  font-size: 40px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  margin-top: 20px;
}
.Mengk-body > div{
  font-size: 18px;
  font-weight: 700;
}
.Mengk-body > p{
  font-size: 16px;
  margin: 20px 0;
}
.Mengk-body .Mengk-text{
  font-size: 14px;
  font-weight: 400;
}
.Mengk-body .Mengk-carousel-item{
  height: 160px;
  color: #fff;
}
.Mengk-carousel .Mengk-carousel-item1{
  background: url("@assets/images/Mengk-swipe1.png") no-repeat;
  height: 160px;
}
.Mengk-carousel .Mengk-carousel-item2{
  background: url("@assets/images/Mengk-swipe2.png") no-repeat;
  height: 160px;
}
.Mengk-carousel .Mengk-carousel-item3{
  background: url("@assets/images/Mengk-swipe3.png") no-repeat;
  height: 160px;
}
.Mengk-carousel .Mengk-carousel-item4{
  background: url("@assets/images/Mengk-swipe4.png") no-repeat;
  height: 160px;
}

.Mengk-modal h3{
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}
.Mengk-modal p{
  margin: 10px;
}
.Mengk-modal .Mengk-modal-but{
  height: 64px;
  background: rgb(250, 250, 250);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;
}
.Mengk-modal{
  width: 820px;
  height: 400px;
}