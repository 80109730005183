.a-title-tooltip-overlay .ant-popover-title {
  font-size: 18px;
  font-weight: 500;
  border-bottom: none;

  padding: 16px 16px 4px;
}

.a-title-tooltip-overlay .ant-popover-inner {
  width: 360px;
  font-size: 12px;
}

.a-title-tooltip-overlay.has-title .ant-popover-inner-content {
  padding: 4px 16px 16px;
}

.a-title-tooltip-overlay .ant-popover-inner-content {
  padding: 16px;
}

.a-title-tooltip-overlay .ant-popover-inner-content .desc {
  font-size: 14px;
}
