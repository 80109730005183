
.kc22_content {
	width: 98%;
	margin: 0 auto;
	font-size: 14px;
	color: #053439;
}
.kc22_navChild ul span{
	font-size: 20px;
	color: #034951;
}
.kc22_navChild{
	margin-top: 10px;
	font-size: 14px;
}
.kc22_navChild ul{
	display: flex;
	color: #0aa2b9;
}
.kc22_navChild ul li{
	padding: 20px 15px 10px;
	line-height: 30px;
}
.kc22_navChild ul li:nth-of-type(1){
	padding: 20px 15px 10px 5px;
	line-height: 20px;
}

.kc22_search{
	overflow: hidden;
	padding: 10px 20px;
	background-color: #f4f4f4;
	border-top:1px solid #b2b8b9 ;
	border-bottom:1px solid #b2b8b9 ;
	font-size: 14px;
	color: #053439;
	margin-top: 20px;
}
.kc22_search select{
	width: 180px;
	font-size: 12px;
	height: 30px;
	line-height: 20px;
	appearance: none; /* 去掉默认图标 */
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	/* background: url(../img/下拉1.png) center no-repeat #fff; */
	background-position: 90% 50%;
	background-size: 10px;
	padding:5px 10px 0 10px;
}
.kc22_search_l{
	float: left;
	font-weight: 600;
}
.kc22_search_l button{
	background-color: #fafafa;
	border:1px solid #b2b8b9 ;
	padding: 10px ;
	margin-left: 5px;
}
.kc22_search_r{
	float: right;
}
.kc22_search_r div{
	width: 70px;
	display: inline-block;
	font-weight: bold;
}
.kc22_back{
	margin-top: 20px;
}
.kc22_back button{
	border: none;
	padding:10px 20px;
	font-weight: 600;
	color: #053439;
	background-color: #e3eced;
}
.kc22_data{
	display: flex;
	font-size: 14px;
	color: #686868;
	font-weight: 600;
	border-bottom: 3px solid #b6b6b6;
	padding: 10px 0;
}
.kc22_data li:nth-of-type(1){
	width: 800px;
	padding: 0 10px 0 20px;
}
.kc22_data li{
	width: 300px;
	padding: 0 10px;

}
.kc22_data li div:nth-of-type(1)::after{
	content: "";
	width: 1px;
	height: 20px;
	background-color:#686868;
	position: absolute;
	right: 0;
}
.kc22_data li div:nth-of-type(1):last-of-type::after{
	display: none;
}
.kc22_data li div:nth-of-type(1){
	color: #053439;
	width: 100%;
	position: relative;
}
.kc22_data li:nth-of-type(4){
	text-align: right;
	padding-right: 20px;
}
.kc22_data li:nth-of-type(4) div{
	padding-right: 20px;
}
.kc22_icon{
	position: relative;
	margin-left: 5px;
	color: #1e9eb1;
}
.kc22_icon::after{
	content: "";
	width: 15px;
	height: 15px;
	border: 2px solid #1e9eb1;
	border-radius: 50%;
	position: absolute;
	left: -5px;
}

