.kc06_center {
    width: 96%;
    margin: 0 auto;
    overflow: hidden;
}
.kc06_box1 {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bold;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.kc06_box1 a {
    color: #003399;
    line-height: 6px;
}

.kc06_box1-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.kc06_box1-ul li {
    height: 15px;
    padding: 0 10px;
    color: #CC6600;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
}

.kc06_box1-ul li a {
    display: block;
}

.kc06_box1-ul li:last-of-type {
    border-right: 0;
}

.kc06_mybody {
    width: 100%;
    padding: 10px 0;
}

.kc06_h1 {
    font-size: 22px;
    color: #CC6600;
    font-weight: bold;
    margin-bottom: 5px;
}

.kc06_h1 span {
    margin-left: 10px;
    font-size: 14px;
    color: #1976ac;
}


.handlingCargoBox .kc06_body-ul {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #CCC;
    box-sizing: border-box;
}


.kc06_body-ul li {
    height: 32px;
    color: #0070b1;
    font-size: 15px;
    font-weight: bold;
    padding-right: 30px;
    padding-bottom: 5px;
    border-bottom: 3px solid #E47911;
    display: flex;
    align-items: center;
}

.kc06_body-ul li:nth-child(4) {
    color: #E47911;
    margin-right: 0;
    position: relative;
}

.kc06_body-ul li:nth-child(4) img {
    width: 12px;
    position: absolute;
    left: 20px;
    bottom: -5px;
    transform: translateX(50%);
    z-index: 2;
}

.handlingCargoBox .kc06_body-table {
    border-bottom: none;
    padding: 10px 0;
}
.handlingCargoBox .kc06_table1{
	width: 35%;
	box-shadow: none;
}
.handlingCargoBox .kc06_table1 tr{
	height: auto;
}
.handlingCargoBox .kc06_table1 tr:first-child{
    background-color: #ffffff;
}


.handlingCargoBox .kc06_table1 th,
.handlingCargoBox .kc06_table1 td {
    width: 160px;
	padding: 0;
    padding-right: 35px;
    text-align: left;
    font-size: 12px;
    color: #666666;
	border: none;
	height: auto;
}

.kc06_itbox {
    width: 100%;
    margin-top: 10px;
    display: flex;
}
.kc06_itbox1 {
    width: 100%;
}
.kc06_itbox li {
    font-size: 14px;
    color: #666666;
}

.handlingCargoBox .kc06_itbox li:nth-child(1) {
    width: 25%;
    padding-left: 10px;
}

.handlingCargoBox .kc06_itbox li:nth-child(2) {
    width: 220px;
}

.handlingCargoBox .kc06_itbox li:nth-child(3) {
    width: 50px;
    text-align: center;
}

.handlingCargoBox .kc06_itbox li:nth-child(4) {
    width: 120px;
    margin-left: 40px;
    text-align: center;
}

.handlingCargoBox .kc06_itbox li:nth-child(5) {
    width: 100px;
    margin-left: 20px;
}

.handlingCargoBox .kc06_commodity {
    border-bottom: 1px solid #000;
    border-top: 2px solid #000;
    padding-bottom: 20px;
}

 .handlingCargoBox .kc06_it-r {
    width: 100%;
    text-align: left;
    margin-left: 150px;
}

 .handlingCargoBox .kc06_it-r p {
    font-size: 13px;
    color: #014C91;
    font-weight: bold;
}

 .handlingCargoBox .kc06_it-r-p1 {
    margin: 10px 0;
    width: 100%;
    height: 13px;
    /* border-right: 1px solid #014C91; */
    padding-right: 5px;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
}
.kc06_center .body-tab .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 0 70px;
	border-radius: 5px;
}
.kc06_center .body-tab p:nth-of-type(1) span{
	color: cornflowerblue;
}
.kc06_center .body-tab .body-tab-div .i{
	width: 30px;
	position: absolute;
	top: 10px;
	left: 20px;
}
.kc06_center .body-tab .body-tab-div p{
	display: inline;
}
.kc06_center .body-tab .xs{
	font-size: 13px;
}
.handlingCargoBox .body-tab-div{
	display: flex;
	align-items: center;
}
.handlingCargoBox .body-tab-div .i{
	width: 30px;
}
.handlingCargoBox .body-tab-div p{
	margin-bottom: 0;
	margin-left: 10px;
}
.handlingCargoBox .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 10px 20px;
	border-radius: 5px;
}
.handlingCargoBox .chulihuoijianBox table td:nth-child(1){
    width: 45%;
}
.handlingCargoBox .chulihuoijianBox table td{
    min-width: 100px;
}
.handlingCargoBox .shipmentBox{
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #CCC;
}
.handlingCargoBox .childBox{
    display: flex;
    justify-content: flex-end;
}
.handlingCargoBox .childBox table{
    width: 94%;
}
.handlingCargoBox .kc_10_show{
    width: 94%;
    font-weight: bold;
}
.handlingCargoBox .shipmentInp{
    margin-left: 10px;
    width: 200px;
    height:30px;
    padding-left: 10px;
}
.handlingCargoBox ul .shipmentShow{
    color:#004b91;
    cursor: pointer;
}
.handlingCargoBox .kc06_it-r button {
    width: 200px;
    height: 28px;
    padding: 2px 10px;
    color: #000068;
    border: 2px solid #1c408b;
    background-image: linear-gradient(#ffdf93, #d1a12b);
    font-weight: bold;
    border-radius: 7px;
    margin-bottom: 10px;
    cursor: pointer;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
}
.handlingCargoBox .kc_10_btn2 {
    right: 5px;
    bottom: 2px;
}
.handlingCargoBox .kc_10_table1 th,
.handlingCargoBox .kc_10_table1 td{
    text-align: center;
}
.handlingCargoBox table tr th,
.handlingCargoBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
}
.handlingCargoBox table tr th,
.handlingCargoBox table tr td{
    border-left: none;
    border-right: none;
}




	
	
	
