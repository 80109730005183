

.DevelopApp_head{
    padding: 0 50px;
    width: 100%;

}
.DevelopApp_head_titel{
    font-size: 18px;
    padding: 40px 0 10px  0;
}

.DevelopApp_head_titelII{
    font-weight: bold;
    margin-bottom: 8px;
}

.DevelopApp_head_titelII span{
    color: #008296;
    text-decoration: underline;
    

    }


.DevelopApp_data{
    width: 100%;    
}
.DevelopApp_data_titel{
    display: flex;
    align-items: center;
}
.DevelopApp_data_color{
    width: 12px;
    height: 12px;
    background: #008296;
}

.DevelopApp_data_content{
    width: 100%;
    display: flex;

}

.DevelopApp_data_contentI{
    width: 7.6%;
    font-weight: bold;
}
.DevelopApp_data_contentII{
    width: 9%;
    font-weight: bold;
}

.DevelopApp_warn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.DevelopApp_warnBox{
    width: 27.9%;
    display: flex;
    background: #FFF5D1;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.DevelopApp_warn_icon{
    margin-right: 10px;
}

.DevelopApp_warn_icon span svg{
    width: 3rem;
    height: 3rem;
}

.DevelopApp_warn_contentI span{
    font-size: 13px;
    font-weight: bold;
}
.DevelopApp_warn_content_btn{
    margin-top: 10px;
}

.DevelopApp_warn_content_btn button{
    color: #ffffff;
    background: #008296;
    padding: 7px;
    outline-style: none;
    border: 0;
    box-shadow: 1px 3px 4px #dddddd;
}
.DevelopApp_Add{
    width: 100%;
    border: 1px solid #dddddd;
    padding: 10px;
    box-shadow: #dddddd 1px 2px 3px;
}

.DevelopApp_Add button{
    padding: 5px 15px;
    outline-style: none;
    border: 0;
    background: #F5F9FA;
    box-shadow: 1px 3px 4px #dddddd;
}


.DevelopApp_button{
    font-size: 15px;
    padding: 15px 0;
    margin-bottom: 30px;
}