.oneDelivery {
    /* width: 70%; */
}
.oneDelivery .bolds,
.editressModalBox .bolds{
    font-weight: bold;
}
.oneDelivery .col296{
    color:#008296;
}
.oneDelivery ul li{
    list-style: inside;
}
.oneDelivery .propotionSelect span{
    background: #e7e9ec;
    border-radius: 3px;
    border-color: #ADB1B8 #A2A6AC #8D9096;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    text-align: center;
    text-decoration: none!important;
    vertical-align: middle;
    padding: 5px 10px;
}
.oneDelivery .propotionSelect .selectBtn{
    margin-left: 10px;
    background: #f4d078;
}
.oneDelivery .propotionSelect{
    margin-bottom: 20px;
}
.oneDelivery .a-section-expander-container{
    line-height: 1.255;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #f3f3f3;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    padding: 11px 18px 11px 18px;
    border: 1px solid #00000050;
    border-bottom: 0;
}
.oneDelivery .a-section-expander-container .leftTitle{
    font-size: 17px;
    color: #0F1111;

}
.oneDelivery .a-section-expander-container .rightBtn{
    border: 1px solid #a2a6ac;
    background-color: #eaecef;
    padding: 1px 8px 2px;
    height: 22px;
    font-size: 14px;
    color: #000;
    cursor: pointer;
}
.oneDelivery .tableBox{
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
    border: 1px solid #00000050;
}
.oneDelivery .coupons1 td:nth-child(1){
    width: 27%;
    text-align: right;
    line-height: 40px;
    background: #f3f3f3;
    font-size: 14px;
    font-weight: bold;
    padding-right: 20px;
}
.oneDelivery .a-alert-heading{
    font-weight: 700;
    color: #7c6200;
    font-size: 17px;
    line-height: 1.255;
    padding-bottom: 4px;
}
.oneDelivery .tipsBox {
    background-color: #fff;
    box-shadow: 0 0 0 4px #fffae7 inset;
    padding-left: 100px;
}

.oneDelivery .tipsBox {
    border-radius: 4px;
    position: relative;
    padding: 14px 18px;
    border-color:1px solid #8c6e00;
    margin: 16px 0 !important;
}
.oneDelivery .tableBox table{
    width: 100%;
}
.oneDelivery .tableBox table td{
    padding: 0 10px;
}
.addressModalBox .flex1{
    display: flex;
}
.addressModalBox .flex1>div{
    flex: 1;
}
.addressModalBox .ant-form-item{
    display: block;
}
.addressModalBox .Postcode .ant-input{
    width: 50%;
    margin-right: 15px;
}
.addressModalBox .ant-form-item .ant-form-item-label .ant-form-item-required,
.addressModalBox .ant-form-item .ant-form-item-label label{
    font-weight: bold;
}
.editressModalBox .modalLeft p{
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.editressModalBox .addressBtn{
    margin: 10px 0;
    cursor: pointer;
}
.editressModalBox .modalLeft button{
    padding: 0 0 0 5px;
}
.editressModalBox .modalLeft .ant-radio-wrapper{
    /* white-space: wrap; */
    display: flex;
    /* align-items: center; */
    white-space:normal;
    word-break:break-all;
    word-wrap: break-word;
}
.editressModalBox .color06{
    color: #0066c0;
}