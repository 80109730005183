.ASIN_body{
    padding-bottom: 500px;
}

.ASIN_box{
    border: 1px solid #dddddd;

}
.ASIN_head{
    font-size: 12px;
    font-weight: bold;
}
.ASIN_inpBox{
    width: 100%;
    background: #fafafa;
    padding: 20px;
    border-bottom: 1px solid #dddddd;
}
.ASIN_inp{
    width: 210px;
    height: 36px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #ffffff;
}
.ASIN_inp_font{
    font-size: 24px;
}
.ASIN_inp input{
    width: 100%;
    border: 0;
    outline-style: none;
}
.ASIN_inpBox_head{
    width: 100%;
    background: #fafafa;
    padding: 20px;
    border-bottom: 1px solid #dddddd;

}
.ASIN_inp_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
.ASIN_inp_title div:nth-child(1){
    width: 80%;
    margin-left: 8%;
}
.ASIN_list{
    width: 100%;
    padding: 20px;
    overflow: auto;
   
}
.ASIN_listNav{
    width: 101.2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.2%;
}
.ASIN_listNav p{
    margin: 0;
}
.ASIN_listNav_img{
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ASIN_list div:nth-child(2){
    width: 80%;
}
.ASIN_listNav_font p:nth-child(1){
    color: #008296;
}

.ASIN_listNav_font div{
    width: 65px;
    background: #008296;
    color: #ffffff;
    border-radius: 15px;
    padding: 0 10px;
}