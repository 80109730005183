.atAglancetab2Box table{
    width: 70%;
}
.atAglancetab2Box table tr td,
.atAglancetab2Box table tr th{
    text-align: center;
    padding: 5px;
}
.atAglancetab2Box table tr th:nth-child(5){
    width: 4%;
}
.atAglancetab2Box table tr th:last-child{
    width: 1%;
}
.atAglancetab2Box table .tagNumber div:first-child{
    margin-bottom: 30px;
}