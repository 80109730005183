.atAglancetab1Box .blurs{
    color: #0066c0;
}
.atAglancetab1Box .warningBox{
    border: 2px solid #8c6e00;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.atAglancetab1Box .warningBox span{
    margin-left: 20px;
}
.atAglancetab1Box .TimelineBox{
    margin-top: 30px;
}
.atAglancetab1Box .TimelineBox .ant-timeline-item-content,
.atAglancetab1Box .TimelineBox .ant-timeline-item-label{
    font-size: 20px;
}
.atAglancetab1Box .TimelineBox .ant-timeline.ant-timeline-label .ant-timeline-item-label{
    width: 10%;
}
.atAglancetab1Box .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.atAglancetab1Box .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.atAglancetab1Box .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.atAglancetab1Box .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.atAglancetab1Box .ant-timeline.ant-timeline-right .ant-timeline-item-head,
.atAglancetab1Box .ant-timeline.ant-timeline-label .ant-timeline-item-head,
.atAglancetab1Box .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.atAglancetab1Box .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.atAglancetab1Box .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.atAglancetab1Box .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.atAglancetab1Box .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.atAglancetab1Box .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content{
    left: 11%;

}