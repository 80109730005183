.end07_mybody{
	width: 94%;
	margin: 0 auto;
	margin-top: 20px;
}
.end07_mybody p{
	font-family:"宋体"
}
.feedback a{
	text-decoration: none;
	color: #386C73;
}
.end07_mybody .p1{
	color: #0F3548;
	font-weight: bolder;
}
.end07_mybody .p2{
	font-size: 12px;
	color: #0F3548;
	margin-top: 10px;
}
.end07_mybody .p3{
	color: #162728;
	font-weight: bolder;
	font-size: 16px;
	margin-top: 10px;
}
.end07_mybody .p4{
	font-size: 12px;
	color: #0D2A2E;
}
.end07_mybody .p4 span{
	font-weight: bolder;
	font-size: 14px;
}
.feedback table{
	width: 94%;
	border-color: rgba(0,0,0,0.2);
	box-shadow: rgba(0,0,0,0.2) -1px 1px 2px;
}
.feedback table tr{
	height: 40px;
	font-size: 14px;
}
.feedback table th{
	border: none;
	text-align: left;
}
.feedback table .num::before{
	content: "";
	margin-left: -3px;
	padding-left: 15px;
	border-left: 1px solid #c0c4cc;
	height: 15px;
	display: inline-block;
	/* vertical-align: middle; */
}
.end07_mybody table td{
	border: none;
	border-top: 1px solid rgba(0,0,0,0.1);
	padding-left: 15px;
}
.end07_mybody table .pj{
	font-weight: bolder;
}
.end07_mybody table tr:nth-of-type(2) span{
	color: limegreen;
}
.end07_mybody table tr:nth-of-type(3) span{
	color: grey;
}
.end07_mybody table tr:nth-of-type(4) span{
	color: #62242F;
}
.end07_mybody .table-p{
	width: 94%;
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}
.end07_mybody .table-p .p5{
	font-size: 12px;
}
.end07_mybody .table-p .p6{
	font-size: 12px;
	margin-top: 0px;
}
.end07_mybody .end07_mybody-div{
	width: 94%;
	height: 50px;
	background-color: #F5F9FA;
	position: relative;
	margin-top: 5px;
	margin-bottom: 3px;
}
.end07_mybody .end07_mybody-div .i{
	width: 50px;
	position: absolute;
	top: 10px;
}
.end07_mybody .end07_mybody-div2{
	margin-top: 20px;
	height: 200px;
}
.end07_mybody .end07_mybody-div2 .p7{
	color: #042E33;
	font-size: 18px;
}
.end07_mybody .end07_mybody-div2 .end07_mybody-div .p8{
	font-size: 12px;
	color: #002F36;
	margin-left: 40px;
	padding-top: 20px;
}
.end07_mybody .end07_mybody-div2 .end07_mybody-div .pj{
	margin-top: 30px;
}
.tab2 .date{
	padding-left: 20px;
}
.tab2 td{
	padding-left: 20px;
}
.tab2 .last button{
	height: 25px;
	background-color: #E2ECED;
	border: 1px solid rgba(0,0,0,0.3);
	padding: 2px 9px;
	border-radius: 4px 0 0 4px;
}
.tab2 .last button span{
	margin: 0 5px;
	border-left: 1px solid rgba(0,0,0,0.5);
}
.tab2 .last .xl{
	margin-left: 5px;
}

.down{
	width: 88px;
	position: relative;
	margin: 0;
}
.down_btn{
	display: flex;
}
.down_btn button{
    border: none;
}
.down_btn div{
	padding: 0 3px;
	background-color: #E2ECED;
	border-radius: 0  4px 4px 0 ;
}
.down_list p{
	text-align: center;
}

.downIcon{
	border: 1px solid #000000;
	height: 25px;
}
.down_btn  span svg{
	color: #000;
}
.down_list{
	display: none;
    position: absolute;
    background-color: #E2ECED;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 10;
}
.down_list a{
    text-decoration: none;
	display: block;
	width: 88px;
	margin: 0;
	padding-left: 10px;
	margin: 10px 0;
}
.down:hover .down_list{
	display: block;
}

.foot-button{
	width: 98%;
	margin: 0 auto;
}
.foot-button .fk{
	background-color: #008296;
	color: white;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.1);
	padding: 2px 7px;
	position: fixed;
	bottom: -2px;
}
.mybody_div2_warn{
	width: 100%;
	background: #F5F9FA;
	display: flex;
	align-items: center;
	justify-content: left ;
	padding: 10px 	20px;
}

