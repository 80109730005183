.commodity-collect-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 2px;
    font-size: 12px;
}
.commodity-collect-box span{
    color: #008296;
    font-size: 14px;
}
.commodity-collect {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 16px;
}
.commodity-hint{
    margin: 10px 0;
    font-size: 12px;
}
.commodity-h2{
    font-family: 方正楷体, serif;
}
.commodity-hint span{
    color: #008296;
}

.commodity-container h2{
    font-size: 24px;
}
.commodity-icon svg{
    width: 2rem;
    height: 2rem;
}
.CollectionBox .ant-spin-nested-loading{
    height: 300px !important;
}
.CollectionBox .collectionList .ant-list{
    height: 300px;
    overflow: auto;
}
.CollectionBox .collectionList .noCollectionClass{
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    font-size: 14px;
    padding-left: 20px;
    border: 1px solid #eee;
}
