.kc25_cennav{
    background-color: #F4F4F4;
   height: 36px;
   line-height: 36px;
    border-bottom: 1px solid #ccc;
}
.kc25_cennav>ul{
    width: 700px;
    min-width: 700px;
    list-style: none;
    margin: 0 auto;
    display: flex;
    font-size: 14px;
    font-weight: 600;
}
.kc25_cennav>ul>li{
    margin-right: 5px;
}
.kc25_cennav>ul>li:not(:nth-of-type(1))::before{
    content: "|";
    width: 20px;
    margin-left: 10px;
    margin-right: 15px;
}
.kc25_cennav>ul>li .kc25_gongn{
    color: #D3812E !important;
    margin-left: 10px;
   
}
.kc25_cennav>a{
    color: #1845AC;
}









.kc25_fanhui{
    width: 100%;
    height:380px;
    overflow: hidden;
    background:#E9EDEF;
}
.kc25_content{
    width: 96%;
    height: 330px;
    margin:20px auto;
    background: white;
    border-radius: 12px;
    overflow: hidden;
}
.kc25_content>div{
    margin: 10px;
}
.kc25_content>div:first-of-type{
    margin-left:10px;
    color: #D1761B;
    font-size: 16px;
}
.kc25_content>div:nth-of-type(2){
    font-size: 13px;
    margin-top: 0;
}
.kc25_content>div:nth-of-type(2) a{
    color: #1845AC;
    margin-left:6px;
}
.kc25_content>div:nth-of-type(3){
    background:#BEDCFE ;
    height:50px;
    border: 2px solid #A5C7F8;
}
.kc25_content>div:nth-of-type(3)>div{
    width: 130px;
    height: 30px;
    margin-left: 10px;
    margin-top:12px;
    background:white ;
    color: #D1761B;
    overflow: hidden;
    border: 2px solid #A5C7F8;
    border-top-right-radius: 6px;
   border-top-left-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.kc25_content>div:nth-of-type(3)>div>img{
    display: block;
    width: 20px;
    float:left ;
    margin-top: 8px;
    margin-left: 10px;
}
.kc25_content>div:nth-of-type(3)>div>span{
    font-size: 12px;
    display: block;
    margin-top:8px;
    margin-left: 5px;
    float:left ;
}
.kc25_content-foot{
    height:180px;
    background: #EFF5FA;
    overflow: hidden;
    border-bottom: 3px solid #5D7CBE;
}
.kc25_content-foot>div{
    margin-left:25px;
}
.kc25_content-foot>div:first-of-type{
    margin-top: 15px;
    font-size: 18px;
    color:#555;
}
.kc25_content-foot>div:nth-of-type(3){
    margin-top: 26px;
    color: #D1761B;
}
.kc25_content-foot>div:last-of-type{
    width: 320px;
    margin-top: 5px;
    height:30px;
    background: #EBEBEB;
    border:2px solid #5D7CBE;
    display: flex;
    justify-content: right;
    align-items: center;
}
.kc25_content-foot>div:last-of-type>img{
    width:33px;
    float: right;
    margin-top: 6px;
}


.kc25_content_img{
    width: 20px;
    height: 16px;
    background-image: url("../../assets/images/kc25_tb.JPG");
    background-size: 20px;
}
.kc25_content_foot_img{
    width: 32px;
    height: 19px;
    background-image: url("../../assets/images/kc25_sku.jpg");
    background-size: 32px;
    margin-left: 90%;
}