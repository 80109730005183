



.imgN_Box{
    width: 100%;
    display: flex;
    justify-content: center;
}

.imgN_lBox{
    border: 1px solid red;
    width: 50%;
}

.imgN_lBox_tab{
    width: 100%;
    display: flex;
    border-bottom: 1px solid #000000;
    margin-bottom: 10px;
    padding-bottom: 4px;
}
.imgN_lBox_tab div{
    display: inline-block;
    color: #008296;
    text-decoration: underline;
}
.imgN_lBox_tab div:nth-child(9){
    font-weight: bold;
    color: #000000;
    text-decoration: none;
}
.imgN_lBox_tab span{
    margin: 0 3px;
    color: #000000;
    font-weight: bold;
}
.imgN_lBox_head{

}

.imgN_lBox_head_titel{
    font-size: 29px;
}

.imgN_lBox_head_titelII{
    font-size: 16px;
}

.imgN_lBox_head_titelIII{
    font-size: 16px;
    margin: 13px 0;
}
.imgN_lBox_head_titelIV{
    width: 100%;
    padding: 10px 10px;
    background: #FFF8CD;
    border-left: 4px solid #E9DB9E;
    font-size: 15px;
    margin-bottom: 17px;
}


.imgN_lBox_nav{}

.imgN_lBox_navTitel{
    font-size: 24px;
    margin: 10px 0;
}

.imgN_lBox_nav{
    font-size: 15px;
}
.imgN_lBox_nav span{
    color: #008296;
    font-size: 15px;
}



.imgN_lBox_nav_ul{
    margin-top: 20px;
}

.imgN_lBox_nav_ul ul li{
    list-style:inside;
    padding-left: 30px;
    font-size: 15px;
    }

.imgN_lBox_imgNavBox{
    padding-left: 20px;
}
.imgN_lBox_imgNav{
    width: 96%;
    height: 464px;
    background-image: url("../../../../assets/images/imgNeed1.png");
}


.imgN_lBox_imgNavI{
    width: 96%;
    height: 424px;
    background-image: url("../../../../assets/images/imagenedd2.png");
}

.imgN_lBox_imgNavII{
    width: 96.1%;
    height: 424px;
    background-image: url("../../../../assets/images/imageneed3.png");
}

.imgN_lBox_imgNavIII{
    width: 96.3%;
    height: 257px;
    background-image: url("../../../../assets/images/imageneed4.png");
}


.imgN_lBox_imgNavIV{
    width: 96.1%;
    height: 467px;
    background-image: url("../../../../assets/images/imageneed5.png");
}

.imgN_lBox_imgNavV{
    width: 96.2%;
    height: 467px;
    background-image: url("../../../../assets/images/imageneed6.png");
}

.imgN_lBox_imgNavVI{
    width: 96.2%;
    height: 467px;
    background-image: url("../../../../assets/images/imageneed6.png");
}








































.imgN_RBox{
    width: 25%;
    height: 500px;
    border: 1px solid blue;
}