.end19_content {
	width: 100%;
}

.end19_content ul {
	border-bottom: 1px solid #CCCCCC;
	display: flex;
	margin: 35px 25px;
}

.end19_content ul li {
	color: #0048da;
	padding: 5px 12px;
}

.end19_content ul li:nth-of-type(1) {
	font-weight: bold;
	color: #000000;
	border: 2px solid #CCCCCC;
	border-bottom: none;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.end19_content_title {
	margin: 0 25px;
}

.end19_content_title span {
	font-size: 26px;
	color: #CC6600;
	margin-right: 25px;
}

.end19_content_report {
	margin: 15px 25px;
}

.end19_content_report div:nth-of-type(1) {
	font-weight: bold;
	padding: 5px 0;
	padding-left: 5px;
	border: 2px solid #CCCCCC;
	background: #EEEEEE;
	border-bottom: none;
}

.end19_content_report div:nth-of-type(2) {
	padding: 5px 0;
	padding-left: 5px;
	border: 2px solid #CCCCCC;
	border-top: none;
	background: #F9F9F9;
}

.end19_content_report div:nth-of-type(2) button {
	border-radius: 15px;
	padding: 0 5px;
	font-weight: bold;
	color: #000054;
	margin-left: 8px;
	background-image: linear-gradient(#f6f6e7, #e1e1bd);
}

.end19_feedback {
	width: 80px;
	height: 26px;
	color: #ffff;
	line-height: 30px;
	padding: 0 7px;
	padding-left: 11px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background: #008296;
	position: absolute;
	bottom: 0;
	left: 35px;

}
