

.UploadOrder_box{
    margin-top: 50px;
    padding: 0 50px;
}


.UploadOrder_head_titel{
    font-size: 26px;
}

.UploadOrder_tabBox{
    border: 1px solid #dddddd;
    margin-top: 4px;
}
.UploadOrder_tabBox .ant-tabs-tab-btn{
    color: #0066c0;
}
.UploadOrder_tabBox .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    background: #F3F3F3;
}
.UploadOrder_tabBox .ant-tabs-tab-active{
    background: #ffffff !important;
}