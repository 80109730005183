.shopup-body {
  width: 100%;
  padding: 0 40px;
}

.shopup-titel {
  display: flex;
  align-items: center;
  padding: 20px 0;
  position: relative;
}
.shopup-titel div:nth-child(1) {
  font-size: 24px;
  margin-right: 20px;
}
.shopup-titel div:nth-child(2) a {
  margin: 20px 10px 0 10px;
}
.shopup-tab{
}
.shopupBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e87911 !important;
}
.ant-tabs-tab-btn {
  color: #0066c0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height: 0;
}
.ant-radio-inner::after{
  background-color: #e87911;
}
.ant-radio-checked .ant-radio-inner{
  border-color: #000;
}
.shopup-a a{
  color: #0066C0;

}
.shopup-a a:hover{
  color: #e87911;
  text-decoration: underline;
}
.shopup-aI{
  position: absolute;
  right: 4%;
  top: 130px;
  display: flex;
  z-index: 10;
}
.shopup-aI a{
  color: #0066c0;

}
.shopup-aI div a:hover{
  color: #e87911;

}
.shopup-aI>a{
margin-right: 20px;
}
