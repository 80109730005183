



.distribution_body{
    width: 100%;
}

.distribution_head{
    width: 100%;
    border-bottom: 1px solid #dddddd;
    padding: 30px;
    padding: 40px 8% 30px 8%;

}

.distribution_head_titel{
    font-size: 26px;
    margin-bottom: 10px;
}


.distribution_middle{
    margin: 40px 8% 30px 8%;
}


.distribution_middle_titel{
    font-weight: bold;
    font-size: 20px;

}

.distribution_middle_content{
    width: 30%;
    display: flex;
    border: 1px solid #dddddd;
    box-shadow: 1px 3px 4px #dddddd;
    padding: 20px;
    margin-top: 20px;
}
.distribution_middle_img{
    margin-right: 20px;
}
.distribution_middle_font{
    font-size: 16px;
    color: #008296;
}

.distribution_bottomBox{
    margin: 40px 8% 30px 8%;
  
}



.distribution_bottom{
    display: flex;
    margin-top: 20px;
    margin-bottom: 301px;
}

.distribution_bottom_boxI{
    width: 30%;
    border: 1px solid #dddddd;
    box-shadow: 1px 3px 4px #dddddd;
    padding: 20px;
    margin-right: 10px;
    display: flex;
}


.distribution_middle_fontII{
    margin: 5px 0 20px 0;
}

.distribution_bottom_label span{
    background: #EBF8FA;
    padding: 5px;
    margin: 0 10px 10px 0;
    border-radius: 13px;
}

.distribution_bottom_labelII{
    background: #EBF8FA;
    padding: 5px;
    border-radius: 13px;
    margin-top: 10px;
    width: 66%;
    display: flex;
    justify-content: center;
}