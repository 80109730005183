
.kc20_content {
	margin:0 15%  0 2%  ;
}

.kc20_haed_nav {
	height: 40px;
	display: flex;
	border-bottom: 2px solid  #dcdcdc;
	padding-top: 10px;
}

.kc20_haed_nav li {
	color: #0aa2b9;
	font-size: 14px;
	padding: 10px 20px;
	font-weight: bold;
	height: 20px;
}

.kc20_haed_nav li:nth-of-type(2) {
	color: #c45500;
	border-style: solid;
	border-color: #c45500 #dcdcdc #fff #dcdcdc;
	border-width: 2px ;
	padding-top: 8px;
}

.kc20_haed_nav span {
	font-size: 12px;
	color: #ff9900;
	margin: 5px 0 0 5px;
}

.kc20_navChild{
    display: flex;
    align-items: flex-end;
	font-size: 14px;
    padding: 20px 0;
}
.kc20_navChild ul{
	height: 16px;
	display: flex;
	color: #0aa2b9;
	align-items: flex-end;
}
.kc20_navChild ul li{
	float: left;
	padding: 0 10px;
	border-right: 2px solid rgba(0, 0, 0, 0.kc20_3);
}
.kc20_navChild ul li:last-of-type{
	border-right:0
}
.kc20_navChild-p1{
    font-size: 22px;
	color: #034951;
}
.kc20_navChild>div:nth-of-type(1){
	text-align: right;
	margin: 0;
}

.kc20_body-box{
    /* height: 200px; */
    padding: 10px 20px;
    border: 1px solid #CCC;
    background-color: #FFF;
    border-radius: 3px;
}
.kc20_body-p1{
	font-size: 15px;
	margin: 0;
}
.kc20_body-box .kc20_body-maxul{
    display: flex;
    margin: 20px 0 0;
}
.kc20_body-box .kc20_body-maxul>li{
    height: 150px;
    width: 30%;
    margin-left: 35px;
    border-right: 1px solid #CCC;
}
.kc20_body-maxul>li:last-child{
	border-right: none;
}
.kc20_body-li-p1{
    font-size: 14px;
	color: #5C5C5C;
	margin: 0;
}
/*  */
.kc20_tooltip {
    position: relative;
    background-color: #E9EDED;
    width: 95%;
    height: 30px;
    border-radius: 0.kc20_25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid #CCC;
	margin-left: 10px;
	padding-bottom: 4px;
	background-image: url(../../../../assets/images/kc20_information-o.png);
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: 8px;
}
.kc20_tooltip p{
	margin: 0;
}
.kc20_triangle {
    display: block;
    height: 10px;
    width: 10px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -7px;
    left: calc(50% - 10px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 2px;
}
.kc20_triangle-p{
	font-size: 14px;
}
.kc20_shadebox{
	margin-top: 20px;
	display: flex;
	align-items: flex-end;
	color: #5C5C5C;
	font-size: 14px;
	margin-bottom: 5px;
}
.kc20_shade{
      width: 75%;
      height: 12px;
	  margin: 2px 5px;
      background: repeating-linear-gradient(
        90deg,
		#F4A8C1 0px,
		#F4A8C1 40%,
		#DEF4A7 60px,
		#DEF4A7 45%,
		#B1D8A2 100%
	  );
}
.kc20_body-minul li p{
	width: 40%;
	font-size: 14px;
	padding: 4px 0;
	margin: 0;
}

.kc20_body-minul li {
	display: flex;
	align-items: center;
}

.kc20_body-minul li svg{
	width: 2rem;
	height: 2rem;
}

.kc20_shadebox-l{
	width: 200px;
	height: 16px;
	display: flex;
	align-items: center;
	color: #5C5C5C;
	font-size: 14px;
	border-left: 2px solid #000;
	margin-left: 10px;
}
.kc20_shadebox-r{
	width: 200px;
	height: 16px;
	display: flex;
	align-items: center;
	color: #5C5C5C;
	font-size: 14px;
	border-right: 2px solid #000;
	margin-left: 10px;
}
.kc20_shade2{
      width: 100%;
      height: 6px;
      background: repeating-linear-gradient(
        90deg,
		#E32564 0px,
		#E32564 30%,
		#FFC401 0px,
		#FFC401 50%,
		#ADE422 0px,
		#ADE422 70%,
		#3B9D17 0px,
		#3B9D17 100%
	  );
	 
}
.kc20_icon{
	height: 16px;
	margin-left: 5px;
}
.kc20_dialogbox{
	/* width: 470px; */
	padding: 10px;
	background-color: #EBF8FA;
	border: 1px solid #8DDBE5;
	border-radius: 5px;
	margin: 20px 10px;
	font-size: 15px;
}
.kc20_body-box2{
	margin-top: 40px;
}
.kc20_box2-p1{
	font-size: 20px;
}
.kc20_box2-ul {
	margin-top: 20px;
}
.kc20_box2-ul li{
	height: 100px;
	padding:  10px  20px ;
	background-color: #FFF;
	border: 1px solid #CCC;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
}
.kc20_lv{
	border-left: 5px solid #3B9D17 !important;
}
.kc20_red{
	border-left:  5px solid #E32564 !important;
}
.kc20_lv-font{
	color: #3B9D17;
	font-size: 17px;
}
.kc20_red-font{
	color: #E32564;
	font-size: 17px;
}
.kc20_box2-li-p1 span{
	margin: 0;
}
.kc20_box2-li-p1{
	font-size: 18px;
	color: #5C5C5C;
	margin: 0;

}
.kc20_box2-li-p1 svg{
	width: 2rem;
	height: 2rem;
}
.kc20_box2-li-p1 img{
	height: 20px;
	margin-right: 10px;
}
.kc20_box2-li-p2{
	font-size: 14px;
	color: #5C5C5C;
	margin: 0;

}
.kc20_easier{
	font-size: 14px;
	display: flex;
	align-items: center;
	margin: 10px 0;
	
}
.kc20_easier span{
	margin-left: 5px;
}
.kc20_box-l{
	width: 75%;
	height: 100%;
}
.kc20_box-r{
	height: 50px;
	border-left:1px solid #CCC ;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}
.kc20_box-r button{
	width: 250px;
	height: 35px;
}
.kc20_shade2_icon{
	font-size: 20px;
    margin-left: 3px;
}