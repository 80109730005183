.desktop_body{
    width: 100%;

}

.desktop_link{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
.desktop_Nav{
   width: 100%;
    display: flex;
    justify-content: center;
}
.desktop_NavII{
    width: 300px;
    height: 370px;
    margin-right: 20px;
}
.desktop_Nav_img{
   width: 100%;
   height: 300px;
   background: #008296;
}
.desktop_Nav_name p{
    margin: 0;
font-weight: bold;
font-size: 17px;
}
.desktop_Nav_name div{
    font-weight: bold;
    font-size: 12px;
}