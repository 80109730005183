.manageInventory-radio select {
    background-color: #f5f6f9;
    border-radius: 3px;
}

.manageInventory-orderForm {
    min-width: 1200px;
    border: #eaeaea solid 1px;
    margin-top: 5px;
    font-size: 12px !important;
}

.manage-orderForm-top {
    background-color: #f3f3f3;
    display: flex;
    padding: 5px 0;
    border-bottom: #eaeaea solid 1px;
}

.manage-orderForm-data {
    display: flex;
    padding: 5px 0;
    border-bottom: #eaeaea solid 1px;
}

.manage-orderForm-margin {
    margin: 0 4px;
    font-weight: bold;
}

.manage-orderForm-dingDan {
    margin: 0 4px;
    word-wrap: break-word;
    word-break: normal;
}

.manage-orderForm-weight {
    color: #666666;
    font-weight: lighter;
}

.manage-orderForm-color {
    color: #0066c3;
}

.manage-orderForm-radio {
    width: 2%;
}

.manage-orderForm-state {
    width: 5.6%;
}

.manage-orderForm-image {
    width: 75px;
}

.manage-orderForm-condition {
    width: 4%;
}

.manage-orderForm-serialNumber {
    width: 6%;
}

.manage-orderForm-name {
    width: 14%;
}

.manage-orderForm-date {
    width: 7%;
}

.manage-orderForm-date div:nth-child(1) {
    color: #e47911;
}

.manage-orderForm-endibility {
    width: 3%;
}

.manage-orderForm-endibility input {
    /* text-align: right; */
    border-radius: 3px;
    border: #a6a6a6 solid 1px;
    width: 100%;
    padding: 0 4px;
}

.manage-orderForm-bePutInStorage {
    width: 3%;
}

.manage-orderForm-doNotSell {
    width: 3.5%;
    text-align: right;
}

.manage-orderForm-reserved {
    width: 4%;
    text-align: right;
}

.manage-orderForm-estimatedCost {
    width: 1.9%;
    text-align: right;
}

.manage-orderForm-price {
    width: 6%;
    text-align: right;
}

.manage-orderForm-price input {
    /* text-align: right; */
    border-radius: 3px;
    border: #a6a6a6 solid 1px;
    padding: 0 4px;
    width: 100%;
}

.manage-orderForm-enterprisePrice {
    width: 6%;
    text-align: right;
}

.manage-orderForm-enterprisePrice input {
    /* text-align: right; */
    border-radius: 3px;
    border: #a6a6a6 solid 1px;
    padding: 0 4px;
    width: 95%;
}

.manage-orderForm-reservePrice {
    width: 3%;
    text-align: right;
}

.manage-orderForm-salesRank {
    width: 1%;
    text-align: right;
}

.manage-orderForm-buttonPrice {
    width: 1.9%;
    text-align: right;
}

.manage-orderForm-buttonStatus {
    width: 1%;
    text-align: right;
}

.manage-orderForm-fnSku {
    width: 4%;
}

.manage-orderForm-distribution {
    width: 3.5%;
    text-align: right;
}

.manage-orderForm-upcEan {
    width: 4.3%;
    text-align: right;
}

.manage-orderForm-save {
    width: 132px;
    text-align: center;
}

.manage-orderForm-save button {
    width: 62px;
    background-color: #f9f3e5;
    border: #e8e0cd solid 1px;
}

.manage-orderForm-save select {
    border-radius: 2px;
    height: 20px;
    background-color: #f0f1f4;
    margin: 0 !important;
    width: 100%;
}

.manage-orderForm-save .ant-select {
    width: 100% !important;
}

.manageInventory-header {
    margin-top: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.manageInventory-header li {
    color: #0066c0;
    list-style: none;
    margin: 0 !important;
}

.manageInventory-header li:last-child {
    color: #cc6600;
    margin-left: 0.187rem !important;
    font-size: 11px !important;
}

.manageInventory-header span {
    margin: 0 11px !important;
    color: #dddddd;
}

.manageInventory-Title {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.manage-Title-left {
    display: flex;
    align-items: center;
}

.manage-Tit-l {
    font-size: 25px;
}

.manage-Tit-r {
    margin: 15px 0 0 11px;
    font-size: 12px;
    color: #0066c0;
}

.manage-Tit-r span:nth-child(2) {
    color: #dddddd;
    margin: 0 8px !important;
}

.manage-Title-right {
    display: flex;
    align-items: center;
}

.manage-Title-right button {
    background-color: #f4f5f7;
    padding: 4px 6px;
    border: solid #adb1b8 1px;
    font-size: 12px;
    margin-left: 10px;
}

.manage-Title-right a {
    color: #000;
}

.manage-Title-right span {
    color: #cc6600;
    margin-left: 7px !important;
    font-size: 11px !important;
}

.manage-Title-right button:nth-child(2) {
    margin: 0 10px;
}

.manageInventory-DropdownSearch {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.manageInventory-DropdownSearch select {
    margin: 0 !important;
    background-color: #f7f8fa;
}

.manageInventory-Search {
    margin-left: 15px;
    height: 25px;
    border: solid #949494 1px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.manageInventory-Search .ant-form-item,
.manageInventory-Search .ant-form-item .ant-form-horizontal,
.manageInventory-Search .ant-form-item-control-input {
    height: 22px;
    min-height: 22px;

}

.manageInventory-Search span {
    width: 25px;
}

.manageInventory-Search input {
    border: none;
    font-size: 13px !important;
}

.manageInventory-DropdownSearch button {
    width: 40px;
    height: 25px;
    margin-left: 5px;
    font-size: 13px;
    color: #ffffff;
    background-color: #666d74;
    border: #3d444c solid 1px;
    border-radius: 3px;
}

.manageInventory-Piece {
    font-size: 13px;
    margin-left: 20px;
    color: #111111;
}

.manageInventory-radio {
    width: 100%;
    padding: 10px 10px;
    background-color: #f3f3f3;
    margin: 8px 0;
    display: flex;
    align-items: center;
}

/* .manageInventory-radio span {
    margin: 0 20px;
    font-size: 20px;
    color: #dddddd;
} */

.manageInventory-screen {
    margin-right: 30px;
    font-size: 13px;
    color: #898989;
}

.manageInventory-state {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.manageInventory-state input {
    margin: 3px 5px 0 5px;
}

.manageInventory-state label {
    color: #111111;
}

.pageBox {
    text-align: center;
    margin: 20px 0;
}

.manage-orderForm-image img {
    width: 60px;
}

.orderFormBox .manageInventory-orderForm .manage-orderForm-radio .ant-checkbox-wrapper>span:nth-child(2) {
    display: none;
}

.orderFormBox .manage-orderForm-save .ant-select-selection-placeholder {
    color: #000;
}

.orderFormBox .limitBox {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}