.kc29_content {
	margin: 0 1%;
}

.kc29_haed_nav {
	height: 40px;
	display: flex;
	border-bottom: 1px solid  #dcdcdc;
	padding-top: 10px;
}

.kc29_haed_nav li {
	color: #0aa2b9;
	font-size: 14px;
	padding: 10px 20px;
	font-weight: bold;
	height: 20px;
}

.kc29_haed_nav li:nth-of-type(6) {
	color: #c45500;
	border-style: solid;
	border-color: #c45500 #dcdcdc #fff #dcdcdc;
	border-width: 2px ;
	padding-top: 8px;
}

.kc29_haed_nav span {
	font-size: 12px;
	color: #ff9900;
	margin: 5px 0 0 5px;
}

.kc29_navChild{
    width: 100%;
    display: flex;
    justify-content: space-between;
	margin-bottom: 0;
}
.kc29_navChild-l{
    display: flex;
    align-items: flex-end;
	font-size: 14px;
}
.kc29_navChild-l ul{
	height: 16px;
	display: flex;
	color: #0aa2b9;
	align-items: flex-end;
}
.kc29_navChild-l ul li{
	float: left;
	padding: 0 10px;
	border-right: 2px solid rgba(0, 0, 0, 0.3);
	color: #0066C0;
}
.kc29_navChild-l ul li:last-of-type{
	border-right:0
}
.kc29_navChild-p1{
    font-size: 28px;
	color: #000;
	margin-bottom: 0;
}
.kc29_navChild-l>div:nth-of-type(2){
	text-align: right;
}
.kc29_navChild-r button{
    padding: 5px 10px;
    background-color: #F3F4F6;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
	font-size: 13px;
	margin-right: 3px;
}
.kc29_content-p1{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;
    
}
.kc29_content-p2{
	border: 1px solid #008296;
	padding: 8px;
	border-radius: 4px;
	background: #F6F6F6;
}
.kc29_content-p2 p{
	margin-left: 50px;
	margin-bottom: 0;

}
.kc29_content-p2_titel{
	display: flex;
	align-items: center;
	padding-left:10px ;
}
.kc29_content-p2_titel p{
	margin-left: 14px;
	margin-bottom: 0;
}

.kc29_content-p2_titel svg{
	width: 2rem;
	height: 2rem;
	color: #008296;
}


.kc29_tooltip {
    position: relative;
    background-color: #F6F6F6;
    height: 100px;
    padding:10px  60px ;
    border-radius: 5px;
    border:1px solid #CCC;
	background-image: url(../../../../assets/images/kc20_information-o.png);
	background-size: 22px;
	background-repeat: no-repeat;
	background-position: 20px 12px;
}

.kc29_triangle-p1{
	font-size: 19px;
    margin-bottom: 5px;
}
.kc29_triangle-p2{
	font-size: 14px;
}
.kc29_triangle-p2 span{
    font-weight: bold;
}

.kc29_inpbox{
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
}
.kc29_page-r{
		display: flex;
	}
	.kc29_page-r button{
		background: #F7F8FA !important;
		color: #000 !important;
	}

	.kc29_page-r button:focus {
		box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
		outline: none;
	}


	.kc29_sele2{
		height: 28px;
		border: 1px solid rgba(0,0,0,0.3);
		font-size: 14px;
		display: flex;
		align-items: center;
		padding: 0 10px;
		background-color: #F7F8FA;
		border-radius: 3px;
	}
	.kc29_page-r select{
		height: 28px;
		border: 1px solid rgba(0,0,0,0.3);
		border-left:0 ;
		background-color: #F7F8FA;
		border-radius: 0 3px 3px 0;
	}
.kc29_inpbox  input{
    height: 30px;
    padding:0 15px 0  20px ;
    margin-left: 20px;
    border-radius: 3px;
    border: 1px solid #000;
    background-image: url(../../../../assets/images/kc28_sousuo.png);
    background-size: 15px;
    background-position: 5px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    
}
.kc29_inpbox button{
    height: 30px;
    padding: 3px 10px;
    color: #FFF;
    background-color: #70767D;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 3px;
    margin-right: 15px;
}
.kc29_inpbox span{
    height: 30px;
    font-size: 13px;
    margin-left: 15px;
    margin-top: 5px;
}

.kc29_indentbox ul{
	height: 50px;
	display: flex;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.2);
	padding:0 10px;
	margin-bottom: 0;
	border-top: 0;
}
.kc29_indentbox ul:nth-child(1){
	background-color: #F4F4F4;
}
.kc29_indentbox ul li{
	height: 50px;
	font-size: 13px;
	color: #70767D;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.kc29_indentbox ul li p{
	margin: 0;
}
.kc29_indentbox ul li:nth-child(1){
	width: 80px;
}
.kc29_indentbox ul li:nth-child(2){
	width: 170px;
}
.kc29_indentbox ul li:nth-child(3){
	width: 185px;
}
.kc29_indentbox ul li:nth-child(4){
	width: 145px;
}
.kc29_indentbox ul li:nth-child(5){
	width: 200px;
}
.kc29_indentbox ul li:nth-child(6){
	width: 100px;
}
.kc29_indentbox ul li:nth-child(7){
	width: 150px;
}
.kc29_indentbox ul li:nth-child(8){
	width: 220px;
	padding-left: 15px;
}
.kc29_indentbox ul li:nth-child(9){
	width: 180px;
}
.kc29_indentbox ul li:nth-child(10){
	width: 180px;
}

.kc29_blackwft{
	color: #000 !important;
	font-weight: bold;
	margin: 0;
}
.kc29_blackft{
	color: #000 !important;
}
.kc29_bluewft{
	color: #0D6AC1 !important;
	font-weight: bold;
}
.kc29_blueft{
	color: #0066C0 !important;
	margin: 0;
}
.kc29_blueft span{
	width: 66px;
}
 /* .ant-tooltip-inner{
	color: #000000 !important;
} */
.kc29_blueft a{
	color:#0066C0 ;
}
.kc29_orangeft{
	color: #ff9900 !important;
}
.kc29_active{
	color: #ff9900 !important;
	font-weight: bold;
	border-top:1px solid #ff9900;
	
	display: flex;
	flex-direction: row !important;
	align-items: center;
	justify-content: left !important;
}
.kc29_active img{
	width: 15px;
	margin-left: 10px;
}
.kc29_libtn{
	width: 150px;
	background-color: #F9F3E5;
	height: 32px !important;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	text-align: center;
	color: #000 !important;
}
.kc29_q{
	background-image: url(../../../../assets/images/kc28_my.png);
	background-size: 15px;
	background-repeat: no-repeat;
	background-position: 10px center ;
	text-align: right;
	padding-right: 10px;
	width: 105px;
	height: 28px;
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 3px;
}
.kc29_sele{

}
.kc29_sele select{
	height: 26px;
	background-color: #F2F3F6;
	border-radius:0  3px 3px 0;
	border: 1px solid rgba(0,0,0,0.4);
}
.kc29_sele-l{
	width: 140px;
	height: 26px;
	border: 1px solid rgba(0,0,0,0.4);
	border-radius:  3px 0 0 3px ;
	border-right: 0;
	color: #000000;
	display: flex;
	align-items: center;
	padding-left: 12px;
	background-color: #F2F3F6;
	font-size: 13px;
}
.kc29_sele_btn a button:focus{
	box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
	outline: none;
}


.kc29_sele-lI{
	height: 26px;
	border: 1px solid rgba(0,0,0,0.4);
	border-radius: 0 3px 3px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #F2F3F6;
	position: relative;
	z-index: 3;
}
.kc29_sele_btn{
	display: flex;
	align-items: center;
}
.kc29_sele_list{
	
	display: none;
    position: absolute;
    background-color: #E2ECED;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 10;
}
.kc29_sele_list a{
	text-decoration: none;
	width: 88px;
	margin: 0;
	padding-left: 10px;
	margin: 10px 0;
}
.kc29_sele-lI:hover .kc29_sele_list{
	display: block;
}


.kc29_inpbox_btm{
	width: 100%;
	height: 35px;
	border: 1px solid #000;
	display: none;
}

