*{
    padding: 0;
    margin: 0;
}
ul{
	list-style: none;
}
/*头部*/
.kc_header{
    min-width: 1650px;
    height: 50px;
    line-height: 50px;
    padding: 0px 50px;
    display: flex;
    justify-content:space-between;
    border-bottom: 1px solid rgba(0, 0,.0, 0.3);
}
.kc_header ul{
    display: flex;
    justify-content:space-around;
    list-style: none;
    font-size: 14px;
}
.kc_header_logo{
    vertical-align:middle;
}
.kc_header_head_l>li{
	cursor: pointer;
}
.kc_header_head_l>li:not(:nth-of-type(1)){
    margin-left: 20px;
}
.kc_header_head_r>li{
    margin-right: 20px;
    position: relative;
}
.kc_header_head_r>li:nth-of-type(1){
	margin-right: 10px;
}
.kc_header_head_r>li:nth-of-type(1)>img{
    height: 20px;
    vertical-align: middle;
}
.kc_header_head_r input{
   padding: 3px 8px;
   border-radius: 3px;
   border: 1px solid rgba(0, 0,.0, 0.9);
}
.kc_header_head_r select{
    padding:3px 32px 3px 8px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0,.0, 0.3);
	font-size: 12px;
	text-align: left;
 }
.kc_header_head_r select>option{
 
 }
.kc_header_head_r .kc_header_meiguo{
    width: 150px;
 }
 .kc_header_head_r .kc_header_yuyan{
    width: 80px;
 }
.kc_header_head_r .kc_header_search{
    width: 120px;
 }
.kc_header_searchImg{
    height:24px;
    vertical-align: middle;
    margin-left: 2px;
    margin-top: -2px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
 }
.kc_header_maijia{
    font-weight: 600;
 }
.kc_header_maijia>span{
     color: rgba(0, 0,.0, 0.3);
 }
.kc_header_sanjiao1{
     width: 15px;
     position: absolute;
     top: 20px;
     right: 2px;
 }
 
 
 /*尾部*/
 .kc_footr {
     margin-top: 50px;
 	border-top: 2px solid #dcdcdc;
 	padding: 20px 40px;
 }
 .kc_footr ul{
     list-style: none;
 	width: 100%;
 	font-size: 14px;
 	color:#06393e;
 	overflow: hidden;
 }
 .kc_footr ul li select{
 	width: 100px;
 	appearance: none; /* 去掉默认图标 */
 	-moz-appearance: none; /* Firefox */
 	-webkit-appearance: none; /* Safari and Chrome */
 	padding:5px 10px 0 10px;
 	/* background:url(../img/下拉.png) no-repeat right center; */
     border-radius: 5px;
 }
 
 .kc_footr ul li:last-of-type{
 	color: #999999;
 	float: right;
 
 }
 .kc_footr ul li:nth-of-type(2)::after{
 	content: "";
 	width: 2px;
 	height: 20px;
 	background-color:#DCDCDC;
 	position: absolute;
 	right: 0;
 }
 .kc_footr ul li:nth-of-type(2)::before{
 	content: "";
 	width: 2px;
 	height: 20px;
 	background-color:#DCDCDC;
 	position: absolute;
 	left: 0;
 }
 .kc_footr ul li{
 	padding: 0 10px;
 	float: left;
 	position: relative;
 }
 .kc_footr_foo_sanjiao{
     width: 15px;
     position:absolute;
     right: 15px;
     top: 5px;
 }
 ul {
	list-style: none;
}


.atAglanceBox .kc06_center {
    width: 96%;
    margin: 0 auto;
    overflow: hidden;
}
.atAglanceBox .kc06_box1 {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bold;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.atAglanceBox .kc06_box1 a {
    color: #003399;
}

.atAglanceBox .kc06_box1-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.atAglanceBox .kc06_box1-ul li {
    height: 15px;
    padding: 0 10px;
    color: #CC6600;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
}

.atAglanceBox .kc06_box1-ul li a {
    display: block;
}

.atAglanceBox .kc06_box1-ul li:last-of-type {
    border-right: 0;
}

.atAglanceBox .kc06_mybody {
    width: 100%;
    padding: 10px 0;
}

.atAglanceBox .kc06_h1 {
    font-size: 22px;
    color: #CC6600;
    font-weight: bold;
    margin-bottom: 5px;
}

.atAglanceBox .kc06_h1 span {
    margin-left: 10px;
    font-size: 14px;
    color: #1976ac;
}


.atAglanceBox .kc06_body-ul {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #CCC;
    box-sizing: border-box;
}


.atAglanceBox .kc06_body-ul li {
    height: 32px;
    color: #0070b1;
    font-size: 15px;
    font-weight: bold;
    padding-right: 30px;
    padding-bottom: 5px;
    border-bottom: 3px solid #E47911;
    display: flex;
    align-items: center;
}

.atAglanceBox .kc06_body-ul li:nth-child(6) {
    color: #E47911;
    margin-right: 0;
    position: relative;
}

.atAglanceBox .kc06_body-ul li:nth-child(5) {

    padding-left: 30px;
    border-bottom: 3px solid #E47911;
}

.atAglanceBox .kc06_body-ul li:nth-child(6) {
    border-bottom: 3px solid #E47911;
}

.atAglanceBox .kc06_body-ul li:nth-child(6) img {
    width: 12px;
    position: absolute;
    left: 5px;
    bottom: -5px;
    transform: translateX(50%);
    z-index: 2;
}

.atAglanceBox .kc06_body-table {
    border-bottom: none;
    padding: 10px 0;
}
.atAglanceBox .kc06_table1{
	width: 75%;
	box-shadow: none;
}
.atAglanceBox .kc06_table2{
    width: 40%0;
}

.atAglanceBox .kc06_table1 tr{
	height: auto;
}
.atAglanceBox .kc06_table1 tr:first-child{
    background-color: #ffffff;
}


.atAglanceBox .kc06_table1 th,
.atAglanceBox .kc06_table1 td {
    width: 160px;
	padding: 0;
    padding-right: 35px;
    text-align: left;
    font-size: 12px;
    color: #666666;
	border: none;
	height: auto;
}
.atAglanceBox .jcContent{
    font-size: 18px;
    border-bottom: 1px solid #CCC;
    color: red;
    margin-top: 20px;
}

.atAglanceBox .kc06_itbox {
    margin-top: 10px;
    display: flex;
}

.atAglanceBox .kc06_itbox li {
    font-size: 14px;
    color: #666666;
}

.atAglanceBox .kc06_itbox li:nth-child(1) {
    width: 700px;
    padding-left: 10px;
}

.atAglanceBox .kc06_itbox li:nth-child(2) {
    width: 220px;
}

.atAglanceBox .kc06_itbox li:nth-child(3) {
    width: 30px;
    text-align: right;
}

.atAglanceBox .kc06_itbox li:nth-child(4) {
    width: 80px;
    margin-left: 40px;
    text-align: right;
}

.atAglanceBox .kc06_itbox li:nth-child(5) {
    margin-left: 20px;
}

.atAglanceBox .body-box2{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    padding-bottom: 20px;
    margin-top: 30px;
}

.atAglanceBox .kc06_it-r {
    width: 200px;
}

.atAglanceBox .kc06_it-r button {
    width: 200px;
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #CECEAD;
    background-image: linear-gradient(#ffde91, #d9a62d);
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
}
.atAglanceBox .body-box2 {
	height: 150px;
}
.atAglanceBox .btn-l{
	float: left;
}
.atAglanceBox .btn-l button{
    margin-right: 5px;
}
.atAglanceBox .btn-r{
	float: right;
	position: relative;
}
.atAglanceBox .btn1{
	height: 20px;
	position: absolute;
	left: 2px;
	top: 0px;
}
.atAglanceBox .body-box2 button {
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #868654;
    background-image: linear-gradient(#F7F7EE, #CECEAD);
    font-weight: bolder;
    border-radius: 5px;
}
.atAglanceBox .body-box2 .btn-r button {
    background-image: linear-gradient(#ffde91, #d9a62d);
}

.atAglanceBox .body-box2_button2 {
	font-size: 12px;
	margin-left: 10px;
    padding: 0px 10px;
    color: #000068;
    background-color: #dfdfdf !important;
    border: 2px solid #000068 !important;
    background-image: linear-gradient(#ffde91, #d9a62d) !important;
    font-weight: bolder;
    border-radius: 5px;
    position: relative;
}
.atAglanceBox .kc06_it-r p {
    font-size: 13px;
    color: #014C91;
    font-weight: bold;
    text-align: left;
}

.atAglanceBox .kc06_it-r-p1 {
    margin: 10px 0;
    width: 110px;
    height: 13px;
    border-right: 1px solid #014C91;
    padding-right: 5px;
    display: flex;
    align-items: center;
}
.kc06_center .body-tab .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 0 70px;
	border-radius: 5px;
}
.kc06_center .body-tab p:nth-of-type(1) span{
	color: cornflowerblue;
}
.kc06_center .body-tab .body-tab-div .i{
	width: 30px;
	position: absolute;
	top: 10px;
	left: 20px;
}
.kc06_center .body-tab .body-tab-div p{
	display: inline;
}
.kc06_center .body-tab .xs{
	font-size: 13px;
}
.atAglanceBox .body-tab-div{
	display: flex;
	align-items: flex-start;
}
.atAglanceBox .body-tab-div .i{
	width: 30px;
}
.atAglanceBox .body-tab-div p{
	margin-bottom: 0;
	margin-left: 10px;
}
.atAglanceBox .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 10px 20px;
	border-radius: 5px;
    margin-top: 20px;
}
.atAglanceBox .psTypeBox{
    display: flex;
    margin-top: 20px;
}
.atAglanceBox .psType{
    margin-right: 100px;
}

.atAglanceBox .psType>div{
    margin: 10px 0;
}
.atAglanceBox .psshang>div select{
    width: 200px;
    margin-right: 20px;
    height: 35px;
    margin-bottom: 20px;
}
.atAglanceBox .bzBox select{
    width: 200px;
    height: 35px;
}
.atAglanceBox .wpInfoBox>div{
    display: flex;
}
.atAglanceBox .wpInfoBox>div>div{
    width: 30%;
}

.atAglanceBox .bolds{
    font-weight: bold;
    margin: 15px 0;
}
.atAglanceBox .wpInfoRadioBox>div{
    display: flex;
}
.atAglanceBox .wpInfoRadioBox>div div{
    margin-left: 15px;
}

.atAglanceBox .wpInfoRadioBox p:first-child{
    display: block;
    font-weight: bold;
}
.atAglanceBox .submitBox{
    text-align: right;
    width: 100%;
}
.atAglanceBox .submitBox1{
    text-align: center;
    width: 45%;
}
.atAglanceBox .submitBox button{
    margin-top: 0;
    width: 75px;
}

.atAglanceBox .wpInfoTableBox{
    width: 99%;
    margin-top: 20px;
}
.atAglanceBox .wpInfoTableBox input{
    width: 30%;
}
.atAglanceBox .wpInfoTableBox .sizeBox input{
    width: 100%;
}
.atAglanceBox .wpInfoTableBox .ant-form-item{
    margin-bottom: 0;
}
.atAglanceBox .wpInfoTableBox tr td:nth-child(1){
    width: 35%;
}
.atAglanceBox .wpInfoTableBox tr td:nth-child(2),
.atAglanceBox .wpInfoTableBox tr td:nth-child(3),
.atAglanceBox .wpInfoTableBox tr td:nth-child(4),
.atAglanceBox .wpInfoTableBox tr td:nth-child(5),
.atAglanceBox .wpInfoTableBox tr td:nth-child(6){
    width: 10%;
}
.atAglanceBox .surplus{
    margin: 10px;
}
.atAglanceBox .kc06_table2{
    width: 40%;
}
.atAglanceBox .kc06_table3{
    width: 30%;
}
.atAglanceBox .kc06_table3 select{
    width: 150px;
    height: 35px;
}
.atAglanceBox .m20{
    margin: 20px 0;
}
.atAglanceBox .kc_10_btn2{
    top: 23px;
}
.atAglanceBox .kc06_table1 .bolds{
    font-weight: bold;
}
.atAglanceBox .ant-form-item-explain.ant-form-item-explain-error{
    display: none;
}
.atAglanceBox .setGoodsBox table{
    width: 100%;
}
.atAglanceBox .setGoodsBox th,
.atAglanceBox .setGoodsBox td{
    text-align: center;
}
.atAglanceBox .setGoodsBox table tbody tr:first-child{
    background-color: #dfdfdf;
}
.tabsBox{
    margin-top: 30px;
}

.atAglanceBox .tabFooter {
	height: 150px;
    border-top: 1px solid #ccc;
}
.atAglanceBox .tabFooter button {
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #868654;
    background-image: linear-gradient(#F7F7EE, #CECEAD);
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
}
.atAglanceBox .tabFooter .btn-r button {
    background-image: linear-gradient(#ffde91, #d9a62d);
}
.atAglanceBox .signBox{
    background-color: #f7f7ee;
    color: #000066;
    border: 2px solid #898956;
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px;
    width: 95px;
    text-align: center;
    cursor: pointer;
}


	
	
	
