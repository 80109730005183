

.MakePlan_body{
    width: 100%;
    padding: 10px 0 300px 0;
}

.MakePlan_contentBox{
    width: 34.8%;
    margin: 0 auto;
    border: 1px solid #dddddd;
    box-shadow: 1px 3px 4px #dddddd;
    padding: 20px;
}


.MakePlan_head_titel{
    font-size: 20px;
    margin-bottom:5px ;
}
.MakePlan_head_titel_content{
    font-size: 13px;
}
.MakePlan_head_titel_content span{
    color: #008296;
    font-size: 13px;
}


.MakePlan_head_titelII{
    font-weight: bold;
    font-size: 20px;
    padding: 10px 0;
}
.MakePlan_head_titelII_ul{
    padding: 0 18px;
}

.MakePlan_head_titelII_ul ul li {
    list-style:disc ;
    color: coral;
    font-size: 16px;
}
.MakePlan_head_titelII_ul ul li span{
    color: #000000;
    font-size: 14px;
}
.MakePlan_head_titelII_ul ul li marker{
    color: #008296;
    lighting-color: coral;
}

.MakePlan_bottom{
    display: flex;
    align-items: center;
    font-size: 15px;
}

.MakePlan_bottom span{
    font-size: 15px;
    color: #008296;
}

.MakePlan_bottom input{
    color: #f5f5f5;
}

.MakePlan_bottom button{
    padding: 5px;
    width: 70px;
    background: #879596;
    border: 0;
    outline-style: none;
    box-shadow: 2px 4px 4px #dddddd;
    color: #ffffff;
}