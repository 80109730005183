.commodity-crumbs{
    color: #008296;
    font-size: 13px;
}
.ant-list-header{
    background-color: #fff!important;
}
.commodity-crumbs-icon{
    font-size: 13px;
}
.commodity-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #879596;
}
.commodity-type-box{
    font-size: 12px;
    display: flex;
    align-items: center;
}
.commodity-type{
    text-align: right;
    margin-right: 6px;
    color: #879596;
}
.commodity-select button{
    width: 70px;
    color: #002F36;
    font-weight: bold;
    border: none;
    box-shadow: 0 0 2px #000;
    padding: 3px;
    background-color: #e3eced;
}

.commodity-container .daddemo-infinite-container{
    height:500px;
    overflow-y: scroll;
}

.breadcrumb-container{
    height: 60px;
    padding-left: 20px;
    padding-right: 15px;
    border: 1px solid #eee;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
.breadcrumb-container p.empty{
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #999;
}
.breadcrumb-container .clear{
    width: 40px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.breadcrumb-container .clear span{
    cursor: pointer;
}
.breadcrumb-container .ant-breadcrumb{
    height: 100%;
    display: flex;
    align-items: center;
}
.breadcrumb-container .ant-breadcrumb>span{
    font-size: 16px;
    color: #008296;
}
.breadcrumb-container .ant-breadcrumb>span:not(:nth-last-child(1)){
    cursor: pointer;
}
.breadcrumb-container .ant-breadcrumb>span:not(:nth-last-child(1)):hover span:nth-child(1){
    text-decoration: underline;
}

.commodity-main .ant-list-item{
    cursor: pointer;
    padding: 16px 16px 16px 24px;
}
.commodity-main .ant-list-item.isNextLevel:hover{
    background-color: #d8dbdc;
}
.commodity-container .ant-spin-nested-loading{
    height: 500px;
    background-color: #fff;
}
