.searchList_search{
  background-color: rgb(245, 249, 250);
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.searchList_search > p{
  text-align: left;
  width: 500px;
}

.search_body_left{
  width: 360px;
  position: absolute;
  left: 0;
  padding-left: 20px;
}
.search_body_rigth{
  position: absolute;
  left: 360px;
  right: 20px;
  border: 1px solid rgb(233, 233, 233);
  box-sizing: border-box;
}
.search_list_body{
  position: relative;
  height: 300px;
  padding-top: 20px;
  padding-right: 20px;
}
.search_body_rigth_header{
  border: 1px solid rgb(233, 233, 233)
}
.search_body_left{
  display: flex;
  flex-direction: column;
  align-items: self-start;
}
.search_body_left strong{
  margin: 10px 0;
}
.search_body_left button{
  margin: 10px 0;
}
.search_table_but{
  margin: 10px 0;
}