.video-body{
    padding: 0 40px;
    padding-bottom:70px;
}
.video-Box{
    display: flex;
}
.video-titel{
    width: 55%;
    height: 71px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.video-titel div:nth-child(1){
    font-size: 24px;
}
.video-titel div:nth-child(2){
    width: 70%;
}

.video-leftBox{
    border: 1px solid black;
    width: 55%;
    padding: 60px;
    text-align: center;
    background: #f7f7f7;
}
.video-leftBox div{
        padding: 12px 0 0 0;
}
.video-leftBox-img{
    margin: 0 auto;
    width: 140px;
    height: 113px;
    background-image: url(../../assets/images/Video.png);
}
.video-leftBox div:nth-child(2){
    padding: 0 !important;
    font-size: 24px;
}
.video-leftBox div:nth-child(5){
    padding: 20px;
}
.video-leftBox button{
    width: 116px;
    height: 35px;
    background: #E3ECED;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 3px 4px #dddddd;
    outline-style: none;
}
.video-leftBox button:hover{
    background: #B6D0D3;
    color: #000000;
}

.video-rightBox{
    width: 30%;
    height: 500px;
    padding:0 30px;
}
.video-rightBox-top{
    margin-bottom: 25px;
}
.video-rightBox-shopName{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
}
.video-rightBox-shopName div:nth-child(2){
    display: flex;
    align-items: center;
}
.video-rightBox-inp input{
    width: 100%;
    height: 36px;
}
.video-rightBox-middle{
    padding-bottom: 20px;
}
.video-rightBox-middle-btn{
    width: 129px;
    height: 33px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #fafafa;
    border: 1px solid  #dddddd;
}
.video-rightBox-middle-btn div{
    width: 100%;
    text-align: center;

}
.video-rightBox-bottom-img{
    width: 168px;
    height: 103px;
    background: url(../../assets/images/pot.png);
    margin-bottom: 20px;
}

.video-rightBox-bottom-btn button{
    width: 105px;
    height: 39px;
    margin: 0 20px 5px 0;
    border: none;
    box-shadow: 2px 2px 2px #dddddd;
    outline-style: none;
    position: relative;

}
.video-rightBox-bottom-btn button:active{
    top: 2px;
}

.video-rightBox-tiem{
    width: 22px;
    height: 22px;
    background: url(../../assets/images/tiem.png) ;
    margin-right: 10px;
}

.video-rightBox-bottom-img_box{
    width: 168px;
    display: flex;
    position: relative;
}



.video-rightBox-wran{
    width: 202px;
    height: 103px;
    padding: 10px 0;
    display: none;
    position: absolute;
    left: 100%;
}

.video_rightBox_wran_head{
    font-size: 14px;
    font-weight: bold;

}

.video_rightBox_wran_bottom{
    color: #dddddd;
    font-size: 12px;
}

.video-rightBox-bottom-img_box:hover  .video-rightBox-wran{
    display: block;
}

.video-rightBox-buttom span{
    color: #008296;
}