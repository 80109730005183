.container {
}

.header {
  height: 44px;
  border-bottom: 1px solid #ccc;
}

.list {
  height: 400px;
  overflow: auto;
}

.listItem {
  border-bottom: 1px solid #ccc;
  padding: 4px;
}

.listItem:last-child {
  border-bottom: none;
}
