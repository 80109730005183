.taxationBox .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #C45500;
    border: 1px solid #C45500;
}
.taxationBox .topTitle{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.taxationBox .ant-steps{
    margin: 20px auto 50px;
    width: 70%;
}
.taxationBox .program-overview-values {
    padding: 20px;
    margin: 0 auto;
    width: 70%;
}
.taxationBox .eCkQDX{
    display: none;
}
.taxationBox .jXxwsB span.icon,
.taxationBox .jXxwsB .websit.ant-select,
.taxationBox .jXxwsB .language.ant-select .ant-select-selector,
.taxationBox .jXxwsB .ant-select-arrow,
.taxationBox .jXxwsB .search,
.taxationBox .jXxwsB .language.ant-select,
.taxationBox .jXxwsB .ilQBwr button{
    display: none;
}
.taxationBox .hoQOMo>div{
    display: block;
}
.taxationBox .jXxwsB .ilQBwr button:last-child{
    display: inline-block;
}
.taxationBox .jXxwsB .ilQBwr{
    text-align: right;
}
.taxationBox .CompanyAddress{
    width: 40%;
    margin: 0 auto;
}
.taxationBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;
}
.taxationBox .rightCollapse{
    width: 30%;
}
.taxationBox .rightCollapse>p{
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}
.taxationBox .operationBtn{
    display: flex;
    justify-content: center;
}
.taxationBox .operationBtn .next{
    width: 30%;
}
.taxationBox .previousPage{
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}



