.addTemplateBox .flex0{
    display: flex;
    justify-content: space-between;
}
.addTemplateBox .shopupUL-type{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.addTemplateBox .addTemplateBox{
    margin: 30px 0;
}
.addTemplateBox .addTemplateBox span:first-child{
    display: inline-block;
    background-color: #eff0f3;
    border: 1px solid #a2a6ac;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    height: 30px;
}
.addTemplateBox .addTemplateTitle{
    padding: 30px 0 30px 30px;
}

.addTemplateBox .fz20{
    font-size: 20px;
}
.addTemplateBox .fz22{
    font-size: 22px;
}
.addTemplateBox .fz24{
    font-size: 24px;
}
.addTemplateBox .fz26{
    font-size: 26px;
}
.addTemplateBox .fz13{
    font-size: 13px;
}
.addTemplateBox .cc0{
    color:#0066c0 !important;
}
.addTemplateBox .c46{
    color:#0066c4;
}
.addTemplateBox .bolds{
    font-weight: bold;
}

.addTemplateBox .shopupUL-type div:nth-child(1){
    margin-right: 40px;
}
.addTemplateBox .shopupUL-labelBtm{
    padding-left: 107px;
}
.addTemplateBox .shopupDL-rbox{
    width: 17%;
    margin-top: 0;
}
.addTemplateBox .shopupDL-bor{
    margin: 0;
}
.addTemplateBox .shopupDL-boxPD{
    min-height: 300px;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.addTemplateBox .shopupDL-boxHed{
    justify-content: space-between;
}
.addTemplateBox .shopupDL-boxHed .detaultBox{
    background-color: #ffa500;
    color: #fff;
    padding: 5px;
    border-radius: 5px 8px;
}
.addTemplateBox .shopupUL-navBody{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.addTemplateBox .shopupUL-checkbox{
    margin-right: 30px;
}
.addTemplateBox .shopupUL-upBtn{
    width: 100%;
    padding-left: 100px;
}
.addTemplateBox .shopupUL-upBtn button{
    width: 50px;
    height: 30px;
    background: #f3cd6f;
    border:1px solid #000;
    border-radius: 4px;
}
.addTemplateBox .tableBox{
    padding: 0;
    margin-bottom: 20px;
}
.addTemplateBox .rightBox{
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
}
.addTemplateBox .col296{
    color:#008296;
}
.addTemplateBox .a-section-expander-container .leftTitle,
.addTemplateBox .a-section-expander-container .leftTitle span{
    font-size: 17px;
    color: #0F1111;
    font-weight: bold;

}
.addTemplateBox .a-section-expander-container .leftTitle span:nth-child(2),
.addTemplateBox .a-section-expander-container .leftTitle span:nth-child(3){
    /* display: flex; */
    font-size: 14px;
    font-weight: 400;
}

.addTemplateBox .coupons1 td:nth-child(1){
    width: 60%;
    line-height: 1.5;
    text-align: left;
    font-weight: 400;
    background-color: #fff;
}
.addTemplateBox tr th{
    background-color: #f3f3f3;
    text-align: center;
}
.addTemplateBox tr td{
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
}
.addTemplateBox .shopupDL-lbox {
    width: 100%;
}
.addTemplateBox .templateTableBox{
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid #ccc;
}
.addTemplateBox .leftTips{
    min-width:11%;
    text-align: right;
    padding-right: 30px;
}
.addTemplateBox .leftTips p,
.addTemplateBox .leftTips a{
    font-size: 16px;
    margin-bottom: 0;

}
.addTemplateBox .templateName{
    height: 40px;
    width: 500px;
    padding-left: 10px;
}
.addTemplateBox .radioBox{
    display: flex;

}
.addTemplateBox .radioBox input{
    margin-right: 15px;
}
.addTemplateBox .requirement{
    background-color: #000;
    padding: 5px 8px;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    font-weight: bold;
}
.addTemplateBox .addRule{
    width: 120px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    background-color: #f5d994;
    border: 1px solid #846a29;
    border-radius: 2px;
    text-align: center;
    margin: 5px 0;
}
.addTemplateBox .coupons1 .flex0 span:last-child{
    min-width: 20%;
    text-align: right;
    color: #0066c0;
}
.addTemplateBox .tableBox .ant-input-affix-wrapper{
    width: 55%;
}
.addTemplateBox .footerBtn{
    margin: 20px;
    text-align: center;
}
.addTemplateBox .footerBtn button{
    width: 60px;
    height: 30px;
    margin-right: 15px;
    border-radius: 3px;
    border: 1px solid #846a29;
    
}
.addTemplateBox .footerBtn button:last-child{
    background-color: #f4d483;
    border: 1px solid #846a29;
}





