.kc12_banner {
	width: 100%;
	height: 40px;
	background-color: #f4f4f4;
	border: 1px solid #e5e6e7;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	box-sizing: border-box;
}

.kc12_banner>ul {
	margin: 0 auto;
	display: flex;
}

.kc12_banner>ul>li {
	font-size: 13px;
	font-weight: 600;
	color: #0a3798;
	margin: 0 10px;
}

.kc12_banner>ul>li>a {
	font-size: 13px;
	font-weight: 600;
	color: #0a3798;
}

.kc12_banner>ul>li>span {
	color: #c65200;
	margin: 0 0 0 10px;
	text-decoration: none !important;
}

.kc12_banner>ul>li:not([class="qx"]) {
	position: relative;
}

.kc12_banner>ul>li:not([class="qx"])::after {
	content: '';
	position: absolute;
	width: 1px;
	height: 15px;
	background-color: black;
	right: -10px;
	top: 15%;
}

/* 内容 */
.setQuantityBox .kc12_content {
	width: 1800px;
	margin: 0 auto;
}

.setQuantityBox .kc12_content {
	width: 95%;
	margin: 20px auto 0;
}

.setQuantityBox .kc12_content>h2 {
	color: #cc6e28;
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 15px;
}

.setQuantityBox .kc12_content>h2>span {
	font-size: 15px;
	color: #2766a2;
	font-weight: 500;
	margin-left: 10px;
}

/* 发/补货表格 */
.setQuantityBox .kc12_content .body-border {
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
}

.setQuantityBox .kc12_content .body-border .body-ul {
	height: 32px;
	display: flex;
	align-items: center;
	border-bottom: 3px solid #E47911;
}
.setQuantityBox .kc12_content .body-border .body-ul2 {
	height: 32px;
	display: flex;
	align-items: center;
	border-bottom: 3px solid #ccc;
	/* margin-right: 1px; */
}

.setQuantityBox .kc12_content .body-border .body-ul li {
	height: 32px;
	color: #0070b1;
	font-size: 15px;
	font-weight: bold;
	margin-right: 30px;
}
.setQuantityBox .kc12_content .body-border .body-ul2 li {
	height: 34px;
	color: rgba(0,0,0,0.7);
	font-size: 15px;
	font-weight: bold;
	padding-right: 30px;
	border-bottom: 3px solid #E47911;

}

.setQuantityBox .kc12_content .body-border .body-ul li:nth-of-type(1) {
	color: #E47911;
	margin-right: 0;
	position: relative;
	margin-right: 30px;
}

.setQuantityBox .kc12_content .body-border .body-ul li:nth-of-type(1) img {
	width: 12px;
	position: absolute;
	right: 50%;
	bottom: 0;
	transform: translateX(50%);
	z-index: 2;
}

.setQuantityBox .kc12_content .body-border .body-div {
	flex: 1;
	height: 32px;
	border-bottom: 3px solid #CCC;
	display: flex;
	align-items: center;
}

.setQuantityBox .kc12_content .body-table {
	display: flex;
	padding: 10px 0;
}

/* 选项卡 */
.setQuantityBox .kc12_content .body-tab p:nth-of-type(1){
	height: 50px;
	font-size: 14px;
	color: rgba(0,0,0,0.8);
	line-height: 50px;
}
.setQuantityBox .kc12_content .body-tab .body-tab-div{
	width: 100%;
	border: 1px solid #BBD3DE;
	background-color: #F6F6F6;
	position: relative;
	padding: 0 70px;
	border-radius: 5px;
}
.setQuantityBox .kc12_content .body-tab p:nth-of-type(1) span{
	color: cornflowerblue;
}
.setQuantityBox .kc12_content .body-tab .body-tab-div .i{
	width: 30px;
	position: absolute;
	top: 10px;
	left: 20px;
}
.setQuantityBox .kc12_content .body-tab .body-tab-div p{
	display: inline;
}
.setQuantityBox .kc12_content .body-tab .xs{
	font-size: 13px;
}
.setQuantityBox .kc12_content .body-tab .xs span{
	font-weight: 700;
	margin-left: 5px;
	margin-right: 2px;
}
.setQuantityBox .kc12_content .body-table .table1 th,.table1 td {
	padding-right: 35px;
	text-align: left;
	font-size: 12px;
	color: #666666;
}
.setQuantityBox .typeSwitch{
	cursor: pointer;
	font-weight: bold;
	margin-top: 10px;
}
.setQuantityBox .kc12_content .body-table .table1{
    width: 35%;
    box-shadow: none;
}
.setQuantityBox .kc12_content .body-table .table1 td{
    border: none;
}
.setQuantityBox .kc12_content .body-table .table1 td:nth-child(2){
	min-width: 100px;
}

.setQuantityBox .kc12_content .body-table .table1 .what span{
	color: #004B91;
	font-weight: 400;
}
.setQuantityBox .kc12_content .body-table td span {
	color: #000;
	font-weight: bold;
}

.setQuantityBox .kc12_content .body-tab .tab-ul {
	height: 36px;
	margin-top: 10px;
	display: flex;
	border-bottom: 1px solid #A4A4A4;
	box-sizing: border-box;
}

.setQuantityBox .kc12_content .body-tab .tab-ul li {
	height: 36px;
	line-height: 36px;
	padding: 0 15px;
	color: #126899;
	font-size: 14px;
}
.setQuantityBox .kc12_content .body-tab .tab-ul2{
	float: right;
	display: flex;
	margin-top: -70px;
}
.setQuantityBox .kc12_content .body-tab .tab-ul2 li{
	font-size: 14px;
}
.setQuantityBox .kc12_content .body-tab .tab-ul2 .mj{
	color: #FFA500;
	padding: 0px 15px;
	border-radius: 5px;
	margin-right: 20px;
	border: none;
}
.setQuantityBox .kc12_content .body-tab .tab-ul2 .ck{
	margin-right: 15px;
	color: cornflowerblue;
}

.setQuantityBox .kc12_content .body-tab .tab-ul .active {
	border: 1.5px solid #A4A4A4;
	background-color: white;
	border-bottom: none;
	padding-bottom: 1px;
	font-weight: bold;
	color: #666 !important;
	margin-top: -2px;
}

/* 卖家信息 */
.setQuantityBox .kc12_content #mybox {
	width: 100%;
	margin-top: 25px;
}

.setQuantityBox .kc12_content #mybox>ul {
	width: 100%;
	display: flex;
	list-style: none;
	font-size: 13px;
	border-bottom: 2px solid #a7a7a7;
	margin-bottom: 0;
	padding: 10px;
}

.setQuantityBox .kc12_content #mybox>ul>li {
	display: flex;
}

.setQuantityBox .kc12_content #mybox>ul:nth-child(1) {
	padding-bottom: 15px;
}

.setQuantityBox .kc12_content #mybox>ul:nth-child(2) {
	background-color: #dfdfdf;
	height: 40px;
	align-items: center;
}

.setQuantityBox .kc12_content #mybox>ul:nth-child(3) {
	padding-top: 10px;
	min-height: 75px;
}

.setQuantityBox .kc12_content #mybox>ul:nth-child(4) {
	padding-top: 10px;
	min-height: 60px;
}

.setQuantityBox .kc12_content #mybox>ul>li{
	width:20%;
}
.setQuantityBox .kc12_content #mybox>ul>li img{
	width: 20px;
	height: 20px;
}

/* 卖家信息下方按钮 */
.setQuantityBox .kc12_content .body-box2 {
	height: 150px;
	display: flex;
	justify-content: space-between;
}

.setQuantityBox .kc12_content .body-box2 .btn-l .btn1 {
	height: 20px;
	position: absolute;
	left: 2px;
	top: 0px;
}

.setQuantityBox .kc12_content .body-box2 button {
	margin-top: 20px;
	padding: 2px 10px;
	color: #000068;
	background-color: #F7F7EE;
	border: 2px solid #868654;
	background-image: linear-gradient(#F7F7EE, #CECEAD);
	font-weight: bolder;
	border-radius: 5px;
	margin-right: 5px;
}
.setQuantityBox .kc12_content .body-box2 .btn-l button{
	cursor: pointer;
}

.setQuantityBox .kc12_content .body-box2_button2 {
	margin-top: 20px;
	padding: 2px 20px;
	color: #000068;
	background-color: #dfdfdf !important;
	border: 2px solid #000068 !important;
	background-image: linear-gradient(#ffde91, #d9a62d) !important;
	font-weight: bolder;
	margin-left: -80px;
	padding-right: 30px !important;
	border-top-right-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
	border-top-left-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
	position: relative;
}
.setQuantityBox .kc12_content .body-box2_button2 .btn2{
	width: 20px;
	margin-left: 5px;
	position: absolute;
}
.body-box2 a{
	color: #000;
}
.kc_10_btn2{
	height: 20px;
	position: absolute;
	right: 2px;
	bottom: 5px;
	transform: rotateY(180deg);
}
.btn-l .kc_10_btn2{
	left: 2px;

}
.goodsModalBox{
	width: 50% !important;
}
.goodsModalBox .flex0{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.goodsModalBox table{
	width: 100%;
	margin: 20px 0;
	border: 1px solid #ccc;
}
.goodsModalBox table th,
.goodsModalBox table td{
	text-align: center;
}
.goodsModalBox table td:nth-child(2){
	width: 30%;
}
.goodsModalBox .addGoods{
	background: linear-gradient(#ffe39c,#edb631);
	border: 1px solid #344b7a;
	border-radius: 6px;
	font-weight: bold;
	width: 50%;
	margin: 0 auto;
	cursor: pointer;
}
.setQuantityBox .isDel{
	background-color: #cccccc;
}
