
.MengkGenerator .ant-layout-header{
  background: #fff;
  padding: 0;
  height: auto;
}
.MengkGenerator  .ant-layout-sider{
  background: #fff;
}
.MengkGenerator-tabs{
  height: 48px;
  line-height: 48px;
  display: flex;
  background: rgb(85,100,120);
}
.MengkGenerator-tabs div{
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
}
.MengkGenerator .ant-layout-header h3{
  font-size: 24px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
}
.MengkGenerator-body{
  position: fixed;
  top: 186px;
  width: 100%;
  bottom: 0;
}
.MengkGenerator-body .MengkGenerator-body-left{
  position: absolute;
  left: 0;
  width: 360px;
  padding: 0 10px;
  height: 100%;
  overflow-y: auto;
  background:rgb(243,243,243);
}
.MengkGenerator-body .MengkGenerator-body-content{
  position: absolute;
  left: 360px;
  right: 360px;
  padding: 0 10px;
  height: 100%;
  overflow-y: auto;
  background: rgb(213, 219, 219);
}
.MengkGenerator-body .MengkGenerator-body-right{
  position: absolute;
  right: 0;
  width: 360px;
  padding: 0 10px;
  height: 100%;
  overflow-y: auto;
}