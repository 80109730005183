.informationHead_headII{
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.informationHead_headII_btn button{
    width: 75px;
    height: 37px;
    background-color: #e3eced;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
}
.informationHead_headII_btn button:hover{
    background: #B6C5D3;
}
.informationHead_headII_btnI button:hover{
    background: #B6C5D3;
}
.informationHead_headII_btnII button:hover{
    background: #006878;
}
.informationHead_headII_btnI button{
    width: 110px;
    height: 37px;
    background-color: #e3eced;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;

}
.informationHead_headII_btnII button{
    width: 170px;
    height: 37px;
    background-color: #008296;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
}
.informationHead_head_inp input{
    width: 100%;
    height: 40px;
}
.informationHead_head_label {
    width: 100%;
    height: 40px;
}
.informationHead_head_label  select{
    height: 40px;
    width: 100%;
}
.informationHead_head_label  option{
    height: 40px;
    width: 100%;
}
.informationHead_Steps_liI{
    width: 90%;
    font-size: 12px;
    margin-bottom: 10px;
}
.informationHead_Steps_liII{
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.informationHead_Steps_divI{
    width: 23%;
}
.informationHead_Steps_divII{
    width: 18%;
}

.informationHead_Steps_li{

}

.informationHead_Steps_li div{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.Details_titel h2{
    font-size: 24px;
}