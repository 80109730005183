.end06_mybody .mybody-ul {
    width: 390px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    cursor: pointer;
}

.TaxInvestigationBox .bolds {
    font-weight: bold;
}

.TaxInvestigationBox .ant-form-item-extra {
    color: #000 !important;
}

.TaxInvestigationBox .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #C45500;
    border: 1px solid #C45500;
}

.TaxInvestigationBox .bottomDesc {
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}

.TaxInvestigationBox .topTitle {
    font-size: 24px;
}

.TaxInvestigationBox .selectBox .TaxInvestigationBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}

.TaxInvestigationBox .a-spacing-top-large {
    margin-top: 24px !important;
}

.TaxInvestigationBox .ant-steps {
    margin: 20px auto 50px;
    width: 70%;
}

.CommentatorBox .mybody-ul li:nth-of-type(3) {
    border-bottom: 0;
}

.end06_mybody .mybody-ul li:nth-of-type(1) {
    border-bottom: 3px solid #FFBA53;
}

.commentBg {

    height: 300px;
    background: url('~@/assets/images/commentBg.png') no-repeat;
    background-size: cover;
    display: flex;
}

.program-overview-title-box {
    padding: 60px 0 0 80px;
    width: 400px;
    background: rgba(39, 62, 84, .7);
    height: 100%;
    color: #fff;
}

.program-overview-diagonal {
    height: 0;
    width: 0;
    border-bottom: 300px solid rgba(39, 62, 84, .7);
    border-right: 100px solid transparent;
}

.heading-text {
    color: #fff;
    font-size: 40px;
}

.a-spacing-small,
.a-ws .a-ws-spacing-small {
    margin-bottom: 8px !important;
}

.a-spacing-medium,
.a-ws .a-ws-spacing-medium {
    margin-bottom: 16px !important;
}

.TaxInvestigationBox .a-size-large {
    font-size: 13px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.text-justify {
    text-align: justify;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.TaxInvestigationBox .program-overview-values {
    padding: 20px;
    margin: 0;
    width: 65%;
}

.TaxInvestigationBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}

.TaxInvestigationBox .ng-scope {
    text-align: center;
}

.TaxInvestigationBox .a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.TaxInvestigationBox .a-size-small {
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.a-grid-vertical-align.a-row>div.a-span4 {
    width: 34.93%;
}

.a-grid-vertical-align.a-row>div.a-column {
    float: none !important;
    display: table-cell !important;
    zoom: 1;
    padding-right: 14px;
}

.a-ws div.a-column,
div.a-column {
    margin-right: 2%;
    float: left;
    min-height: 1px;
    overflow: visible;
}

.a-row .a-span4,
.a-ws .a-row .a-ws-span4 {
    width: 31.948%;
}

.value-box {
    padding: 35px !important;
}

.a-text-center {
    text-align: center !important;
}

.reviews-increase-icon {
    height: 78px;
    background: url('~@/assets/images/Business_image._V270892899_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.reviews-faster-icon {
    height: 78px;
    background: url('~@/assets/images/mobile_image._V270892880_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-easy-icon {
    height: 78px;
    background: url('~@/assets/images/credit_card_image._V270892869_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-four-icon {
    height: 78px;
    background: url('~@/assets/images/tax_information_image._V270892852_.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.a-section:last-child {
    margin-bottom: 0;
}

.program-overview-footer {
    padding: 45px;
}

.alternate-bg,
.program-overview-footer {
    background-color: #f4f5f5;
}

.a-button-primary:hover {
    color: #000;
}

.TaxInvestigationBox .eCkQDX {
    display: none;
}

.TaxInvestigationBox .jXxwsB span.icon,
.TaxInvestigationBox .jXxwsB .websit.ant-select,
.TaxInvestigationBox .jXxwsB .language.ant-select .ant-select-selector,
.TaxInvestigationBox .jXxwsB .ant-select-arrow,
.TaxInvestigationBox .jXxwsB .search,
.TaxInvestigationBox .jXxwsB .language.ant-select,
.TaxInvestigationBox .jXxwsB .ilQBwr button {
    display: none;
}

.TaxInvestigationBox .hoQOMo>div {
    display: block;
}

.TaxInvestigationBox .jXxwsB .ilQBwr button:last-child {
    display: inline-block;
}

.TaxInvestigationBox .jXxwsB .ilQBwr {
    text-align: right;
}

.TaxInvestigationBox .CompanyAddress {
    width: 90%;
    margin: 0 auto;
}

.TaxInvestigationBox .selectBox {
    width: 49%;
    height: 35px;
}

.TaxInvestigationBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;

}

.TaxInvestigationBox .rightCollapse {
    width: 35%;
}

.TaxInvestigationBox .rightCollapse>p {
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}

.TaxInvestigationBox .inpBox {
    margin-top: 15px;
}

.TaxInvestigationBox .inpBox1 input {
    padding-left: 5px;
    width: 100%;
    height: 35px;
}

.TaxInvestigationBox .inpBox input:nth-child(2) {
    margin-left: 2%;
}

.TaxInvestigationBox .aCheckbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.TaxInvestigationBox .aCheckbox span {
    margin-left: 5px;
}

.TaxInvestigationBox .receivingMode {
    margin-top: 20px;
}

.TaxInvestigationBox .receivingMode p {
    font-weight: bold;
    margin-bottom: 0;
}

.TaxInvestigationBox .yanzPhone {
    display: flex;
    border: 1px solid #d9d9d9;
}

.TaxInvestigationBox .yanzPhone select,
.TaxInvestigationBox .yanzPhone .ant-input-affix-wrapper {
    border: none;
}

.TaxInvestigationBox .inpBox .next {
    width: 49%;
    margin-left: 2%;
    height: 35px;
}

.TaxInvestigationBox .representative input+input {
    margin-left: 2%;
}

.TaxInvestigationBox .billTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TaxInvestigationBox .billTitle div p {
    font-size: 16px;
    font-weight: bold;
}

.TaxInvestigationBox .billTitle div p:first-child {
    font-size: 22px;
}

.TaxInvestigationBox .billTitle div:last-child {
    font-size: 14px;
    color: rgb(165, 126, 192);
}

.TaxInvestigationBox .inpBox1 {
    display: flex;
}

.TaxInvestigationBox .inpBox1 p {
    font-weight: bold;
}

.TaxInvestigationBox .inpBox1 .dates {
    margin-left: 2%;
    width: 66%;
}

.TaxInvestigationBox .inpBox1 .dates>div {
    display: flex;
}

.TaxInvestigationBox .inpBox1 .dates select {
    height: 35px;
    width: 40%;
}

.TaxInvestigationBox .inpBox1 .dates span {
    margin: 0 10%;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
}

.TaxInvestigationBox .bottomDesc .ant-radio-wrapper {
    word-wrap: break-word !important;
    word-break: normal;
    white-space: pre-wrap
}

.TaxInvestigationBox .depositInfo {
    width: 49%;
    margin-bottom: 15px;
}

.TaxInvestigationBox .depositInfo:nth-child(2) {
    margin-left: 2%;
}

.TaxInvestigationBox .depositInfo select,
.TaxInvestigationBox .depositInfo input {
    width: 100%;
}

.TaxInvestigationBox .operationBtn {
    display: flex;
    justify-content: center;
}

.TaxInvestigationBox .operationBtn .next {
    width: 30%;
}

.TaxInvestigationBox .previousPage {
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}

.TaxInvestigationBox .btnClass {
    display: flex;
    margin-top: 20px;
}

.TaxInvestigationBox .btnClass span {
    display: block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #00000050;
    margin-left: 15px;
    text-align: center;
}

.TaxInvestigationBox .nsInfoTitle {
    margin-top: 40px;
}

.TaxInvestigationBox .nsInfoBox {
    border-radius: 5px;
    border: 1px solid #00000030;
    padding: 30px;
}

.TaxInvestigationBox .xyTable {
    border-radius: 5px;
    background-color: rgb(246, 246, 246);
    padding: 30px;
}

.TaxInvestigationBox .xyTable .bolds {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;

}

.TaxInvestigationBox .xyTable select {
    width: 100%;
    height: 35px;
}

.TaxInvestigationBox .CheckboxClass {
    margin: 30px 0;
}

.TaxInvestigationBox .CheckboxClass p {
    margin-top: 5px;
}

.TaxInvestigationBox .investigationTable {
    text-align: center;
    background-color: rgb(238, 238, 238);
}

.TaxInvestigationBox .investigationTable td {
    padding: 5px;
    border: 1px solid #00000030;
}

.TaxInvestigationBox .investigationTable .fz16 {
    font-size: 16px;
    font-weight: bold;
}

.TaxInvestigationBox .investigationTable .fz20 {
    font-size: 20px;
    font-weight: bold;
    width: 60%;
}

.TaxInvestigationBox .investigationTable .textAlign {
    text-align: left;
}

.TaxInvestigationBox .investigationTable .textAlign .taableTit {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 20px;
    padding-bottom: 0;
}

.TaxInvestigationBox .investigationTable .textAlign ul {
    padding: 20px;
    padding-top: 0;
}

.TaxInvestigationBox .investigationTable .textAlign li {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.TaxInvestigationBox .investigationTable .textAlign li span:last-child {
    min-width: 150px;
    text-align: right;
}

.TaxInvestigationBox .investigationTable .textAlignFooter li span:last-child {
    min-width: 150px;
    text-align: left;
}

.TaxInvestigationBox .investigationTable .bgCol {
    background-color: #000;
    color: #fff;
    padding: 10px;
    font-weight: bold;
    margin-right: 10px;
}

.TaxInvestigationBox .investigationTable .beneficialClass input {
    width: 100%;
    height: 35px;
    border-radius: 3px;
}

.TaxInvestigationBox .investigationTable .bottomInp {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
}

.TaxInvestigationBox .investigationTable .bottomInp input {
    width: 100%;
    height: 30px;
}