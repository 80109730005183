.Related_head{
    width: 100%;
    padding:  0 20px;
    padding-bottom: 500px;
}

.Related_title{
    margin: 20px 0;
}
.Related_navBox{
    width: 100%;
    border: 1px solid #dddddd;
}
.Related_navtitle{
    background: #fafafa;
    margin: 0;
}
.Related_navtitle h3{
    margin: 0;
}
.Related_navhead{
    width: 100%;
    background-color: #fafafa;
    display: flex;
    justify-content: space-between;
    padding:   20px;
}





.Related_inpBox{
    width: 100%;
    background: #fafafa;
    padding: 20px;
    padding-top: 0;
    border-bottom: 1px solid #dddddd;
}
.Related_inp{
    width: 45%;
    height: 36px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #ffffff;
    padding: 0 10px;
}
.Related_inp_font{
    font-size: 24px;
}
.Related_inp input{
    width: 100%;
    border: 0;
    outline-style: none;
}
.Related_bar_head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fafafa;
}

.Related_bar_head div{
    width: 24.9%;
    display: flex; 
    justify-content: space-between;
}

.Related_bar_navI div:nth-child(2){
    width: 30px;
    border-right:2px solid #e3e3e3 ;
    margin-right: 10px;
}
.Related_bar_navI div:nth-child(2) svg{
    width: 23px;
    height: 23px;

}
.Related_bar_navII{
    padding-left:10px ;
}

.Related_bar_nav{
    width: 100%;
    padding:  20px;
}
.Related_bar_navII{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

}

.Related_bar_navII div{
    width: 24.9%;
   
}
.Related_head .ant-btn-primary{
    background: #e3edec;
    border: #dddddd;
    color: #000000;
    font-weight: bold;
}