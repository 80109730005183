





.business_Body{
    width: 100%;
    display: flex;
}
.business_Drawer_box{
    width:11% ;
    position: relative;
    margin-right: 50px;
}

.business_Drawer{
    width: 100%;
    height: 100%;
    background: #BBBBBB;
}

.business_Drawer_titel{
    background: #a5a5a5;
    color: #ffffff;
    padding-left: 5px;
    font-weight: bold;
}

.business_Drawer_bottom_nav{
    padding: 0 12px;

}

.business_Drawer_bottom_titel{
    padding-left: 10px;
    font-weight: bold;

}
.business_Drawer_nav{
    padding: 0 12px;
    background: #669ACC;
    margin: 6px 0;
}
.business_Drawer_btn{
    width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform:rotate(90deg);
    color: #ffffff;
    background: #a5a5a5;
    position: absolute;
    top: 47%;
    left: 179px;
    z-index: -1;
}
.business_content{
    width: 85%;
    height: 600px;
    padding-top: 20px;
}

.business_content_hint{
    border: 1px solid #008296;
    width: 90%;    
    margin:0 auto;
    padding: 15px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #F6F6F6;
}
.business_content_hint span{
    margin-right: 15px;
  
}
.business_content_hint span svg{
    width: 2em;
    height: 2em;
}
.business_content_titel{
    display: flex;
    justify-content: space-between;
}
.business_content_chart{
    margin-top: 20px;
}
.business_content_titelII {
    font-size: 16px;
    font-weight: bold;
}
.business_content_titel span{
    text-decoration:underline;
    color: #004B91;
    margin-left: 20px;
}

.business_content_date{
    width: 100%;
    display: flex;
    border: 1px solid #6699CC;
    background: #F4F6FB;
}

.business_content_date_bd{
    height: 100%;
    border-right: 2px solid #dddddd;
    padding-right: 10px;
    margin-right: 10px;
    padding: 10px 10px;
    font-weight: bold;
}
.business_content_date_select{
    width: 145px;
}
.business_content_date_btn{
    height: 100%;
    margin: auto 0;
}

.business_content_date_btn button{ 
    color: #ffffff;
    background: #A0A0A0;
    width: 55px;
    outline: none;
    border: 0;
}

.business_content_date_btn button:hover { 
    background: #737373;
}
.business_content_btn{
    display: flex;
    align-items: center;
}
.business_content_btn button{
    margin-right: 10px;

}
.business_content_titelIIBox{
    display: flex;
}

.business_content_order{
    width: 100%;
    border: 1px solid #dddddd;
    margin-top: 10px ;
}
.business_content_order_head{
    display: flex;
    align-items: centerc;
    background: #F5F5F5;
    border-bottom: 1px solid #dddddd;
    padding: 6px 0;
}
.business_content_order_head div{
    font-weight: bold;
    font-size: 16px;
}
.business_content_order_head span{
    margin: auto 4px;
    font-weight: bold;
}
.business_content_order_content{
    display: flex;
    align-items: center;
}
.business_content_order_contentBox{
    padding: 3px 10px;
    font-weight: bold;
    margin-right: 10px;
}
.business_content_order_contentBox div{
        font-size: 14px;
}
.business_content_contrastBox{
    width: 100%;
    border: 1px solid #dddddd;
    margin-top: 10px;
    padding: 10px;
    background: #F5F5F5;
}
.business_content_contrastBox_titel{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.business_content_contrastBox_head{
    font-size: 16px;
    font-weight: bold;
}
.business_content_contrastBox_btn{
    display: flex;
    border: 1px solid #dddddd;
}
.business_content_contrastBox_chart{
    display: flex;
    padding-left: 10px;
}
.business_content_contrastBox_chart div{
    width: 50%;
}
.business_chartBox{
    width: 100%;
}

.business_chartBoxII{
    display: flex;
}

.business_chartBox_contentII{
        width: 92%;
        height: 175px;
        border: 1px solid #dddddd;
        background: #ffffff;
        border-bottom: 2px solid #84449D;
}
.business_chartBox_content div{
    margin-bottom: 24px;
    margin-right: 10px;
}
.business_chartBox_date{
    display: flex;
    padding-left: 14px;

}
.business_chartBox_date div{
    margin-right: 4%;
    }

    .business_chartbody{
        width: 100%;
        display: flex;
    }
    .business_chartBox_bottom{
        display: flex;
        margin-top: 20px;
    }
    .business_bottom_content{
        border-right: 2px solid #dddddd;
        padding-right: 20px;
        margin-right: 20px;
        font-weight: bold;
    }

    .business_bottom_Link{
        color: #004B91;
        text-decoration: underline;

    }