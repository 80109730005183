.end01_font_blue{
	/* color: #4c93d2; */
}
.end01_myBody{
	padding: 0 20px;
}
.end01_myBody_header{
	margin-top: 30px;
}
.end01_myBody_header_title{
	display: flex;
	justify-content: space-between;
}
.end01_myBody_header_title h1{
	font-weight: 100;
	font-size: 30px;
}
.end01_myBody_header_title p{
	color: #4c93d2;
}
.end01_myBody_header>p:nth-of-type(1){
	margin-top: 20px;
}
.end01_myBody_header>p:nth-of-type(2){
	margin-top: 14px;
	color: #4c93d2;
}
.end01_myBody_content{
	border: 1px solid #dddddd;
	border-radius: 2px;
	padding-bottom: 40px;
	margin-top: 30px;
}
.end01_myBody_content_header{
	height: 50px;
	background: #F3F3F3;
	border-bottom: 1px solid #dee2e6;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 20px;
}
.end01_myBody_content_header>p{
	display: inline-block;
	background-color: #fff;
	color: #c86112;
	border-top: 1px solid #C86112;
	padding: 0 10px;
	height: 50px;
	line-height: 50px;
	font-weight: bold;
	font-size: 14px;
	border-right: 1px solid #e5e5e5;
}
.end01_myBody_content_con{
	padding: 50px 20px 0;
}
.end01_myBody_con_h{
	display: flex;
	align-items: center;
}
.end01_con_box{
	height: 30px;
	position: relative;
}
.end01_icon-search{
	position: absolute;
	left: 6px;
	top: 8px;
}
.end01_icon-bottom{
	width: 14px;
	position: absolute;
	right: 36px;
	top: 8px;
}
.end01_con_box:not(:nth-of-type(3)){
	border-right: 1px solid #d2d2d2;
	margin-right: 30px;
	padding-right: 30px;
}
.end01_con_box input,.end01_con_box button,.end01_con_box select{
	height: 30px;
	border-radius: 3px;
	border: 1px solid #d2d2d2;
	box-sizing: border-box;
}
.end01_con_left>input{
	padding: 0 36px 0 24px;
}
.end01_con_left>button{
	color: #FFFFFF;
	padding: 0 10px;
	background: linear-gradient(to bottom,#6e747c,#424952);
}
.end01_con_box select{
	width: 140px;
	background-color: #e7e9ec;
	padding-left: 6px;
	border: 1px solid #babcc0;
}
.end01_con-right{
	display: flex;
}
.end01_con-right>span{
	font-weight: bold;line-height: 30px;
}
.end01_con-right div{
	margin-left: 16px;
}
.end01_con-right div input{
	width: 60px;
	text-align: center;
}
.end01_con-right button{
	padding: 0 10px;
	margin-left: 10px;
	background: linear-gradient(to bottom,#f5f6f8,#e7e9ec);
}
.end01_con_txt{
	margin-left: 30px;
}
.end01_con_txt>p{
	line-height: 26px;
}
.end01_con_txt>p:nth-of-type(1){
	font-size: 20px;
}
.end01_con_txt>p:nth-of-type(2),.end01_con_txt>p:nth-of-type(3){
	color: #4c93d2;
}
.end01_table{
	width: 98%;
	border: 1px solid #f3f3f3;
	margin: 50px auto 0;
}
.end01_table_h{
	background: #f3f3f3;
	height: 80px;
}
.end01_table_h th{
	text-align: left;
	border-top: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	padding-top: 10px;
	vertical-align: top;
}
.end01_state{
	color: #0066c0;
	width: 200px;
	padding-left: 20px;
}
.end01_picture{
	width: 100px;
}
.end01_details{
	width: 400px;
}
.end01_num{
	width: 200px;
	color: #0066c0;
}
.end01_bottomPrice{
	width: 400px;
}
.end01_value,.operation{
	width: 150px;
}
.end01_value>p{
	color: gray;
	font-weight: 100;
}
.end01_icon-value{
	width: 26px;
	vertical-align: middle;
}
.end01_table_c{
	height: 50px;
	line-height: 50px;
	text-align: center;
}
