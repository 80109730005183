.end06_mybody .mybody-ul{
	width: 390px;
	height: 40px;
	line-height: 40px;
	display: flex;
	justify-content: space-around;
	font-size: 15px;
	cursor: pointer;
}
.EarlyBox .mybody-ul li:nth-child(1),
.EarlyBox .mybody-ul li:nth-child(2){
	border-bottom: 0 !important;
}
.end06_mybody .mybody-ul li:nth-of-type(3){
	border-bottom: 3px solid #FFBA53;
}
.end06_mybody .sku{
	height: 80px;
	border: 2px solid rgba(0,0,0,0.1);
	overflow: hidden;
	padding-left: 10px;
}
.end06_mybody .sku ul:nth-of-type(1) li{
	line-height: 80px;
	font-size: 30px;
	float: left;
}
.end06_mybody .sku ul:nth-of-type(2){
    display: flex;
    align-items: center;
	float: right;
	margin-right: 10px;
}
.end06_mybody .sku ul:nth-of-type(2) li{
	margin-left: 10px;
}
.end06_mybody .sku ul:nth-of-type(2) input{
	padding: 6px 5px;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.5);
}
.end06_mybody .sku ul:nth-of-type(2) li button{
	background-color: #ECEEF0;
	width: 120px;
	height: 28px;
	border: 1px solid rgba(0,0,0,0.5);
	border-radius: 3px;
}
.end06_mybody .asin{
	width: 99%;
	height: 70px;
	/* border: 1px solid red; */
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
	overflow: hidden;
	position: relative;
}
.end06_mybody .asin ul:nth-of-type(1){
	display: flex;
	justify-content: space-around;
	float: left;
	font-size: 20px;
}
.end06_mybody .asin ul:nth-of-type(2){
	display: flex;
	justify-content: space-around;
	float: right;
}
.end06_mybody .asin ul:nth-of-type(2) input{
	padding: 5px 5px;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.5);
	padding-left: 25px;
}
.end06_mybody .asin .sousuo{
	position: absolute;
    top: 17px;
    right: 195px;
}
.end06_mybody .asin ul:nth-of-type(2) button{
	background-color: #4C535C;
	border: 1px solid rgba(0,0,0,0.5);
	border-radius: 3px;
	padding: 4px 6px;
	color: white;
	margin-left: 5px;
}
.end06_mybody .tab{
	width: 99%;
	margin: 0 auto;
}
.end06_mybody .tab table{
	width: 99%;
	text-align: center;
	position: relative;
}
.end06_mybody .tab .mytr{
	height: 40px;
	background-color: #F2F2F2;
	color: #0F94D5;
	font-size: 14px;
}
.end06_mybody .tab .mytr th,td{
	border-left: none;
	border-right: none;
}
.end06_mybody .tab .mytr th:nth-of-type(1){
	color: black;
}
.end06_mybody .tab .mytr th:nth-of-type(3){
	color: black;
}
.end06_mybody .tab table th:nth-of-type(1){
	width: 65%;
	text-align: left;
	padding-left: 100px;
}
.end06_mybody .tab table td:nth-of-type(1){
	font-size: 13px;
	color: #0F94D5;
	text-align: left;
}
.end06_mybody .tab table td:nth-of-type(1) p{
	margin-left: 100px;
}
.end06_mybody .tab table .shop1{
	position: absolute;
	margin-top: 10px;
	margin-left: 20px;
}
.end06_mybody .tab .mytr2{
	font-size: 13px;
}
.end06_mybody .tab .mytr2 td{
	height: 60px;
}
.end06_mybody .tab .xlcor{
	color: #0F94D5;
}
.end06_mybody .tab .xlcor svg{
	margin-left: 5px;
}
.end06_mybody .tab .mytr2 .nr{
	color: #0F94D5;
}
.end06_mybody .tab .mytr2 p{
	color: rgba(0,0,0,0.6);
}
.foot{
	width: 99%;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	margin-top: 5px;
	position: relative;
}
.foot .page{
	text-align: center;
}
.foot .page button{
	padding: 5px 10px;
	background-color: #EAECEF;
	border: 1px solid rgba(0,0,0,0.3);
}
.foot .page button:nth-of-type(2){
	border: 2px solid #F3BA7E;
	color: #CB6238;
	background: none;
}
.foot .page .not{
	padding: 5px 20px;
	padding-top: 3px;
}
.foot .page .notnot{
	border: none;
	background: none;
}
.foot .fk{
	position: absolute;
	left: 0;
	background-color: #008296;
	color: white;
	border: 1px solid #008296;
	padding: 5px 10px;
	border-radius: 6px;
}
.foot .shop{
	position: absolute;
	right: 20px;
	padding: 5px 10px;
	background-color: #EAECEF;
	border: 1px solid rgba(0,0,0,0.3);
}
.foot .xiala{
	margin-left: 5px;
}