.tab1-header{
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1024px;
    margin: 0 auto;
    background-color: #fff;
}
.tab1-header span{
    font-size: 12px;
    margin-right: 4px;
}
.ant-switch-checked{
    background-color: #008296!important;
}
.tab1-icon{
    margin: 0 10px;
}
.tab1-crumbs{
    font-size: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 1024px;
    margin: 0 auto;
}
.tab1-crumbs-title{
    color: #008296;
}
.tab1-hint{
    height: 70px;
    background-color: #E5F2F4;
    width: 1024px;
    margin: 0 auto;
}

.tab1-hint{
    padding-top: 10px;
    padding-left: 20px;
}
.tab1-hint-icon{
    font-size: 28px;
}
.tab1-hint-title{
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #002F36;
    vertical-align: top;
}
.banner-checkbox-title .ant-checkbox + span{
    font-size: 12px;
    color: #002F36;
}
.banner-checkbox-title{
    margin-left: 36px;
}