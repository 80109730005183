.a-card .ant-card-head-title {
  font-size: 20px;
}
.a-card {
  overflow: hidden;
  position: relative;
}
.a-card .ant-card-head {
  position: relative;
  z-index: 1;
}

.a-card .ant-card-head,
.a-card.ant-card-bordered {
  border-color: #ccc;
}

.a-card.collapsible .a-card__title {
  cursor: pointer;
}

.a-card.collapsible.is-folding .ant-card-body {
  display: none;
}
