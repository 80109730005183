.deliverGoodsBox .kc06_box1 {
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bold;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.deliverGoodsBox .kc06_box1 a {
    color: #003399;
}

.deliverGoodsBox .kc06_box1-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.deliverGoodsBox .kc06_box1-ul li {
    height: 15px;
    padding: 0 10px;
    color: #CC6600;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
}

.deliverGoodsBox .kc06_box1-ul li a {
    display: block;
}

.deliverGoodsBox .kc06_box1-ul li:last-of-type {
    border-right: 0;
}

.deliverGoodsBox .kc06_mybody {
    width: 100%;
    padding: 10px 0;
}

.deliverGoodsBox .kc06_h1 {
    font-size: 22px;
    color: #CC6600;
    font-weight: bold;
    margin-bottom: 5px;
}

.deliverGoodsBox .kc06_h1 span {
    margin-left: 10px;
    font-size: 14px;
    color: #1976ac;
}


.deliverGoodsBox .kc06_body-ul {
    height: 35px;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    border-bottom: 3px solid #CCC;
    box-sizing: border-box;
}


.deliverGoodsBox .kc06_body-ul li {
    height: 32px;
    color: #0070b1;
    font-size: 15px;
    font-weight: bold;
    padding-right: 30px;
    padding-bottom: 5px;
    border-bottom: 3px solid #E47911;
    display: flex;
    align-items: center;
}

.deliverGoodsBox .kc06_body-ul li:nth-child(4) {
    color: #E47911;
    margin-right: 0;
    position: relative;
    padding-right: 0;
}

.deliverGoodsBox .kc06_body-ul li:nth-child(5) {

    padding-left: 30px;
    border-bottom: 3px solid #CCC;
}

.deliverGoodsBox .kc06_body-ul li:nth-child(6) {
    border-bottom: 3px solid #CCC;
}

.deliverGoodsBox .kc06_body-ul li:nth-child(4) img {
    width: 12px;
    position: absolute;
    left: 20px;
    bottom: -5px;
    transform: translateX(50%);
    z-index: 2;
}

.deliverGoodsBox .kc06_body-table {
    border-bottom: 1px solid #CCC;
    padding: 10px 0;
}

.deliverGoodsBox .kc06_table1 th,
.deliverGoodsBox .kc06_table1 td {
    width: 160px;
    padding-right: 35px;
    text-align: left;
    font-size: 12px;
    color: rgb(199, 199, 199);
    border: 2px solid;
    border-left: 0;
    border-right: 0;
}
.deliverGoodsBox .kc06_table1 td:nth-child(2){
    height: auto;
    padding: 0%;
}
.deliverGoodsBox .kc06_table1 tr:first-child {
    background: rgb(245, 245, 245);
}
.deliverGoodsBox .kc06_table1{
    width: 100%;
}

.deliverGoodsBox .kc06_itbox {
    margin-top: 10px;
    display: flex;
}

.deliverGoodsBox .kc06_itbox li {
    font-size: 14px;
    color: #666666;
}

.deliverGoodsBox .kc06_itbox li:nth-child(1) {
    width: 200px;
    padding-left: 10px;
}

.deliverGoodsBox .kc06_itbox li:nth-child(2) {
    width: 220px;
}

.deliverGoodsBox .kc06_itbox li:nth-child(3) {
    width: 300px;
    text-align: center;
}

.deliverGoodsBox .kc06_itbox li:nth-child(4) {
    width: 80px;
    margin-left: 40px;
    text-align: right;
}

.deliverGoodsBox .kc06_itbox li:nth-child(5) {
    margin-left: 20px;
}

.deliverGoodsBox .kc06_commodity {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #CCC; */
    padding-bottom: 20px;
    min-height: 180px;
}

.deliverGoodsBox .kc06_it-r {
    width: 100%;
    text-align: right;
}
.deliverGoodsBox .kc06_it-r .bottomDesc{
    width: 20%;
    margin-left: 80%;
    text-align: left;
}


.deliverGoodsBox .kc06_it-r button {
    width: 200px;
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: rgb(237, 182, 49);
    border: 2px solid #000;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
    background-image: none;
}

.deliverGoodsBox .kc06_it-r p {
    font-size: 13px;
    color: #014C91;
    font-weight: bold;
}

.deliverGoodsBox .kc06_it-r-p1 {
    margin: 10px 0;
    width: 95px;
    height: 13px;
    border-right: 1px solid #014C91;
    padding-right: 5px;
    display: flex;
    align-items: center;
}
.deliverGoodsBox .jihuaBox>div>span:first-child{
    font-weight: bold;
}
.deliverGoodsBox .jihuaBox>div>span:nth-child(2){
    color: rgb(0, 75, 145);
}
.deliverGoodsBox .rukujihuaBox{
    background: rgb(229, 229, 229);
    padding: 5px;
}
.deliverGoodsBox .collan{
    color: rgb(0, 75, 145);

}
.deliverGoodsBox .bottomDesc{
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}
.deliverGoodsBox .tips{
    margin: 20px 0;
}
.deliverGoodsBox .totles{
    display: flex;
    justify-content: space-between;
    color: rgb(199, 199, 199);
    margin: 10px 0;

}
.deliverGoodsBox .c199{
    color: rgb(199, 199, 199);
}
.deliverGoodsBox table tr th,
.deliverGoodsBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
}
.deliverGoodsBox table th,
.deliverGoodsBox table td{
    border-left: none;
    border-right: none;
}
.deliverGoodsBox .errorTipsText{
    text-align: left;
    color: red;
    margin-top: 5px;
    padding-left: 30px;
}