.BrandRegisteredFooter{
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  border-top: 1px solid rgb(221,221,221);
}
.BrandRegisteredFooter .feedback{
  position: absolute;
  bottom: 0;
  left: 60px;
  color: #fff;
  padding-left: 0;
  height: 24px;
  line-height: 24px;
}
.BrandRegisteredFooter .feedback > span{
  position: absolute;
  top: -6px;
  right: 5px;
}
.BrandRegisteredFooter a{
  color: rgb(0,130,150);
}
