
.company_address_inner{
  width: 50%;
}

.company_address h2{
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid rgb(221,221,221);
  font-size: 18px;
  font-weight: 700;
}
.company_address h2 a{
  font-size: 12px;
  color: rgb(0,129,152);
}
.company_address .ant-alert{
  margin-left: 20px;
  margin-bottom: 20px;
  background: rgb(246,246,246);
  border: 1px solid rgb(222,227,229);
}
.company_address_show{
  border: 1px solid rgb(221,221,221);
  margin-left: 20px;
  margin-bottom: 20px;
}
.company_address_show p{
  background: rgb(242,242,242);
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
}
.company_address_show > div{
  padding: 10px 20px;
}
.company_address_show_but{
  margin-top: 20px;
}
.company_address_show_but span{
  margin-right: 20px;
  color: rgb(0,129,152);
  cursor: pointer;
}
.company_address h3{
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid rgb(221,221,221);
  font-size: 14px;
  font-weight: 700;
  padding-left: 0;;
}
.company_address h3 span:nth-child(2){
  font-size: 12px;
  color: rgb(0,129,152);
}
.company_address_add_from{
  margin-left: 20px;
  border: 1px solid rgb(221,221,221);
  padding: 10px;
  margin-bottom: 20px;
}
.setdeposit_Method_bank_but{
  margin-left: 20px;
}
.setdeposit_Method_bank_but button{
  margin-right: 20px;
  width: 220px;
  margin-bottom: 20px;
}
.setdeposit_Method_bank_but button:nth-child(1){
  background: rgb(236,238,240);
  border: 1px solid rgb(193,195,200);
}
.setdeposit_Method_bank_but button:nth-child(2){
  background: rgb(242,204,110);
  border: 1px solid rgb(194,156,61);
}
.company_address .company_address_success{
  background: #fff;
  border: 2px solid rgb(149,211,149);
}