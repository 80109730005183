.A_information_select{
    height: 100%;
}
#root{
    height: 100%;
}
.A_infor_select_body{
    background-color: rgb(248, 248, 248);
}
.A_information_select h2{
    color: #008296;
    height: 45px;
    line-height: 45px;
    padding-left: 32px;
}
.A_information_select .A_infor_select_body{
    padding-top: 20px;
}
.A_infor_select_body h3{
    font-weight: 700;
}
.A_infor_select_body .A_infor_select_inner{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.A_infor_select_body .A_select_basie{
    width: 350px;
    height: 250px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 40px;
}
.A_infor_select_body .A_select_basie div{
    margin-bottom: 10px;
    color: #008296;
    height: 24px;
    line-height: 24px;
}
.A_infor_select_body .A_select_basie button{
    margin-top: 20px;
    background: rgb(227,236,237);
    padding: 10px 20px;
    border: 0;
    border-radius: 5px;
}
.A_infor_select_body .A_select_basie a{
    color: #333;
}
.A_select_rounddot{
    width: 4px;
    height: 4px;
    border-radius: 2px;
    display: inline-block;
    background-color:#008296;
    margin-bottom: 2px;
}