.information_body{
    width: 100%;
    padding: 0 20px;
}
.informationHead_headII{
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.informationHead_headII_btn button{
    width: 75px;
    height: 37px;
    background-color: #e3eced;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
}
.informationHead_headII> div{
    margin: 0 10px;
}
.informationHead_headII_btn button:hover{
    background: #B6C5D3;
}
.informationHead_headII_btnI button:hover{
    background: #B6C5D3;
}
.informationHead_headII_btnII button:hover{
    background: #006878;
}
.informationHead_headII_btnII a{color: #ffffff;}
.informationHead_headII_btnI button{
    width: 110px;
    height: 37px;
    background-color: #e3eced;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;

}
.informationHead_headII_btnII button{
    width: 170px;
    height: 37px;
    background-color: #008296;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
}
.informationHead_head_inp input{
    width: 100%;
    height: 40px;
}
.informationHead_head_label {
    width: 100%;
    height: 40px;
}
.informationHead_head_label  select{
    height: 40px;
    width: 100%;
}
.informationHead_head_label  option{
    height: 40px;
    width: 100%;
}
.informationHead_Steps_liI{
    width: 90%;
    font-size: 12px;
    margin-bottom: 10px;
}
.informationHead_Steps_liII{
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.informationHead_Steps_divI{
    width: 23%;
}
.informationHead_Steps_divII{
    width: 18%;
}


.informationHead_Steps_li div{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.infDetails_ModalBox{
    width: 100%;
    height: 300px;
    border: 1px dashed #dddddd;
    border-width: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 300px 0;
}
.infDetails_ModalBtn button{
    width: 130px;
    height: 60px;
    border: 2px solid #D5DBDB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: #D5DBDB;
    background-color: #ffffff;
}

.infDetails_ModalBtn button:hover {
    color: #008296;
}
.Details_body{
    padding: 0 20px;
}

.Details_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}
.Details_headI{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}

.Details_headI span{
    margin: 0 5px;
    
}
.Details_headII{
    width: 13%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Details_headII_lab{
    width: 100px;
    height: 40px;
    border: 0;
    border-radius: 4px;
    background-color: #e3eced;
    padding: 0 10px;
    box-shadow:  0 1px 2px;
}

.Details_headII_lab option{
    background-color: #ffffff;
}

.Details_headII_btn button{
    width: 76px;
    height: 40px;
    border-radius: 4px;
    background: #1a8191;
    color: #ffffff;
}

.De0tails_Steps_li{
    width: 100%;
}

.Details_Steps_li div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Details_Steps_liI{
    width: 90%;
    font-size: 12px;
    margin-bottom: 10px;

}
.Details_Steps_liI div{
    width: 16%;
}
.Details_Steps_liII{
    width: 90%;
    font-size: 12px;

}
.Details_Steps_liII div{
    width: 16%;
}
.ant-tabs-tab-btn{
    color: #000;
    font-weight: bold;
}

.Details_StepsBox{
    padding: 20px 0 40px 0;
}
.Details_StepsImg{
    background: url(../../../../assets/images/steps.png);
    width: 625px;
    height: 28px;
    margin: 0 auto;
    background-size: 102%;
}
.Details_StepsFont{
    width: 35%;
    display: flex;
    margin: 0 auto;
    font-size: 12px;
    min-width: 653px;
}


.Details_StepsFont div:nth-child(3){
    width: 23%;
}
.Details_StepsFont div:nth-child(4){
    width: 19%;
}
.Details_StepsFont div:nth-child(5){
    text-align: center;
}

.Details_Font_tishi :hover{
    filter: invert(100%);
}

.informationHead_titel{
    font-size: 24px;
}
.information_modal .ant-card-head{
    background-color: rgb(219, 219, 219);
}
.information_modal .ant-col{
    margin: 0 20px;
}
.infDetails_tempate{
    margin: 40px auto;
    width: 50%;
}
.infDetails_tempate-inner{
    width: 800px;
    border: 1px #ccc solid;
}
.infDetails_tempate h3{
    display: flex;
    justify-content: space-between;
    border: 1px #ccc solid;
}
.infDetails_tempate h3 .anticon{
    border: 1px #ccc solid;
}
.infDetails_tempate .imageText_image_fontBox{
    margin-right: 0;
    width: 100%;
}