.tab6-textarea{
    width: 568px;
    height: 100px;
    outline: none;
    border: 1px solid #AAB7B8;
    padding: 5px;
}
.tab6-textarea::-webkit-input-placeholder{
    font-style: italic;
}
.tab6-box{
    width: 1024px;
    margin: 0 auto;
    background-color: #fff;
}