
.sellerInfoBox .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #C45500;
    border: 1px solid #C45500;
}
.sellerInfoBox .bottomDesc{
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.sellerInfoBox .flex0{
    display: flex;
}
.sellerInfoBox .natureBox .ant-form-item{
    width: 49%;
}
.sellerInfoBox .natureBox .ant-form-item input{
    width: 100%;
}
.birthBox .ant-form-item-control,
.termBox .ant-form-item-control{
    min-width: 87px
}
.sellerInfoBox .selectBox
.sellerInfoBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}
.sellerInfoBox .a-spacing-top-large {
    margin-top: 24px!important;
}
.sellerInfoBox .ant-steps{
    margin: 20px auto 50px;
    width: 70%;
}
.sellerInfoBox .a-size-large {
    font-size: 13px!important;
    line-height: 1.3!important;
    font-weight: bold;
}
.sellerInfoBox .program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: 0;
    width: 70%;
}
.sellerInfoBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table!important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}
.sellerInfoBox .ng-scope{
    text-align: center;
}
.sellerInfoBox .a-size-medium {
    font-size: 17px!important;
    line-height: 1.255!important;
}
.sellerInfoBox .a-size-small {
    font-size: 12px!important;
    line-height: 1.5!important;
}
.sellerInfoBox .eCkQDX{
    display: none;
}
.sellerInfoBox .jXxwsB span.icon,
.sellerInfoBox .jXxwsB .websit.ant-select,
.sellerInfoBox .jXxwsB .language.ant-select .ant-select-selector,
.sellerInfoBox .jXxwsB .ant-select-arrow,
.sellerInfoBox .jXxwsB .search,
.sellerInfoBox .jXxwsB .language.ant-select,
.sellerInfoBox .jXxwsB .ilQBwr button{
    display: none;
}
.sellerInfoBox .hoQOMo>div{
    display: block;
}
.sellerInfoBox .jXxwsB .ilQBwr button:last-child{
    display: inline-block;
}
.sellerInfoBox .jXxwsB .ilQBwr{
    text-align: right;
}
.sellerInfoBox .CompanyAddress{
    width: 55%;
    margin: 0 auto;
}
.sellerInfoBox .selectBox{
    width: 49%;
    height: 35px;
}
.sellerInfoBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border: 1px solid #000;
    border-radius: 3px;
}
.sellerInfoBox .rightCollapse{
    width: 30%;
}
.sellerInfoBox .rightCollapse>p{
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}
.sellerInfoBox .inpBox{
    padding-top: 15px;
}
.sellerInfoBox .inpBox .yanzPhone select{
    width: 40%;
}
.sellerInfoBox .receivingMode .next{
    /* width: 35%; */
    margin-left: 2%;
    height: 32px;
}
.sellerInfoBox .inpBox .yanzPhone input{
    width: 100%;
}
.sellerInfoBox .inpBox .yanzPhone .ant-select-selector{
    height: 35px;
}
.sellerInfoBox .inpBox1{
    display: flex;
}
.sellerInfoBox .inpBox1 p{
    margin-bottom: 0;
    font-weight: bold;
}
.sellerInfoBox .inpBox1 .ant-form-item{
    width: 49%;
}
.sellerInfoBox .inpBox1 .selectBox{
    width: 100%;
}
.sellerInfoBox .inpBox1 .dates{
    margin-left: 2%;
}
.sellerInfoBox .inpBox1 .dates>div>div{
    display: flex;
}
.sellerInfoBox .inpBox1 .dates>div .ant-form-item{
    width: 33%;
    margin-bottom: 0;
}
.sellerInfoBox .inpBox1 .stepGuidanceBox1 .ant-form-item{
    width: 100%;
}

.sellerInfoBox .inpBox>div{
    width: 49%;
}
.sellerInfoBox .inpBox input{
    padding-left: 5px;
    width: 49%;
    height: 35px;
}
.sellerInfoBox .inpBox input:nth-child(2){
    margin-left: 2%;
}
.sellerInfoBox .aCheckbox{
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.sellerInfoBox .aCheckbox span{
    margin-left: 5px;
}
.sellerInfoBox .receivingMode{
    margin-top: 20px;
    padding-bottom: 5px;
}
.sellerInfoBox .receivingMode p{
    font-weight: bold;
    margin-bottom: 0;
}
.sellerInfoBox .yanzPhone{
    display: flex;
    /* border: 1px solid #d9d9d9; */
}
.sellerInfoBox .yanzPhone>.ant-form-item{
    width: 100%;
}
.sellerInfoBox .yanzPhone select,
.sellerInfoBox .yanzPhone .ant-input-affix-wrapper{
    border: none;
}
.sellerInfoBox .inpBox .next{
    width: 49%;
    margin-left: 2%;
    height: 35px;
}
.sellerInfoBox .representative input+input{
    margin-left: 2%;
}
.sellerInfoBox .previousPage{
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}
.operationBtn{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.operationBtn .next{
    width: 30%;
}
.sellerInfoBox .yanzPhone{
    display: flex;
}
.sellerInfoBox .yanzPhone .ant-form-item{
    width: 200px;
}
.sellerInfoBox .yanzPhone select,
.sellerInfoBox .yanzPhone .ant-input-affix-wrapper{
    border: 1px solid #d9d9d9;
    border-left: 0;
    height: 32px;
}
.sellerInfoBox .elailTips{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
}
.sellerInfoBox .elailTips img{
    width: 200px;
    height: 200px;
}
.meailModalBox .ant-modal-body{
    text-align: center;
}
.sellerInfoBox .stepGuidanceBox10 .anticon-question-circle{
    color: rgb(204, 204, 204);
    margin-left: 4px;
}
/* .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #C45500;
    border-color: #C45500;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner{
    border-color: #C45500;
}
.ant-radio:hover .ant-radio-inner{
    border-color: #C45500;
} */
.seller-tip{
    width: 260px;
    position: relative;
    padding: 5px 0;
}
.seller-tip .close{
    position: absolute;
    top: -16px;
    right: 0px;
    font-size: 16px;
    cursor: pointer;
}
