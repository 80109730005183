*{
    margin: 0;
    padding: 0;
}
.myhead{
    border-bottom: 1px solid #DBE0E0;
}
.myhead-zj{
     width: 1800px;
     margin: 0 auto;
     overflow: hidden;
}
.myhead-left{
    float: left;
    overflow: hidden;
}
.myhead-left div:nth-of-type(1){
    float: left;
}
.myhead-jkl{
    float: left;
    overflow: hidden;
}
.myhead-jkl li{
    float: left;
    list-style: none;
    margin-left: 8px;
    line-height: 50px;
}
.lanse{
    color: #8DD3FE;
}
.myhead-right{
    float: right;
    display: flex;
    justify-content: space-between;
}
.myhead-right div:nth-of-type(1) img{
    line-height: 50px;
}
.myhead-right div:nth-of-type(2){
    line-height: 50px;
}
.myhead-right div:nth-of-type(2) select:nth-of-type(1){
    height: 25px;
    width: 200px;
}
.myhead-right div:nth-of-type(2) select:nth-of-type(2){
    height: 25px;
    width: 100px;
}
.myhead-right div:nth-of-type(3){
    height: 23px;
    margin-left: 8px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
}
.myhead-right div:nth-of-type(3) input{
   margin-left: 5px;
   border: none;
   line-height: 25px;
   float: left;
}
.myhead-right div:nth-of-type(3) button{
    height: 23px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #D0D0D0;
    float: right;
 }
 .myhead-right div:nth-of-type(3) button img{
    height: 20px;
    width: 20px;
 }
 .myhead-right div:nth-of-type(4){
    
    margin-left: 8px;
 }
 .myhead-right div:nth-of-type(4) ul{
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
 }
 .myhead-right div:nth-of-type(4) ul li{
    float: right;
    line-height: 53px;
    list-style: none;
 }
.xiufu{
    height: 89px;
    border-bottom: 1px solid #DBE0E0;
    padding-left: 8px;
}
.xiufu h1{
    font-size: 22px;
    color: #313044;
    line-height: 89px;
}
.bfuAbttur{
    display: flex;
    
}
.bfuAbttur-left{
    width: 13%;
    border-right: 1px solid #DBE0E0;
    padding:0 10px;
}   
.bfuAbttur-right{
    width: 80%;
    
    
}
.bfuAbttur-left-jkl-contentBox{
    padding: 0 10px;
    margin-top: 8px;
}
.dandu:after{
  float: right;
  margin-top: -15px;
  
}
.bfuAbttur-left-jkl div:nth-of-type(2) ul li{
   list-style: none;
   
}
.bfuAbttur-left-jkl div:nth-of-type(2) ul{
    overflow: hidden;
 }
 .bfuAbttur-left-jkl div:nth-of-type(2) ul li:not(:first-of-type){
    font-size: 14px;
    text-indent:15px;
 }
 .bfuAbttur-left-jkl div:nth-of-type(3) ul li{
    list-style: none;
    padding: 15px 0;
 }
 .bfuAbttur-left-jkl div:nth-of-type(3) ul li:nth-of-type(2){
    text-indent:15px;

 }
 .bfuAbttur-left-jkl div:nth-of-type(3) ul li a{
    text-decoration:none;
    
 }
 .bfuAbttur-left-jkl-titel{
     font-size: 18px;
     font-weight: bold;
 }
 .bfuAbttur-left-jkl-content{
     display: flex;
     align-items: center;
     justify-content: space-between;
     font-weight: bold;
 }
 .dandu{
    font-size: 18px;
 }
 .bfuAbttur-right{
   
 }
 .myhead-right div:nth-of-type(2) select{
     border-radius: 5px;
 }
 .bfuAbttur-right div:nth-of-type(1){
     padding: 20px;
     border-bottom: 1px solid #DBE0E0;
     overflow: hidden;
 }
 .bfuAbttur-right div:nth-of-type(1) select{
    width: 200px;
    height: 35px;
    float: left;
    line-height: 76px;
    border-color:  #DBE0E0;
}
.bfuAbttur-right-jkl{
    width: 250px;
    padding: 0 !important;
    height: 35px;
    border: 1px solid #DBE0E0;
    float: left;
    overflow: hidden;
    
}
.bfuAbttur-right-jkl button{
    background-color: white;
    height: 100%;
    width: 35px;
    border: none;
    float: right;
    border-left: 1px solid #DBE0E0;
}
.bfuAbttur-right-jkl button img{
    width: 15px;
    height: 15px;
    margin-top: 6.25px;
    margin-right: 8.25px;
    
}
.bfuAbttur-right-jkl input{
    height: 100%;
    width: 210px;
    margin-left: 5px;
    border: none;
    
}
.box_qwerr{
    float: left;
    line-height: 38px;
    margin-left: 10px;
}
.box_qwerr p{
    font-size: 14px;
}
.bfuAbttur-right .bfuAbttur-right_app{
    
    background-color: #FAFAFA;
    overflow: hidden;
    padding: 15px;
    border-bottom: 1px solid #DBE0E0;
}
.bfuAbttur-right_app div:nth-of-type(1){
    float: left;
    border-bottom: 0;
    overflow: hidden;
    padding: 0;

}

.bfuAbttur-right_app-uilll p,.bfuAbttur-right_app-uilll button{
    float: left;
    
}
.bfuAbttur-right_app-uilll p{
    margin-right: 10px;
    line-height: 35px;
    
}
.bfuAbttur-right_app-uilll button{
    height: 35px;
    border: 0;
    background-color: #FFFFFF;
  
}
.bfuAbttur-right_app-uilll button:nth-of-type(1){
   width: 50px;
   border: 0.5px solid rgba(0, 0, 0, 0.2);
  
}
.bfuAbttur-right_app-uilll button:nth-of-type(2){
    width: 70px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-left: 0px;
   
 }
.bfuAbttur-right_app div:nth-of-type(2){
    float: right;
}
.bfuAbttur-right_app-uilll div:nth-of-type(2) div{
    width: 20px;
    height: 20px;
    border: 1px solid #8DD3FE;
    margin-top: 8px;
}
.bfuAbttur-right_app-uilll div:nth-of-type(2){
    float: left;
}
.bfuAbttur-right_app-uilll div:nth-of-type(2){
    margin-top: 35px;
    
}

.bfuAbttur-right_app-uilll div:nth-of-type(2) p{
    font-size: 12px;
    margin-left: 20px;
}
.bfuAbttur-right_app-uilll div:nth-of-type(2) p:nth-of-type(2){
    
    margin-left: 40px !important;
}
.bfuAbttur-right_app-uilll_jiii {
    margin-left: -158px;
    padding-top: 50px;
}

.box12_ui{
    float: right;
    width: 700px;
    overflow: hidden;
}
.box12_ui div:nth-of-type(1){
    float: right; 
    overflow: hidden;
}
.box12_ui div:nth-of-type(1) p,.box12_ui div:nth-of-type(1) select{
    float: right; 
}
.box12_ui div:nth-of-type(1) p{
    font-size: 14px;
    line-height: 35px;
    margin-right: 5px;
}
.box12_ui div:nth-of-type(2) {
    float: left;
    margin-top: 100px;
}
.box12_ui div:nth-of-type(2) span:nth-of-type(1){
    margin-right: 50px;
}
.box12_ui div:nth-of-type(2) span{
    font-size: 14px;
    color: #313044;
    font-weight: bold;
}
.box12_ui div:nth-of-type(2) p{
    font-size: 14px;
    padding-top: 8px;
    font-weight: bold;
    color: #D0D0D0;
}
.box13_uiapp{
    padding: 0;
    border-bottom: 1px solid #DBE0E0;
   height: 150px;
    
}
.box13_uiapp-jkl{
    padding: 0;
    border-bottom: none !important;
    display: flex;
    justify-content: space-between;
}
.box13_uiapp-jkl div:nth-of-type(1){
    border-bottom: none;
    padding: 0;
    width: 50%;
    
}

.box13_uiapp-jkl div:nth-of-type(1) div:nth-of-type(1){
    width: 20px  ;
    height: 20px ;
    border: 1px solid #8DD3FE;
    
    
}
.box13_uiapp-jkl_app_qqqqq{
    
    margin-left: 35px;
    
}
.box13_uiapp-jkl_app_qqqqq img{
    float: left;
    margin-top: 16px;
}
.box13_uiapp-jkl_app_qqqqq p{
    margin-left: 60px;
    font-size: 12px;
}
.box13_uiapp-jkl_app_qqqqq p span{
    font-weight: bold;
}
.box13_uiapp-jkl_app_qqqqq p:nth-of-type(1){
    color: #8DD3FE;
}

.box13_uiapp-jkl div:nth-of-type(2){
    border-bottom: none;
    width: 50%;
    overflow: hidden;
}
.box13_uiapp-jkl div:nth-of-type(2) div:nth-of-type(1){
    float: right;
    overflow: hidden;
    width: 100%;
}
.box13_uiapp-jkl div:nth-of-type(2) div:nth-of-type(1) button{
    float: right;
    width: 100px;
    height: 50px;
    border: none;
    background-color: #F5F9FA;
    box-shadow: 3px 3px 5px 5px #313044;
    
}
.box13_uiapp-jkl div:nth-of-type(2) div:nth-of-type(1) input{
    float: right;
    height: 45px;
    width: 100px;
    margin-right: 10px;
    text-align: right;
}
.myspan{
    background-color: #637778;
    color: white;
}
.box13_uiapp-jkl div:nth-of-type(2) div:nth-of-type(1) p:nth-of-type(1){
    float: right;
    margin-right: 10px;
    font-size: 14px;
    color: #313044;
}
.box13_uiapp-jkl div:nth-of-type(2) div:nth-of-type(1) p:nth-of-type(2){
    font-size: 14px;
}
.bfuAbttur-right .box13_uiapp:nth-of-type(5){
    border-bottom: 0 !important;
}
.bfuAbttur-right .box13_uiapp:last-of-type{
    border-bottom: 0 !important;
}

.myhadyj{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    overflow: hidden;
     
}
.myhadyj_yi{
    display: flex;
    justify-content: space-between;
    border-bottom: 0 !important;
}
.myhadyj_yi p:nth-of-type(1){
    line-height: 50px;
    margin-right: 5px;
    color:#404042;
}
.myhadyj_yi p:nth-of-type(2){
    line-height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    color: #404042;
}
.myhadyj_yi input{
    width: 100px;
    height: 48px;
}
.myhadyj_yi button{
    width: 100px;
    height: 50px;
    border: none;
    color: white;
    border-radius: 5px;
    background-color: #C3CACB;
}
.myhadyj_er span{
   line-height: 100px;
   margin: 0 10px;
}
.myhadyj_er span:not(:first-of-type){
    color: #008296;
 }
 .myhadyj_san select{
   width: 400px;
   margin-top: 45px;
   margin-right: 10px;
   height: 20px;
 }
.myfoot{
    height: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}
.myfoot div:nth-of-type(1){
    margin-top: 18px;
}
.myfoot_span{
    color: rgba(0, 0, 0, 0.2);
    margin: 0 10px;
}
.myfoot div:nth-of-type(1) button{
    display: block;
    margin-top: 50px;
    width: 60px;
    height: 20px;
    background-color: #008296;
    border: none;
    color: #FFFFFF;
    margin-left: -15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.myfoot div:nth-of-type(1) select{
    width: 70px;
    height: 25px;
}
.myfoot div:nth-of-type(2) p{
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-top: 20px;
}
.kc_header_logo{
    vertical-align: initial !important;
}