.fbaConfirmBox .fbaConfirm .fbaTitle{
    color: #C60;
    font-size: medium;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
}
.fbaConfirmBox .tableBox{
    padding: 20px;
}
.fbaConfirmBox .a-section-expander-container {
    font-size: 17px;
    line-height: 1.255;
    display: block;
    position: relative;
    color: #0f1111;
    background-color: #f3f3f3;
    transition: all .1s linear;
    padding: 11px 54px 11px 18px;
    border: 1px solid rgba(0,0,0,.3137254901960784);
    border-bottom: 0;
}
.fbaConfirmBox .fbaBox table th{
    background: #CDDEF3;
    text-align: center;
}
.fbaConfirmBox .returnclass{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: #006;
    font-size: 14px;
    background: #CDDEF3;
}
.fbaConfirmBox .returnclass span{
    margin-right: 15px;
    color: #006;
    font-size: 14px;
    font-weight: bold;
}
.fbaConfirmBox .fbaConfirm .titles{
    font-weight: 700;
    margin-bottom: 0;
}
.fbaConfirmBox .fbaConfirm .desc{
    margin-bottom: 10px;
}
.fbaConfirmModal{
    width: 800px !important;
}
.fbaConfirmBox .fbaConfirm .request-info-popup-desc{
    border-bottom: solid 4px #5D7CBE;
}
.fbaConfirmBox .fbaConfirm .request-info-required-responses {
    margin: 10px 0px 10px 0px;
}
.fbaConfirmModal .proInfo{
    display: flex;
    align-items: center;
}
.fbaConfirmModal .proInfoBox > div p{
    margin-bottom: 0;
}
.fbaConfirmModal .proInfoBox .proInfoText{
    width: 67%;
}
.fbaConfirmBox .proInfoBox{
    background-color: #EDF5FF;
    overflow: hidden;
    border-radius: 10px;
}
.fbaConfirmBox .proInfoBox .proInfo{
    overflow: hidden;
    margin: 15px;
}
.fbaConfirmBox .proInfoBox .proInfo:first-child{
    padding-bottom: 15px;
    border-bottom: solid 1px #bcc4ca;
}
.fbaConfirmModal .request-info-warning {
    color: #C60;
    margin: 10px 0px 10px 0px;
}
.fbaConfirmModal .request-info-required-responses {
    margin: 10px 0px 10px 0px;
}
.fbaConfirmBox .returnclass{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: #006;
    font-size: 14px;
    background: #CDDEF3;
}
.fbaConfirmBox .returnclass span{
    margin-right: 15px;
    color: #006;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    cursor: pointer;
}
.fbaConfirmBox .returnclass .returnBtn{
    height: 30px;
    border: 2px solid rgb(137, 137, 86);
    background: rgb(214, 214, 180);
    padding: 1px 10px;
    border-radius: 3px;
    position: relative;
    padding-left: 25px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;

}
.fbaConfirmBox .returnclass .returnBtn .kc_10_btn2{
    left: 2px;
    bottom: 4px;
    transform: rotateY(360deg);
}

.fbaConfirmBox .returnclass .transformation,
.fbaConfirmBox .returnclass .sendOut{
    border: 2px solid rgb(28, 64, 139);
    background: rgb(237, 182, 49);
    padding: 3px 10px;
    border-radius: 3px;
    position: relative;
    
}
.fbaConfirmBox .returnclass .sendOut{
    padding-right: 25px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}
.fbaConfirmBox table tr td:nth-child(2) {
    width: 100%;
    height: auto;
}

.fbaConfirmBox .returnclass>div{
    display: flex;
}
.fbaConfirmBox .returnclass .sendOut .kc_10_btn2{
    bottom: 4px;
}
.fbaConfirmBox .tableBox table td{
    text-align: center;
}
.fbaConfirmBox .flex0,
.fbaConfirmModal .flex0{
    display: flex;
    align-items: center;
    justify-content: center;
}
.fbaConfirmModal .flex0{
    align-items: baseline;
}
.fbaConfirmModal .flex0>span{
    min-width: 50px;
}
.fbaConfirmModal .dangerousGoodsContent .ant-row{
    text-align: center;
}
.fbaConfirmModal .dangerousGoodsContent .ant-checkbox-wrapper{
    margin: 15px 0;
}
.fbaConfirmModal .proInfoBox{
    background-color: #edf5ff;
    border-radius: 10px;
    padding: 20px;
}
.fbaConfirmBox .reds{
    color: red;
    font-size: 13px;
    font-weight: bold;
}
.fbaConfirmBox .returnclass a{
    color: #000;
}
.fbaConfirmBox table tr th,
.fbaConfirmBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
}
.end06_mybody .tab .mytr th,
.fbaConfirmBox table td{
    border-left: none;
    border-right: none;
}