/* 内容2 */
.LabelingBox .kc_10_box2{
	width: 100%;
	height: 40px;
	background-color: #f4f4f4;
	border: 1px solid #e5e6e7;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	box-sizing: border-box;

}
.LabelingBox .kc_10_box2>ul{
	margin: 0 auto;
	display: flex;
	list-style: none;
}
.LabelingBox .kc_10_box2>ul>li{
	font-size: 13px;
	font-weight: 600;
	color: #0a3798;
	margin: 0 10px;
}
.LabelingBox .kc_10_box2>ul>li>a{
	font-size: 13px;
	font-weight: 600;
	color: #0a3798;
}
.LabelingBox .kc_10_box2>ul>li>span{
	color: #c65200;
	margin: 0 0 0 10px;
	text-decoration:none !important;
}
.LabelingBox .kc_10_box2>ul>li:not([class="qx"]){
position: relative;
}
.LabelingBox .kc_10_box2>ul>li:not([class="qx"])::after{
	content:'';
	position: absolute;
	width: 1px;
	height: 15px;
	background-color: black;
	right: -10px;
	top: 15%;
	
}	


/* 内容3 */
.LabelingBox #kc_10_box3{
	width: 95%;
	margin: 20px auto 0;
	
}
.LabelingBox #kc_10_box3>h2{
	color: #cc6e28;
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 15px;
}
.LabelingBox #kc_10_box3>h2>span{
	font-size: 15px;
	color: #2766a2;
	font-weight: 500;
	margin-left: 10px;
}


/* .box3_svn{
	width: 100%;
	box-sizing: border-box;
	display:  flex;
	justify-content: left;
	align-items: center;
	color: #106ab2;
	font-weight: 600;
	font-size: 17px;
	border-bottom: 3px solid #959595;
}
.box3_svn>li{
	padding: 5px;
} */

.LabelingBox .kc_10_body-border{
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
}

.LabelingBox .kc_10_h1 {
  font-size: 22px;
  color: #CC6600;
  font-weight: bold;
  margin-bottom: 5px;
}
.LabelingBox .kc_10_h1 span {
  margin-left: 10px;
  font-size: 14px;
  color: #1976ac;
}
.LabelingBox .kc_10_body-ul {
	width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #CCC;
  box-sizing: border-box;
}
.LabelingBox .kc_10_body-ul li {
  height: 32px;
  color: #0070b1;
  font-size: 15px;
  font-weight: bold;
  padding-right: 30px;
  padding-bottom: 5px;
  border-bottom: 3px solid #E47911;
  display: flex;
  align-items: center;
}

.LabelingBox .kc_10_body-ul li:nth-child(3) {
  color: #E47911;
  position: relative;
  padding-right: 30px;
}
.LabelingBox .kc_10_body-ul li:nth-child(3) img {
  width: 12px;
  position: absolute;
  left: 20px;
  bottom: -5px;
  transform: translateX(50%);
  z-index: 2;
}
/* 表格 */

.LabelingBox .kc_10_body-table {
	display: flex;
	padding: 10px 0;
}
.LabelingBox .kc_10_table1{
	box-shadow: none;
	width: 25%;
}

.LabelingBox .kc_10_table1 th,
.LabelingBox .kc_10_table1 td {
	text-align: left;
	font-size: 12px;
	color: #666666;
	border: none;
	padding: 0;
	padding-left: 15px;
	height: 10px;
	min-width: 120px;
}
.LabelingBox .kc_10_table1 td:nth-child(3){
	min-width: 20%;
}

.LabelingBox td span {
	color: #000;
	font-weight: bold;
}

.LabelingBox .blue {
	color: #126899;
}
.LabelingBox .kc_10_tab-p {
	color: #ffa500;
	font-size: 13px;
	text-align: right;
	position: absolute;
	right: 0;
	bottom: 8px;
}
.LabelingBox .kc_10_tab_p_span{
	padding: 2px 10px 2px 10px;
	border: 2px solid #FFA500;
	border-radius: 5px;
}
.LabelingBox .tab-ul {
	height: 36px;
	margin-top: 40px;
	display: flex;
	border-bottom: 2px solid #A4A4A4;
	box-sizing: border-box;
	position: relative;
	list-style: none;
}

.LabelingBox .tab-ul li {
	height: 36px;
	line-height: 36px;
	padding: 0 15px;
	color: #126899;
	font-size: 14px;
	font-weight: bolder;
}
.LabelingBox .kc_10_active {
	border: 2px solid #A4A4A4;
	background-color:white;
	border-bottom: none;
	padding-bottom: 1px;
	font-weight: bold;
	color: #666 !important;
	margin-top: -2px;
}
.LabelingBox .body-box2 {
	height: 150px;
}
.LabelingBox .btn-l{
	float: left;
}
.LabelingBox .btn-r{
	float: right;
	position: relative;
}
.LabelingBox .btn1{
	height: 20px;
	position: absolute;
	left: 2px;
	top: 0px;
}
.LabelingBox .body-box2 button {
    margin-top: 20px;
    padding: 2px 10px;
    color: #000068;
    background-color: #F7F7EE;
    border: 2px solid #868654;
    background-image: linear-gradient(#F7F7EE, #CECEAD);
    font-weight: bolder;
    border-radius: 5px;
	margin-right: 5px;
	cursor: pointer;
}
.LabelingBox .body-box2 .btn-r button {
	padding-right: 35px;
	border-radius: 10px 15px 15px 10px;
	background-image: linear-gradient(#ffde91, #d9a62d);
}

.LabelingBox .body-box2 .btn-r .kc_10_btn2{
	right: 12px;
}
.LabelingBox .body-box2_button2 {
	font-size: 12px;
	margin-left: 10px;
    padding: 0px 10px;
    color: #000068;
    background-color: #dfdfdf !important;
    border: 2px solid #000068 !important;
    background-image: linear-gradient(#ffde91, #d9a62d) !important;
    font-weight: bolder;
    border-radius: 5px;
	position: relative;
}
.LabelingBox .btn-l button:first-child {
    position: relative;
    padding-left: 25px;
    border-radius: 15px 10px 10px 15px;
}
.LabelingBox .KC_10_BOO{
	margin-left: 20px;
}
.LabelingBox .kc_10_quan{
	font-size: 14px;
}
.LabelingBox .kc_10_bottom_ul{
	list-style: none;
	display: flex;
	font-size: 12px;
}
.LabelingBox #kc_10_ululul{
	margin-top: 10px;
}
.LabelingBox .kc_10_US{
	height: 60px;
	border-top: 2px solid #a7a7a7;
	background-color: #efefef;
	text-align: center;
	position: relative;
}
.LabelingBox .kc_10_select111{
	padding: 5px;
	margin-top: 10px;
}
.LabelingBox .kc_10_show{
	padding: 15px;
	border-bottom: 1px solid black;
}
.LabelingBox .btn-l .kc_10_btn2{
	height: 20px;
	position: absolute;
	right: 2px;
	bottom: 3px;
	transform: rotateY(360deg);
}
.LabelingBox .btn-r .kc_10_btn2{
	bottom: 5px;
}
.LabelingBox .kc06_table1{
	width: 100%;
}
.LabelingBox .kc06_table1 td,
.LabelingBox .kc06_table1 th{
	text-align: center;
}
.LabelingBox .isDel{
	background-color: #cccccc;
}
.LabelingBox table tr th,
.LabelingBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
}
.LabelingBox table tr th,
.LabelingBox table tr td{
    border-left: none;
    border-right: none;
}




	
	
	
