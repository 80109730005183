
.MengkSiderContent{
  height: 100%;
}
.MengkSiderContent-header{
 text-align: center;
 height: 50px;
 line-height: 50px;
 background-color: #fff;
}
.MengkSiderContent-body{
  height: 100%;
}
.MengkSiderContent-body h3{
  text-align: left;
  background-color: #fff;
  margin-top: 10px;
  height: 48px;
  line-height: 48px;
}
.MengkSiderContent-body .ant-col{
  height: 30vh;
}
.MengkSiderContent-body .ant-row{
 margin-bottom: 20px;
}
.MengkSiderContent-body .mengk-hover{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.MengkSiderContent-body .mengk-hover:hover{
  background-color: rgb(253,235,209);
  border: 1px solid rgb(255,167,36);
}
.MengkSiderContent-package{
  height: 20vh;
  background-color: #fff;
  position: relative;
  background-image: url("@assets/images/Mengk-box.png");
  background-repeat: repeat-x;
  background-position: 40%;
  background-size: 50%;
}
.MengkSiderContent-package div:nth-child(1){
  position: absolute;
  left: 0;
  top: 50%;
  background-color:rgba(0, 0, 0, 0.4);
}
.MengkSiderContent-package div:nth-child(2){
  position: absolute;
  right: 0;
  top: 50%;
}