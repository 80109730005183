.deposit_Method {
  width: 70%;
}

.deposit_Method h2 {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  border-bottom: 1px solid rgb(221, 221, 221);
  font-size: 30px;
}

.deposit_Method h2 a {
  font-size: 14px;
}

.setdeposit_Method .deposit_Method_set {
  text-align: left !important;
  font-size: 18px;
  font-weight: 700;
  padding-left: 20px;
  height: auto;
}

.deposit_Method_set a {
  margin-left: 20px;
}

.deposit_Method_body {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid rgb(221, 221, 221);
  margin: 0 0px 20px 20px;
}

.setdeposit_Method .deposit_Method_body>div {
  width: 50%;
  height: auto;
}

.deposit_Method_body_left {
  padding-right: 20px;
  position: relative;
}

.deposit_Method_body_left button {
  width: 90%;
  position: absolute;
  bottom: 20px;
  left: 0;
  background: rgb(235, 237, 239);
  border: 1px solid rgb(130, 133, 138);
}

.deposit_Method_body_title {
  font-weight: 500;
}

.deposit_Method_body_right p {
  border-bottom: 1px solid rgb(221, 221, 221);
  font-weight: 700;
}

.deposit_Method_body_right div {
  border-bottom: 1px solid rgb(221, 221, 221);
  height: 40px;
  line-height: 40px;
}

.deposit_Method_body_right img {
  margin-right: 20px;
}

.deposit_Method_body_center p {
  font-weight: 700;
}

.deposit_Method_body_center div {
  margin-bottom: 20px;
}

.setdeposit_Method .deposit_Method_body_right {
  padding-right: 20px;
}

.setdeposit_Method_bank {
  border: 1px solid rgb(221, 221, 221);
  margin-left: 20px;
}

.setdeposit_Method_bank strong {
  margin-top: 20px;
  display: block;
}

.setdeposit_Method_bank h3 {
  background: rgb(240, 242, 242);
  padding: 20px;
  font-weight: 700;
}

.setdeposit_Method_bank_title {
  display: flex;
  height: 50px;
  line-height: 50px;
  justify-content: space-between;
  padding: 0 20px;
}

.setdeposit_Method_bank .setdeposit_Method_bank_addText {
  color: rgb(0, 130, 150);
  cursor: pointer;
}

.setdeposit_Method_bank_distinguish {
  background: rgb(240, 242, 242);
  padding: 20px;
  margin: 20px 0 0px 20px;
}

.setdeposit_Method_bank_but {
  margin-left: 20px;
  margin-top: 20px;
}

.setdeposit_Method_bank_but button {
  margin-right: 20px;
  width: 220px;
  margin-bottom: 20px;
}

.setdeposit_Method_bank_but button:nth-child(1) {
  background: rgb(236, 238, 240);
  border: 1px solid rgb(193, 195, 200);
}

.setdeposit_Method_bank_but button:nth-child(2) {
  background: rgb(242, 204, 110);
  border: 1px solid rgb(194, 156, 61);
}

.setdeposit_Method_inner {
  display: flex;
}

.setdeposit_Method_tips {
  width: 29%;
  padding: 20px 30px;
  font-size: 12px;
}

.setdeposit_Method_tips div {
  color: rgb(38, 114, 195);
  margin-bottom: 10px;
}

.setdeposit_Method_tips p {
  margin-bottom: 20px;
}

.setdeposit_Method_tips .anticon-up {
  margin-left: -15px;
  margin-right: 5px;
  font-size: 8px;
}

.setdeposit_Method .ant-form {
  padding: 0 20px;
}

.setdeposit_Method .anticon-exclamation-circle {
  font-size: 14px;
  color: rgb(204, 28, 57);
  margin-right: 5px;
}

.setdeposit_Method .ant-form-item-explain.ant-form-item-explain-error {
  color: rgb(204, 28, 57);
}

.setdeposit_Method .ant-form-item-label>label {
  font-weight: 700;
}

.setdeposit_Method_bank_Radio {
  border-top: 1px solid #dddddd;
}

.setdeposit_Method_bank_Radio .ant-radio-group {
  width: 100%;
}

.setdeposit_Method_bank_Radio .ant-space {
  width: 100%;
}

.setdeposit_Method_bank_Radio .ant-radio-wrapper {
  display: flex;
}

.setdeposit_Method_bank_Radio .item {
  display: flex;
  min-height: 40px;
  padding-top: 10px;
}

.setdeposit_Method_bank_Radio .ant-space-item {
  border-top: 1px solid #dddddd;
  padding: 0 20px;
}

.setdeposit_Method_bank_Radio .ant-space-item:nth-child(1) {
  border-top: none;
}

.setdeposit_Method_bank_Radio .ant-space {
  gap: 0px !important;
}

.setdeposit_Method_bank_Radio .item>div {
  margin-left: 80px;
}

.setdeposit_Method_bank_Radio .item>div:nth-child(1) {
  width: 100px;
}

.setdeposit_Method_bank_Radio .item>div:nth-child(2) {
  width: 100px;
}

.setdeposit_Method_bank_Radio .item>div:nth-child(3) {
  width: calc(100% - 1120px);
}

.setdeposit_Method_bank_Radio .item>div:nth-child(4) {
  width: 400px;
}
.setdeposit_Method_bank_Radio .item>div:nth-child(5) {
  width: 200px;
  text-align: right;
}
.setdeposit_Method .shiyongmoren{
  display: flex;
  flex-direction: column;
  line-height: 15px;
  margin-bottom: 10px;
  color: rgb(0,138,79);
}

.setdeposit_Method_bank_Radio .ant-radio+span {
  width: calc(100%);
}

.setdeposit_Method_modal .ant-modal-footer {
  background-color: #fff !important;
  border: 0;
}

.setdeposit_Method_modal .ant-modal-footer .footer {
  padding-right: 30px;
  padding-bottom: 30px;
}

.setdeposit_Method_modal .footer .ant-btn {
  width: 90px;
  height: 32px;
  border-radius: 10px;
  padding: 0;
  box-shadow: none;
}

.setdeposit_Method_modal .footer .ant-btn-primary {
  background: rgb(255, 216, 20) !important;
  border: none !important;
}

.setdeposit_Method_modal .ant-modal-body div {
  margin: 20px 0;
  font-weight: 700;

}