.distribution-box{
    border: 1px solid #D3D3D3;
    font-size: 12px;
    color: #002F36;
}
.distribution-box span.ant-radio + *{
    font-size: 12px;
    color: #002F36;
}
.distribution-top{
    padding: 10px 20px;
}
.distribution-bottom{
    border-top: 1px solid #D3D3D3;
    padding: 10px 20px;
}
.distribution-box p , .distribution-bottom p {
    padding-left: 20px;
}
.distribution-char-title{
    font-size: 12px;
    color: #667575;
    letter-spacing: 1px;
}
.distribution-char-icon{
    margin-top: 10px;
    margin-right: 20px;
}
.tab3-text textarea{
    width: 565px;
    height: 98px;
    border: 1px solid #AAB7B8;
    background-color: #f4f6f6;
    outline: none;
}
.tab3-price{
    width: 200px;
    height: 32px;
    display: flex;
    align-items: center;
}
.tab3-price span{
    flex: 1;
    text-align: center;
    font-size: 12px;
    background-color: #fafafa;
    border: 1px solid #AAB7B8;
    height: 100%;
    line-height: 30px;
    border-right: none;
    color: #002F36;
}
.tab3-price .tab3-price-input{
    flex: 2;
}
.ant-picker-input input::-webkit-input-placeholder , textarea::-webkit-input-placeholder,.ant-select-selection-placeholder{
    color: #BFBFBF;
    font-size: 12px;
}
.tab3-box{
    width: 1024px;
    margin: 0 auto;
    background: #fff;
}
.ant-form-item-extra{
    font-size: 12px!important;
    color: #667575!important;
}
.tab3-box .formBox,
.tab4-box .formBox,
.tab6-box .formBox,
.tab7-box .formBox,
.tab8-box .formBox{
    padding: 20px 0;
}
.tab3-box .distribution-box .ant-radio-group{
    width: 100%;
}
.tab3-box .distribution-box .ant-radio-wrapper{
    display: flex;
    align-items: center;
    padding: 10px 10px 0 20px;
    margin-right: 0;

}
.tab3-box .distribution-box .ant-radio-wrapper:first-child{
    border-bottom: 1px solid #ccc;
}

.tab3-box .distribution-char{
    width: 600px;
    margin-left: 170px;
}
.tab3-box .flex1{
    display: flex;
}
.tab3-box .bolds{
    font-weight: bold;
    margin-bottom: 5px;
    padding-left: 4px;
}
.tab3-box .showSizeBox>div{
    margin-bottom: 10px;
}