.login_foot {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0 10px 0;
}

.login_foot div {
    margin: 0 25px;
    font-size: 12px;
}

.gvRLFR p {
    padding: 0 !important;
}

.login_line {
    height: 44px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
}

.login_line::after {
    display: block;
    width: 100%;
    height: 44px;
    background-color: transparent;
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
    content: "";
}

/* .login .ant-form-item-control{
    
} */
.login .ant-form-item-explain {
    position: absolute;
    /* bottom: -15px; */
    line-height: normal;
}

.login .ant-form-item {
    margin-bottom: 18px !important;
}