.qr6_contcent{
    width: 100%;
    height:700px;
    overflow: hidden;
}
.qr6_contcent>div{
    margin-left: 20px;
    margin-right: 20px;
}
.qr6_contcent>div:first-of-type{
    font-size:15px;
    margin-top:36px;
    color: #0B6BC1;
}
.qr6_contcent>div:first-of-type span:first-of-type{
    color: #000;
}
.qr6_contcent>div:first-of-type span:nth-of-type(2){
    color: #D88F38;
}
.qr6_contcent>div:nth-of-type(2){
    margin-top:26px;
    font-size:25px;
}
.qr6_contcent>div:nth-of-type(2) span{
    font-size: 13px;
    color: #0B6BC1;
     position: absolute;
     margin-left: 10px;
     
}
.qr6_contcent>div:nth-of-type(2) span:nth-of-type(2){
    margin-left:92px;
    color: #000;
}

.qr6_contcent>div:nth-of-type(2) span:nth-of-type(3){
    margin-left:98px;
}
.qr6_contcent-box{
    width:1390px;
    margin-top: 36px;
    height:100px;
    border: 1px solid #acacac;
}
.qr6_contcent-box>div{
    margin-left:20px;
    font-size: 13px;
    overflow: hidden;
}
.qr6_contcent-box>div{
    font-size: 12px;
}
.qr6_contcent-box>div:first-of-type{
    font-size: 16px;
    font-weight: 700;
    margin-bottom:8px;
    margin-top: 10px;
}
.qr6_contcent-box>div:last-of-type span{
    color: #0B6BC1;
}

.qr6_contcent>div:nth-of-type(4){
    margin-top: 30px;
    height:26px;
}
.qr6_contcent>div:nth-of-type(4)>span {
    font-size: 12px;
    height:26px;
    line-height: 26px;
    float: left;
    font-weight: 600;
}
.qr6_contcent>div:nth-of-type(4) button{
    height:26px;
    font-size: 12px;
    display: block;
    margin-left: 10px;
    float: left;
}
.qr6_contcent>div:nth-of-type(4) button:nth-of-type(1){
   margin-left:6px;
   width: 120px;
   
}
.qr6_contcent>div:nth-of-type(4) button:nth-of-type(2){
    margin-left:16px;
    width: 130px;
 }
 .qr6_contcent>div:nth-of-type(4) button:nth-of-type(3){
    margin-left:7px;
    width:60px;
 }
 .qr6_img{
     width: 13px;
     height: 13px;
     margin-top: 6px;
 }

.qr6_contcent>div:nth-of-type(4) input{
    width: 160px;
    height:22px;
    font-size: 12px;
}
.qr6_contcentII{
    display: flex;
    align-items: center;
}
.qr6_inputII{
    border: 1px solid #dddddd;
    width: 12%;
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-left: 5px;
    padding: 0 10px;
    border-radius: 4px;
}
.qr6_inputII input{
    width: 100%;
    outline-style: none;
    border: 0;
    padding-left: 10px;
}
.qr6_contcent-ul{
    /* overflow: hidden; */
    list-style: none;
    margin-top:30px ;
    margin-left: 20px;
    height:25px;
    margin-right: 20px;
    line-height:25px;
    border-bottom: 2px solid #acacac;
}
.qr6_contcent-ul li{
    float: left;
    margin-right: 20px;
}
.qr6_contcent-ul li:first-of-type{ 
    border-bottom: 2px solid #bb4242;
}

.qr6_contcent-button{
    margin-top:20px;
    height:26px;
    line-height: 20px;
    color: #acacac;
}
.qr6_contcent-button button{
  height: 26px;
  width: 130px;
  margin-right:20px;
  margin-left: 20px;
}
.qr6_contcent-button>button:first-of-type{
    margin-left:0;
    width:90px;
    background: #a1a1a11a;
    border:1px solid #acacac;
  }
  .qr6_contcent-button>button:last-of-type{
      width: 165px;
      height:36px;
      float: right;
      background:#F2C76C;
      font-size: 12px;
      border:1px solid #adadad;
      color: #000;
  }
  .qr6_contcent div:nth-of-type(6){
      margin-top: 20px;
      font-size: 12px;
      color: #acacac;
  }
  .qr6_contcent-cke{
      height: 11%;
      background:#F3F3F3;
      overflow: hidden;
  }
  .qr6_contcent-cke>div{
      float: left;
      margin-left:50px;
      margin-top:3px;
      font-size: 13px;
      font-weight: 600;
  }
  .qr6_contcent-cke>div:first-of-type{
    width: 10px;
    height: 10px;
    margin-top:5px;
    margin-left:10px;
    border:1px solid #adadad;
    background:#F3F3F3;
}
.qr6_contcent-cke>div p{
    font-size: 12px;
    font-weight:100;
    color:#7B7B7B;
    
}
.qr6_contcent-cke div:nth-of-type(4){
    margin-left: 33px;
    
}
.qr6_contcent-cke div:nth-of-type(5){
    margin-left:300px;  
}
.qr6_contcent-cke div:nth-of-type(6){
    font-size: 13px;
    margin-top:3px;
    color: #000;
    margin-left:260px;  
}
.qr6_contcent-cke div:nth-of-type(7),.qr6_contcent-cke div:nth-of-type(8){
    margin-left:180px; 
}
.qr6_contcent-cke div:nth-of-type(9){
    margin-left:50px;
}
.qr6_contcent-cke div:nth-of-type(10){
    margin-left:120px;  
}
.qr6_contcentxc{
    height: 60px;
    border: 1px solid#F3F3F3;
}
.qr6_contcentxc>div{
    margin: 8px 15px;
    height:39px;
    border: 2px solid #bfe3f3;
    background: #F6F6F6;
}
.qr6_contcentxc>div p{
    float: left;
    margin:10px 15px;
}
.qr6_contcentxc>div p:first-of-type{
   width:20px;
   height:20px;
   text-align: center;
   border:1px solid #bfe3f3;
   color:  #bfe3f3;
   background: white;
   border-radius: 50%;
}
.qr6_contcentxc>div p:last-of-type{
    font-size: 13px;
    margin-left: 0;
 }
 .qr6_contcent>div:nth-of-type(9){
    margin-top: 10px;
     height:30px;
     text-align: center;
 }
 .qr6_contcent>div:nth-of-type(9) button{
    height:30px;
    width:120px;
   
 }
 .qr6_contcent>div:nth-of-type(9) button:nth-of-type(1),.qr6_contcent>div:nth-of-type(9) button:nth-of-type(2){
    background: #F7F8FA;
    border: 1px solid #ECECEC;
 }
 .qr6_contcent>div:nth-of-type(9) button:last-of-type{
   float: right;
 }
 .qr6_contcent>div:nth-of-type(9) button:last-of-type img{
    width: 15px;
    position: absolute;
  }
