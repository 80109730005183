
.return_address_setting{
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.return_setting_item{
  border: 1px solid rgb(221,221,221);
  padding: 20px;
  margin-bottom: 20px;
}
.return_setting_item h3{
  padding-left: 0;
  font-weight: 700;
}
.return_setting_box{
  display: flex;
  justify-content: space-between;
}
.return_setting_box span{
  color: rgb(0,102,192);
}
.return_address_setting_left{
  width: 65%;
}
.return_address_setting_right{
  width: 34%;
  padding-left: 20px;
}
.return_address_setting_right_item h3{
  padding-left: 0;
  border-top: 1px solid rgb(221,221,221);
  height: 60px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 0;
}
.return_span{
  display: inline-block;
  color: rgb(0,102,192);
  cursor: pointer;
  box-sizing: border-box;
}
.return_address_setting .return_span:hover{
  color: rgb(196,85,0);
  border-bottom: 1px solid rgb(196,85,0);
  box-sizing: border-box;
}
.return_address_cancel{
  width: 20px;
  height: 20px;
  border: 1px solid rgb(196,85,0);
}
.return_address_setting_modal .ant-modal-header{
  background: rgb(241,241,241);
}
.return_set_address{
  background: rgb(242,204,110);
  border: 1px solid rgb(194,156,61);
}