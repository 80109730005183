.end18_content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.end18_content .end18_content_left {
	width: 13%;
}

.end18_content_left ul {
	color: #008296;
	font-size: 14px;
	font-weight: bold;
}

.end18_content_left ul li {
	height: 30px;
	line-height: 30px;
	padding-left: 35px;
}

.end18_content_left_kc {
	margin-top: 25px;
	margin-bottom: 12px;
}

.end18_content_left_kc li:nth-of-type(1) {
	padding: 5px 0;
	background: #E3ECED;
	border-left: 5px solid #00a4b4;
	font-weight: bold;
}

.end18_content_left_kc li:nth-of-type(2) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
}

.end18_content_left_kc li:last-child {
	color: #3b3b3b;
}

.end18_content_left_xs {margin-bottom: 12px;}

.end18_content_left_xs li:nth-of-type(1) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
	color: #3b3b3b;
}
.end18_content_left_xs li:last-child {
	color: #3b3b3b;
}
.end18_content_left_fk{
	margin-bottom: 12px;
}
.end18_content_left_fk li:nth-of-type(1) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
	color: #3b3b3b;
}
.end18_content_left_fk li:last-child {
	color: #3b3b3b;
}
.end18_content_left_mj{
	margin-bottom: 12px;
}
.end18_content_left_mj li:nth-of-type(1) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
	color: #3b3b3b;
}
.end18_content_left_yc{
	margin-bottom: 12px;
}
.end18_content_left_yc li:nth-of-type(1) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
	color: #3b3b3b;
}
.end18_content_left_bb{
	margin-bottom: 12px;
}
.end18_content_left_bb li:nth-of-type(1) {
	padding: 5px 0;
	font-size: 18px;
	color: #3b3b3b;
	text-indent: 2em;
	color: #3b3b3b;
}
.end18_content_right{
	width: 85%;
}
.end18_content_right_top{
	font-weight: bold;
	margin-top: 25px;
}
.end18_content_right_top div{
	width: 80%;
	border: 1px solid #0A2836;
	border-left: 2px solid #1c78a2;
	background: #E3ECED;
	height: 45px;
	line-height: 45px;
	text-indent: 1em;
	margin-bottom: 5px;
}
.end18_content_right_con_txt span{
	font-size: 18px;
	display: block;
	margin: 8px 0;
}
.end18_content_right_con_txt p{
	font-size: 14px;
}
.end18_content_right_con_jy{
	margin: 8px 0;
}
.end18_content_right_con_jy span{
	font-weight: bold;
}
.end18_content_right_con_jy p {
	margin: 8px 0;
}
.end18_content_right_con_jy p span{
	font-weight: 400;
	color: #008296;
}
.end18_content_right_con_jy ul{
	list-style-type: disc;
	margin: 8px 0;
	margin-left: 25px;
}
.end18_content_right_con_bpld{
	font-weight: bold;
	margin: 8px 0;
}
.end18_content_right_con_jy ul li span{
	color: #008296;
	font-weight: 400;
}
.end18_title{
	height: 85px;
	line-height: 85px;
	border-bottom: 1px solid #CCCCCC;
}
.end18_title span:nth-of-type(1){
	font-size: 26px;
	margin-left: 65px;
}
.end18_title span:nth-of-type(2){
	font-size: 14px;
	margin-left: 25px;
	color: #008296;
}