.share-hint {
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    border: #bbd3de solid 1px;
    border-radius: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #444444;
}

.share-hint-icon {
    margin: 0 16px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: #3388aa solid 2px;
    color: #3388aa;
    font-size: 14px !important;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-hint span {
    color: #004b91 !important;
}