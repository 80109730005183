.skutitle{
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 10px;
}
.CommentatorBox{
    padding: 14px 18px 18px;
}
.a-box-inner {
    overflow: hidden;
    padding: 12px 18px 11px;
    background: #f0f0f0;
    box-shadow: 0 1px 0 rgb(255 255 255 / 50%) inset;
    background: #f3f3f3;
    background: -webkit-linear-gradient(top,#f8f8f8,#eee);
    background: linear-gradient(to bottom,#f8f8f8,#eee);
    font-size: 13px;
    font-weight: bold;
}
.a-padding-small {
    padding: 6px 10px!important;
}
.oneBox{
    border-top-color: #d8d8d8;
    display: block;
    border-radius: 4px;
    border: 1px #ddd solid;
    background-color: #fff;
    padding: 6px 10px!important;
    font-size: 13px;
}
.oneColumn{
    color: #0066c0;
    font-weight: bold;
    font-size: 14px;
    border-top: 1px solid #00000050;
}
.twoBox{
    margin-top: 20px;
}
.fileClass{
    border-top: 1px solid #00000050;
    border-bottom: 1px solid #00000050;
    padding: 20px 0;
}
.fileBtn{
    width: 75px;
    height: 30px;
    padding: 15px 0;
    background: #f0c14b;
    color: #565959!important;
    border: none;
    text-align: center;
    border-radius: 5px;
    line-height: 0;
    margin-top: 20px;
}
.a-spacing-medium h2{
    font-weight: 700;
    font-size: 21px;
    line-height: 1.3;
    margin: 15px 0;
}
.fourBox table{
    width: 100%;
    padding: 0 20px;
}
.fourBox table th,
.fourBox table tr{
    text-align: center;
}