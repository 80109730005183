.end06_mybody .mybody-ul {
    width: 390px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    cursor: pointer;
}

.CommentatorBox .mybody-ul li:nth-of-type(2),
.CommentatorBox .mybody-ul li:nth-of-type(3) {
    border-bottom: 0 !important;
}

.end06_mybody .mybody-ul li:nth-of-type(1) {
    border-bottom: 3px solid #FFBA53;
}

.commentBg {

    height: 300px;
    background: url('~@/assets/images/commentBg.png') no-repeat;
    background-size: cover;
    display: flex;
}

.program-overview-title-box {
    padding: 60px 0 0 80px;
    width: 400px;
    background: rgba(39, 62, 84, .7);
    height: 100%;
    color: #fff;
}

.program-overview-diagonal {
    height: 0;
    width: 0;
    border-bottom: 300px solid rgba(39, 62, 84, .7);
    border-right: 100px solid transparent;
}

.heading-text {
    color: #fff;
    font-size: 40px;
}

.a-spacing-small,
.a-ws .a-ws-spacing-small {
    margin-bottom: 8px !important;
}

.a-spacing-medium,
.a-ws .a-ws-spacing-medium {
    margin-bottom: 16px !important;
}

.a-size-large {
    font-size: 21px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.text-justify {
    text-align: justify;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: auto;
}

.a-grid-vertical-align.a-row {
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
}

.a-grid-vertical-align.a-row>div.a-span4 {
    width: 34.93%;
}

.a-grid-vertical-align.a-row>div.a-column {
    float: none !important;
    display: table-cell !important;
    zoom: 1;
    padding-right: 14px;
}

.a-ws div.a-column,
div.a-column {
    margin-right: 2%;
    float: left;
    min-height: 1px;
    overflow: visible;
}

.a-row .a-span4,
.a-ws .a-row .a-ws-span4 {
    width: 31.948%;
}

.value-box {
    padding: 35px !important;
}

.a-text-center {
    text-align: center !important;
}

.reviews-increase-icon {
    height: 100px;
    background: url(~@/assets/images/comment1.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.reviews-faster-icon {
    height: 100px;
    background: url('~@/assets/images/comment2.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.starting-easy-icon {
    height: 100px;
    background: url('~@/assets/images/comment3.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.a-section:last-child {
    margin-bottom: 0;
}

.program-overview-footer {
    padding: 45px;
}

.alternate-bg,
.program-overview-footer {
    background-color: #f4f5f5;
}

.a-button-primary:hover {
    color: #000;
}