
.kc34_tab{
    min-width: 1650px;
    padding: 0px 20px;
/*    margin: 0 20px; */
}
.kc34_tab>.kc34_tab_list{
    margin-top: 30px;
    display: flex;
    list-style: none;
    border-bottom: 3px solid rgba(0, 0,.0, 0.3);
    font-size: 18px;
    height: 35px;
}
.kc34_tab_list>li{
    margin-right: 30px;
    position: relative;
	border-bottom: 4px solid #CC6600;
}
.kc34_tab_list>li:nth-of-type(1){
    display: flex;
    color: #CC6600;
    height: 35px;
}
.kc34_tab_list>li:nth-of-type(1) img{
    width: 10px;
    position:absolute;
    bottom: 0;
    left: 300px;
}
.kc34_tab_list>li>div{
    margin-right: 30px;
}
.kc34_tab_list>li>div:last-of-type{
	margin: 0;
}
.kc34_sp_text{
    margin-top: 30px;
}
.kc34_tx_1>h3{
    font-size: 18px;
}
.kc34_tx_1>p{
    font-size: 14px;
	 margin-top: 20px;
}
.kc34_tx_1 p>span{
	font-weight: bold;
}
.kc34_tx_1 p>a{
	text-decoration: none;
	color: #0777ff;
}
.kc34_tx_2{
    margin: 30px 40px 0;
}
.kc34_box1 p{
    font-weight: 600;
}
.kc34_box2{
    background: #F3F3F3;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
	font-size: 14px;
}
.kc34_box2 input{
	background-color: #999999;
	vertical-align: middle;
}
.kc34_btn{
    margin-top: 30px;
    position: relative;
    padding-bottom: 300px;
}
.kc34_btn button:nth-of-type(1){
    padding: 7px 13px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0,.0, 0.3);
}
.kc34_btn button:nth-of-type(2){
    padding: 7px 10px;
    position: absolute;
    right: 0;
    background-color: #f1deaf;
    border-radius: 3px;
    border: 1px solid rgba(0, 0,.0, 0.3);
}
.kc_header_head_r li{
	position: relative;
}
.svgs{
	position: absolute;
	right:4px;
	top: 20px;
}