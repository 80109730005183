.report-body{
    width: 100%;
    padding: 0 50px;
    margin: 0 auto;
    padding-bottom: 400px;
}
.report-titel{
    display: flex;
}
.report-titelFont{
    font-size: xx-large;
}
.report-reportboxHead{
    width: 100%;
    height: 168px;
    border: 1px solid #dddddd;
    background: #f3f3f3;
    margin-top: 20px;
    border-radius: 4px;
}
.report-reportboxTitel{
    width: 100%;
    height: 34px;
    border-bottom: 2px solid #dddddd;
    padding: 6px;
}
.report-reportboxNav{
    height: 93px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
}
.report-reportboxNav div:nth-child(1){
    margin-right: 30px;
}


.report-reportboxBottom{
    width: 100%;
    height: 34px;
    border-top: 2px solid #dddddd;
    display: flex;
    justify-content: center;
}
.report-reportboxBottom button{
        background: #f3ce72;
        border:1px solid #000;
        border-radius: 4px;
        width: 90px;
        margin-top: 3px;
}
.report-reportboxdownload{
    width: 100%;
    margin-top:40px;
    border: 1px solid #dddddd;
}
.report-downloadHead{
    width: 100%;
    height: 38px;
    background: #f3f3f3;
    padding: 8px;
    border-bottom: 1px solid #dddddd;
}
.report-downloadTitel{
    width: 100%;
    height: 38px;
    background: #f3f3f3;
    padding: 8px;
    border-bottom: 1px solid #dddddd;
    padding: 0 30px;
    display: flex;
    align-items: center;
}
.report-downloadTitel strong:nth-child(1){
    width: 11%;
}
.report-downloadTitel strong:nth-child(2){
    width: 16%;
}
.report-downloadTitel strong:nth-child(3){
    width: 25%;
}
.report-downloadTitel strong:nth-child(4){
    width: 25%;
}
.report-downloadTitel strong:nth-child(5){
    width: 17%;
}
.report-downloadTitel strong:nth-child(6){
    width: 7%;
}

.report-downloadNav{
    width: 100%;
    height:31px ;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 12px;
    border-bottom: 1px solid #dddddd;
}
.report-downloadNav div:nth-child(1){
    width: 11% ;
}
.report-downloadNav div:nth-child(2){
    width: 16% ;
}
.report-downloadNav div:nth-child(3){
    width: 25% ;
}
.report-downloadNav div:nth-child(4){
    width: 25% ;
}
.report-downloadNav div:nth-child(5){
    width: 17% ;
}
.report-downloadNav div:nth-child(6){
width: 7%;
}

.report-reportboxNav select{
    background: #ebedef;
    border-radius: 4px;
}

.report-titelFontII a{
    color: #0080D2;
}

.redundance-searchLabel select:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}