.fbaInboundBox{
    padding: 20px;
}
.fbaInboundBox .fbaTitle{
    color: #C60;
    font-size: medium;
    font-weight: bold;
    margin: 0;
}
.fbaInboundBox .fbaBox table th{
    background: #CDDEF3;
    text-align: center;
}
.fbaInboundBox .returnclass{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: #006;
    font-size: 14px;
    background: #CDDEF3;
}
.fbaInboundBox .a-section-expander-container {
    font-size: 17px;
    line-height: 1.255;
    display: block;
    position: relative;
    color: #0f1111;
    background-color: #f3f3f3;
    transition: all .1s linear;
    padding: 11px 54px 11px 18px;
    border: 1px solid rgba(0,0,0,.3137254901960784);
    border-bottom: 0;
}
.fbaInboundBox .returnclass span{
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    cursor: pointer;
}
.fbaInboundBox .returnclass span a{
    color: #006;

}

.fbaInboundBox .returnclass .returnBtn{
    height: 30px;
    border: 2px solid rgb(137, 137, 86);
    background: rgb(214, 214, 180);
    padding: 1px 10px;
    border-radius: 3px;
    position: relative;
    padding-left: 25px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;

}
.fbaInboundBox .returnclass .returnBtn .kc_10_btn2{
    left: 2px;
    bottom: 3px;
    transform: rotateY(360deg);
}

.fbaInboundBox .returnclass .transformation,
.fbaInboundBox .returnclass .sendOut{
    border: 2px solid rgb(28, 64, 139);
    background: rgb(237, 182, 49);
    padding: 3px 10px;
    border-radius: 3px;
    position: relative;
    
}
.fbaInboundBox .returnclass .sendOut{
    padding-right: 25px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}

.fbaInboundBox .returnclass>div{
    display: flex;
}
.fbaInboundBox .returnclass .sendOut .kc_10_btn2{
    bottom: 3px;
}
.fbaInboundBox .tableBox table td{
    text-align: center;
}
.fbaInboundBox .moreBox{
    display: block;
    margin:20px 0;
    color: #004b91;
}

.fbaInboundBox table tr th,
.fbaInboundBox table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    min-height: 40px;
    padding: 0 10px;
}
.fbaInboundBox table tr td:nth-child(2){
    width: 100%;
    height: auto;
}
.fbaInboundBox table th,
.fbaInboundBox table td{
    border-left: none;
    border-right: none;
}