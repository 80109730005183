

.add-offer-inner{
    background-color: rgb(245, 249, 250);
    padding-bottom: 40px;
}
.add-offer-header{
    color: #008296;
    font-size: 14px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    /* margin-bottom: 40px; */
}
.add-offer-body{
    width: 800px;
    border: 1px solid rgb(233, 233, 233);
    margin: 40px auto;
    width: 50%;
    background-color: #fff;
}
.add-offer-body input{
    width: 424px;
}
.add-offer-body textarea{
    width: 424px;
}
.add-offer-body .ant-picker{
    width: 150px;
}
.add-offer-body .ant-input-group-wrapper{
    width: 150px;
}
.add-offer .add-offer-body  .ant-select-selector{
    width: 424px;
}
/* .add-offer-body .ant-form{
    margin: auto;
    width: 500%;
} */
.add-offer-body > div{
    text-align: right;
    padding: 20px;
    border: 1px solid rgb(233, 233, 233)
}