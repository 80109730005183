* {
  margin: 0;
  padding: 0;
}
.advertising_box{
  display: flex;
  width: 100%;
}
.advertising_boxII{
}
.gg5_body {
  overflow: hidden;
}
.gg5_myleft {
  width: 2%;
  height: 100vh;
  float: left;
  background: #232f3e;
  position: relative;
  position: fixed;
  z-index: 10;
}
.gg5_myleft img {
  width: 100%;
  margin-top: 20px;
}
.g5_myleft_logo {
position: absolute;
top: 0;
left: -5px;
 margin-top: 10px;
  z-index: 2;
  transform: scale(0.4);
}
.g5_myleft_logo svg{
    width: 2rem;
    height: 2rem;
}

.gg5_myleft .gg06_sidebar {
  transform: translateX(-260px);
  transition: 0.4s;
}
.gg5_myleft:hover .gg06_sidebar {
  transform: translateX(0);
}

.gg06_sidebar a{
  color: #fff;
  width: 100%;
}
.gg06_sidebar_li:hover{
  background: #dddddd;
}
.gg06_sidebar {
  width: 260px;
  height: 100%;
  background-color: #232f3e;
  position: absolute;
  z-index: 41;

}
.gg06_sidebar img {
  vertical-align: middle;
}
.gg06_sidebar ul {
  list-style: none;
  color: white;
}
.gg06_sidebar ul li {
  padding: 10px 0 10px 10px;
  width: 85%;
  margin: 5px auto 0;
  border-radius: 3px;
  position: relative;
}
.gg06_sidebar ul li:nth-of-type(2) {
  background-color: rgba(255, 255, 255, 0.3);
}
.gg06_sidebar ul li svg {
  position: absolute;
  right: 0;
}
.gg06_tx_min {
  font-size: 13px;
  font-weight: 300;
}
.gg06_segmentation {
  padding: 20px 0 20px 10px !important;
  margin: 15px auto !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.gg5_myrightII {
  width: 97%;
  height: 100vh;
}
.gg5_myrightII > div:first-of-type p {
  color: white;
  font-size: 16px;
  float: left;
  margin-left: 20px;
}
.gg5_myrightII > div:first-of-type img {
  height: 60px;
  float: right;
}
.gg5_myrightII_ul {
  list-style: none;
  height: 50px;
  overflow: hidden;
  font-size: 16px;
  background: #f8f8f8;
  border-bottom: 1px solid #f0f0f0;
}

.gg5_myrightII_ul li:nth-of-type(2) {
  color: #000;
  border-bottom: 2px solid #4f78aa;
}

.gg5_table {
  width: 100%;
  height: 450px;
}

.gg5_table table{
  width: 100%;
}
.gg5_table tr {
  height: 36px;
}
.gg5_red {
  color: #fc0707;
}
.gg5_ye {
  color: #da935e;
}
.gg5_bu {
  color: #609fd8;
}


.gg5_tableII{
  width: 100%;
  height: 450px;
  overflow: auto;
}