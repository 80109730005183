.Details_headII_btn a{ color: #ffffff;}

.tab1 #rc-tabs-0-tab-2  {
    display: block !important;
    border-right: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    padding: 0 20px !important;
  

}
.tab2  #rc-tabs-0-tab-2{
    border: 0 !important;
    padding: 0 !important;
 

  }
