.end08_mybody .nav{
	width: 100%;
	border-bottom: 1px solid rgba(0,0,0,0.4);
	background-color: #F5F9FA;
	font-family: "宋体";
}
.end08_mybody .nav .mybody-div{
	width: 100%;
	padding-bottom: 40px;
	margin: 0 auto;
	text-align: center;
	position: relative;
}
.end08_mybody .nav .mybody-div .p1{
	color: #1D5B71;
	font-size: 20px;
	font-weight: 600;
	padding-top: 20px;
}
.end08_mybody .nav .mybody-div .p2{
	color: rgba(0,0,0,0.7);
	font-size: 38px;
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 20px;
}
.end08_mybody .nav .mybody-div .ipt{
	width: 280px;
	height: 28px;
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 3px;
	margin-bottom: 20px;
	padding-left: 30px;
}
.end08_mybody .nav .mybody-div .ss{
	position: absolute;
	left: 800px;
	top: 157px;
}
.end08_mybody .nav .mybody-div .btn{
	background-color: #016879;
	color: white;
	padding: 3px 5px;
	border-radius: 3px;
}
.end08_mybody .nav .mybody-div .sp1{
	font-size: 14px;
}
.end08_mybody .nav .mybody-div .sp2{
	font-size: 14px;
	color: #2B585F;
	font-weight: 600;
}
.end08_mybody .nr{
	width: 75%;
	overflow: hidden;
	margin: 0 auto;
	margin-top: 30px;
	padding-bottom: 150px;
}
.end08_mybody .nr .left{
	width: 200px;
	float: left;
}
.end08_mybody .nr .left .p3{
	font-size: 20px;
	margin-bottom: 10px;
}
.end08_mybody .nr .left .ul-left{
	border: 1px solid rgba(0,0,0,0.3);
	border-bottom: none;
	border-radius: 3px;
	width: 290px;
}
.end08_mybody .nr .left .ul-left .tip{
	background-color: #FFF;
	position: relative;
	padding-left: 30px;
	color: #277080;
	font-weight: 600;
}
.end08_mybody .nr .left .ul-left .tip .sl{
	position: absolute;
	top: 7px;
	left: 10px;
}
.end08_mybody .nr .left .ul-left .tip .by{
	position: absolute;
	top: 5px;
	right: 10px;
}
.right ul li {
	width: 190px;
}
.right ul li img{
	width: 100%;
}
.end08_mybody .nr .left .ul-left .ul1-tip{
	position: relative;
}
.end08_mybody .nr .left .ul-left .ul1-tip .g{
	position: absolute;
	top: 5px;
	right: 10px;
}
.end08_mybody .nr .left .ul-left li{
	height: 30px;
	line-height: 30px;
	padding-left: 35px;
	border-bottom: 1px solid rgba(0,0,0,0.3);
	font-size: 14px;
	background-color: #F5F9FB;
}
.end08_mybody .nr .left .ul-left2{
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 3px;
	border-top: none;
	width: 290px;
}
.end08_mybody .nr .left .ul-left2 .tip{
	background-color: #FFF;
	position: relative;
	padding-left: 30px;
	color: #277080;
	font-weight: 600;
}
.end08_mybody .nr .left .ul-left2 .tip p{
	width: 230px;
}
.end08_mybody .nr .left .ul-left2 .tip .sl{
	position: absolute;
	top: 7px;
	left: 10px;
}
.end08_mybody .nr .left .ul-left2 .tip .by{
	position: absolute;
	top: 5px;
	right: 10px;
}
.end08_mybody .nr .left .ul-left2 li{
	/* height: 30px; */
	line-height: 30px;
	padding-left: 20px;
	font-size: 14px;
	border-bottom: 1px solid rgba(0,0,0,0.3);
	background-color: #FFF;
}
.end08_mybody .nr .right{
	width: 70%;
	float: right;
	margin: 0 auto;
	margin-bottom: 40px;
}
.end08_mybody .nr .right .p4{
	font-size: 20px;
	margin-bottom: 10px;
}
.end08_mybody .nr .right .ul-right{
	display: flex;
	justify-content: space-around;
}
.end08_mybody .nr .right .ul-right .ul-right-div{
	width: 190px;
	border: 1px solid rgba(0,0,0,0.1);
	box-shadow: rgba(0,0,0,0.1) 0px 1px 3px;
	margin-top: -4px;
	padding: 0 20px;
	position: relative;
}
.end08_mybody .nr .right .ul-right .ul-right-div .p5{
	width: 170px;
	font-weight: bold;
	color: #0A2836;
	margin-bottom: 10px;
	margin-top: 5px;
}
.end08_mybody .nr .right .ul-right .ul-right-div .p6{
	font-size: 14px;
	font-weight: bolder;
}
.end08_mybody .nr .right .ul-right .ul-right-div .g{
	position: absolute;
}
.end08_mybody .nr .right .ul-right .ul-right-div .p7{
	padding-left: 20px;
	font-size: 12px;
}
.end08_mybody .nr .right .ul-right .ul-right-div .sp1{
	font-size: 12px;
	color: rgba(0,0,0,0.3);
	display: inline-block;
	margin-bottom: 5px;
	margin-top: 20px;
}
.end08_mybody .nr .right .ul-right .ul-right-div .sp2{
	margin-left: 43px;
}
.end08_mybody .nr .right .ul-right .ul-right-div .p8{
	border-top: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 10px;
	padding-top: 5px;
	font-size: 14px;
	color: #297279;
	font-weight: bolder;
}