.AdvertisingCampaign-box {
  display: flex;
}
.AdvertisingCampaign-box-left {
  width: 35px;
  line-height: normal;
  background-color: #000;
  color: #fff;
  padding: 10px;
}
.AdvertisingCampaign-box-right {
  width: 100%;
  height: 100%;
}
.box-right-head {
  height: 55px;
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  background-color: #005a95;
}
.box-right-head-right {
  display: flex;
}
.box-right-head-left {
  color: #fff;
}
.box-right-head-right div {
  margin-right: 22px;
}
.icons-list {
  color: #fff;
}
.BatchOperation {
  background-color: #f3f3f3;
  padding-left: 300px;
  padding-top: 28px;
  padding-bottom: 25px;
}
.BatchOperation-span {
  font-size: 14px;
  font-weight: 700;
}
.BatchOperation-div {
  font-size: 12px;
}
.BatchOperation-button {
  width: 100px;
  height: 30px;
  background-color: #f6be45;
  border: solid 1px #f6be45;
  border-radius: 3px;
  margin: 15px 0;
}
.BatchOperation-box {
  display: flex;
  margin-top: 20px;
}
.BatchOperation-1 {
  width: 400px;
  font-size: 12px;
  border-right: 1px solid #e4e4e4;
}
.BatchOperation-1 span {
  font-size: 12px;
}
.BatchOperation-1 span:nth-child(1) {
  font-weight: 700;
  font-size: 14px;
}
.BatchOperation-1 div:nth-child(2) {
  font-weight: 700;
  margin-top: 17px;
}
.BatchOperation-1 div:nth-child(4) {
  font-weight: 700;
  margin-top: 18px;
}
.BatchOperation-select {
  margin-left: 0px !important;
}
.BatchOperation-2 {
  width: 335px;
  margin-left: 20px;
  font-size: 12px;
  border-right: 1px solid #e4e4e4;
}
.BatchOperation-li {
  list-style: disc;
}
.BatchOperation-2 span:nth-child(1) {
  font-size: 14px;
  font-weight: 700;
}
.BatchOperation-2 div:nth-child(2) {
  margin-top: 10px;
}
.BatchOperation-3 {
  margin-left: 20px;
  font-size: 12px;
}
.BatchOperation-3 span:nth-child(1) {
  font-weight: 700;
  font-size: 14px;
}
.BatchOperation-3 button {
  margin-top: 10px;
  width: 170px;
  height: 30px;
  border: 1px solid #adb1b8;
}
.BatchOperationHistory {
  padding-left: 300px;
  padding-top: 28px;
}
.BatchOperationHistory div:nth-child(1) {
  font-size: 12px;
}
.BatchOperationHistory {
  font-size: 12px;
}
.BatchOperationHistory-download {
  width: 567px;
}
.BatchOperationHistory-list{
    margin-top: 20px;
    display: flex;
}
.BatchOperationHistory1{
    font-weight: 700;
}