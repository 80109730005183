.end12_navlist {
	background-color: #F9F9F9;
	border-bottom: 1px solid #ccc;
}

.end12_navlist ul {
	margin-left: 30px;
	display: flex;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
}

.end12_navlist ul li {
	margin-right: 50px;
	color: #3275af;
}

.end12_navlist ul li:nth-of-type(1) {
	color: #7F7A77;
}

.end12_navlist ul li svg {
	vertical-align: middle;
}

.end12_title {
	margin: 10px 20px;
}

.end12_jinggao {
	padding-left: 20px;
	height: 50px;
	line-height: 50px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	box-shadow: 1px 0px 10px #efef6d;
	color: #cbaf77;
	font-size: 15px;
	font-weight: 600;
}

.end12_jinggao svg {
	vertical-align: middle;
	margin-right: 15px;
}

.end12_h2 div {
	font-weight: 500;
	font-size: 24px;
}

.end12_h2 div span {
	margin-left: 15px;
	font-size: 13px;
	color: #3275AF;
}

.end12_h2 p {
	font-size: 13px;
}

.end12_h2 p span {
	color: #3275AF;
}

.end12_jubao {
	margin-top: 10px;
	font-size: 13px;
	color: #2b6798;
}

.end12_cenlist {
	margin: 15px 20px 0;
	display: flex;
	justify-content: space-between;
}

.end12_centent {
	width: 32%;
}
.end12_cen1{
	border: 1px solid #ccc;
}

.end12_cen1_tit {
	padding:10px 20px;
	border-bottom: 1px solid #ccc;
	font-weight: 600;
	font-size: 15px;
}

.end12_cen1_chak {
	text-align: center;
	height: 40px;
	line-height: 40px;
	font-size: 13px;
	color: #2B6798;
	position: relative;
}
.end12_cen1_chak div{
	position: absolute;
	top: 0%;
	left: 25%;
	transform: rotate(-0% -25%);
	height: 1px;
	width: 50%;
	background: linear-gradient(to left,white,black,white);
	box-shadow: 1px 3px 5px #ccc;
}
.end12_cen1_text{
	margin: 10px;
	margin-bottom: 40px;
}
.end12_cen1_text_1{
	height: 30px;
	line-height: 30px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: rgba(0,0,0,0.7);
}
.end12_cen1_text_2{
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 12px;
	padding-left: 10px;
}
.end12_cen1_text_1 p:nth-of-type(1){
	width: 50%;
}
.end12_cen1_text_1 p:nth-of-type(2){
	width: 35%;
}
.end12_cen1_text_1 p:nth-of-type(3){
	width: 12%;
}
.end12_cen1_text_1 svg{
	vertical-align: middle;
}
.end12_dingdan{
	color: #3275AF;
}
.end12_text3{
	margin-top: -12px;
}
.end12_bold{
	font-weight: 600;
}
.end12_zhibiao{
	margin-top: 20px;
	padding: 0 10px;
	font-size: 12px;
}
.end12_zb_flex{
	display: flex;
	justify-content: space-between;
	height: 50px;
	line-height: 50px;
}
.end12_zhibiao>ul{
	list-style: disc;
	padding-left: 20px;
}
.end12_zhibiao>ul>li:nth-of-type(1){
	border-bottom: 1px solid #ccc;
}
.end12_zb_flex li{
	color: rgba(0,0,0,0.7);
}
.end12_zb_flex li:nth-of-type(1){
	width: 40%;
	color: #2B6798;
}
.end12_zhengce{
	display: flex;
	justify-content: space-between;
}
.end12_zhengce p {
	overflow: hidden;
}
.end12_zhengce svg{
	vertical-align: middle;
}
.end12_textcc{
	font-size: 12px;
	color: rgba(0,0,0,0.6);
}
.end12_textcc span{
	color: rgba(0,0,0,0.8);
}
.end12_zhishu{
	height: 20px;
	overflow: visible !important;
}
.end12_zhishu span{
	position: relative;
}
.end12_zhishu span:nth-of-type(1){
	display: inline-block;
	width: 35px;
	height: 10px;
	background-color: #CC0D39;
}
.end12_zhishu span:nth-of-type(2){
	display: inline-block;
	width: 35px;
	height: 10px;
	background-color: #FEC400;
}
.end12_zhishu span:nth-of-type(2)>span{
	display: block;
	width: 45px;
	height: 35px;
	position: absolute;
	top: -42px;
	text-align: center;
	background-color: #FEC400;
}
.end12_zhishu span:nth-of-type(2)>span>svg{
	position: absolute;
	bottom: -13px;
	left: 13px;
}
.end12_zhishu span:nth-of-type(3){
	display: inline-block;
	width: 35px;
	height: 10px;
	background-color: #538001;
}
.end12_weigui{
	margin: 10px 20px;
	font-size: 13px;
}
.end12_weigui>p{
	text-align: right;
}
.end12_weigui>ul>li{
	display: flex;
	justify-content: space-between;
	height: 30px;
	padding: 5px 5px 0;
}
.end12_weigui>ul>li:not(:nth-of-type(1)){
	border-top: 1px solid #ccc;
}
.end12_weigui>ul>li span:nth-of-type(1){
	color: #2B6798;
}
.end12_weigui>ul>li span:nth-of-type(2){
	font-weight: 500;
	font-size: 18px;
}
.end12_weigui>ul>li svg{
	vertical-align: middle;
	margin-right: 20px;
}
.end12_centen2_weifan{
	border-top: 1px solid #ccc;
}
.end12_centen2_weifan div{
	margin: 0 20px;
	padding: 15px 5px;
	display: flex;
	justify-content: space-between;
	font-size: 13px;
}
.end12_centen2_weifan div span:nth-of-type(1){
	color: #2B6798;
}
.end12_centen2_weifan div span:nth-of-type(2){
	font-weight: 500;
	font-size: 18px;
}
.end12_kehu{
	margin-top: 15px;
}
.end12_xinwen{
	margin: 10px 20px;
}
.end12_xinwen li{
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	margin-top: 5px;
}
.end12_xinwen li span:nth-of-type(1){
	color: #2B6798;
}
.end12_maijiaps svg{
	vertical-align: middle;
}
.end12_maijiaps{
	font-size: 13px;
	color: rgba(0,0,0,0.7);
}
.end12_chonggou{
	width: auto !important;
}
.end12_chonggou_text2{
	margin-bottom: 5px;
	padding-bottom: 20px;
}
.end12_cichu{
	color: #2B6798;
	margin-bottom: 60px;
}
.end12_boldspan{
	font-size: 18px;
}