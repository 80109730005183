.end13_navlist {
	background-color: #F9F9F9;
	border-bottom: 1px solid #ccc;
}

.end13_navlist ul {
	margin-left: 30px;
	display: flex;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
}

.end13_navlist ul li {
	margin-right: 50px;
	color: #3275af;
}

.end13_navlist ul li:nth-last-of-type(1) {
	color: #7F7A77;
}

.end13_navlist ul li svg {
	vertical-align: middle;
}

.end13_title {
	margin: 10px 20px;
}

.end13_lllff {
	margin: 20px 0;
	padding: 0 15px;
}

.end13_rongyutit {
	list-style: none;
	display: flex;
	position: relative;
}

.end13_rongyutit li:nth-of-type(1) {
	font-size: 24px;
	font-weight: 500;
}

.end13_rongyutit li:nth-of-type(2) {
	font-size: 12px;
	font-weight: 600;
	height: 40px;
	line-height: 45px;
	margin-left: 10px;
	color: #4891D2;
}

.end13_rongyutit li:nth-of-type(2) span {
	font-size: 10px;
	margin: 0 5px;
	vertical-align: top;
	color: black !important;
}

.end13_lllff p {
	font-size: 12px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.7);
}

.end13_mingxi {
	margin-top: 20px;
}

.end13_mingxi ul {
	display: flex;
	justify-content: space-between;
	border: 1px solid #ccc;
	padding: 10px 0;
}

.end13_mingxi ul li {
	text-align: center;
	width: 14%;
	height: 100px;
	line-height: 35px;
}

.end13_mingxi ul li:nth-of-type(1) {
	text-align: left;
	position: relative;
}

.end13_mingxi ul li:nth-of-type(1) p:nth-of-type(1) {
	color: black;
	font-size: 14px;
	font-weight: 600;
	position: absolute;
	top: -10px;
	left: 15px;
}

.end13_mingxi ul li:nth-of-type(1) p:nth-of-type(2) {
	position: absolute;
	bottom: -10px;
	left: 15px;
	color: #3685c1;
}

.end13_leftxian {
	border-left: 1px solid #ccc;
}

.end13_leftxian p:nth-of-type(1) {
	font-size: 12px;
	color: white;
}

.end13_leftxian p:nth-of-type(2) {
	font-size: 25px;
	font-weight: 600;
}

.end13_leftxian p:nth-of-type(3) {
	font-size: 12px;
	color: #3d9bdd;
}

.end13_left_but {
	width: 80px;
	height: 20px;
	line-height: 20px;
	margin: 8px auto;
	border-radius: 40px;
	text-align: center;
	color: white !important;
}

.end13_red {
	background-color: #CC0D38;
}

.end13_orange{
	background-color: #FC8C00;
}
.end13_yell{
	background-color: #FAC100;
	color: white !important;
}
.end13_green{
	background-color: #A2D51E;
}
.end13_green2{
	background-color: #538001;
}


.end13_xuanze{
	margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.end13_xuanze>div{
  height: 30px;
  line-height: 30px;
  margin-right: 20px;
}
.end13_xuanze>div:nth-of-type(2){
    font-size: 12px;
    font-weight: 400;
    padding: 0 10px;
    background: #ECEEF0;
    border-radius: 5px;
    border: 1px solid rgba(0, 0,.0, 0.3);
	box-sizing: border-box;
}
.end13_xuanze>div:nth-of-type(3){
    font-size: 13px;
    font-weight: 400;
}
.end13_xuanze>div:nth-of-type(2)>svg{
	margin-left: 5px;
    vertical-align: middle;
}
.end13_xuanze>div:nth-of-type(1){
    position: relative;
}
.end13_xuanze>div:nth-of-type(1)>svg{
    position: absolute;
    top: 10px;
    left: 5px;
}
.end13_xuanze>div:nth-of-type(1)>input{
    height: 30px;
    padding: 0 0 0 30px;
    box-sizing:border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0,.0, 0.3);
}
.end13_xuanze>div:nth-of-type(1)>button{
    background: #6D747B;
    color: white;
    height: 30px;
    width: 50px;
    border-radius: 3px;
    font-weight: 600;
}
.end13_dataList{
	margin-top: 5px;
	border: 1px solid #ccc;
}
.end13_tab{
	text-align: left;
}
.end13_tab p{
	font-size: 12px ;
}
.end13_tab thead{
	background-color: #F1F1F1;
}
.end13_tab thead p:nth-of-type(1){
	font-weight: 600 !important;
}
.end13_tab thead>tr>th:nth-of-type(1){
	width: 50px;
	text-align: center;
}
.end13_tab thead>tr>th:nth-of-type(3){
	width: 100px;
}
.end13_tab thead>tr>th:nth-of-type(4){
	padding-right: 25px;
	width: 40px;
}
.end13_tab thead>tr>th:nth-of-type(5){
	padding-right: 25px;
	width: 40px;
}
.end13_tab thead>tr>th:nth-of-type(6){
	padding-right: 25px;
	width: 60px;
}
.end13_tab thead>tr>th:nth-of-type(7){
	padding-right: 25px;
	width: 40px;
}
.end13_tab thead>tr>th:nth-of-type(8){
	padding-right: 25px;
	width: 50px;
}
.end13_tab thead>tr>th:nth-of-type(9){
	padding-right: 25px;
	width: 90px;
}
.end13_tab thead>tr>th:nth-of-type(10){
	padding-right: 20px;
	width: 50px;
}
.end13_tab thead>tr>th:nth-of-type(11){
	padding-right: 20px;
	width: 70px;
}
.end13_tab thead>tr>th:nth-of-type(12){
	padding-right: 20px;
	width: 100px;
}
.end13_tab thead>tr>th:nth-of-type(2){
	width: 850px;
}
.end13_tab thead>tr>th{
	height: 40px;
	padding: 5px;
	vertical-align: super;
}
.end13_tab tbody>tr>td{
	border-top: 1px solid #ccc;
	height: 50px;
	padding: 5px;
	vertical-align: super;
}
.end13_tab tbody>tr>td:nth-of-type(8) p{
	width: 50px;
}
.end13_tab tbody>tr>td img{
	vertical-align: top;
}
.end13_bule{
	color: #3C74B7 !important;
}
.end13_yell{
	text-align: center;
}
.end13_dele{
	font-style:italic;
}
.end13_chakan{
	width: 120px;
	padding: 5px 0;
}