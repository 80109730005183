.a-input-money .a-input__addon {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 0 6px;
  background-color: #fafafa;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  transition: all 0.3s linear;
}

.a-input-money .a-input__addon > span {
  position: relative;
  top: -1px;
}

.a-input-money.disabled .a-input__addon {
  color: rgba(0, 0, 0, 0.25);
}

.a-input-money > * {
  vertical-align: middle;
}

.a-input-money .ant-input-number:focus,
.a-input-money .ant-input-number-focused,
.a-input-money .ant-input-number:hover {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-form-item-has-error .a-input-money:not(.disabled) .ant-input-number:hover {
  border-color: #d9d9d9;
  box-shadow: none;
}

.a-input-money {
  height: 32px;
  box-sizing: border-box;
  display: inline-block;
}

.a-input-money .a-input-number {
  border-radius: 0;
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  width: calc(100% - 22px);
}

.ant-form-item-has-error .a-input-money .a-input__addon,
.ant-form-item-has-error .a-input-money .ant-input-number {
  border-color: #d9d9d9;
}
