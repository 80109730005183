
.ausste_body{
    margin-top: 20px;
    display: flex;
    padding-bottom:150px;
}



.ausste_left{
    width: 17%;
    background: #F3F3F3;
    font-size: 12px;
    border: 1px solid #dddddd;
    margin-right: 40px;
}
.ausste_leftP{
    padding:  20px;

}
.ausste_left_headNav div{
    font-weight:bold;
}
.ausste_left_head{
    border-bottom: 1px solid #dddddd;

}
.ausste_left_middle ul li{
    margin: 4px 0;
}

.ausste_left_middle{
    border-bottom: 1px solid #dddddd;
    padding-top: 10px;
}
.ausste_left_bottomI{
    border-bottom: 1px solid #dddddd;
    padding: 10px 0 5px 0;
    font-weight: bold;
}
.ausste_left_bottomII{
    border-bottom: 1px solid #dddddd;
    padding: 3px 0;
}

.ausste_left_bottomIII{
    border-bottom: 1px solid #dddddd;
    padding: 10px 0 3px 0;
    font-weight: bold;
}
.ausste_left_bottomIIII{
    border-bottom: 1px solid #dddddd;
    padding: 5px 0;
}
.ausste_right{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.ausste_right_headI{
    display: flex;
    align-items: center;
}
.ausste_right_headI_font{
    font-size: 24px;
    font-weight: bold;
    margin-left: 10px;
    
}
.ausste_right_headII{
    display: flex;
}
.ausste_right_headI button{
    width: 90px;
    height: 28px;
    background: #5C636B;
    color: #ffffff;
    padding: 3px 7px;
    font-size: 12px !important;
    margin-right: 1 0px;
}
.ausste_right_headII{
    height: 28px;
    margin-bottom: 50px;
}
.ausste_right_headII select{
    height: 26px;
    background: rgba(236,238,240,.6);
    font-size: 12px;
    border-top: 0;
    box-shadow: 0px -1px 2px #ADB1B8;
    padding-left: 8px;
    border-radius: 3px;

}
.ausste_right_headII_midButton{
    margin: 0 10px;
}
.ausste_right_headII_midButton button{
    padding: 0 10px;
    border-radius: 3px;

}
.ausste_right_headII button{
    background: #EBECEF;
    outline-style: none;
    border: 1px solid #000000;
    font-size: 12px;
    height: 26px;

}
.ausste_right_headII_endButton button{
    width: 173%;
    border-radius: 3px;
}
.ausste_right_headII_midButton select:hover{
    background-color: rgb(236,238,240);
}
.ausste_right_headII_midButton select:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
.ausste_right_headII_midButton button:hover{
    background-color: rgb(236,238,240);
}
.ausste_right_headII_midButton button:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
.ausste_right_headIII select{
    height: 26px;
    background: rgba(236,238,240,.6);
    font-size: 12px;
    border-top: 0;
    box-shadow: 0px -1px 2px #ADB1B8;
}
.ausste_right_headIII{
    padding:0 10px;
    text-align: right;
}
.ausste_right_headIII select:hover{
    background-color: rgb(236,238,240);
}
.ausste_right_headIII select:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
.order_headR_tab_font div:nth-child(1){
    color: #0066C0;
}

.ausste_right_headII_endButton button:hover{
    background-color: rgb(236,238,240);
}

.ausste_right_headII_endButton button:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
