.kc14_header-nav {
  background: #f4f4f4;
  margin: 0 auto;
  padding: 10px 0;
}
.kc14_header-nav ul {
  display: flex;
  justify-content: center;
}
.kc14_header-nav ul li {
  position: relative;
  padding: 0 10px;
  font-size: 14px;
}
.kc14_header-nav ul li span {
  color: #cc6600;
  font-weight: bold;
  display: inline-block;
  padding: 0 4px;
}
.kc14_header-nav ul li:not(:last-of-type) {
  /* margin-right: 6px; */
}
.kc14_header-nav ul li:not(:last-of-type)::before {
  content: "";
  width: 1px;
  height: 16px;
  background-color: black;
  position: absolute;
  top: 4px;
  right: 0;
}
.kc14_header-nav ul li:not(:last-of-type) a {
  color: #003399;
}
.kc14_header-nav ul li a {
  font-weight: bold;
}
.kc14_header-nav ul li:last-of-type a {
  color: black;
  text-decoration: none;
}
.kc14_content {
  padding: 0 50px;
  margin-top: 20px;
}
.kc14_content > h2 {
  /* font-weight: 400; */
  color: #cc6600;
  font-family: "仿宋";
}
.kc14_content > ul {
  display: flex;
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}
.kc14_content > ul li {
  width: 120px;
  position: relative;
}
.kc14_content > ul li:first-of-type a {
  color: #003399;
}
.kc14_content > ul li:last-of-type a {
  color: #000000;
  text-decoration: none;
}
.kc14_content > ul li:first-of-type::before {
  content: "";
  width: 2px;
  height: 14px;
  background-color: #000;
  position: absolute;
  top: 4px;
  right: 16px;
}
.kc14_c-con {
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 16px;
  font-family: "宋体";
}
.kc14_c-con h3 {
  font-size: 14px;
  background: #e6e6e6;
  padding: 6px;
  font-family: "黑体";
  border-bottom: 2px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kc14_c-con h3 button {
  border-radius: 8px;
  background: #e6e6c2;
  border: 1px solid rgba(0, 0, );
  padding: 2px 8px;
  color: #001d58;
  font-weight: bold;
  cursor: pointer;
}
.kc14_c-con > div {
  background: #f4f4f4;
  padding: 6px;
  /* font-weight: bold; */
  font-size: 12px;
}
.kc14_c-con div p a {
  color: #003399;
  /* font-weight: bold; */
}
.kc14_c-con div p:nth-of-type(2) {
  margin-top: 10px;
}
.kc14_c-con > div > button {
  display: block;
  margin: 10px auto;
  border-radius: 8px;
  border: 1px solid rgba(0, 0,);
  background: #e6e6c2;
  padding: 2px 8px;
  color: #000066;
  font-weight: bold;
  cursor: pointer;
}
.kc14_c-con > ul li {
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  height: 36px;
  line-height: 36px;
}
.kc14_c-con > ul li a {
  color: #003399;
}
.kc14_c-con > ul li:nth-of-type(1) {
  background: #e6e6e6;
  padding-left: 6px;
}
.kc14_c-con > ul li:nth-of-type(2) {
  display: flex;
}
.kc14_c-con > ul li:nth-of-type(2),
.kc14_c-con > ul li:nth-of-type(3) {
  background: #f4f4f4;
}
.kc14_c-con > ul li:nth-of-type(2) div {
  width: 50%;
  padding: 0 6px;
}
.kc14_c-con > ul li:nth-of-type(2) div:first-of-type {
  text-align: right;
  border-right: 1px solid #dedede;
  font-weight: bold;
  line-height: 30px;
  font-size: 12px;
}
.kc14_c-con > ul li:nth-of-type(2) div button {
  padding: 0 4px;
  height: 26px;
  line-height: 26px;
}
.kc14_c-con > ul li:nth-of-type(3) button {
  display: block;
  margin: 0 auto;
  transform: translateY(6px);
  background-image: linear-gradient(rgb(247, 247, 230), rgb(223, 171, 47));
  color: rgb(13, 13, 107);
  border: 1px solid rgb(143, 143, 109);
  border-radius: 8px;
  padding: 2px 8px;
  color: #001d58;
  font-weight: bold;
  height: 26px;
  line-height: 0;
}
.kc14_c-con > p {
  font-size: 12px;
  background: #f4f4f4;
  text-align: center;
  padding: 20px 0;
}
/* .kc14_f-content{
	padding: 0 50px;
}
.kc14_f-content ul{
	display: flex;
	margin-top: 20px;
}
.kc14_f-content ul li{
	margin-right: 20px;
	color: #32585d;
}
.kc14_f-content ul li:not(:nth-of-type(3)){
	position: relative;
}
.kc14_f-content ul li:not(:nth-of-type(3))::before{
	position: absolute;
	content: "";
	width: 1px;
	height: 18px;
	background-color: #ccc;
	position: absolute;
	top: 2px;
	right: -10px;
} */
