.end11_title{
	margin: 20px 45px;
}
.end11_title_h2{
	font-size: 36px;
	font-weight: 400;
	letter-spacing: 4px;
	color: #295f66;
}
.end11_title_p1{
	font-size: 12px;
	color: #295f66;
}
.end11_title_p1 span{
	color: #4ea4b1;
}
.end11_paging{
	position: relative;
	height: 35px;
}
.end11_pagbox{
	position: absolute;
	right: 0px;
	height: 35px;
}
.end11_pagbox select{
	appearance: auto;
	width: 260px;
	height: 35px;
	border: 1px solid #ccc;
	padding-left: 3px;
	margin-right: 30px;
	color: #06393E;
}
.end11_tab{
	margin-top: 5px;
}
.end11_tab_1{
	
}
.end11_tab_1>li{
	padding-left: 40px;
	height: 40px;
	line-height: 40px;
	border-top: 1px solid #ccc;
	font-size: 12px;
	color: #06393E;
}
.end11_tab_1>li:nth-of-type(1){
	font-weight: 700;
	font-size: 13px;
}
.end11_tab_1_1{
	display: flex;
	color: #46a1ad;
}
.end11_tab_1_1>li{
	position: relative;
}
.end11_tab_1_1>li:nth-of-type(1){
	width: 85%;
}
.end11_tab_1_1>li:nth-of-type(2){
	width: 10%;
}
.end11_li1>li:nth-of-type(2)::before{
	content: "|";
	position: absolute;
	left: -15px;
	color: #ccc;
}
.end11_tab_1_1>li:nth-of-type(3){
	width: 5%;
}
.end11_li1>li:nth-of-type(3)::before{
	content: "|";
	position: absolute;
	left: -15px;
	color: #ccc;
}
.end11_jump{
	border-top: 1px solid #ccc;
	background-color: #F9FAFC;
	height: 100px;
	line-height: 100px;
	position: relative;
}
.end11_jump_1{
	margin-left: 50px;
	font-size: 12px;
	display: flex;
	align-items: center;
}
.end11_jump_1 input{
	width: 35px;
	height: 25px;
	padding-left: 5px;
	vertical-align: text-bottom;
}
.end11_jump_1 span{
	margin: 0 15px;
}
.end11_jump_1 button{
	width: 70px;
	height: 35px;
	border: 1px solid #ccc;
	border-radius: 5px;
    background-color: #E2ECED;
    line-height: 0 !important;
}
.end11_jump_2{
	position: absolute;
	right: 50px;
	bottom: 0;
	display: flex;
	font-size: 12px;
	font-weight: 600;
}
.end11_jump_2 svg{
	vertical-align: middle;
}
.end11_jump_2 ul{
	display: flex;
	margin: 0 10px;
}
.end11_jump_2 ul li{
	margin: 0 10px;
}