.header {
  background-color: #f3f3f3;
  cursor: pointer;
}

.header:hover {
  background-color: #e9e9e9;
}

.closeIcon {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.closeIcon.close {
  transform: rotate(-180deg);
}
