
.taxationInfoBox .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #C45500;
    border: 1px solid #C45500;
}
.taxationInfoBox .bottomDesc{
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}
.taxationInfoBox .topTitle{
    font-size: 24px;
}
.taxationInfoBox .selectBox
.taxationInfoBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}
.taxationInfoBox .a-spacing-top-large {
    margin-top: 24px!important;
}
.taxationInfoBox .ant-steps{
    margin: 20px auto 50px;
    width: 70%;
}
.CommentatorBox .mybody-ul li:nth-of-type(3){
    border-bottom: 0;
}
.taxationInfoBox .program-overview-values {
    padding: 20px;
    margin: 0;
    width: 65%;
}
.taxationInfoBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table!important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}
.taxationInfoBox .ng-scope{
    text-align: center;
}
.taxationInfoBox .a-size-medium {
    font-size: 17px!important;
    line-height: 1.255!important;
}
.taxationInfoBox .a-size-small {
    font-size: 12px!important;
    line-height: 1.5!important;
}
.taxationInfoBox .eCkQDX{
    display: none;
}
.taxationInfoBox .jXxwsB span.icon,
.taxationInfoBox .jXxwsB .websit.ant-select,
.taxationInfoBox .jXxwsB .language.ant-select .ant-select-selector,
.taxationInfoBox .jXxwsB .ant-select-arrow,
.taxationInfoBox .jXxwsB .search,
.taxationInfoBox .jXxwsB .language.ant-select,
.taxationInfoBox .jXxwsB .ilQBwr button{
    display: none;
}
.taxationInfoBox .hoQOMo>div{
    display: block;
}
.taxationInfoBox .jXxwsB .ilQBwr button:last-child{
    display: inline-block;
}
.taxationInfoBox .jXxwsB .ilQBwr{
    text-align: right;
}
.taxationInfoBox .CompanyAddress{
    width: 90%;
    margin:0;
}
.taxationInfoBox .selectBox{
    width: 49%;
    height: 35px;
}
.taxationInfoBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;

}
.taxationInfoBox .rightCollapse{
    width: 35%;
}
.taxationInfoBox .rightCollapse>p{
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}
.taxationInfoBox .inpBox{
    margin-top: 15px;
}
.taxationInfoBox .inpBox1 input{
    padding-left: 5px;
    width: 100%;
    height: 35px;
}
.taxationInfoBox .inpBox input:nth-child(2){
    margin-left: 2%;
}
.taxationInfoBox .aCheckbox{
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.taxationInfoBox .aCheckbox span{
    margin-left: 5px;
}
.taxationInfoBox .receivingMode{
    margin-top: 20px;
}
.taxationInfoBox .receivingMode p{
    font-weight: bold;
    margin-bottom: 0;
}
.taxationInfoBox .yanzPhone{
    display: flex;
    border: 1px solid #d9d9d9;
}
.taxationInfoBox .yanzPhone select,
.taxationInfoBox .yanzPhone .ant-input-affix-wrapper{
    border: none;
}
.taxationInfoBox .inpBox .next{
    width: 49%;
    margin-left: 2%;
    height: 35px;
}
.taxationInfoBox .representative input+input{
    margin-left: 2%;
}
.taxationInfoBox .billTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.taxationInfoBox .billTitle div p{
    font-size: 16px;
    font-weight: bold;
}
.taxationInfoBox .billTitle div p:first-child{
    font-size: 22px;
}
.taxationInfoBox .billTitle div:last-child{
    font-size: 14px;
    color: rgb(165, 126, 192);
}
.taxationInfoBox .inpBox1{
    display: flex;
}
.taxationInfoBox .inpBox1 p{
    font-weight: bold;
}
.taxationInfoBox .inpBox1 .dates{
    margin-left: 2%;
    width: 66%;
}
.taxationInfoBox .inpBox1 .dates>div{
    display: flex;
}
.taxationInfoBox .inpBox1 .dates select{
    height: 35px;
    width: 40%;
}
.taxationInfoBox .inpBox1 .dates span{
    margin: 0 10%;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
}
.taxationInfoBox .bottomDesc .ant-radio-wrapper{
    word-wrap: break-word !important;
    word-break: normal;
    white-space:pre-wrap
}
.taxationInfoBox .depositInfo{
    width: 49%;
    margin-bottom: 15px;
}
.taxationInfoBox .depositInfo:nth-child(2){
    margin-left: 2%;
}
.taxationInfoBox .depositInfo select,
.taxationInfoBox .depositInfo input{
    width: 100%;
}
.taxationInfoBox .operationBtn{
    display: flex;
    justify-content: center;
}
.taxationInfoBox .operationBtn .next{
    width: 30%;
}
.taxationInfoBox .previousPage{
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}
.taxationInfoBox .btnClass{
    display: flex;
    margin-top: 20px;
}
.taxationInfoBox .btnClass span{
    display: block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #00000050;
    margin-right: 15px;
    text-align: center;
    cursor: pointer;
}
.taxationInfoBox .nsInfoTitle{
    margin-top: 40px;
}
.taxationInfoBox .nsInfoBox{
    border-radius: 5px;
    border: 1px solid #00000030;
    padding: 30px;
}
.taxationInfoBox .selectClass{
    background-color: #fef8f2;
    border: 1px solid #e77b09;
}
.taxationInfoBox .benefit .ant-select{
    width: 100%;
}
.taxationInfoBox h1{
    padding-left: 0;
}
.taxationInfoBox hr{
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 10px;
    margin-bottom: 14px;
}