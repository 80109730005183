.goodsImg {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.goodsInfo {
  margin-left: 12px;
}

.goodsName {
  padding: 0;
  line-height: 18px;
  font-size: 14px;
  color: #0066c0;
  height: auto;
  margin-bottom: 6px;
  cursor: pointer;
}

.goodsName:hover {
  color: #c45500;
  text-decoration: underline;
}

.priceRow {
  height: 18px;
  margin-bottom: 6px;
}

.priceRow > ul > :global(li.ant-rate-star) {
  font-size: 10px;
  margin-right: 2px;
}

.line {
  display: inline-block;
  height: 65%;
  width: 1px;
  background-color: #ccc;
  margin: 0 6px;
}

.codeRow {
  height: 16px;
  font-size: 12px;
  line-height: 16px;
}
