.a-button.ant-btn:not(.ant-btn-link):not([disabled]) {
  border-color: #d9d9d9;
  background: #fff;
}
.a-button.ant-btn:not(.ant-btn-link):not([disabled]):hover,
.a-button.ant-btn:not(.ant-btn-link):not([disabled]):focus {
  color: #000;
  background: #f9f9f9;
  border-color: #d9d9d9;
}

.a-button.ant-btn {
  font-weight: normal;
}

.a-button.ant-btn.ant-btn-primary:not([disabled]) {
  background-color: #f6bd41;
  border-color: #ccc;
  color: #000;
  font-weight: normal;
}

.a-button.ant-btn.ant-btn-primary:not([disabled]):hover,
.a-button.ant-btn.ant-btn-primary:not([disabled]):focus,
.a-button.ant-btn.ant-btn-primary:not([disabled]):active {
  color: #000;
  background: #ebb43f;
}

/* .a-button.ant-btn.ant-btn-link {
  color: #1890ff;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
} */
