
.Affirm_body{
    padding: 0 13px;
}
.Affirm_body_titel span{
    color: #0066c6;
    
}
.Affirm_boxI{
    height: 131px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.Affirm_boxI_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F3;
    height: 40px;
    width: 100%;
    padding: 0 10px;
}
.Affirm_boxI_head_titel{
    font-size: 16px;
}
.Affirm_boxI_head_icon{
    transform: rotate(180deg);
    
}
.Affirm_boxI_head_icon span svg{
    width: 1.4rem;
    height: 1.4rem;
}
.Affirm_boxI_content{
    padding: 0 10px;
}
.Affirm_boxI_content span{
    color: #0066c6;
}
.Affirm_boxI_Btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.Affirm_boxI_Btn button{
    padding: 2px 8px;
    margin-left: 62px;
}

.Affirm_boxII_btn{
    text-align: center;
    margin-top: 20px;
}
.Affirm_boxII_btnI button{
    padding: 0 10px;
    background: #E8E8E8;
    border: 1px solid #000000;
}
.Affirm_boxII_btnII{
    margin-top: 10px;
}

.Affirm_boxII_btnII button{
    margin: 0 0 0 62px;
    padding: 4px 10px; 
    background: #F1C65B;
    border: 1px solid #000000;
}
.Affirm_boxII{
    height: 151px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.Affirm_boxIII{
}
.Affirm_boxIII_head{
    background: #E6E6E6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 10px;
    border: 1px solid #dddddd;

}
.Affirm_boxIII_head div{
    font-size: 16px;

}
.Affirm_boxIII_head button{
    font-size: 12px;
    padding: 4px 6px;

}
.Affirm_boxIII_content{
    margin-top: 10px;
    padding: 0 10px;
}
.Affirm_boxIII_content div span{
    color: #0066c6;
}

.Affirm_boxIV_head{
    display: flex;
    align-items: center;
    background: #F3F3F3;
    margin-top: 20px;
    height: 30px;
    padding: 0 10px
}

.Affirm_boxIV_head_date{
    width: 18%;
}
.Affirm_boxIV_head_id{
    width: 10%;
}
.Affirm_boxIV_head_state{
    width: 42%;
}

.Affirm_boxIV_content{
    display: flex;
    align-items: center;
    padding: 0 10px;
}
