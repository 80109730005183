

.RepertoryTable1-HeadFont{
    display: flex;
}
.RepertoryTable1-HeadFont span{
    padding-top: 25px;
}
.RepertoryTable1-head-font{
    font-size: xx-large;
}
.RepertoryTable1-head-link a{
    margin: 0 10px;
}

.RepertoryTable1-count{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between ;
}
.RepertoryTable1 .ant-col-5 {
    flex:none !important;
    max-width:none !important;
}

.RepertoryTable1-count-box{
    width: 23%;
    height: 121px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 20px;
    padding: 7px;
}
.RepertoryTable1-box1-head{
    font-size: 18px;
}
.RepertoryTable1-box1-warn{
    width: 100%;
    height: 47px;
    border: 2px solid #dddddd;
    margin: 12px 0  2px 0;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #e9eded;
}
.RepertoryTable1-box1-nav{
    display: flex;
    align-items: center;
    justify-content: center;
}
.RepertoryTable1-box1-line{
    width: 310px;
    height: 10px;
    border: 1px solid #dddddd;
    background: repeating-linear-gradient(90deg, #f4a8ce 0%, #f4a8ce 60%, green 0%, green 100%);

    margin: 0   7px;
}
.RepertoryTable1-Box{
    width: 100%;
    margin-top: 20px;
    display: flex;
   justify-content: space-between;
}
.RepertoryTable1-BoxNav{
        width: 900px;
        height: 253px;
        border: 1px solid #dddddd;
        margin: 0 20px 0 0;
        padding: 10px;
}
.RepertoryTable1-BoxP{
    padding-bottom: 50px;
}

.RepertoryTable1-box-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

}
.RepertoryTable1-box-head2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-size: 12px;
    margin-top: 12px;
}
.RepertoryTable1-box2-head div:nth-child(2){
    font-size: 24px;
    color: #000000;
}
.RepertoryTable1-box2-bottom{
    margin-top: 25px;
}
.RepertoryTable1-BoxNav-head{
    width: 100%;
    height: 66px;
    border-bottom: 1px solid #e7e7e7;
}
.RepertoryTable1-BoxNav-head div:nth-child(1){
    font-size: 18px;
}
.RepertoryTable1-box-head button{
    width: 81px;
    height: 22px;
    font-size: 12px;
}
.RepertoryTable1-box-head2 button{
    width: 200px;
    height: 22px;
    font-size: 12px;
}
.RepertoryTable1-progress{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    height: 135px;
}
.RepertoryTable1-progress-per{
    padding-top: 22px;
    color: #000000;
}
.RepertoryTable1-progress-per1{
    width: 350px;
    height: 10px;
    border: 1px solid #000000;
    background: #000;
}
.RepertoryTable1-progress-per2{
    margin-top: 12px;
    width: 350px;
    height: 10px;
    border: 1px solid #e7e7e7;
    background: #e4e4e4;
    border-left: 1px solid #000000;
}
.RepertoryTable1-progress-per progress{
    color: #000000;
    width: 120px;
}
.RepertoryTable1-BoxNav-bottom{
    margin-top:10px ;
}
.RepertoryTable1-box-remind{
    color: #dddddd;
}
.RepertoryTable1-box1-font{
    margin: 0 7px;
}
.RepertoryTable1-box2-bottom span{
    font-size: 15px;
}
