.Replenish-titel {
  display: flex;
  justify-content: left;
  align-items: center;
}
.Replenish-theme {
  font-size: 26px;
  margin-right: 16px;
}
.Replenish-link {
  margin-top: 12px;
}
.Replenish-link a{
 color:#008296 ;
}
.Replenish-link span{
  margin:0 12px;
  font-weight: bold;
}
.Replenish-warn {
  width: 100%;
  height: 77px;
  border: 1px solid #00a484;
  border-radius: 5px;
  background: #ebf8fa;
  padding: 15px;
  position: relative;
  margin-bottom: 20px;
}
.Replenish-warnTitel {
  display: flex;
  justify-content: left;
  align-items: center;
}
.Replenish-warnTitel-font {
  font-size: 15px;
  display: flex;
}
.Replenish-warnTitel-font strong{
  font-size: 16px;
}
.Replenish-warnTitel svg{
  height: 1.3rem;
  width: 1.3rem;
}
.Replenish-warnTitel-font strong span {
  position: absolute;
  right: 1%;
  top: 22%;
}
.Replenish-warnFont {
  margin: 0 8px;
}
.Replenish-warnNav {
  margin-left: 35px;
  font-size: 12px;
}
.Replenish-inp {
  display: flex;
  justify-content: left;
  align-items: center;
}
.Replenish-select {
  height: 31px;
  outline: none;
  border: 1px solid #d0d0d0;
  font-size: 12px;
  margin-left: 0 !important;
}
.Replenish-inp-SKU {
  border: 1px solid #dddddd;
  width: 190px;
  height: 30px;
  margin-left: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Replenish-inpFont {
  margin: 0 10px;
}
.Replenish-inp-SKU input {
  outline: none;
  border: none;
  width: 173px;
}
.Replenish-inpBtn {
  width: 50px;
  height: 31px;
  background: #686e76;
  color: #fff;
  margin: 0 10px;
  border-radius: 4px;
}
.Replenish-select2 {
  height: 40px;
  outline: none;
  border: 1px solid #d0d0d0;
  font-size: 12px;
  border-bottom: none;
  border-radius: 0;
  margin: 0 10px;
 
}
.Replenish_select_37{
  display: flex;
  line-height:45px ;
}
.Replenish_select_37 div{
  line-height: 45px;
}
.Replenish-screen {
  width: 100%;
  height: 85px;
  border: 1px solid #dddddd;
  margin-top: 0px!important;
}
.Replenish-screenTop {
  height: 43px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}
.Replenish-screen-condition {
  margin: 0 10px;
  color: #dddddd;
}
.Replenish-screen-advise {
  padding: 0 10px;
  border-right: 1px solid #dddddd;
}
.Replenish-screenTop button {
  margin: 0 10px;
  background: #E9EBED;
  outline-style: none;
  border: 1px solid #898989;
  padding: 4px;
}
.Replenish-screenBottom {
  height: 43px;
  display: flex;
  justify-content: left;
  align-items: center;
  background: #f3f3f3;
}
.Replenish-screenBottom-font {
  color: #898989;
  margin-left: 10px;
}
.Replenish-label {
  margin: 0 10px;
}
.Replenish-label select {
  border: none;
  background: #f3f3f3;
}
.Replenish-label2 {
  padding-right: 14px;
  border-left: 2px solid #dddddd;
  border-right: 2px solid #dddddd;
}
.Replenish-label2 select {
  border: none;
  background: #f3f3f3;
}
.Replenish-label3 {
  padding-right: 14px;
}
.Replenish-label3 select {
  border: none;
  background: #f3f3f3;
}
.Replenish-inventory {
  width: 100%;
  border: 1px solid #f3f3f3;
  margin-top: 8px;
  margin-bottom: 217px;
}
.Replenish-inventory-head {
  height: 80px;
  width: 100%;
  display: flex;
  padding: 10px;
  background: #f3f3f3;
  font-weight: bold;
}
.Replenish-inventory-head div{
  font-size: 14px;
}
.Replenish-inventory-beforeBox{
  width: 131px;
  display: flex;
}
.Replenish-inventory-before {
  width:131px ;
}
.Replenish-inventory-before span {
  font-size: xx-small;
}
.Replenish-inventory-after {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.Replenish-commodity-beforeBox{
  display: flex;
  align-items: center;
  width: 50%;
}
.Replenish-commodity{
    width: 100%;
    height: 117px;
    border-bottom: 1px solid #ddd;
}
.Replenish-commodityTag{
    width: 165px;
    height: 30px;
    background: linear-gradient(114deg, #00a4b4 92%, transparent 8%);
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 10px;
}
.Replenish-commodityTag span{
    margin-left: 30px;
    font-size: 19px;
}
.Replenish-commodityList{
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.Replenish-shopName{
  width: 680px;
}
.Replenish-shopName div:nth-child(1){
    color: #008296;
    width: 100%;
    overflow: hidden;
}
.Replenish-unassigned{
  width: 130px;
}
.Replenish-commodityImg {
  width: 66px;
  height: 74px;
  margin-right: 34px;
}
.Replenish-commodityImg img{
  width: 100%;
  height: 100%;
}
.ant-tabs ant-tabs-top{
  width: 100%;
}
.ant-tabs-tabpane{
  width: 100%;
}
.ant-tabs{
  width: 100%;
}
.Replenish-commodityCheck{
  margin-right: 20px;
}
.Replenish-commoditySKU{
  margin-right:30px;
}
.Replenish-commodity-after{

  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Replenish-situation{
  /* margin-left: 20px; */
  width:100px ;
}
.Replenish-price{
  width: 90px;
}
.Replenish-stock{
  width: 130px;
}
.Replenish-stock div:nth-child(1){
    margin-left: 10px;
}
.Replenish-stock div:nth-child(2){
  padding-left: 6px;
}
.Replenish-stock div:nth-child(3){
  margin-left: 10px;
}
.Replenish-stock div:nth-child(4){
  margin-left: 10px;
  color:#c40000 ;
}
.Replenish-stock select{
  margin-left: 0 !important;
  background: #ffffff;
  outline: none;
  border: none;
}
.Replenish-listProgress{
  width: 83px;
  height: 12px;
  border-left: 3px solid black;
  display: flex;
  justify-content: left;
  align-items: center;
}
.Replenish-listProgress div{
  width: 100%;
  height: 6px;
  background: repeating-linear-gradient(90deg, green 0%,green 60%, red 0%, red 100%);
  margin: 0 !important;
}
.Replenish-amount{
  width: 120px;
  height: 100px;
  margin-left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.Replenish-shipments{
  width: 105px;
  display: flex;
  align-items: center;
}
.Replenish-listSelect{
  width: 135px ;
  height: 30px;
  background: #eeeff2;
}
.Replenish-warnTitel-warnFont{
  font-size: 23px;
  height: 20px;
  margin-right: 12px;
}
.Replenish_warnBtn{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  padding-left: 90%;
}
.Replenish_warnBtn div{
  margin-right: 10px;
}

.Replenish_warnBtn div button{
  padding: 4px;
  border-top:0 ;
  border-bottom: 0;
  background: #F0F1F4;
  outline-style: none;
  position: relative;

}
.Replenish_warnBtn div button:active{
  top: 2px;
}