* {
	padding: 0;
	margin: 0;
}

.gg07_ASD_box {
	width: 100vw;
	display: flex;
	padding-left: 2%;
}

.gg07_sidebar {
	width: 50px;
	height: 100vh;
	position: relative;
	background-color: #232F3E;
}

.gg07_sidebar svg {
	display: block;
	margin: 18px auto 0;
}

.gg07_contentbox {
	width: 100%;
	border: 1px solid black;
}

.gg07_contentbox_title {
	width: 100%;
	height: 55px;
	padding: 15px 20px;
	display: flex;
	justify-content: space-between;
	background-color: #005A95;
	color: white;
	font-weight: 300;
	position: fixed;
	z-index: 9;
}
.gg07_contentbox_title_font{
	font-size: 15px;

}

.gg07_iconlist {
	display: flex;
	text-align: right;
}

.gg07_iconlist svg {
	margin-left: 20px;
}

.gg07_iconlist_p1 {
	font-size: 13px;
	font-weight: 500;
	position: relative;
	margin-right: 20px;
	color: #000000;
}

.gg07_iconlist_p1 svg {
	position: absolute;
	right: -17px;
	top: 5px;
}

.gg07_contentbox_location {
	padding-left: 20px;
	background-color: #F8F8F8;
	padding-bottom: 40px;
	position: relative;
	box-shadow: 1px 3px 1px rgba(0,0,0,0.1);
	margin-top: 3%;
}

.gg07_contentbox_location h2 {
	padding-left: 10px;
}

.gg07_location_sp {
	margin-top: 20px;
	font-size: 10px;
}

.gg07_location_sp span {
	margin-right: 20px;
}

.gg07_contentbox_operation {
	height: 700px;
	display: flex;
}

.gg07_operation_l {
	width: 220px;
	background-color: #F8F8F8;
}
.gg07_operation_l ul{
	width: 80%;
	margin: 20px auto 0;
	list-style: none;
}
.gg07_operation_l ul li:nth-of-type(1){
	background-color: #E6E8E9;
	font-weight: 800;
}
.gg07_operation_l ul li{
	padding: 8px 0 8px 10px;
	margin-bottom: 5px;
	font-size: 13px;
	border-radius: 3px;
}
.gg07_operation_r{
	width: 100%;
	padding: 20px;
}
.gg07_operation_r_p1{
	display: flex;
	justify-content:space-between;
}
.gg07_operation_r_p1 p{
	font-size: 12px;
	letter-spacing: 1px;
}
.gg07_operation_r_p1 svg{
	vertical-align: middle;
}
.gg07_zhijie{
	font-size: 13px;
	padding: 5px 0 5px 10px;
	background-color: #EBEBEB;
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 5px;
}
.gg07_zhijie svg{
	margin: 0 5px;
}
.gg07_yh{
	width: 25%;
}
.gg07_yj{
	width: 50%;
}
.gg07_cz{
	width: 25%;
}
.gg07_guanli{
	padding: 10px 5px;
	background-color: #F3F3F3;
	font-size: 13px;
	font-weight: 900;
}
.gg07_data{
	padding: 10px 5px;
	font-size: 10px;
}
.gg07_gl{
	color: #0066C0;
}
.gg_footr{
	width: 100%;
}
.gg_footr div{
	width: 560px;
	margin: 20px auto;
	font-size: 10px;
	color: rgba(0,0,0,0.4);
}
.gg_footr div span{
	color: black;
	margin-left: 20px;
}
.AccessSettings_box{
width: 100%;
display: flex;

}