
.SellingApplications_box{
    padding: 0 25px;
    margin-bottom: 300px;
}

.SellingApplications_titel{
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
}
.SellingApplications_choose{
    margin-top: 40px;

}

.SellingApplications_choose_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SellingApplications_choose p{
    margin: 0;
}

.SellingApplications_choose_content_left{
    width: 70%;
    display: flex;
    align-items: center;
}

.SellingApplications_choose_content_left button{
    padding: 3px 10px;
    margin: 0 4px;
    color: #888888;
    background: #F6F6F6;
    border: 1px solid #dddddd;
}
.SellingApplications_choose_content_left span{
    margin: 0 12px;
    color: #ddd;
}

.SellingApplications_choose_content_right{
    width: 50%;
    display: flex;
    align-items: center;

}
.SellingApplications_choose_content_right_font{
    font-size: 16px;
    margin-right: 8px;
}
.SellingApplications_choose_content_right_inp input{
    width: 205px;
    height: 28px;
    margin-right: 4px;
    border-radius: 3px;
    padding: 0 3px;
    outline: none;
}
.SellingApplications_choose_content_right_inp input:focus{
    box-shadow: 0px 0px 5px 1px #ff8c00, 0px 0 5px 1px #ff8c00;
    outline: none;
}
.SellingApplications_choose_content_right_inp button{
    width: 115px;
    height: 28px;
    color: #ffffff;
    background: #515961;
    border-radius: 3px;
}
    .SellingApplications_list_header{
        display: flex;
        align-items: center;
        background: #F2F2F2;
        border-radius: 2px;
        padding: 10px 50px;
        margin: 20px 0 7px 0;
        border: 1px solid #ddd;
    }
    .SellingApplications_list_name{
        width: 46%;
    }
    .SellingApplications_list_type{
        width: 10%;
    }
    .SellingApplications_list_change{
        width: 15%;
        color: #ff8c00;
    }

  
    .SellingApplications_list_state{
        width: 20%;
    }
    .SellingApplications_list_content{
        border-radius: 2px;
    }
    .SellingApplications_list_product{
        height: 41px;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        border: 1px solid #ddd;
        padding: 0px 50px;
    }
    .SellingApplications_list_btn{
        width: 158px;
    }
    .SellingApplications_list_btn button{
        width: 100%;
        padding: 2px 0;
    }
    .SellingApplications_pageBtn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .SellingApplications_pageBtn{
        margin-top: 10px;
    }
    .SellingApplications_pageBtn button{
        width: 30px ;
        height: 30px;
        margin: 0 3px;
    }
.SellingApplications_pageBtn_next{
    margin-right: 10px;
}

    .SellingApplications_pageBtn_down button{
        width: 90px;
        height: 30px;
    }