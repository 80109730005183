.adminV-body{
    width: 100%;
    padding: 0 8%;

}
.adminV-titel{
    height: 71px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.adminV-title-help{
    display: flex;
    justify-content: center;
    align-items: center;
}
.adminV-title-helpButton{
    width: 170px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #008296;
    color: #ffffff;
}

.adminV-nav-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.adminV-top-inp{
    display: flex;
}
.adminV-top-inp svg{
    width: 2rem;
    height: 2rem;
}
.adminV-label{
    width: 68px;
    height: 36px;
    margin: 0 !important;
    border-radius: 0;
    border: 1px solid #a2a6ac;
}
.adminV-top-inp div:nth-child(2) input {
    height: 36px;
    width: 282px;
    border: 1px solid #dddddd;
}
.adminV-top-inp  button {
    width: 40px;
    height: 36px;
    background: #aab7b8;
    border: 0;
    color: #ffffff;
    font-size: 20px;
}
.adminV-top-page{
    display: flex;
    font-size: 20px;
}
.adminV-top-page div:nth-child(2){
    margin: 0 20px ;
}
.adminV-navList-box{
    width: 100%;
    border-bottom: 1px solid #dddddd;
}
.adminV-navList{
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.adminV-navList div{
    width: 25%;
    font-weight: bold;
}
.adminV-navShop{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}
.adminV-navShop-befor{
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

}
.adminV-navShop-befor div:nth-child(1){ 
    height: 67px;
}
.adminV-navShop-befor div{
    width: 25%;
    display: flex;
    align-items: center;
}
.adminV-navShop-after{
    width: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
.adminV-navShop-after button{
    background: #e3edec;
    width: 74px;
    height: 40px;
    border: 0;
    border-radius: 4px;
    box-shadow: 2px 2px 2px #dddddd;
}
.adminV-bottom{
   padding-bottom: 55px;
    text-align: center;
}
.adminV-bottom div{
    margin: 20px 0;
}
.adminV-bottom button{
    width: 168px;
    height: 36px;
    color: #ffffff;
    background: #008296;
    border: 0 ;
    margin-top: 40px;
    outline-style: none;
}
.adminV-footer{
    border-top: 1px solid #dddddd;
}

.adminV-footer footer{
    border: 0;
}