

.activity_navBox{
    width: 100%;
    height: 30px;
    background: #f2f2f2;
    line-height: 30px;
    border: 1px solid #dddddd;
}

.activity_navBoxII{
    padding-right: 16%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-weight: bold;
    padding-left: 10px;
}