.cardList {
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 20px;
}

.cardItem {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  height: 86px;
}

.cardItem:hover {
  background-color: #fafafa;
}

.cardItem .closeIcon {
  color: #999;
  position: absolute;
  top: 12px;
  right: 12px;
}

.cardItem .closeIcon:hover {
  color: #333;
}

.addButton {
  background-color: #fff;
  cursor: pointer;
  border: 1px dashed #333;
  height: 86px;
}

.graph {
  height: 340px;
  /* min-width: 950px; */
}