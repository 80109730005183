.automate-pricing * {
	margin: 0;
	padding: 0;
	font-size: 12px;
}

ul li {
	list-style: none;
}

/*头部*/
.qd_15_myhead {
	width: 100%;
	border-bottom: 2px solid #dddddd;
	padding: 10px 0 0;
	overflow: hidden;
}

.qd_15_head_l {
	display: flex;
	float: left;
	margin-left: 50px;
	font-size: 14px;
	line-height: 25px;
}

.qd_15_head_l li {
	color: #0f3b42;
	padding: 0 10px;
}

.qd_15_headr-r {
	float: right;
	display: flex;
	overflow: hidden;
	margin-right: 50px;
}

.qd_15_headr-r span {
	font-size: 12px;
	font-weight: 600;
	color: #4a4a4a;
	margin: 5px 5px 0;
}

.qd_15_flag {
	height: 25px;
	margin: 2px 5px 0;
}

.qd_15_headr-r select {
	height: 25px;
	border: 1px solid #CCC;
	border-radius: 3px;
	padding: 0 50px 0 10px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	/* background: url(.qd_15_.qd_15_/img/下拉1.qd_15_png) center no-repeat #qd_15_fff; */
	background-position: 95% 50%;
	background-size: 10px;
	margin-right: 20px;
}

.qd_15_headr-r input {
	height: 25px;
	padding: 0 10px;
	border: 1px solid #CCC;
	border-radius: 3px 0 0 3px;
	box-sizing: border-box;
	margin-right: 1px;
}

.qd_15_headr-r button {
	width: 25px;
	height: 25px;
	border: 1px solid #CBCBCB;
	border-left: none;
	background-color: #CCC;
	border-radius: 0 3px 3px 0;
	line-height: 25px;
	margin-right: 5px;
}

.qd_15_headr-r button img {
	width: 13px;
}

.qd_15_helpbox {
	height: 25px;
	display: flex;
	align-items: flex-end;
}

.qd_15_help {
	height: 20px;
	display: flex;
	overflow: hidden;
}

.qd_15_help li {
	font-size: 14px;
	font-weight: bold;
	color: #5C5C5C;
	padding: 0 5px;
	border-right: 1px solid #CCC;
}

.qd_15_help li:last-of-type {
	border: none;
}

/* 头部下面UL */
.qd_15_box1 {
	height: 30px;
	line-height: 30px;
	text-align: center;
	padding: 5px 0;
	font-size: 14px;
	font-weight: bold;
	background-color: #F4F4F4;
	display: flex;
	justify-content: center;
}

.qd_15_box1 a {
	color: #003399;
}

.qd_15_box1-ul {
	display: flex;
	align-items: center;
}

.qd_15_box1-ul li {
	padding: 0 10px;
	color: #CC6600;
	position: relative;
}

.qd_15_box1-ul li::after {
	content: "";
	width: 2px;
	height: 15px;
	background-color: #5d0000;
	position: absolute;
	right: 0;
	top: 8px;
}

.qd_15_box1-ul li:last-of-type::after {
	display: none;
}

.qd_15_box1-ul li:nth-of-type(2) a {
	color: #000000;
	text-decoration: none;

}

/*提示*/
.qd_15_fanhui {
	min-width: 1650px;
	padding: 0px 20px;
	margin: 0 50px 0 40px;
	margin-top: 10px;
	background: #F6F6F6;
	padding: 15px;
	border: 2px solid #C2D7E1;
	border-radius: 5px;
}

.qd_15_fanhui>img {
	width: 30px;
	vertical-align: middle;
}

.qd_15_fanhui>span {
	margin-left: 20px;
	font-size: 12px;
}

.qd_15_fanhui>span>span {
	color: #064F93;
}


.qd_15_tab {
	min-width: 1650px;
	padding: 0px 20px;
	margin: 0 20px;
}

.qd_15_tabList {
	display: flex;
	justify-content: space-between;
	color: #1E78C7;
	font-weight: 600;
	margin: 10px 0;
	border-bottom: 2px solid rgba(0, 0, .0, 0.3);
}

.qd_15_tabList>ul {
	height: 43px;
	list-style: none;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 10;
	font-size: 15px;
}

.qd_15_tabList>ul li {
	padding: 10px 20px;
	box-sizing: border-box;
}

.qd_15_tabList>ul li span {
	font-size: 10px;
	font-weight: 500;
	color: #FFA928 !important;
	margin-left: 5px;
}

.qd_15_guanli {
	height: 45px;
	line-height: 35px;
	padding: 0 20px !important;
	color: rgba(0, 0, .0, 0.5);
	border: 2px solid rgba(0, 0, .0, 0.3);
	border-top: 3px solidrgba(0, 0, .0, 0.3);
	border-bottom: 3px solid white;
	position: relative;
	z-index: 99;
}

.qd_15_tabList>div {
	font-size: 15px;
	line-height: 43px;
}

.qd_15_lllff {
	display: flex;
	justify-content: space-between;
}

.qd_15_rongyutit {
	list-style: none;
	display: flex;
	position: relative;
	margin-top: 20px;
}

.qd_15_rongyutit li>h2 {
	font-size: 28px;
	font-weight: 400;
	color: #DB8800;
}

.qd_15_rongyutit li:nth-of-type(2) {
	font-size: 14px;
	font-weight: 600;
	height: 40px;
	line-height: 55px;
	margin-left: 10px;
	color: #4891D2;
}

.qd_15_rongyutit li:nth-of-type(2) span {
	font-size: 10px;
	margin: 0 5px;
	vertical-align: top;
	color: black !important;
}

.qd_15_lllff p {
	font-size: 14px;
	font-weight: 600;
	height: 40px;
	line-height: 55px;
	margin-left: 10px;
	color: #4891D2;
}

.qd_15_lllff p>span:nth-of-type(1) {
	padding: 0 5px;
	border: 1px solid rgba(0, 0, .0, 0.5);
	border-radius: 5px;
	background: #E6E6C2;
	color: #000066;
	margin-right: 10px;
	cursor: pointer;
}

.qd_15_lllff p>span:nth-of-type(2) {
	padding: 0 5px;
	border: 1px solid rgba(0, 0, .0, 0.5);
	border-radius: 5px;
	background: #FFC53A;
	color: #221A5E;
	margin-right: 10px;
	cursor: pointer;
}

.qd_15_rongyu>p {
	color: #AAAAAA;
}

.qd_15_rongyu>p>span {
	color: #2E81CB !important;
}

.qd_15_tuijie {
	margin: 20px 0 0;
	font-size: 14px;
}

.qd_15_tuijie>span {
	font-size: 15px;
	font-weight: 600;
	margin: 0 10px 0 0;
	font-weight: 600;
}

.qd_15_tuijie>input {
	padding: 5px 10px;
	margin-left: 10px;
	margin-right: 5px;
	vertical-align: middle;
	font-weight: 600;
}

.qd_15_tuijie_r {
	float: right;
	position: relative;

}

.qd_15_tuijie_r img {
	width: 40px;
	vertical-align: middle;
}

.qd_15_tuijie_r>input {
	padding: 5px 5px;
}

.qd_15_tuijie_r p {
	margin-top: 5px;
	text-align: right;
	font-size: 14px;
}

.qd_15_tuijie_r p>span {
	color: #4E82B2;
}

.qd_15_shujuList {
	margin-top: 30px;
}

.qd_15_shujuList>ul {
	min-width: 1800px;
	width: 100%;
	list-style: none;
	display: flex;
	font-size: 14px;
}

.qd_15_shujuList>ul>li img {
	width: 10px;
}

.qd_15_shujuList>ul>li:nth-of-type(1) {
	width: 300px;
	margin-left: 100px;
}

.qd_15_shujuList>ul>li:nth-of-type(2) {
	width: 200px;
	margin: 0 0px 0 0px;
}

.qd_15_shujuList>ul>li:nth-of-type(3) {
	width: 200px;
	margin: 0 0 0 0px;
}

.qd_15_shujuList>ul>li:nth-of-type(4) {
	width: 200px;
	margin: 0 0 0 0px;
}

.qd_15_shujuList>ul>li:nth-of-type(5) {
	width: 50px;
	margin: 0 30px 0 0px;
	text-align: right;
}

.qd_15_shujuList>ul>li:nth-of-type(6) {
	width: 50px;
	margin: 0 30px 0 0px;
	text-align: right;
}

.qd_15_shujuList>ul>li:nth-of-type(7) {
	width: 50px;
	margin: 0 30px 0 0px;
	text-align: right;
}

.qd_15_shujuList>ul>li:nth-of-type(8) {
	width: 100px;
	margin: 0 0 0 0px;
}

.qd_15_headbor:nth-last-of-type(1) {
	border: none !important;
}

.qd_15_daosanjiao {
	transform: rotateX(180deg);
	vertical-align: middle;
	margin-bottom: 5px;
}

.qd_15_lan {
	font-size: 14px;
	color: #004B91;
}

.qd_15_chu {
	font-weight: 600;
}

.qd_15_headbor {
	padding: 10px 0;
	border-bottom: 2px solid rgba(0, 0, .0, 0.5);
}

.qd_15_xi {

	border-bottom: 1px solid rgba(0, 0, .0, 0.3);
}

.qd_15_zuihou {
	margin-left: 200px;
}

.qd_15_zuihou>div {
	padding: 0 70px;
	background-image: linear-gradient(#F7F7E6, #dfab2f);
	color: #0D0D6B;
	border: 1px solid #8F8F6D;
	border-radius: 5px;
	font-weight: 700;
}

.qd_15_zuihou {
	color: #CD6A06;
	font-weight: 600;
	cursor: pointer;
}

.qd_15_zuihou>p {
	margin: 5px 0;
	color: #0B5396;
	font-size: 12px;
}

.qd_15_black1 {
	color: black !important;
}

.qd_15_que {
	color: #D45252;
}

.qd_15_que span {
	color: #3E77AC !important;
	cursor: pointer;
}

.qd_15_zhuyi {
	font-size: 14px;
	margin-bottom: 100px;
}

.qd_15_zhuyi p:nth-of-type(1) {
	text-align: right;
	margin-bottom: 20px;
}

.qd_15_zhuyi p:nth-of-type(1) span {
	color: #5184B4;
}

/* 底部 */
.qd_15_myfoot {
	margin-top: 130px;
	border-top: 1px solid #dddddd;
	padding: 20px 40px;
}

.qd_15_myfoot ul {
	width: 100%;
	font-size: 14px;
	color: #06393e;
	overflow: hidden;
}

.qd_15_myfoot ul li select {
	width: 100px;
	appearance: none;
	/* 去掉默认图标 */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	padding: 5px 10px 0 10px;
	/* background: url(./img/下拉.qd_15_png) no-repeat right center; */

}

.qd_15_myfoot ul li:last-of-type {
	color: #999999;
	float: right;

}

.qd_15_myfoot ul li:nth-of-type(2)::after {
	content: "";
	width: 2px;
	height: 20px;
	background-color: #DCDCDC;
	position: absolute;
	right: 0;
}

.qd_15_myfoot ul li:nth-of-type(2)::before {
	content: "";
	width: 2px;
	height: 20px;
	background-color: #DCDCDC;
	position: absolute;
	left: 0;
}

.qd_15_myfoot ul li {
	padding: 0 10px;
	float: left;
	position: relative;
}

.qd_15_search {
	overflow: hidden;
	padding: 10px 20px;
	background-color: #f4f4f4;
	border-top: 1px solid #b2b8b9;
	border-bottom: 1px solid #b2b8b9;
	font-size: 14px;
	color: #053439;
	margin-top: 20px;
}

.qd_15_search select {
	width: 180px;
	font-size: 12px;
	height: 30px;
	line-height: 20px;
	appearance: none;
	/* 去掉默认图标 */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	/* background: url(./img/下拉1.qd_15_png) center no-repeat #fff; */
	background-position: 90% 50%;
	background-size: 10px;
	padding: 5px 10px 0 10px;
}

.qd_15_search_l {
	float: left;
	font-weight: 600;
}

.qd_15_search_l button {
	background-color: #fafafa;
	border: 1px solid #b2b8b9;
	padding: 10px;
	margin-left: 5px;
}

.qd_15_search_r {
	float: right;
}

.qd_15_search_r div {
	width: 50px;
	display: inline-block;
	font-weight: bold;
}

.qd_15_back {
	margin-top: 20px;
}

.qd_15_back button {
	border: none;
	padding: 10px 20px;
	font-weight: 600;
	color: #053439;
	background-color: #e3eced;
}

.qd_15_data {
	display: flex;
	font-size: 14px;
	color: #686868;
	font-weight: 600;
	border-bottom: 3px solid #b6b6b6;
	padding: 10px 0;
}

.qd_15_data li:nth-of-type(1) {
	width: 800px;
	padding: 0 10px 0 20px;
}

.qd_15_data li {
	width: 300px;
	padding: 0 10px;

}

.qd_15_data li div:nth-of-type(1)::after {
	content: "";
	width: 1px;
	height: 20px;
	background-color: #686868;
	position: absolute;
	right: 0;
}

.qd_15_data li div:nth-of-type(1):last-of-type::after {
	display: none;
}

.qd_15_data li div:nth-of-type(1) {
	color: #053439;
	width: 100%;
	position: relative;
}

.qd_15_data li:nth-of-type(4) {
	text-align: right;
	padding-right: 20px;
}

.qd_15_data li:nth-of-type(4) div:nth-of-type(1) {
	padding-right: 20px;
}

.qd_15_icon {
	position: relative;
	margin-left: 5px;
	color: #1e9eb1;
}

.qd_15_icon::after {
	content: "";
	width: 15px;
	height: 15px;
	border: 2px solid #1e9eb1;
	border-radius: 50%;
	position: absolute;
	left: -5px;
}

.qd_15_day {
	height: 40px;
	line-height: 40px;
}

.qd_15_option {
	width: 100px;
	height: 30px;
	padding-left: 5px;

}

.qd_15_bottom {
	height: 50px;
	line-height: 50px;
	margin-top: 30px;
	border: 1px solid #b4d5e7;
	font-size: 12px;
}

.qd_15_bottom ul {
	display: flex;
}

.qd_15_bottom li:nth-of-type(1) {
	width: 50px;
	line-height: 50px;
	background-color: #d0e4f0;
	margin-right: 10px;
}

.qd_15_bottom li:nth-of-type(1) img {
	margin-left: 5px;
	margin-bottom: 5px;
	vertical-align: middle;
}

.qd_15_bottom li:nth-of-type(3) {
	color: #003399;
	font-weight: 800;
}

.qd_15_num ul {
	display: flex;
	border-bottom: 5px solid #a4a4a4;
	margin-bottom: 10px;
	position: relative;
}

.qd_15_num ul::before {
	content: '';
	background-color: #cc6600;
	height: 5px;
	width: 355px;
	position: absolute;
	bottom: -5px;
	left: 0;
}

.qd_15_num ul li {
	margin: 10px 20px 15px 0;
}

.qd_15_content {
	margin-top: 30px;
}

.qd_15_content_h1 {
    font-size: 30px;
    margin: 0;
}

.qd_15_content_h1 span {
	font-size: 12px;
	vertical-align: text-top;
	margin-left: 10px;
	color: #4281b8;
	font-weight: 500;
}

.qd_15_content1 {
	margin-top: 30px;
}
.qd_15_content1 p{
    margin: 0;
}

.qd_15_bigcontent {
	margin-left: 20px;
}

.qd_15_content2 {
	margin-top: 20px;
}

.qd_15_content3 {
	margin-top: 30px;
	position: relative;
}

.qd_15_bigcontent1 {
	margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.qd_15_content4 {
	margin-left: 20px;
}

.qd_15_xingdong {
	text-align: left;
	height: 50px;
	width: 100%;
	margin-top: 5px;
}
.qd_15_xingdong tr td{
    border: 0;
    padding: 0 !important;
}

#qd_15_tigong {
	color: #000000;
}

.qd_15_cont3_yu {
	font-weight: 600;
    font-size: 15px;
    margin: 0;
}

.qd_15_cont3_yu a{
	color: #000000;
}

.qd_15_cont3_sele {
	font-weight: 600;
	border: 0;
	color: #65b3dd;
}

.qd_15_cont3_btn {
	position: absolute;
	right: 20px;
	bottom: 0px;
	background-color: #fccd54;
	padding: 7px;
	border: 1px solid #737373;
    border-radius: 5px;
    width: 124px;
    height: 32px;
    font-size: 12px;
}

.qd_15_big1 {
	text-align: left;
	padding: 10px;
}

.qd_15_tr1 {
	vertical-align: top;
	height: 50px;
	background-color: #f3f3f3;
	border: 1px solid lightgray;
}

.qd_15_tr1 th {
	padding: 10px;
}

.qd_15_th1 {
	width: 18%;
}

.qd_15_th2 {
	width: 10%;
}

.qd_15_th3 {
	width: 6%;
}

.qd_15_th4 {
	width: 9%;
}

.qd_15_th5 {
	padding: 10px;
}

.qd_15_th6 {
	width: 15%;
}

.qd_15_th7 {
	width: 10%;
}

.qd_15_tr2 {
	vertical-align: top;
}

.qd_15_tr2 td {
	padding: 10px;
}

.qd_15_tr2_sel {
	font-weight: 600;
	border: 0;
	color: #65b3dd;
}

#qd_15_tr2_btn {
	width: 80%;
	padding: 2px;
}
#qd_15_tr2_btn a{
	color: #000000;
}
.qd_15_con4_p {
	font-weight: 600;
    font-size: 28px;
    margin: 0;
    font-size: 15px;
}

.qd_15_con4_p2 {
	width: 95%;
	padding: 20px;
	border: 1px solid lightgray;
	margin-top: 5px;
}
.qd_15_cont2_sel {
    padding: 3px 13px 3px 10px;
    background: #EFEFEF;
}
#qd_15_cont{
	width: 108px;
	position: relative;
}

.qd_15_cont3_seleI{
    font-weight: 600;
    border: 0;
    color: #65b3dd;
    width: 78px;
    height: 18px;
    font-size: 12px;
}
.qd_15_content2 p{
    margin: 0;
    font-weight: bold;
}
.qd_15_body{
    width: 100%;
    padding-bottom: 300px;
}