.end14_content {
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
}

.end14_sidebar {
	width: 240px;
	padding: 30px 0 30px 30px;
	background-color: #F4F8FA;
}

.end14_sidebar ul li {
	margin-bottom: 15px;
}

.end14_sidebar ul li:nth-last-child(1) {
	color: #008296;
	font-weight: 600;
}

.end14_sidebar>p {
	margin-top: 30px;
	font-size: 13px;
	margin-bottom: 20px;
}

.end14_but {
	width: 90%;
	height: 35px;
	line-height: 35px;
	font-size: 13px;
	margin-bottom: 20px;
	box-shadow: 1px 2px 1px #ccc;
	border-radius: 3px;
	background-color: #E3ECED;
	text-align: center;
}


.end14_switch {
	width: 40px;
	height: 20px;
	position: relative;
	display: inline-block;
}

.end14_switch input {
	display: none;
}

.end14_round {
	border-radius: 24px;
}

.end14_slider {
	background:  #879596;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: all .4s;
	cursor: pointer;
}

.end14_slider:after {
	content: '';
	width: 13px;
	height: 13px;
	display: block;
	border-radius: 50%;
	background-color: #FFFFFF;
	position: absolute;
	top: 3px;
	left: 4px;
	transition: all .4s;
}

input:checked+.end14_slider {
	background: #2196F3;
}

input:checked+.end14_slider:after {
	transform: translateX(20px);
}
.end14_biaoji{
	vertical-align: super;
	font-size: 14px;
}
.end14_maijia{
	width: 100%;
	padding: 30px 15px 0 30px;
}
.end14_pinglun{
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 10px;
}
.end14_pinglun span{
	font-size: 13px;
	color: #00a9c3;
	margin-left: 20px;
}
.end14_qingzy{
	background-color: #E5F2F4;
	font-size: 14px;
	padding: 10px;
	position: relative;
}
.end14_qingzy span{
	display: inline-block;
	width: 90%;
}
.end14_qingzy svg:nth-of-type(1){
	margin-left: 20px;
	margin-right: 10px;
	vertical-align: super;
}
.end14_qingzy svg:nth-of-type(2){
	position: absolute;
	right: 20px;
	top: 15px;
}
.end14_pinglunss{
	width: 65%;
	background-color: #FAFAFA;
	height: 100px;
	line-height: 100px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.end14_pinglunss>input{
	width: 220px;
	height: 35px;
	border: 1px solid rgba(0,0,0,0.3);
	padding-left: 20px;
	font-style: oblique;
}
.end14_qzy2{
	height: 60px !important;
	line-height: 60px;
	padding-left:50px;
}
.end14_pinglunss span:nth-of-type(1){
	vertical-align: middle;
	display: inline-block;
	text-align: center;
	width: 35px;
	height: 35px;
	line-height: 35px;
	border: 1px solid rgba(0,0,0,0.3);
	border-left: none;
}
.end14_pinglunss span:nth-of-type(1) svg{
	vertical-align: middle;
}
.end14_pinglunss span:nth-of-type(2){
	margin-left: 20px;
	font-size: 14px;
}