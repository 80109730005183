.repertoryTable{
    width: 100%;
    padding: 0 50px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding-top: 20px;
}
.repertoryTable{
    padding: 20px 20px 0 20px !important;
}