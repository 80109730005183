*{
	margin: 0;
	padding: 0;
}
.gg04_header{
	border-bottom: 1px solid #ececec;
	display: flex;
	justify-content: space-between;
    align-items: center;
	padding: 20px 20px;
	margin-bottom: 40px;
}
.gg04_header_l,.gg04_header_r{
	display: flex;
	align-items: center;
}
.gg04_icon-xx{
	width: 10px;
}
.gg04_header_l p{
	margin-left: 20px;
    margin-bottom: 0!important;
}
.gg04_header_l a{
    color: #000000;
}
.gg04_header_r p{
	color: #0468c1;
	margin-right: 16px;
}
.gg04_header_r button{
	padding: 4px 8px;
	border-radius: 4px;
	border: 1px solid #d3a237;
	background: linear-gradient(to bottom,#f8cd6d,#f6bd41);
	margin-right: 16px;
}
.gg04_header_r_icon{
	width: 20px;
}
.gg04_content{
	width: 80%;
	margin: 0 auto;
	border: 1px solid #ececec;
	border-radius: 4px;
	margin-top: 20px;
}
.gg04_content_header{
	padding: 20px;
	border-bottom: 1px solid #ececec;
}
.gg04_content_header p{
	font-size: 18px;
}
.gg04_content_con{
	padding: 30px 20px;
}
.gg04_content_con>div{
	display: flex;
	/* margin-left: 160px; */
	margin-bottom: 10px;
}
.gg04_content_con>div>p{
	font-weight: bold;
	text-align: right;
	width: 200px;
}
.gg04_content_con div ul{
	list-style: none;
	margin-left: 30px;
}
.gg04_content_con>div ul li{
	margin-bottom: 8px;
}
.gg04_icon_th{
	vertical-align: middle;
}
.gg04_content_con>div ul li span{
	background: #0066c0;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 14px;
	padding: 0 4px;
}
.gg04_content_con>div>div{
	margin-left: 30px;
	position: relative;
}
.gg04_content_con>div>div>button{
	padding: 6px 30px 6px 10px;
	border: 1px solid #d7d7d7;
	background: linear-gradient(to bottom,#ececec,#d3d3d3);
	border-radius: 4px;
}
.gg04_icon_bottom{
	width: 10px;
	position: absolute;
	top: 8px;
	right: 8px;
}
.gg04_nameBar>div input{
	width: 250px;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #d6d6d6;
	padding-left: 10px;
}
.gg04_nameBar{
	position: relative;
	padding-bottom: 20px;
}
.gg04_nameBar::before{
	content: "";
	width: 1126px;
	height: 1px;
	background: #ececec;
	position: absolute;
	bottom: 0;
	left: 0;
}
.gg04_content_title{
	margin-bottom: 20px;
}
.gg04_content_con>div>p span:first-of-type{
	background: #0066c0;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 14px;
	margin-right: 10px;
	padding: 0 4px;
}
.gg04_content_con>div>p span:last-of-type{
	display: block;
	font-weight: 400;
	color: gray;
	font-size: 14px;
}
.gg04_content_inpBar input{
	width: 400px;
	height: 46px;
	border-radius: 4px;
	border: 1px solid #d6d6d6;
	padding-left: 10px;
}
.gg04_content_inpBar p{
	color: gray;
	font-size: 14px;
}
.gg04_content_footer{
	width: 80%;
	margin: 0 auto; 
}
.gg04_content_footer{
	font-size: 14px;
	margin-top: 10px;
	color: dimgray;
}
.gg04_blue{
	color: #0066c0 !important;
}
.gg04_footer{
	text-align: center;
	color: #797979;
	font-size: 14px;
	margin-top: 50px;
}