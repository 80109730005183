.agreementsBox .a-size-large {
    font-size: 13px!important;
    line-height: 1.3!important;
    font-weight: bold;
}
.agreementsBox .a-size-medium {
    font-size: 17px!important;
    line-height: 1.255!important;
}
.agreementsBox .program-overview-values {
    padding: 20px !important;
    /* max-width: 1500px; */
    margin: 0;
    width: 70%;
}
.agreementsBox.agreements .a-grid-vertical-align.a-row {
    width: 60%;
    display: table!important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}
.agreementsBox .ng-scope{
    text-align: center;
}
.agreementsBox .a-size-medium {
    font-size: 17px!important;
    line-height: 1.255!important;
}
.agreementsBox .a-size-small {
    font-size: 12px!important;
    line-height: 1.5!important;
}
.agreementsBox .eCkQDX{
    display: none;
}
.agreementsBox .jXxwsB span.icon,
.agreementsBox .jXxwsB .websit.ant-select,
.agreementsBox .jXxwsB .language.ant-select .ant-select-selector,
.agreementsBox .jXxwsB .ant-select-arrow,
.agreementsBox .jXxwsB .search,
.agreementsBox .jXxwsB .language.ant-select,
.agreementsBox .jXxwsB .ilQBwr button{
    display: none;
}
.agreementsBox .hoQOMo>div{
    display: block;
}
.agreementsBox .jXxwsB .ilQBwr button:last-child{
    display: inline-block;
}
.agreementsBox .jXxwsB .ilQBwr{
    text-align: right;
}
.agreementsBox.agreements .CompanyAddress{
    width: 40%;
    margin: 0 auto;
}
.agreementsBox .selectBox{
    width: 100%;
    height: 35px;
}
.agreementsBox .next {
    width: 70%;
    color: #111;
    border-color: #e77600;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    margin-top: 20px;
    border: 1px solid #000;
    border-radius: 3px;
}
.agreementsBox .rightCollapse{
    width: 30%;
}
.agreementsBox .rightCollapse>p{
    font-weight: 700;
    font-size: 17px;
    line-height: 1.255;
    color: #565959;
    padding: 10px 14px;
}
.agreementsBox .ant-popover-open.center{
    margin: 0 auto;
    width: 1px;
    display: block;
}
