.moblie_body{
width: 100%;    
margin-bottom: 500px;
}


.moblie_NavBox{
    width: 100%;
    display: flex;
    justify-content: center;

}
.moblie_nav{
    width: 540px;
    height: 800px;
    overflow: auto;
}
.moblie_NavII{
    width: 100%;
    height: 370px;
    margin-right: 20px;
}
.moblie_Nav_img{
   width: 100%;
   height: 300px;
   background: #008296;
}
.moblie_Nav_name p{
    margin: 0;
font-weight: bold;
font-size: 17px;
}
.moblie_Nav_name div{
    font-weight: bold;
    font-size: 12px;
}