





.canceled_right_sp{
    height: 37px;
    line-height: 46px;
    margin-left: 6px;
    color: #cccccc;
}