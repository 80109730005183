









.ManageApp_headBox{
    width: 100%;
    padding: 0 20px;
}
.ManageApp_head_font{
    color: #008296;
    font-size: 12px;
    margin: 10px 0;
}
.ManageApp_head_fontII{
    font-size: 22px;
    margin: 10px 0;
}
.ManageApp_head_fontIII{
    font-size: 12px;
    margin: 10px 0;
}
.ManageApp_head_btn button{
    background: #008296;
    color: #ffffff;
    outline-style: none;
    border: 0;
    padding: 6px;
    margin-top: 10px;
}

.ManageApp_contentBox{
    width: 100%;
    height: 100%;
    border-bottom:1px solid #000000 ;
    margin-bottom: 500px;
}

.ManageApp_type{
    width: 100%;
    display: flex;
    padding: 15px 20px;
    border-top: 1px solid #000;
    margin-top: 20px;
}
.ManageApp_type_Id{
    width: 21.6%;
    margin-right: 10px;
    font-weight: bold;
}

.ManageApp_type_Id div:nth-child(1){
    border-right: 1px solid #000000;

}
.ManageApp_type_Id div:nth-child(2){
    color: #667575;
}

.ManageApp_type_name{
    width: 26%;
    margin-right: 10px;
    font-weight: bold;

}
.ManageApp_type_name div:nth-child(1){
    border-right:1px solid #000000 ;
}
.ManageApp_type_state{
    width: 8%;
    margin-right: 10px;
    font-weight: bold;

}
.ManageApp_type_state div:nth-child(1){
    border-right:1px solid #000000 ;
}
.ManageApp_type_date{
    width: 19.5%;
    margin-right: 10px;
    font-weight: bold;

}
.ManageApp_type_date div:nth-child(1){
    border-right: 1px solid #000000;
}
.ManageApp_type_date div:nth-child(2){
    color: #667575;
}
.ManageApp_type_MWS{
    width: 15%;
    margin-right: 10px;
    font-weight: bold;

}
.ManageApp_type_MWS div:nth-child(1){
    border-right: 1px solid #000000;
}
.ManageApp_type_handle{
    width: 7%;
    font-weight: bold;
    
}

.ManageApp_content_Id{
    width: 21.6%;
    margin-right: 10px;
    font-weight: bold;
}
.ManageApp_content_name{
    width: 26%;
    margin-right: 10px;
    font-weight: bold;

}
.ManageApp_content_state{
    width: 8%;
    margin-right: 10px;
    font-weight: bold;
}
.ManageApp_content_date{
    width: 19.5%;
    margin-right: 10px;
    font-weight: bold;
}
.ManageApp_content_MWS{
    width: 15%;
    margin-right: 10px;
    font-weight: bold;
}

.ManageApp_content_handle{
    width: 7%;
    font-weight: bold;
}
.ManageApp_content_handle button{
    padding: 5px 35px;
    background: #E3ECED;
    outline-style: none;
    border: 0;
    box-shadow: 0px 2px 4px #ddd;
}
.ManageApp_content_handle button:hover {
    background: #B6D0D3;
}