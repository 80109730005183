.end20_content{
	margin: 0 45px;
}
.end20_content_title{
	height: 85px;
	color: #000000;
	font-size: 14px;
}
.end20_content_title div{
	color: #0048da;
	margin: 5px 0;
}
.end20_content_title div span{
	font-size: 26px;
	color: #000000;
	margin-right: 25px;
}
.end20_content_report{
	height: 115px;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	
}
.end20_content_report div:nth-of-type(1){
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}
.end20_content_report p{
	font-weight: bold;
	margin-left: 8px;
	margin-top: 10px;
}
.end20_content_report p:nth-of-type(2){
	color: #0048da;
	font-weight: 400;
}
.end20_content_report div:nth-of-type(2){
	display: flex;
}
.end20_content_report div:nth-of-type(2) select{
	width: 155px;
	padding: 5px 8px;
	margin: 0 8px;
	border: none;
	border-left: 2px solid #CCCCCC;
	border-right: 2px solid #CCCCCC;
	background: #E9EBEE;
	border-radius: 2px;
	appearance: button;
}
.end20_content_report div:nth-of-type(2) button{
	border: none;
	border-left: 2px solid #CCCCCC;
	border-right: 2px solid #CCCCCC;
	width: 125px;
	background-image: linear-gradient(#F6da97, #f1c454);
}
.end20_content table{
	width: 100%;
	margin-top: 25px;
	border-collapse: collapse;
	border: 1px solid #CCCCCC;
}
.end20_content table tr td{
	height: 35px;
	text-indent: 1em;
	font-size: 14px;
}
.end20_content table tr{
	border-top: 1px solid #CCCCCC;
	border-bottom: 1px solid #CCCCCC;
}
.end20_content_hader{
	background: #E6E6E6;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
}
.end20_content_table_one{
	width: 30%;
}
.end20_content_table_two{width: 20%;}
.end20_content_table_three{width: 25%;}
.end20_content_table_four{width: 10%;}
.end20_content_table_five{width: 15%;}
.end20_content_table_btn{
	background: #E9EBEE;
	border: none;
	width: 105px;
	padding: 5px 0;
	font-size: 14px;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	float: right;
	margin-right: 15px;
}
.end20_content_table_vo{
	height: 55px;
}
