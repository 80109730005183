.kc30_myhead {
	width: 100%;
	border-bottom: 2px solid #dcdcdc;
	padding: 10px  0 0;
	overflow: hidden;
}

.kc30_head_l {
	display: flex;
	float: left;
	margin-left: 50px;
	font-size: 14px;
	line-height: 25px;
}

.kc30_head_l li {
	color: #0f3b42;
	padding: 0 10px;
}

.kc30_headr-r {
		float: right;
		display: flex;
		overflow: hidden;
		margin-right: 50px;
	}
	.kc30_headr-r span {
		font-size: 12px;
		font-weight: 600;
		color: #4a4a4a;
		margin: 5px 5px 0;
	}

	.kc30_flag {
		height: 25px;
		margin: 2px 5px 0;
	}

	.kc30_headr-r select {
		height: 25px;
		border: 1px solid #CCC;
		border-radius: 3px;
		padding: 0 50px 0 10px;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		/* background: url(.kc30_.kc30_/img/下拉1.kc30_png) center no-repeat #fff; */
		background-position: 95% 50%;
		background-size: 10px;
		margin-right: 20px;
	}

	.kc30_headr-r input {
		height: 25px;
		padding: 0 10px;
		border: 1px solid #CCC;
		border-radius: 3px 0 0 3px;
		box-sizing: border-box;
		margin-right: 1px;
	}

	.kc30_headr-r button {
		width: 25px;
		height: 25px;
		border: 1px solid #a8a8a8;
		border-left: none;
		background-color: #CCC;
		border-radius: 0 3px 3px 0;
		line-height: 25px;
		margin-right: 5px;
		box-sizing: border-box;
	}

	.kc30_headr-r button img {
		width: 13px;
	}

	.kc30_helpbox {
		height: 25px;
		display: flex;
		align-items: flex-end;
	}

	.kc30_help {
		height: 20px;
		display: flex;
		overflow: hidden;
	}

	.kc30_help li {
		font-size: 14px;
		font-weight: bold;
		color: #5C5C5C;
		padding: 0 5px;
		border-right: 1px solid #CCC;
	}

	.kc30_help li:last-of-type {
		border: none;
	}
.kc30_header-nav a{
	/* padding: 0 10px;	 */
	color: #0F6FC3;
}
.kc30_header-nav a:hover{
	text-decoration: underline;
	color: #E67A16;
}
.kc30_header-nav ul{
	display: flex;
	margin-top: 10px;
}
.kc30_header-nav ul li{
	color: #469ddb;
	padding: 0 20px;
	line-height: 18px;
}
.kc30_header-nav ul li:not(:last-of-type){
	border-right: 1px solid #ccc;
}
.kc30_myBody{
	padding: 0 20px;
	margin-top: 20px;
}
.kc30_m-header{
	display: flex;
	justify-content: space-between;
}
.kc30_m-left{
	display: flex;
}
.kc30_m-left h2{
	font-size: 30px;
	font-weight: 400;
}
.kc30_m-left ul{
	display: flex;
	height: 18px;
	line-height: 18px;
	margin-top: 20px;
}
.kc30_m-left ul li{
	color: #469ddb;
	padding: 0 10px;
}
.kc30_m-left ul li:first-of-type{
	border-right: 1px solid #ccc;
}
.kc30_m-right button{
	padding: 6px 8px;
	background-color: #edeff1;
	border: 1px solid #ccc;
	border-radius: 2px;
	background-image: linear-gradient( rgb(244, 245, 247),rgb(232, 234, 237));
}
.kc30_m-right button:first-of-type{
	margin-right: 10px;
}
.kc30_search{
	display: flex;
	margin-top: 20px;
}
.kc30_search select{
	padding: 0 8px;
	background-color: #f7f8fa;
	color: #6b6c67;
	border: 1px solid #ccc;
	border-radius: 2px;
	height: 30px;
	box-sizing: border-box;
	margin-top: 1px;
}
.kc30_search div{
	margin-left: 20px;
}
.kc30_search div input{
	background-image: url(../../assets/images/kc30_search.png);
	background-repeat: no-repeat;
	background-position:6px 50%;
	padding-left: 30px;
	width: 170px;
	border: 1px solid #ccc;
}
.kc30_search div button{
	padding: 0 10px;
	background-color: #666d74;
	border: 0;
	color: white;
	border: 1px solid #4b4f55;
}
.kc30_search div input,.kc30_search div button{
	height: 30px;
	box-sizing: border-box;
	border-radius: 2px;
}
.kc30_search div span{
	font-size: 14px;
	margin-left: 20px;
}
.kc30_content{
	margin-top: 16px;
}
.kc30_c-header{
	display: flex;
	align-items: center;
	background-color: #f3f3f3;
	border: 1px solid #dedede;
	border-radius: 2px;
	padding: 0 10px 6px;
	height: 50px;
	background-image: linear-gradient(rgb(247, 247, 247), rgb(236, 236, 236));
}
.kc30_c-header>p{
	color: #959595;
}
.kc30_c-header div{
	margin-left: 20px;
	position: relative;
	padding: 0 20px;
}
.kc30_c-header div::before{
	content: "";
	width: 1px;
	height: 30px;
	background-color: #ccc;
	position: absolute;
	top: 0;
	right: 0;
}
.kc30_c-header div input{
	/* color: #CCC; */
}
 .kc30_c-header div input:checked:hover{
    background: #821134;
    color: #ccc;
    font-weight: bold;
}
.kc30_c-header div span{
	font-size: 14px;
}
.kc30_c-header select{
	margin: 8px 0 0 10px;
	padding: 0 10px;
	height: 30px;
	background-color: #e9ebee;
	border: 1px solid #ccc;
	border-radius: 2px;
	background-image: linear-gradient(rgb(247, 247, 247), rgb(223, 223, 223));
}
.kc30_c-con{
	margin-top: 6px;
}
.kc30_blueFont{
	color: #0f6fc3;
	/* font-weight: bold; */
}
.kc30_c-tr{
	background: linear-gradient(rgb(247, 247, 247), rgb(236, 236, 236));
	border-bottom: 1px solid #ccc;
}
.kc30_table{
	border: 1px solid #ccc;
	width: 100%;
}
.kc30_table tr th p:nth-of-type(2){
	font-weight: 400;
	color: gray;
}
.kc30_table tr{
	/* border: 1px solid #CC6600; */
}
.kc30_table th,.kc30_table td{
	border: 0;
	padding: 0 6px;
	box-sizing: border-box;
	font-size: 14px;
}
.kc30_table th{
	text-align: left;
	vertical-align: top;
	padding: 10px 6px;
}
.kc30_table td{
	vertical-align: top;
}
.kc30_align-r{
	text-align: right;
}
.kc30_border-b{
	border-bottom: 1px solid #ccc;
}
.kc30_border-b td{
	padding-top: 4px;
	padding-bottom: 2px;
	border-bottom: 1px solid #ccc;
}
.kc30_border-input{
	background: #dedede !important;
	
}
.kc30_inp{
	text-align: right;
	box-sizing: border-box;
	padding: 4px 6px;
	border-radius: 4px;
	border: 1px solid #ccc;
	background-image: url(../../assets/images/kc30_moery.png);
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: 10px 2px;
}
.kc30_c-select{
	border: 0;
	color: #0f6fc3;
}
.kc30_compile{
	display: flex;
}
.kc30_compile select{
	width: 80px;
}
.kc30_compile>div{
	border: 1px solid #ccc;
	width: 60px;
	height: 20px;
	border-radius: 2px 0 0 2px;
	padding: 2px 0 2px 6px;
	/* background-color: linear-gradient(rgb(244, 245, 247), rgb(233, 235, 238)); */
	background-color: #f3f4f6;
}
.kc30_compile>p{
	height: 20px;
	width: 20px;
	border-radius: 0 2px 2px 0;
	border: 1px solid #ccc;
	background-image: url(../../assets/images/kc30_xl2.png);
	background-size: 14px;
	background-repeat: no-repeat;
	background-position: 50%;
	background-color: #f3f4f6;
	padding: 2px 0;
}
.kc30_c-btn{
	width: 90px;
	padding: 2px 0;
	background-color: #f9f3e5;
	border: 1px solid #eae5d8;
	border-radius: 2px;
}
.kc30_myBody_foot{
	display: flex;
	justify-content: space-between;
    margin-top: 4px;
    padding-bottom: 300px;
}
.kc30_myBody_foot_l input{
	height: 36px;
	width: 100px;
	border-radius: 4px;
	border: 1px solid #adadad;
	padding-left: 6px;
}
.kc30_myBody_foot_l button{
	height: 36px;
	padding: 0 10px;
	border-radius: 4px;
	border: 1px solid #adadad;
	background: linear-gradient(#f5f6f8,#e8eaed);
}
.kc30_myBody_foot_c button{
	height: 36px;
	padding: 0 14px;
	background: linear-gradient(#f6f7f9,#e7e9ec);
	border: 1px solid #adadad;
	border-radius: 4px;
}
.kc30_myBody_foot_c button:first-of-type{
	border: 0;
	background: 0;
}
.kc30_myBody_foot_c button:nth-of-type(2){
	border: 1px solid #ec9439;
	color: #ec9439;
	background: 0;
}
.kc30_myBody_foot_r select{
	height: 36px;
	padding: 0 10px;
	background: linear-gradient(#f6f7f9,#e7e9ec);
	border: 1px solid #adadad;
	border-radius: 4px;
}
.kc30_th1{
	width: 30px;text-align: center;
}
.kc30_th2{
	width: 80px;
}
.kc30_th3{
	width: 80px;text-align: center;
}
.kc30_th4{
	width: 150px;border-top: 2px solid #e47a16;
}
.kc30_th4_p{
	display: inline-block;color: #e47a16;
}
.kc30_th5{
	text-align: center;
}
.kc30_th6{
	text-align: right;
}
.kc30_th6_p{
	margin-right: 15px;
}
.kc30_th7{
	width: 80px;text-align: right;
}
.kc30_th8{
	width: 90px;
}
.kc30_th9{
	width: 50px;
}
.kc30_th10{
	width: 100px;
}
.kc30_th11{
	width: 570px;
}
.font-gray{
	color: gray;
}
.colorBlue{
	color: #0f6fc3;
}
.kc30_th12{
	width: 14px;
}
.kc30_th13{
	width: 680px;
}
.kc30_th14{
	width: 180px;
}
.kc30_c-header_radio input{
	margin-left: 10px;
}
p{
	margin: 0;
}
 input::-webkit-input-placeholder{
	font-style: normal !important;
	color: #ccc;
}

.ToDo{
width: 100%;
height: 300px;

}
.tableImg img{
	width: 60px;
}