.searchBox {
  padding: 12px;
  border-bottom: 1px solid #ccc;
}

.operaLine {
  padding: 6px 12px;
  border-bottom: 1px solid #ccc;
}

.goodsList {
  height: 330px;
  overflow: auto;
  padding: 0 12px;
  border-bottom: 1px solid #ccc;
}

.goodsList .goodsItem:last-child {
  border-bottom: none;
}


.paginationContainer {
  padding: 6px 12px;
}
