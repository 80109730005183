.qd07_titbox1{
    margin: 10px 0 0 20px;
}
.qd07_titbox1>p{
    font-size: 22px;
    font-weight: 100;
    color: rgba(0,0,0,0.7);
}
.qd07_titbox1>p>span{
    font-size: 13px;
    color: #008296;
    margin-left: 20px;
}
.qd07_titbox2{
    margin-top: 20px;
    padding: 20px 0 20px 20px;
    background: #e5f2f4;
    display: flex;
    position: relative;
}
.qd07_titbox2>svg{
    height: 40px;
    vertical-align: middle;
}
.qd07_titbox2>.qd07_box_con{
    margin-left: 10px;
    position: relative;
}
.qd07_titbox2>.qd07_box_con>p{
    font-size: 14px;
    font-weight: 700;
}
.qd07_titbox2> .qd07_con_x{
    position: absolute;
    right: 20px;
}
.qd07_titbox2>.qd07_box_con>span{
    font-size: 14px;
}
.qd07_titbox2>.qd07_box_con>span>span{
    color: #008296;
}
.qd07_zshou{
    margin-top: 20px;
}
.qd07_zshou>.qd07_splist{
    padding-left: 20px;
    display: flex;
    background: #fafafa;
}
.qd07_splist a{
    color: #000;
    
}
.qd07_zshou>.qd07_splist>li{
    font-size: 14px;
    margin-right: 30px;
    padding-top: 10px;
    height: 50px;
}
.qd07_splist>li:nth-of-type(1){
    border-bottom:2px solid #008296;
}
.qd07_gailaii{
    margin: 20px 0 0 20px;
}
.qd07_gailaii>p{
    margin-left: 20px;
    font-size: 18px;
    font-weight: 500;
}
.qd07_glbox{
    width: 600px;
    margin-top: 20px;
    border: 1px solid #ccc;
}
.qd07_glbox>p{
    font-size: 16px;
    margin: 10px 0 0 20px;
}
.qd07_glbox>p>svg{
	margin-left: 3px;
    vertical-align: middle;
}
.qd07_gl_con{
    width: 100px;
    margin: 0 auto;
    padding: 30px 0;
}
.qd07_gl_con>p{
    font-size: 26px;
    margin: 10px 0;
}
.qd07_gl_con>span{
    font-size: 12px;
    color: #008296;
}
.qd07_cuos{
    margin: 30px 20px 0 20px;
}
.qd07_cuos>span:nth-of-type(1){
    font-size: 18px;
    font-weight: 100;
    color: #008296;
}
.qd07_cuos>p:nth-of-type(1){
    margin-top: 10px;
    font-size: 13px;
}
.qd07_cuos>p:nth-of-type(1)>span{
    color: #008296;
}
.qd07_fizs{
    font-size: 18px;
    font-weight: 0;
    color: #008296;
    margin: 20px 0 10px 20px;
}
.qd07_fzsList{
	min-width: 100%;
    margin-top: 1px;
    margin-left: 1px;
    display: flex;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    border-bottom: 3px solid #ccc;
}

.qd07_fzsList>li{
    
}
.qd07_fzsList>li>.qd07_miaos:nth-of-type(1){
    
}
.qd07_fzsList>li>.qd07_miaos1{
    min-width: 15%;
}

.qd07_fzsList>li>.qd07_miaos{
    height: 40px;
    padding: 10px 0 20px;
    position: relative;
}
.qd07_miaos>p:nth-of-type(1){
    margin-left:20px;
    font-size: 14px;
    font-weight: 800;
}
.qd07_miaos>p:nth-last-of-type(1){
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    margin-left:20px;
}
.qd07_miaos2{
    border-top: 1px solid #ccc;
    padding: 30px 20px;
    position: relative;
}
.qd07_miaos2 p{
    margin: 0;
}
#qd07_iskey{
    height: 50px;
}
.qd07_flex1{
    display: flex;
    padding-left: 20px;
}
.qd07_key_ms{
    font-size: 13px;

}
.qd07_key_ms p{
    margin: 0;
}
.qd07_key_ms>p:nth-of-type(1){
    color: #008296;
}
.qd07_key_ms>p:nth-of-type(2){
    color: #008296;
}
.qd07_bufuhe{
    font-size: 13px;
    margin-top: 20px;
}
.qd07_bufuhe p{
    font-weight: 800;
}
.qd07_bufuhe p>span{
    font-weight: 400;
}
.qd07_myjiage{
    margin-top: 50px;
    font-size: 12px;
    text-align: right;
}
.qd07_myp_right{
    text-align: right;
}
.qd07_myto{
    font-size: 12px;
    margin-top: 65px;
}
.qd07_myshez{
    margin-top: 50px;
    font-size: 12px;
}
.qd07_iscank{
    padding: 0;
}
.qd07_myck{
    text-align: right;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.qd07_gengxin{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #008296;
    color: white;
    overflow: hidden;
    position: absolute;
    right: 10px;
}
.qd07_gengxin>p{
    width: 10px;
    font-size: 12px;
    margin: 13px auto 0;
}
.qd07_mar1{
    width: 100px;
}
.qd07_mar2{
    width: 180px;
}
.qd07_mar3{
    width: 200px;
}
.qd07_mar4{
    width: 150px;
}
.qd07_mar5{
    width: 118px;
    text-align: right;
    
}
.qd07_mar5 p:nth-of-type(1){
    padding-right: 20px;
}
.qd07_gengduo{
    
}
.qd07_gengduo>div{
    margin: 30px auto 10px;
    width: 130px;
    height: 40px;
    line-height: 40px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 000;
    text-align: center;
    color: rgba(0,0,0,0.6);
    background: #f5f9fa;
    cursor: pointer;
}