


.SAFET_box{
}

.SAFET_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 20px;
}

.SAFET_head div{
    font-size: 24px;
}
.SAFET_head div span{
    color: #0066c6;
}
.SAFET_head div button{
    font-size: 14px;
    padding: 0 6px;
    background: #F1C75F;
}
.SAFET_tab{
    padding:  0 20px;
    margin: 20px 0;
    
}
.SAFET_tab .ant-tabs-content{
    border: 1px solid #999999;
    border-top:none;
}