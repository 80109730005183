.CommodityPromotion-foot {
  text-align: center;
  margin-top: 15% !important;
  font-size: 12px;
}
.CommodityPromotion-setUp-content_inp input {
  width: 270px;
  padding: 5px;
}
.SetUp-BudgetSelection2_content {
  margin-left: 25px;
}

.SetUp-BudgetSelection_bottom .ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: collapse;
}
.BiddingStrategy-title {
  display: flex;
  align-items: center;
}

.BiddingStrategy-title_left {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.BiddingStrategy-title_left span svg {
  margin-left: 5px;
}

.BiddingStrategy-title_right a {
  color: #0066c0;
  font-size: 13px;
}
.SetUp-BudgetSelection2_bottom a {
  color: #0066c0;
  margin-left: 6px;
}
.Add-promotional-products-titel {
  font-size: 20px;
}
.Add-promotional-products-Match-type-text {
  width: 444px;
}
.Negative-keywordaddition-add-delete-a a {
  color: #0066c0;
}
.negative-exclude-box-Navigation .ant-tabs-tab-active {
  border-top: 0 !important;
  border-bottom: 2px solid #0066c0 !important;
}
.negative-exclude-box-Navigation
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #000 !important;
}

.card-body--no-padding .ant-card-body {
  padding: 0;
}
