.inforASIN_body{
    width: 100%;
    padding:  0 20px;
    padding-bottom: 100px;
}


.inforASIN_title{
    margin-top: 40px;
}



.inforASIN_inpBox{

    display: flex;
}

.inforASIN_inp{
    width: 410px;
    height: 36px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #ffffff;
    margin-right: 20px;
}

.inforASIN_inp input{
    width: 100%;
    border: 0;
    outline-style: none;
}

.inforASIN_titleII{
    margin-top: 30px;
}
.inforASIN_ASINIIBox{
    width: 100%;
    border: 1px solid #dddddd;
    overflow-y: auto;
}

.inforASIN_ASINIIBox_inpbox{
     width: 100% ;
     height: 73px;
     border-bottom:1px solid #dddddd;
     display: flex;
     justify-content: space-between;
     align-items: center;
     background-color: #fafafa;
    padding: 0 20px;

}
.inforASIN_ASINIIBox_inp{
    width: 210px;
    height: 36px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #ffffff;
}
.inforASIN_ASINIIBox_inp input{ 
    width: 100%;
    border: 0;
    outline-style: none;
}


.inforASIN_ASINIIBoxI{
    width: 100%;
    height: 63px;
    background-color: #fafafa;
    padding: 0 20px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
}
.inforASIN_ASINIIBoxII{
    width: 100.03%;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.inforASIN_head{
    width: 32%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inforASIN_body .A_alert_new{
    display: inline-block;
    border-radius: 12px;
    background-color: #008296;
    padding: 3px;
    color: #fff;
    font-size: 14px;
}