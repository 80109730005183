.Administration{
  /* overflow-y: auto; */
  min-width: 950px;
}
.Administration-body{
  width: calc(100vw - 250px);
  min-width: 700px;
}
.Administration-inner{
  width: 100%;
}

