.BrandRegistered{
  height: 100%;
  background: rgb(231,231,231);
}
.BrandRegistered_body{
  display: flex;
  padding-top: 35px;
}
.BrandRegistered_your_branch{
  width: 440px;
  min-width: 300px;
  height: 200px;
  background: #fff;
  margin: 0 35px;
  padding: 0 26px;
}
.BrandRegistered_use_branch{
  width: 900px;
  min-width: 500px;
  background: #fff;
  padding: 0 26px;
}
.BrandRegistered_body h3{
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  padding-left: 0;
  margin: 0;
}
.BrandRegistered_your_branch > div{
  display: flex;
  justify-content: space-between;
}
.BrandRegistered_your_branch > a{
  color:#008296;
  display: block;
  text-align: center;
}
.BrandRegistered_body a{
  color: rgb(0,102,192);
}
.BrandRegistered_use_item div:nth-child(1){
  font-size: 15px;
  color: #000;
  font-weight: 700;
}
.BrandRegistered_use_item div:nth-child(2){
  font-size: 14px;
  margin-bottom: 20px;
}

.BrandRegistered_Tips{
  width: 646px;
  margin: 0 auto;
}
.BrandRegistered_Tips h2 {
  text-align: center;
  margin-top: 20px;
  height: 60px;
  line-height: 60px;
}
.BrandRegistered_Tips .BrandRegistered_Tips_body{
  background: rgb(245,249,250);
  padding: 20px;
  line-height: 24px;
}
.BrandRegistered_Tips_body > strong{
  margin-bottom: 20px;
  display: block;
}
.BrandRegistered_Tips_body p{
  padding: 0 40px;
  height: 24px;
  line-height: 24px;
}
.BrandRegistered_Tips_spot{
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #000;
  margin-bottom: 2px;
  margin-right: 10px;
}
.BrandRegistered_Tips_butn{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.BrandRegistered_Tips_butn button:nth-child(1){
  border: 1px solid rgb(0,130,150);
}
.BrandRegistered_Tips_butn button:nth-child(2){
  background: rgb(0,130,150);
  border: 1px solid rgb(0,130,150);
  color: #fff;
}