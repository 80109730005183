.tabs-box{
    background: #f5f9fa;
}
.tab1Banner-box{
    width: 1024px;
    margin: 0 auto;
    background: #fff;
}
.ant-tabs-nav{
    margin: 0!important;
}

.tab1-row{
    padding: 20px;
    align-items: center;
    font-size: 12px;
}
.tab-col-right{
    text-align: right;
}
.tab-col-right-icon{
    margin-left: 5px;
    color: #008296!important;
    font-size: 13px;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    color: #008296!important;
}

.tab1-form-title{
    font-size: 12px;
    font-weight: bold;
    color: #002F36;
}

.ant-form-item-extra{
    font-size: 12px!important;
    color: #000!important;
}
.tab1Banner-box .tab1-form{
    margin: 40px 0;
}
.tab1-button{
    padding-bottom: 50px;
    width: 1024px;
    margin: 0 auto; 
    display: flex;
    justify-content: flex-end;
}
.tab1-button button{
    margin-left: 20px;
    font-size: 12px;
    color: #fff;
}
.tab1-button button:first-child{
    background-color: #e3eced;
    color: #002F36;
}
.tab1-button button:last-child{
    background-color: #008296;
}
.tab1-button button:hover{
    background-color: #006878;
    color: #fff;
    border-color: #006878;
}
