.MengkSiderLeft .MengkSiderLeft-title{
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.MengkSiderLeft .MengkSiderLeft-content{
  font-size: 14px;
}
.MengkSiderLeft .MengkSiderLeft-tips{
  font-size: 14px;
  color: #666;
}
.MengkSiderLeft{
  height: 100%;
}
.MengkSiderLeft h3{
  height: 48px;
  line-height: 48px;
  text-align: right;
  border-bottom: 1px solid rgb(221,221,221);
}
.MengkSiderLeft .MengkSiderLeft-add-but{
 margin: 20px 0;
}
.MengkSiderLeft-tag{
  display: flex;
  justify-content: space-around;
  border-left: 2px solid rgb(196,86,21);
  background: #fff;
}
.MengkSiderLeft .ant-radio-button-wrapper{
  width: 150px;
  height: 265px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.MengkSiderLeft .MengkSiderLeft-add label:nth-child(1){
  background: url("@assets/images/Mengk-add-radio1.png");
}
.MengkSiderLeft .MengkSiderLeft-add label:nth-child(2){
  background: url("@assets/images/Mengk-add-radio2.png");
}
.MengkSiderLeft .MengkSiderLeft-add label:nth-child(3){
  background: url("@assets/images/Mengk-add-radio3.png");
}
.MengkSiderLeft .MengkSiderLeft-add label:nth-child(4){
  background: url("@assets/images/Mengk-add-radio4.png");
}
.MengkSiderLeft .MengkSiderLeft-set label:nth-child(1){
  background: url("@assets/images/Mengk-add-radio5.png");
}
.MengkSiderLeft .MengkSiderLeft-set label:nth-child(2){
  background: url("@assets/images/Mengk-add-radio6.png");
}
.MengkSiderLeft-tailoring{
  background: url("@assets/images/Mengk-tailoring.png") no-repeat 100%;
  width: 300px;
  height: 300px;
  position: relative;
}
.MengkSiderLeft-tailoring button {
  position: absolute;
  top: 13px;
  right: 13px;
}