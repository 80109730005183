.qd08_content {
    margin: 1%;
}

.qd08_tab-ul {
    display: flex;
}

.qd08_tab-ul li {
    height: 16px;
    padding: 0 15px;
    color: #0D6AC1;
    font-size: 15px;
    border-left: 1px solid #CCC;
    display: flex;
    align-items: center;
}

.qd08_tab-ul li:nth-child(1) {
    padding-left: 0;
    border-left: none;
}

.qd08_tab-ul li span {
    font-size: 13px;
    margin-left: 5px;
    color: #E57912;
}

.qd08_tab {
    margin-bottom: 20px;
}

.qd08_title-p-l {
    font-size: 27px;
}

.qd08_title-p-l span {
    font-size: 14px;
    margin-left: 10px;
    color: #0D6AC1;
}

.qd08_title-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.qd08_title-box-r button {
    padding: 5px 10px;
}
.qd08_title-box-r button:nth-child(1) {
    margin-right: 20px;
}

.qd08_warnbox {
    padding: 20px 80px;
    border: 1px solid #000;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: inset 0 0 3px #E57912;
    background-image: url(../../assets/images/qd08_jg.png);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 24px 15px;
    margin-bottom: 1px;
}

.qd08_inpbox {
    display: flex;
    align-items: flex-start;
}

.qd08_inpbox select {
    height: 30px;
    border-radius: 3px;
    border: 1px solid #CCC;
    padding: 6px;
    background-color: #F7F8FA;
}

.qd08_inpbox input {
    width: 180px;
    height: 30px;
    padding: 0 0 0 10px;
    margin-left: 20px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #000;
    background-image: url(../../assets/images/qd08_ss.png);
    background-size: 15px;
    background-position: 5px;
    background-repeat: no-repeat;
    box-sizing: border-box;

}

.qd08_inpbox button {
    height: 30px;
    padding: 3px 10px;
    color: #FFF;
    background-color: #70767D;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 3px;
}

.qd08_inpbox span {
    height: 30px;
    font-size: 12px;
    margin-left: 15px;
    margin-top: 5px;
}

.qd08_screen {
    height: 60px;
    font-size: 12px;
    background-color: #F3F3F3;
    padding: 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.qd08_sn-p {
    color: #999da1;
    margin-right: 15px;
}

.qd08_active {
    height: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.qd08_active input {
    margin: 0 5px 0 10px;
}

.qd08_acse {
    padding: 0 15px;
}

.qd08_acse select {
    height: 30px;
    padding: 5px;
    background-color: #E9EBEE;
    border-radius: 3px;
}

.qd08_sn1 {
    font-size: 12px;
    background-color: #F3F3F3;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    display: flex;
    align-items: center;
    display: flex;
}

.qd08_sn2 {
    font-size: 12px;
    background-color: #F3F3F3;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    display: flex;
    background-color: #FFFFFF;
}

.qd08_screen-ul {
    width: 100%;
    box-sizing: content-box;
    display: flex;
    padding: 0 10px 10px;
    margin-top: 10px;
}

.qd08_screen-ul li {
    width: 90px;
    font-size: 12px;
    color: #000000;
}

.qd08_screen-ul li input {}

.qd08_gray {
    color: rgba(0, 0, 0, 0.8) !important;
    font-weight: 400;
    font-size: 12px;
}

.qd08_screen-ul li:nth-child(1) {
    width: 40px;
}

.qd08_screen-ul li:nth-child(2) {
    width: 80px;
    display: flex;
    justify-content: center;
}

.qd08_screen-ul li:nth-child(3) {
    width: 125px;
    margin-left: 5px;
    color: #0D6AC1;
}

.qd08_screen-ul li:nth-child(4) {
    width: 850px;
    color: #0D6AC1;
}

.qd08_screen-ul li:nth-child(5) {
    width: 40px;
    text-align: right;
}

.qd08_screen-ul li:nth-child(6) {
    width: 50px;
    text-align: center;
    padding: 0 10px;
}

.qd08_screen-ul li:nth-child(7) {
    width: 100px;
    margin-left: 10px;
    text-align: right;
}

.qd08_screen-ul li:nth-child(8) {
    width: 120px;
    color: #0D6AC1;
    margin-left: 10px;
    text-align: right;
}

.qd08_screen-ul li:nth-child(9) {
    width: 140px;
    text-align: right;
}

.qd08_screen-ul li:nth-child(10) {
    width: 80px;
    text-align: right;
}

.qd08_screen-ul li:nth-child(11) {
    width: 40px;
    text-align: right;
    margin-left: 10px;
}

.qd08_screen-ul li:nth-child(12) {
    width: auto;
}

.qd08_blueft {
    color: #0D6AC1;
}

.qd08_xl {
    display: flex;
    justify-content: flex-end;
}

.qd08_jz {
    float: right;
    width: 50px;
    text-align: center;
}

.qd08_tu {
    display: flex;
    align-items: center;
}

.qd08_tu img {
    width: 64px;
}

.qd08_border-l{
    border-left: 3px solid rgba(0, 0, 0, 0.2);
}
.qd08_radiobox{
    display: flex;
    align-items: center;
}
.qd08_radiobox-r{
    margin-left: 5px;
    width: 18px;
}

.qd08_icon {
    margin-top: -3px;
    height: 20px;
}

.qd08_screen-ul>li>.qd08_q {
    text-align: right;
    padding-right: 10px;
    width: 105px;
    height: 26px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}

.qd08_q {

    background-image: url(../../assets/images/qd08_my.png);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 10px center;
}

.qd08_hcolor {
    color: #000000;
}

.qd08_qdjg {
    width: 130px;
    height: 30px;
    background-color: #F9F3E5;
    border: 1px solid #CCC;
    color: #000;
    font-size: 12px;
    margin: 0 10px;
    border-radius: 3px;
}
.qd08_sele-li{
    display: flex;
    margin: 0 10px;
}
.qd08_sele-r{
    height: 28px;
    background-color: #F2F3F6;
    border-radius:0  3px 3px 0;
    border: 1px solid rgba(0,0,0,0.4);
}
.qd08_sele-l{
    width: 90px;
    height: 28px;
    font-size: 13px;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius:  3px 0 0 3px ;
    border-right: 0;
    color: #000000;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #F2F3F6;
}
.qd08_pagination{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: relative;
}
.qd08_pagination span{
    margin:0 10px;
}
.qd08_pagination button{
    height: 34px;
    box-sizing: border-box;
    padding: 0 12px;
    background-color: #F2F3F6;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.4);
    margin: 0 2px;
}
.qd08_pagination button:first-of-type{
    border: 1px solid #E57912;
    background-color: #FFF;
    color: #E57912;
    
}
.qd08_page-r{
display: flex;
position: absolute;
right: 0;
}
.qd08_sele2{
width: 145px;
height: 35px;
border: 1px solid rgba(0,0,0,0.5);
font-size: 14px;
display: flex;
align-items: center;
padding: 0 10px;
border-right:0 ;
background-color: #F6F7F9;
border-radius: 3px 0 0 3px;
}
.qd08_page-r select{
height: 35px;
border: 1px solid rgba(0,0,0,0.5);
border-left:0 ;
background-color: #F6F7F9;
border-radius: 0 3px 3px 0;
}