.content{
	width: 100%;
}
.end16_title{
	/* width: 100%; */
	border-bottom: 1px solid rgba(0, 0, .0, 0.3);
	padding: 25px 0;
}
.end16_title_head{
	display: flex;
	padding:  0 25px;
}
.end16_title_head div:nth-of-type(1){
	font-size: 31px;
	color: #003100;
}
.end16_title_head div:nth-of-type(2){
	font-size: 16px;
	color: #48a5b3;
	font-weight: bold;
	line-height: 41px;
	margin-left: 15px;
}
.end16_title div:nth-of-type(2){
	display: flex;
	justify-content: space-between;
	padding:  5px 25px;
}
.end16_title div:nth-of-type(2) div:nth-of-type(1){
	font-size: 14px;
	width: 830px;
}
.end16_title div:nth-of-type(2) div:nth-of-type(2){
	font-size: 14px;
	align-items:flex-end;
}
.end16_title div:nth-of-type(2) div:nth-of-type(2) span{
	color: #188EA0;
}
.end16_function{
	height: 55px;
	background-color: #FAFAFA;
	display: flex;
	justify-content: space-between;
}
.end16_function div:nth-of-type(1){
	display: flex;
	margin-left: 35px;
}
.end16_function div:nth-of-type(1) div:nth-of-type(1){
	height: 31px;
	padding: 0 12px;
	line-height: 31px;
	width: 360px;
	border: 2px solid #D5DEDB;
	margin-top: 8px;
	font-style: oblique;
	color: #878787;
}
.end16_function div:nth-of-type(1) div:nth-of-type(2){
	width: 31px;
	height: 31px;
	margin-top: 8px;
	border: 2px solid #D5DEDB;
	text-align: center;
}
.end16_function div:nth-of-type(1) div:nth-of-type(2) svg{
    width: 2rem;
	height: 2rem;
}
.end16_function div:nth-of-type(1) div:nth-of-type(3) {
	line-height: 55px;
	margin-left: 15px;
	font-size: 14px;
}
.end16_function div:nth-of-type(2){
	display: flex;
}
.end16_function div:nth-of-type(2) div:nth-of-type(1){
	line-height: 55px;
	padding-right: 25px;
}
.end16_function_btn{
	background: #E3ECED;
	height: 35px;
	margin-top: 6px;
	line-height: 40px;
	padding: 0 55px;
	border-radius: 3px;
	margin-right: 25px;
	border: 0.5px solid #e2e2e2;
	box-shadow:1px 1px 1px 1px rgba(0,0,0,.1) ;
}
.end16_commodity{
	/* width: 100%; */
	margin: 0 2px;
	border: 1px solid #a1a7a5;
}
.end16_commodity .end16_commodity_top{
	padding: 8px 0;
	border-top: 1px solid #a1a7a5;
	border-bottom: 1px solid #a1a7a5;
	display: flex;
	justify-content: space-between;
}
.end16_commodity .end16_commodity_top .end16_commodity_con {
	width: 400px;
	margin-left: 15px;
}
.end16_commodity .end16_commodity_top .end16_commodity_con div:nth-of-type(1){
	color: #002f36;
	font-weight: bold;
}
.end16_commodity .end16_commodity_top .end16_commodity_con div:nth-of-type(2){
	font-size: 14px;
	color: #969696;
}
.end16_commodity_right{
	float: right;
}
.end16_commodity_right ul{
	list-style: none;
	display: flex;
}
.end16_commodity_right ul li{
	width: 120px;
	font-weight: bold;
	font-size: 14px;
	border-left: 1px solid #CCCCCC;
	padding-left: 12px;
	line-height: 24px;
    display: flex;
    align-items: center;
	justify-content: space-between;
	color: #002F36;
}

.end16_commodity_right ul li:nth-of-type(1){
	border: none;
	text-align: right;
	display: inline-block;
}
.end16_commodity_right ul li img{
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
.end16_commodity_right p{
	width: 300px;
	text-align: center;
	font-size: 14px;
	color: #878787;
	height: 28px;
	line-height: 28px;
	margin-left: 450px;
}
.end16_commodity_bottom{
	border-top: 1px solid #a1a7a5;
	border-bottom: 2px solid #a1a7a5;
	display: flex;
	height: 65px;
	justify-content: space-between;
}
.end16_commodity_bottom .end16_commodity_bottom_txt{
	margin-top: 12px;
}
.end16_commodity_bottom div:nth-of-type(1) .end16_commodity_bottom_txt p{
	color: #188EA0;
	font-size: 12px;
	margin-left: 15px;
}
.end16_commodity_bottom div:nth-of-type(1) p:nth-of-type(2){
	color: #878787;
}
.end16_commodity_bottom div:nth-of-type(2){
	height: 100%;
	display: flex;
}
.end16_commodity_bottom div:nth-of-type(2) ul{
    display: flex;
    margin: 0;
}
.end16_commodity_bottom div:nth-of-type(2) ul li{
	width: 120px;
	background: #F5F9FA;
	padding: 12px 0;
	font-size: 14px;
	padding-left: 8px;
}
.end16_commodity_bottom div:nth-of-type(2) ul li:nth-of-type(2){
	color: #ff0000;
}
.end16_commodity_bottom div:nth-of-type(2) ul li:nth-of-type(3){
	color: #188EA0;
}
.end16_commodity_bottom div:nth-of-type(2) div:nth-of-type(1){
	width: 150px;
	background: #FAFAFA;
	margin-left: 8px;
}
.end16_commodity_bottom div:nth-of-type(2) div:nth-of-type(2){
	width: 400px;
}
.end16_commodity_bottom div:nth-of-type(2) div:nth-of-type(2) button{
	height: 40px;
	width: 150px;
	background: #188EA0;
	border: none;
	margin: 10px 0 0 175px;
	color: #FFFFFF;
}
.end16_transit{
	height: 25px;
	background-color: #f1f1f1;
	margin-top: 65px;
	display: flex;
    justify-content: space-between;
    padding: 5px  10px 0 10px;

}
.end16_transit_zuo{
    transform: rotate(180deg);
}
.end16_page{
	padding-left: 15px;
}
.end16_page p{
	margin: 12px 0;
	font-size: 14px;
	color: #06393e;
}
.end16_page div:nth-of-type(1){
	display: flex;
	justify-content: space-between;
	line-height: 37px;
}
.end16_page div:nth-of-type(1) div:nth-of-type(1){
	display: flex;
	color: #06393e;
}
.end16_page div:nth-of-type(1) div:nth-of-type(1) div{
	width: 55px;
	height: 35px;
	line-height: 35px;
	border: 1px solid #CCCCCC;
	margin: 0 5px;
}
.end16_page div:nth-of-type(1) div:nth-of-type(2) p span{
	color: #188EA0;
	font-weight: bold;
	margin: 0 5px;
}
.end16_page div:nth-of-type(1) div:nth-of-type(3) select{
	width: 260px;
	padding: 8px 5px ;
	appearance: button;
	color: #0c5159;
	margin-right: 5px;
}
.end16_feedback{
    height: 26px;
    color: #ffff;
    line-height: 26px;
    padding: 4px 7px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #008296;
    position: absolute;
    bottom: 0;
    left: 35px;
}
