

.Referral_body{
    width: 100%;
    padding: 10px 20%;
}
.Referral_head{

}
.Referral_head_titel{
    display: flex;
    justify-content: space-between;
}
.Referral_head_titel div{
    font-size: 28px;
}
.Referral_head_content{
    font-size: 16px;
}
.Referral_head_bottom{
    width: 100%;
    height: 30px;
    padding:  20px;

}
.Referral_head_bottom div{
        width: 100%;
        border-bottom: 1px solid #008296;
}
.Referral_content{
    width: 100%;
    border: 1px solid #000000;
    display: flex;
    justify-content: space-between;
}

.Referral_content_left{
    width: 65%;
    padding: 38px;
}
.Referral_content_left_titel{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Referral_content_left_titel div{
    font-size: 34px;
    color: #008296;

}
.Referral_content_left_content{
    padding: 15px 0;
    font-size: 16px;
}

.Referral_content_left_button{
    margin-top: 100px;
    padding: 0 30px;
}
.Referral_content_left_button button{
    width: 40%;
    padding: 10px;
    background-color: #008296;
    color: #ffffff;
    outline-style: none;
    border: 0;
}
.Referral_content_right{
    width: 30%;
    padding: 38px;
}
.Referral_content_right_img{
    width: 100%;
    height: 100%;
    position:  relative;
}
.Referral_content_right_img img{
    width: 100%;
    height: 100%;

}
.Referral_content_right_img_icon{
    position: absolute;
    top: 40%;
    left: 39%;
    font-weight: bold;
}
.Referral_content_right_img_icon svg{
    width: 5rem;
    height: 5rem;
}
.Referral_content_bottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.Referral_content_bottom{
    padding: 40px 0;
}
.Referral_content_bottom_Box{
    width: 30%;
    border: 1px solid #000000;
    padding: 20px;
}
.Referral_content_bottom_Box_titel{
        display: flex;
        justify-content: space-between;
       padding-bottom: 18px;

}
.Referral_content_bottom_Box_titel div{
    font-size: 17px;
}
.Referral_content_bottom_Box_img{
    width: 100%;
    padding-bottom:13px ;
    position: relative;
}
.Referral_content_bottom_Box_img img{
    width: 100%;
    height: 100px;
}
.Referral_content_bottom_content{
    font-size: 16px;
    padding-bottom: 25%;
}
.Referral_content_bottom_btn{
    width: 100%;
    padding: 0 10px;
}
.Referral_content_bottom_btn button{
    width: 100%;
    padding: 5px;
    background: #E3ECED;
    outline-style: none;
    border: 0;
    box-shadow: 1px 3px 4px #dddddd;
    font-weight: bold;
}
.Referral_content_right_img_iconII{
    position: absolute;
    top: 25%;
    left: 40%;
}
.Referral_content_right_img_iconII svg{
    width: 4rem;
    height: 4rem;

}