.kc23_cennav{
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}
.kc23_cennav>ul{
    width: 700px;
    min-width: 700px;
    list-style: none;
    margin-left:50px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
}
.kc23_cennav>ul>li{
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: #20A6E0;
}
.kc23_cennav>ul>li:nth-of-type(1){
    font-weight: 700;
}
.kc23_cennav>ul>li>img{
    width: 30px;
    vertical-align: middle;
    margin-right: 5px;
}
.kc23_cennav>ul>li:not(:nth-of-type(1))::before{
    content: "|";
    width: 20px;
    margin-left: 10px;
    margin-right: 15px;
    color: #ccc;
}
.kc23_cennav>ul>li .kc23_gongn{
    color: #D3812E !important;
    margin-left: 10px;
   
}
.kc23_fanhui{
    min-width: 1650px;
    padding: 0px 20px;
    margin: 0 50px 0 40px;
    margin-top: 10px;
    background: #F6F6F6;
    padding: 15px;
    border: 2px solid #C2D7E1;
    border-radius: 5px;
}
.kc23_fanhui>img{
    width: 30px;
    vertical-align: middle;
}
.kc23_fanhui>span{
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
}
.kc23_fanhui>span>span{
   color: #064F93;
  }
  

  .kc23_tab{
    min-width: 100%;
    padding: 0px 20px;
    margin: 0 0px;
}

.kc23_lllff{
    display: flex;
    justify-content:space-between;
}
.kc23_rongyutit{
    list-style: none;
    display: flex;
    position: relative;
}
.kc23_rongyutit li>h2{
    font-size: 28px;
    font-weight: 400;
    color: black;
}
.kc23_rongyutit li{
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}
.kc23_rongyutit li:nth-of-type(1){
    margin-right: 10px !important;
}
.kc23_rongyutit li:nth-last-of-type(2)::before{
    content: "|";
    width: 20px;
    margin-left: 10px;
    margin-right: 15px;
    color: #ccc;
}
.kc23_rongyutit li:nth-last-of-type(2)::after{
    content: "|";
    width: 20px;
    margin-left: 15px;
    margin-right: 10px;
    color: #ccc;
}
.kc23_rongyutit li:nth-of-type(2) span{
    font-size: 10px;
    margin: 0 5px;
    vertical-align: top;
    color: black !important;
}
.kc23_lllff p{
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 55px;
    margin-left: 10px;
    color: #4891D2;
}
.kc23_lllff p>span:nth-of-type(1){
    padding: 0 5px;
    border: 1px solid rgba(0, 0,.0, 0.5);
    border-radius: 5px;
    background: #E6E6C2;
    color: #000066;
    margin-right: 10px;
    cursor: pointer;
}
.kc23_lllff p>span:nth-of-type(2){
    padding: 0 5px;
    border: 1px solid rgba(0, 0,.0, 0.5);
    border-radius: 5px;
    background: #FFC53A;
    color: #221A5E;
    margin-right: 10px;
    cursor: pointer;
}
.kc23_rongyu>p{
    color: #AAAAAA;
}
.kc23_rongyu>p>span{
    color: #2E81CB !important;
}

.kc23_mytable{
    min-width: 100%;
    margin-top: 30px;
    padding: 0 10px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.kc23_mytable .kc23_tab_l{
    width: 70%;
}
.kc23_mytable .kc23_tab_r{
    width: 28%;
}

.kc23_biaoti{
    font-size: 14px;
    height: 66px;
}
.kc23_biaoti>span{
    color: #2E81CB;
    cursor: pointer;
}
.kc23_biaoti2{
    font-size: 22px;
    height: 62px;

}
.kc23_diqu{
    margin-top: 15px;
    border: 1px solid #ccc;
    padding-bottom: 20px;
}
.kc23_diqu_tab{
    list-style: none;
    display: flex;
    background-color: #F3F3F3;
}
.kc23_diqu_tab>li{
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid #ccc;
}
.kc23_diqu_tab>li:nth-of-type(1){
    font-weight: 700;
    border-top: 2px solid #F2B576 !important;
    background-color: white;
}
.kc23_diqu_tab>li>img{
    width: 15px;
    margin-left: 5px;
}

.kc23_xiaosohu{
margin: 30px 0 0 15px;
}
.kc23_guoqi{
    margin-top: 20px;
    display: flex;
    text-align: center;
}
.kc23_guoqi div{
    margin-right: 20px;
}
.kc23_guoqi p{
    font-size: 14px;
}
.kc23_guoqi img{
    width: 60px;
}
.kc23_houxu{
    margin: 30px 30px 0 15px;
    padding: 20px;
    background-color: #F3F3F3;
}
.kc23_houxu p{
    font-size: 14px;
}
.kc23_houxu p>span{
    color: #016DC3;
}
.kc23_houxu p:nth-of-type(1){
    font-weight: 600;
    font-size: 16px;
}
.kc23_biaoge{
    margin: 20px 0 0 0px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 453px;
    overflow: hidden;
    display: flex;
}
.kc23_biaoge_l{
    width: 180px;
    height:453px;
    background-color: #FBFBFB;
    overflow-y: auto;
   
}
.kc23_biaoge_l img{
    width: 20px;
    float: right;
    margin-right: 10px;
}
.kc23_guo div:nth-of-type(1){
    font-size: 14px;
    font-weight: 700;
}
.kc23_guo div:not(:nth-of-type(1)){
    margin-top: 5px;
    padding: 10px 10px;
    font-size: 14px;
}
.kc23_guo .kc23_act{
    border-left: 2px solid #E47911;
    background-color: #F4F4F4;
}

.kc23_biaoge_r{
    width: 100%;
    background: #F4F4F4;
}
.kc23_mynews{
    display: flex;
    flex-wrap: wrap;
}
.kc23_news{
    width: 610px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-bottom: 100px;
}
.kc23_news:nth-of-type(2){
    margin-left: 25px;
}
.kc23_news_box{
    margin: 10px 10px 0 10px;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
}
.kc23_news_box img{
    width: 30px;
    float: right;
    margin-right: 10px;
}
.kc23_news_box2{
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.kc23_news_box2 img{
    width: 30px;
    vertical-align: middle;
}
.kc23_news_box2 select{
    padding: 2px 10px;
    background: #E9EBEE;
    border-radius: 3px;
    margin-left: 5px;
}
.kc23_news_box3{
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
}
.kc23_news_box3 div{
    margin-right:150px;
}

.kc23_news_box3 p{
    font-size: 14px;
    color: rgba(0, 0,.0, 0.6);
    font-weight: 600;
}
.kc23_news_box3 span{
    font-size:30px;
    font-weight: 500;
    color: #4172C0;
}
.kc23_news_box4{
    border-top: 1px solid #ccc;
    margin: 20px 20px 0 0;
    padding-top: 20px;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
}
.kc23_news_box4 img{
    width: 20px;
    margin-right: 5px;
}
.kc23_news_box4 p:nth-of-type(1){
    color: #237CCA;
}
.kc23_news_box4 p:nth-of-type(1)>span{
    color: #46892F;
}
.kc23_news_box4 p:nth-of-type(2){
    margin-left: 24px;
}
.kc23_news_box4 p:nth-of-type(2)>span{
    margin-left: 5px;
    color: #237CCA;
}
.kc23_zhanting{
    margin-top: 10px;
    font-size: 14px;
    color: red;
    position: relative;
}
.kc23_zhanting::before{
    content: "";
    color: red;
    height: 10px;
    border: 2px solid red;
    position: absolute;
    left: -20px;
    top: 6px;
}
.kc23_xiangguan{
    margin-top: 20px;
    margin-right: 10px;
}
.kc23_xiangguan>ul{
    list-style: none;
    border: 1px solid #ccc;
}
.kc23_xiangguan>ul img{
    width: 20px;
    vertical-align: middle;
    float: right;
    margin-right: 20px;
}
.kc23_xiangguan>ul li{
    margin: 1px;
    padding: 15px 10px;
    background-color: #F3F3F3;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.kc23_xiangguan>ul li:nth-child(1) span svg{
    transform: rotate(180deg);
}
.kc23_xiangguan>ul li span svg{
    width: 2rem;
    height: 1rem;
}

.kc23_xiala{
    background-color: #ffffff !important;

}
.kc23_xiala p{
    font-size: 14px;
}
.kc23_xiala p:nth-of-type(2){
    margin-top: 10px;
    color: #0F6FC4;
}
.kc23_spxinxi{
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 20px;
}
.kc23_spxinxi>p:nth-of-type(1){
    font-size: 20px;
    margin-bottom: 5px;
}
.kc23_xinxi_ul ul{
    list-style: none;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}
.kc23_xinxi_ul ul li{
    display: flex;
    font-size: 14px;
    padding: 7px 0;
    text-align: left;
}
.kc23_xinxi_ul ul li:nth-of-type(odd){
    background-color: #F6F6F6;
}
.kc23_xinxi_ul ul li>img{
    height: 15px;
    margin-top: 3px;
    margin-left: 15px;
}
.kc23_xinxi_ul ul li>span:nth-of-type(1){
    width: 60px;
    margin-left: 40px;
}
.kc23_xinxi_ul ul li>span:nth-of-type(2){
    width: 100px;
    margin-left: 80px;
    text-align: left;
}
.kc23_xinxi_ul ul li>span:nth-of-type(3){
    margin-left: 80px;
}
.kc23_xinxi_ul ul li>span:nth-last-of-type(1){
color: #0F6FC4;
}
.kc23_xinxi_ul ul li>span>img{
   vertical-align: middle;
   width: 20px; 
}
.kc23_zhanghu{
    border-top: 2px solid black;
    margin-top: 40px;
}
.kc23_zhanghu p{
    margin-top: 5px;
    font-size: 22px;
}

.kc23_zhanghu p>span{
    font-size: 14px;
    color: #0F6FC4;
    font-weight: 600;
}
.kc23_lianjie{
    background-color: #F6F6F6;
    padding: 5px 60px;
    font-size: 14px;
}
.kc23_lianjie img{
    height: 40px;
    vertical-align: middle;
}
.kc23_lianjie div{
    margin-top: 10px;
}
.kc23_lianjie div>img{
    margin: 0 150px 0 50px;
}
.kc23_lianjie_lan{
    color: #0F6FC4;
}
.kc23_xiaosohu>p:nth-of-type(1){
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.kc23_r_box2{
    display: flex;
}
.kc23_r_box2 div{
    font-size: 14px;
    color: rgba(0, 0,.0, 0.6);
} 
.kc23_r_box2 div:nth-of-type(1) p{
    margin-bottom: 10px;
}
.kc23_r_box2 div:nth-of-type(1) span{
   color: rgba(0, 0,.0, 0.7);
   padding: 5px 10px;
   border: 1px solid rgba(0, 0,.0, 0.6);
   border-radius: 3px;
   margin-right: 10px;
   font-weight: 600;
   background: white;
}
.kc23_r_box2 div:nth-of-type(1) span:nth-of-type(1){
    border: 1px solid #EB8F2E;
    background: #FEF8F2;
}
.kc23_r_box2 div:nth-of-type(2){
    margin-left: 300px;
}
.kc23_r_box2 div:nth-of-type(2) select{
    margin-top: 5px;
    padding: 5px 5px;
    background-color: #E9EBEE;
    border: 1px solid #85868a;
    border-radius: 3px;
}
.kc23_r_box3{
    margin-top: 30px;
    padding-left: 40px;
}
.kc23_r_box3 table{

}
.kc23_r_box3 table tr th,.kc23_r_box3 table tr td{
    border: 1px solid rgba(0, 0,.0, 0.1);
    width: 80px;
    height: 40px;
}
.kc23_bal{
    border-left: 2px solid black;
    position: relative;
}
.kc23_bal span{
    position: absolute;
    left: -19px;
    top: -12px;
    font-size: 14px;
}

.kc23_bal .kc23_to1o{
    position: absolute;
    left: -28px;
    top: -11px;
}
.kc23_bal .kc23_to0{
    position: absolute;
    left: -19px;
    top: 29px;
}

.kc23_r_box3 table .kc23_last_1 td{
    border-bottom: 1px solid black;
    text-align: center;
    position: relative;
}
.kc23_r_box3 table .kc23_last_1 td p{
    position: absolute;
    left: 15px;
    bottom: -35px;
    font-size: 14px;
}
.kc23_last_1 td .kc23_toshu{
    position: absolute;
    height: 10px;
    font-size: 14px;
    overflow: hidden;
    left: -3px;
    bottom: -7px;
}
.kc23_last_1 td .kc23_toshu2{
    position: absolute;
    height: 10px;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    left: -86px;
    bottom: -7px;
}
.kc23_last_1 td .kc23_toshu3{
    position: absolute;
    height: 10px;
    font-size: 14px;
    overflow: hidden;
    right: -2px;
    bottom: -7px;
}
.kc23_r_box4{
    margin-top: 35px;
    font-size: 14px;
}
.kc23_r_box4 p:nth-of-type(1){
    font-weight: 600;
    display: flex;
    align-items: center;
}
.kc23_r_box4 p:nth-of-type(2){
    margin-top: 5px;
    color: rgba(0, 0,.0, 0.6);
}
.kc23_xiaosohu{
    position: relative;
}
.kc23_r_box5{
    position: absolute;
    right: 250px;
    top: 100px;
}
.kc23_r_box5 div p{
    color: rgba(0, 0,.0, 0.5);
    font-size: 14px;

}
.kc23_r_box5 div p span{
    color: rgba(0, 0,.0, 0.9);
    font-size: 30px;
    vertical-align: text-top;
}
.kc23_r_box5 div{
    margin-bottom: 30px;
}
.kc23_xiangxi{
    font-size: 14px;
    color: #2B80CA;
    font-weight: 600;
}
.kc23_dian{
    position: relative;
}
.kc23_dian::before{
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border: 2px solid #FFC53A;
    border-radius: 50%;
    bottom:-5px;
    left: 35px;
}
.kc23_last_1{
    position: relative;
}
.kc23_last_1::after{
    position: absolute;
    content: '';
    width: 580px;
    height: 2px;
    background: #FFC53A;
    opacity: 0.7;
    bottom:78px ;
    left: 80px;
}