

.notification_options .ant-tabs{
  width: 50%;
  margin: 20px auto;
  border: 1px solid rgb(221,221,221);
}
.notification_options .ant-tabs-nav{
  background: rgb(243,243,243);
}
.notification_options .ant-tabs-tab-active{
  background: #fff;
}
.notification_options .ant-tabs-tab{
  padding: 12px 20px;
}