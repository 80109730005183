.atAglancetab4Box .blurs{
    color: #0066c0;
}
.atAglancetab4Box .warningBox{
    width: 40%;
    border: 1px solid #bbd3de;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f6f6f6;
    padding-left: 30px;
}
.atAglancetab4Box .warningBox div{
    font-size: 18px;
    margin-bottom: 20px;
}
