.BrandRegisteredForm_body{
  width: 655px;
  margin: 30px auto;
}
.BrandRegisteredForm_body h2{
  text-align: center;
}
.BrandRegisteredForm{
  padding-bottom: 48px;
}
.BrandRegisteredForm_body_but{
  display: flex;
  justify-content: space-between;
}
.BrandRegisteredForm_body_but_right button:nth-child(1){
  color: rgb(0,130,150);
  border: 1px solid rgb(0,130,150);
}
.BrandRegisteredForm_body_but_right button:nth-child(2){
  background: rgb(0,130,150);
  width: 150px;
  margin-left: 20px;
}