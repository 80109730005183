.qd11_content {
  margin: 0 30px;
}
.qd11_box1 {
  margin: 30px 0 20px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
}
.qd11_box1 span {
  font-size: 14px;
}
.qd11_h1 {
  font-size: 28px;
  margin-right: 10px;
  margin-bottom: 0 !important;

}
.qd11_h1 p{
}
.qd11_box2 {
  width: 65%;
  padding: 20px;
  background-color: #474747;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.qd11_box2 img {
  height: 43px;
}
.qd11_box2-p {
  font-weight: lighter;
}
.qd11_box3 {
  font-size: 13px;
}
.qd11_btn {
  width: 85px;
  padding: 5px 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  font-size: 14px;
  background-color: #f2c659;
  text-align: center;
  margin: 20px 0;
}
.qd11_btn a{
  color: #000000;

}
.qd11_ul4 li {
  font-size: 12px;
}
.qd11_lih1 {
  margin-bottom: 5px;
}
.qd11_wfont{
    color: ;
}
.qd11_box2-l p {
    margin: 0;
font-size: 24px;
}
.qd11_ul4 li:nth-child(1){
    font-weight: bold;
    font-size: 14px;
}
.qd11_box2_img{
    width: 19%;
    height: 40px;
    background-image: url("../../assets/images/qd11_Hlogo.png");
}