.tab-logo-box a{
    height: 60px;
    display: flex;
    padding-top: 10px;
}
.tab-logo-box .home-logo {
    width: 75px;
    height: 25px;
    background: url("../../../../assets/images/global-icon.png");
    background-size: 205px;
    margin-left: 40px;
    margin-top: 6px;
}
.tab-logo-box .logo-char {
    color: #000;
    line-height: 31px;
}

.tab-logo-box{
    align-items: normal;
    border: none;
    background: #fff;
}
.tabs-box-change{
    margin: 0 auto;
}
.tab-icon{
    font-size: 22px;
    margin: 0!important;
}
/* 间距*/
.tabs-box .ant-tabs-tab{
    padding: 12px 10px!important;
}
.ant-tabs-ink-bar{
    background-color: #002F36 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #002F36!important;
    font-weight: bold!important;
}
.tabs-box .ant-tabs-tab-active {
    border-bottom: 3px solid #008296 !important;
    padding-bottom: 9px !important;
    border-top: 0px !important;
  }
.tabs-box .ant-tabs-tab-btn span{
    font-size: 15px;
}
.tabs-box .ant-form-horizontal .ant-form-item-control{
    margin-left: 0;
}
.tabs-box .textareaBox{
    width: 100%;
    min-height: 100px;
    padding: 5px 10px 5px 10px;
}
.tabs-box .icon-suo svg{
    font-size: 18px;
}
.tabs-box .tipsTooltip{
    color: #fff;
}