.imageText_navBox {
  width: 45%;
  margin: 0 auto;
  margin-top: 30px;
}

.imageText_nav {
  width: 100%;
  border: 2px solid #dddddd;
  border-bottom: 0;
}
.imageText_navHead {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}

.imageText_navHead_titel {
  width: 85%;
  height: 100%;
  font-size: 18px;
  border-right: 2px solid #dddddd;
  line-height: 50px;
}
.imageText_navHead_icon {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
}
.imageText_navHead_icon div {
  width: 34%;
  height: 100%;
  line-height: 50px;
  border-right: 2px solid #dddddd;
  text-align: center;
  font-size: 30px;
  color: #667575;
}

.imageText_navHead_icon div:nth-child(3) {
  border: 0;
}
.imageText_navHead_text {
  width: 100%;
  padding: 0 10px;
  border: 2px solid #dddddd;
}
.imageText_navHead_text input {
  width: 100%;
  margin-top: 15px;
  border: 2px solid #dddddd;
}
.imageText_navHead_image {
  width: 100%;
  padding: 20px 0;
  display: flex;
}
.imageText_image_boxI {
  width: 35%;
  margin-right: 15px;
}
.imageText_image_img {
  width: 100%;
  height: 280px;
  background: #008296;
}
.infDetails_ModalBox:hover .infDetails_ModalBtn button {
  color: #008296;
  border-color: #008296;
}
.imageText_image_fontBoxI {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.imageText_image_fontBox {
  width: 35%;
  height: 200px;
  margin-right: 15px;
  border: 2px solid #dddddd;
}

.imageText_fontBox_head {
  width: 100%;
  height: 36px;
  background: #eaeaea;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
}
.imageText_fontBox_head span {
  margin-right: 12px;
}
.imageText_image_fontBox textarea {
  width: 100%;
  height: 160px;
  margin: 0;
  border: 0;
  outline-style: none;
  overflow: auto;
  resize: unset;
  padding: 0 5px;
}
