



.DiscoverApps_nav{
    width: 100%;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    padding: 10px 20px;
}
.DiscoverApps_nav_left{
    font-size: 20px;
}
.DiscoverApps_nav_int{
    width: 20%;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    border: 1x solid #ffffff;
}
.DiscoverApps_nav_int input{
    width: 100%;
    height: 100%;
    outline-style: none;
    border: 0;
    padding: 0 10px;
    color: #000;
}

.DiscoverApps_nav_int span svg{
    background: #008296;
    width: 3rem;
    height: 2.75rem;
    border: 1px solid #ffffff;
}

.DiscoverApps_nav_sele{
    display: flex;
    align-items: center;
    padding-right:65px ;
}
.DiscoverApps_nav_seleII{
    font-size: 16px;
    margin-right: 20px;
}

.DiscoverApps_nav_sele select{
    color: #000000;
    height: 33px;
    width: 80px;
}
.DiscoverApps_head_image{
    width: 100%;
    height: 185px;
    background: url("../../assets/images/ydyy.png");
    background-size: 1923px;
    margin-bottom: 30px;
}

.DiscoverApps_card{
    width: 80%;
    margin: 0 auto;
}
.DiscoverApps_cardII{
    margin-bottom: 40px;
}
.DiscoverApps_card_box{
    border: 1px solid #dddddd;
    box-shadow: 1px 3px 4px #dddddd;
    padding: 10px;
    width: 226px;
    height: 213px;
}
.DiscoverApps_card_img{
    width: 119px;
    height: 95px;
    margin: 0 auto;
}
.DiscoverApps_card_img img{
    width: 100%;
    height: 100%;
}
.DiscoverApps_card_box div:nth-child(2){
    color: #008296;
    padding: 3px 0;
}