.stepControlBox,
.stepControlBox .ant-modal-footer {
  text-align: center;
  border-top: none;
}

.stepControlBox .ant-btn-primary {
  background-color: #28bbac;
  border-color: #28bbac;
}

.stepControlBox .ant-modal-footer {
  padding: 30px 24px;
}

.stepControlBox .disBtn+button {
  margin-left: 0 !important;
}

.stepControlBox .ant-modal-footer button {
  height: 40px;
  min-width: 98px;
  padding: 4px 0;
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid #28BBAC;
}

.stepControlBox .ant-modal-footer .disBtn {
  width: 0;
  min-width: 0;
  height: 0;
  padding: 0;
  border: none;
}

.stepControlBox .ant-modal-content {
  border-radius: 16px;
}

.stepControlBox .ant-modal-header {
  text-align: left;
  background: #F5F7F7;
  font-weight: bold;
  color: #333;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 12px 24px;
}

.register .ant-form-item {
  position: relative;
}

.register .ant-form-item-explain {
  position: absolute;
  /* bottom: -15px; */
  line-height: normal;
}

#root .register .registerForm .ant-form-item {
  margin-bottom: 18px !important;
}