.contactBuyerBox .contactBuyerItem{
    width: 45%;
    margin: 0 auto;
    margin-top: 40px;
}
.contactBuyerBox .fz20{
    font-size: 20px;
}
.contactBuyerBox .fz24{
    font-size: 24px;
}
.contactBuyerBox .fz14{
    font-size: 14px;
}
.contactBuyerBox .mt20{
    margin-top: 20px;
}
.contactBuyerBox .m10{
    margin: 10px 0;
}
.contactBuyerBox .mt0{
    margin-top: 0;
}
.contactBuyerBox .mb0{
    margin-bottom: 0;
}
.contactBuyerBox .fz16{
    font-size: 16px;
}
.contactBuyerBox .col102{
    color: rgb(0, 130, 150);
}
.contactBuyerBox .flex0{
    display: flex;
    justify-content: space-between;
}
.contactBuyerBox .bolds{
    font-weight: bold;
}
.contactBuyerBox .radioBox{
    border: 1px solid #ccc;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}
.contactBuyerBox .radioBox .flexs{
    display: flex;
    width: 30%;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 5%;
}
.contactBuyerBox .radioBox .flexs:nth-child(3),
.contactBuyerBox .radioBox .flexs:nth-child(6){
    margin-right: 0;
}


.contactBuyerBox .radioBox .flexs input{
    margin-right: 10px;
}
.contactBuyerBox .radioBox .flexs>div p{
    margin-bottom: 0;
}
.contactBuyerBox .addfile{
    padding-bottom: 30px;
    border-bottom: 1px solid;
}
.contactBuyerBox .addfile button{
    padding: 5px 10px;
    margin-right: 10px;
}

.contactBuyerBox .selectInfo{
    border: 1px solid #ccc;
    padding: 20px;
}
.contactBuyerBox .selectInfo p{
    padding-bottom: 10px;
}
.contactBuyerBox .selectInfo .flex0{
    margin-bottom: 20px;
}
.contactBuyerBox .selectInfo .newsBox{
    border: 2px solid #000;
    padding: 10px 20px;
    border-radius: 5px;
    min-height: 60px;
    margin-bottom: 20px;
}
.contactBuyerBox .selectInfo .newsBox span{
    font-weight: bold;
}

.contactBuyerBox .caozuoBtn{
    text-align: right;
    margin: 20px 0;
}
.contactBuyerBox .caozuoBtn span{
    display:inline-block;
    width: 80px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    border-radius: 2px;
    margin-right: 10px;
    background-color: rgb(227, 236, 237);
    outline: none;
    text-align: center;
}
.contactBuyerBox .caozuoBtn span:last-child{
    background-color: rgb(0, 130, 150);
}
.contactBuyerBox .caozuoBtn span:last-child a{
    color: #fff;
}



