.tab2-main{
    padding: 20px;
    background-color: #fff;
    margin-top: 30px;
}
.tab2-main P {
    font-size: 12px;
    font-weight: bold;
}
.tab2Banner-box{
    margin-bottom: 50px;
}
.tab2Banner-box .formBox{
    width: 1024px;
    margin: 0 auto;
    background-color: #fff;

}
.tab2-button{
    margin: 0;
    padding: 10px 20px;
}
.SubvariantsBox{
    margin: 30px 0;
}
.tab2Banner-box table tr th,
.tab2Banner-box table tr td{
    text-align: center;
}
.tab2Banner-box .SubvariantsBox .ant-form-item{
    margin: 0 !important;
    width: 25%;
    margin-right: 15px;
}
.tab2Banner-box .ant-form-item{
    margin: 0 !important;
}
.SubvariantsBox>div{
    margin-bottom: 20px;
}
.tab2Banner-box .flex0{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tab2Banner-box .flex1{
    display: flex;
    flex-wrap: wrap;
}
.tab2Banner-box .flex0 Button{
    margin-right: 15px;
}
.tab2Banner-box .flex0 p{
    margin-bottom: 0;
}
.tab2Banner-box table{
    width: 100%;
    margin-top: 15px;
}
.tab2Banner-box table td{
    padding: 10px 5px;
    border: 1px solid #ccc;
}
.tab2Banner-box table tr td:first-child{
    width: 4%;
}
.tab2Banner-box table td .ant-select{
    width: 100%;
}
.tab2Banner-box table tr:first-child td{
    background-color: #d5dbdb;
}
.tab2Banner-box .variantBox{
    display: flex;
}
.tab2Banner-box .checkboxGroupBox .ant-checkbox-wrapper>span:nth-child(2){
    display: none;
}
.tab2Banner-box .delLine{
    text-decoration:line-through;
}
.tab2Banner-box .ant-btn-primary{
    background: #e3edec;
    border: #ddd;
    color: #000;
    font-weight: 700;
}
.tab2Banner-box table td.edit{
    border: none;
    cursor: pointer;
}
.variantShow{
    padding-top: 30px;
}
.variantShow .futiSku{
    width: 70%;
    margin: 0 auto 30px;
    text-align: center;
    border: 1px solid #dededf;
    padding: 5px;
}
.variantShow .futiSku span{
    cursor: pointer;
    color: #5ab7eb;
}
.variantShow .tab2-main{
    border-top: 1px solid #dededf;
    padding-top: 30px;
}

