.end10_mybody{
	width: 95%;
	margin: 0 auto;
	margin-top: 20px;
}
.AToZGuarantee p{
	font-family: "宋体";
}
.AToZGuarantee a{
	text-decoration: none;
	color: black;
}
.end10_mybody .gl{
	font-size: 40px;
	color: #002F36;
}
.end10_mybody .mybody-div{
	width: 65%;
	height: 92px;
	background-color: #E5F2F4;
	position: relative;
	margin-top: 5px;
	margin-bottom: 3px;
}
.end10_mybody .mybody-div .i{
	width: 60px;
	position: absolute;
	top: 20px;
}
.end10_mybody .mybody-div .x{
	position: absolute;
	right: 10px;
	top: 10px;
}
.end10_mybody .mybody-div p:nth-of-type(1){
	font-weight: bolder;
	font-size: 12px;
	color: #002F36;
	margin-left: 65px;
	padding-top: 20px;
}
.end10_mybody .mybody-div p:nth-of-type(2){
	margin-left: 65px;
	font-size: 12px;
	line-height: 15px;
}
.foot-button{
	width: 98%;
	margin: 0 auto;
}
.foot-button .fk{
	background-color: #008296;
	color: white;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.1);
	padding: 2px 7px;
	position: fixed;
	bottom: -2px;
}
.end10_mybody_tab .ant-tabs-tabpane{
	padding: 0;
}
.end10_mybody_tab_inp{
	width: 20%;
	height: 35px;
	border:1px solid #000;
	display: flex;
	justify-content: space-between;
}
.end10_mybody_tab_inpII{
	width: 90%;
}
.end10_mybody_tab_inpII input{
	width: 100%;
	height: 100%;
	border: 0;
	padding-left: 15px;
}
.end10_mybody_tab_inp_btn{
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 1px solid #000;
}
.end10_mybody_tab_inp_btn a{
	text-decoration: underline;
    color: #386C73;
}
.end10_mybody_tab_inp_btn span svg{
	width: 1.5rem;
	height: 1.5rem;
}
.Ato_claimDemage_body{
	padding: 20px 0;
	border-top: 1px solid rgb(213,219,219);
	border-bottom: 1px solid rgb(213,219,219);
	margin-top: 20px;
	line-height: 26px;
	font-size: 12px;
}

.end10_mybody_tab .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
	border: 0;
}
.Ato_claimDemage_details{
	line-height: 26px;
	background: rgb(250,250,250);
	border: 1px solid rgb(213,213,213);
	padding: 20px;
	font-size: 12px;
	color: rgb(0,47,54);
	margin-bottom: 20px;
}
.Ato_claimDemage_details strong{
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
}
.Ato_claimDemage_yourState div {
	margin-bottom: 20px;
}
.Ato_claimDemage_body a{
	color: rgb(0,130,150);
	font-weight: 700;
	font-size: 14px;
}
.Ato_claimDemage_body h3{
	padding-left: 0;
	font-weight: 700;
}
