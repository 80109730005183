
.Reports_Body{
    padding-bottom: 800px;
}



.ant-tabs-tabpane{
    padding: 20px 0;
}

.Reports_Box{
    display: flex;
}


.Reports_leftBox{
    width: 16%;
    height: 100%;
    border: 1px solid #dddddd;
    border-radius: 4px;
}
.Reports_leftBox_top{
    width: 100%;
    padding: 0 20px ;
    background-color: #F2F2F2;
    font-weight: bold;
    height: 32px;
    display: flex;
    align-items: center;
}
.Reports_leftBox_middle{
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid #cccccc;
}

.Reports_leftBox_bottom{
    width: 100%;
    height: 112.4px;
    padding: 20px;
    overflow: auto;
    border-top: 1px solid #cccccc;
    font-weight: bold;

}
.Reports_rightBox{
    width: 80%;
    padding-left: 20px;
    margin-bottom: 20px;
}
.Reports_rightBox_head{
    background: #FBF7DD;
}
.Reports_rightBox_head ul li{    
    list-style:inside;
}

.Reports_rightBox_headII{
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.Reports_rightBox_headII button{
    width: 37px;
    height: 28px;
}
.Reports_rightBox_headII div:nth-child(2){
    font-weight: bold;
    font-size: 23px;
    margin: 0 10px;
}
.Reports_rightBox_headII div:nth-child(4){
    margin-left: 20px;
    color: #9BC3E6;
}
.Reports_rightBox_headIII{
    height: 45px;
    padding: 0 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 4px;

}
.Reports_rightBox_headIII span svg{
    width: 2em;
    height: 2em;
    margin-right: 10px;
}

.Reports_rightBox_headIV{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}
.Reports_rightBox_TopBtn{
    display: flex;
    align-items: center;
}
.Reports_rightBox_TopBtn_inp{
    border: 1px solid #cccccc;
    margin-right: 10px;
    padding: 0 6px;
}
.Reports_rightBox_TopBtn_inp input{
    outline: none;
    border: 0;
}
.Reports_rightBox_TopBtn_btn button{
    width: 50px;
    height: 26px;
    background: #4C545C;
    color: #ffffff;
    padding: 0 10;
}
.Reports_rightBox_TopBtn_font{
    margin: 0 20px;
}
.Reports_rightBox_TopBtn_btnI button{
    width: 50px;
    height: 26px;
    margin-right: 10px;
    padding: 0 10px;
}
.Reports_rightBox_TopBtn_btnII button{
    height: 26px;
    padding: 0 10px;
}
.Reports_rightBox_TopBtn_btnIII button{
    height: 26px;
    padding: 0 10px;
}
.Reports_rightBox_categoryBox{
    height: 60px;
    border: 1px solid #dddddd;
    background: #f2f2f2;
    text-align: center;
    padding-top: 10px;
}
.Reports_rightBox_categoryBoxI{
    padding: 0 10px ;
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.Reports_rightBox_categoryName{
    color: #0066C0;
}
.Reports_rightBox_categoryName:hover{
    color: #E47911;
}