


.MobileApps_Box{
    width: 100%;
    padding-bottom:200px ;
    min-width: 1280px;
}

.MobileApps_header{
    width: 100%;
    height: 377px;
}
.MobileApps_header img{
    width: 100%;
    height: 377px;
    min-width: 1280px;
}
.MobileApps_promptBox{
    width: 100%;
    background: #FAFAFA;
}


.MobileApps_prompt{
    width: 37%;
    padding: 15px 0;
    margin: 0  auto;
    font-size: 15px;
}

.MobileApps_prompt_QrCodeBox{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.MobileApps_prompt_QrCodeContent{
    width: 166px;
    height: 178px;
}
.MobileApps_QrCode_app{
    width: 85px;
    height: 86px;
    margin: 0 auto;
    margin-bottom: 5px;
}
.MobileApps_QrCode_app img{
    width: 100%;
}
.MobileApps_QrCode_appII{
    width: 162px;
    height: 57px;
}
.MobileApps_QrCode_appII img{
    width: 100%;
}
.MobileApps_listI{
    width: 50%;
    display: flex;
    margin: 20px auto;
    
}


.MobileApps_list_img{
    margin-right: 9%;
}

.MobileApps_list_ul{
    padding-left: 35px;
    font-family:'Courier New', Courier, monospace ;
}


.MobileApps_list_ul li{
    font-size: 16px;
    list-style:disc;
    
}

.MobileApps_list_titel{
    font-size: 23px;
    color: #008296;
    margin-bottom: 12px;
}

