.end06_mybody .mybody-ul {
    width: 390px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    cursor: pointer;
}

.AuthenticationThreeBox .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #C45500;
    border: 1px solid #C45500;
}

.AuthenticationThreeBox .bottomDesc {
    background: rgb(246, 246, 246);
    border: 1px solid rgb(187, 211, 222);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.agreementsBox .selectBox .AuthenticationThreeBox hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid #e7e7e7;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 14px;
}

.AuthenticationThreeBox .a-spacing-top-large {
    margin-top: 24px !important;
}

.AuthenticationThreeBox .ant-steps {
    margin: 20px auto 50px;
    width: 70%;
}

.CommentatorBox .mybody-ul li:nth-of-type(3) {
    border-bottom: 0;
}

.end06_mybody .mybody-ul li:nth-of-type(1) {
    border-bottom: 3px solid #FFBA53;
}

.commentBg {

    height: 300px;
    background: url('~@/assets/images/commentBg.png') no-repeat;
    background-size: cover;
    display: flex;
}

.program-overview-title-box {
    padding: 60px 0 0 80px;
    width: 400px;
    background: rgba(39, 62, 84, .7);
    height: 100%;
    color: #fff;
}

.program-overview-diagonal {
    height: 0;
    width: 0;
    border-bottom: 300px solid rgba(39, 62, 84, .7);
    border-right: 100px solid transparent;
}

.heading-text {
    color: #fff;
    font-size: 40px;
}

.a-spacing-small,
.a-ws .a-ws-spacing-small {
    margin-bottom: 8px !important;
}

.a-spacing-medium,
.a-ws .a-ws-spacing-medium {
    margin-bottom: 16px !important;
}

.agreementsBox .a-size-large {
    font-size: 13px !important;
    line-height: 1.3 !important;
    font-weight: bold;
}

.a-size-medium {
    font-size: 17px !important;
    line-height: 1.255 !important;
}

.text-justify {
    text-align: justify;
}

.a-button-primary {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0 10px 0 11px;
    font-size: 13px;
    border-radius: 3px;
}

.agreementsBox .program-overview-values {
    padding: 20px;
    /* max-width: 1500px; */
    margin: 0 auto;
    width: 80%;
}

.agreementsBox .a-grid-vertical-align.a-row {
    width: 65%;
    display: table !important;
    table-layout: fixed;
    zoom: 1;
    border-collapse: collapse;
    margin: 0 auto;
}

.agreementsBox .ng-scope {
    text-align: center;
}



.AuthenticationThreeBox .previousPage {
    background: #e7e9ec;
    margin-right: 2%;
    border: none !important;
}

.AuthenticationThreeBox .operationBtn {
    display: flex;
    justify-content: center;
}

.AuthenticationThreeBox .operationBtn .next {
    width: 30%;
}

.AuthenticationThreeBox .a-size-medium {
    font-size: 22px !important;
    line-height: 1.255 !important;
    margin-bottom: 15px;
}

.AuthenticationThreeBox .help {
    text-align: right;
    color: rgb(0, 102, 192);
    width: 97%;
    margin: 20px auto;
}

.AuthenticationThreeBox .uploadInfo {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.AuthenticationThreeBox .uploadInfo span:first-child {
    font-weight: bold;
}

.genghuan {
    font-weight: 400;
    color: #0066c0;
    cursor: pointer;
    margin-left: 10px;
}

/* .AuthenticationThreeBox .uploadInfo span:last-child{
    color: rgb(0, 102, 192);
} */
.AuthenticationThreeBox .threeTableBox {
    width: 100%;
    margin-top: 25px;
}

.AuthenticationThreeBox .threeTableBox tr td {
    padding: 8px 0 8px 30px;
    border: 1px solid #00000050;
}

.AuthenticationThreeBox .threeTableBox tr td:first-child {
    padding-left: 15px;
    width: 25%;
    background-color: rgb(243, 243, 243);
}

.AuthenticationThreeBox .threeTableBox button {
    background: rgb(235, 237, 240);
    border: 1px solid #00000030;
    border-radius: 3px;
    position: relative;
    padding: 5px 10px;
    width: 250px;
    overflow: hidden;
    /* 超出button的input部分会被切掉 */
}

.AuthenticationThreeBox .threeTableBox button .ant-upload.ant-upload-select-picture-card {
    border: none;
    margin-bottom: 0;
    font-size: 14px;

}

.AuthenticationThreeBox .threeTableBox input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    /* 透明度为零。隐藏input的默认样式，即整个input被隐藏了，但功能还在 */
}

.AuthenticationThreeBox .editInfo {
    cursor: pointer;
}

.AuthenticationThreeBox .imgClass {
    margin-top: 20px;
}

.updata.ymx-modal .ant-upload.ant-upload-select-picture-card {
    background-color: transparent !important;
    border: none;
}

.updata.ymx-modal .operationBtn .next {
    width: 176px;
    height: 31px;
}

.updata.ymx-modal .operationBtn .previousPage {
    width: 176px;
    height: 31px;
}

.updata.ymx-modal .tipUl {
    margin: 0 0 18px 18px;
    color: #949494;
    margin-bottom: 0 !important;
}

.updata.ymx-modal .tipUl li {
    list-style: disc;
    color: #111;
    margin: 10px 0 6px;
    font-size: 13px;
    line-height: 19px;
}

.updata.ymx-modal .tipUl li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

.AuthenticationThreeBox .stepGuidanceBox0 .but:nth-child(1) {
    margin-top: 20px;
}

.AuthenticationThreeBox .stepGuidanceBox0 .but:nth-child(2) {
    margin-bottom: 20px;
}

.stepGuidanceBox1 button {
    margin: 0px 0 20px;
}

.spacing-base {
    margin-bottom: 14px !important;
    padding: 14px 18px;
    display: block;
    border-radius: 4px;
    border: 1px #c40000 solid;
    box-shadow: 0 0 0 4px #fcf4f4 inset;
    background-color: #fff;
    height: 49px;
    padding-left: 63px;
    position: relative;
}

.spacing-base .a-icon-alert {
    height: 27px;
    width: 30px;
    position: absolute;
    left: 18px;
    top: 11px;
    background-image: url('~@assets/images/Amazon.png');
    -webkit-background-size: 400px 670px;
    background-size: 400px 670px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: top;
    background-position: -248px -35px;
}

.spacing-base .ng-scope {
    display: list-item;
    text-align: -webkit-match-parent;
    text-align: left;
    position: relative;
    padding-left: 20px;
}

.spacing-base .ng-scope::before {
    content: ' ';
    width: 5px;
    height: 5px;
    background-color: #111;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 46%;
    left: 0px;
}