.bottomContainerBox::before {
  position: absolute;
  content: ".";
  display: inline-block;
  width: 1px;
  height: 50px;
  transform: translateY(-2px);
  background-color: #ccc;
}

.bottomContainerBox {
  position: relative;
  padding-left: 8px;
}

.bottomContainerBox > div {
  margin-left: 18px;
}

.bottomContainerBoxBtm::before {
  height: 322px;
}
