.registerSteps {
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
}
.registerSteps .Steps-item{
  margin: 0 70px;
  position: relative;
  text-align: center;
}

.registerSteps .Steps-item .line{
  position: absolute;
  top: 15px;
  left: 30px;
  width: 170px;
  height: 1px;
  background-color: #999;
}

.registerSteps .Steps-item .title{
  text-align: center;
  position: absolute;
  bottom: -20px;
  z-index: 2;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
}

.registerSteps .Steps-item .icon{
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
  border: 1px solid #999;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  z-index: 2;
  font-size: 16px;
}
.registerSteps .Steps-item .anticon-check-circle{
  font-size: 30px;
  position: relative;
  z-index: 2;
  color: rgb(0,154,44);
  background-color: #fff;
}

.registerSteps .Steps-item:nth-last-child(1) .line{
  display: none;
}

.registerSteps .Steps-item .icon.action{
  color: #fff;
  border-color: #C45500;
  background-color: #C45500;
}
.registerSteps .Steps-item .title.filled{
  color: rgb(0,154,44);
}
.registerSteps .Steps-item .title.action{
  color: #C45500;
  white-space: nowrap;
}