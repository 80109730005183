.distributionTemplate .flex0{
    display: flex;
    justify-content: space-between;
}
.distributionTemplate .shopupUL-type{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.distributionTemplate .addTemplate{
    margin: 30px 0;
}
.distributionTemplate .addTemplate button{
    background-color: #eff0f3;
    border: 1px solid #a2a6ac;
}

.distributionTemplate .fz20{
    font-size: 20px;
}
.distributionTemplate .fz24{
    font-size: 24px;
}
.distributionTemplate .fz13{
    font-size: 13px;
}
.distributionTemplate .cc0{
    color:#0066c0;
}
.distributionTemplate .shopupUL-type div:nth-child(1){
    margin-right: 40px;
}
.distributionTemplate .shopupUL-labelBtm{
    padding-left: 107px;
}
.distributionTemplate .shopupDL-rbox{
    width: 17%;
    margin-top: 0;
}
.distributionTemplate .shopupDL-bor{
    margin: 0;
}
.distributionTemplate .shopupDL-boxPD{
    min-height: 300px;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.distributionTemplate .shopupDL-boxHed{
    justify-content: space-between;
    font-size: 14px;
}
.distributionTemplate .shopupDL-boxHed .detaultBox{
    background-color: #ffa500;
    color: #fff;
    padding: 5px;
    border-radius: 5px 8px;
}
.distributionTemplate .shopupUL-navBody{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.distributionTemplate .shopupUL-checkbox{
    margin-right: 30px;
}
.distributionTemplate .shopupUL-upBtn{
    width: 100%;
    padding-left: 100px;
}
.distributionTemplate .shopupUL-upBtn button{
    width: 50px;
    height: 30px;
    background: #f3cd6f;
    border:1px solid #000;
    border-radius: 4px;
}
.distributionTemplate .tableBox{
    padding: 0;
}
.distributionTemplate .rightBox{
    border: 1px solid #ccc;
    padding: 20px;
}
.distributionTemplate .col296{
    color:#008296;
}
.distributionTemplate .a-section-expander-container .leftTitle{
    font-size: 17px;
    color: #0F1111;
    font-weight: bold;

}
.distributionTemplate .coupons1 td:nth-child(1){
    width: 60%;
    line-height: 1.5;
    text-align: left;
    font-weight: 400;
    background-color: #fff;
}
.distributionTemplate tr th{
    background-color: #f3f3f3;
    text-align: center;
}
.distributionTemplate tr td{
    text-align: center;
    border: 1px solid #ccc;
}
.distributionTemplate .shopupDL-lbox {
    width: 80%;
}
.shopupUL_modal p{
    height: 50px;
    line-height: 50px;
    border: 1px solid rgb(255,153,0);
    padding: 0 20px;
    box-shadow: 0px 0px 5px rgb(255 153 0);
    border-radius: 5px;
    margin-bottom: 20px;
}
.shopupUL_modal .ant-modal-header{
    background: rgb(238,238,238);
}

.shopupUL_modal .ant-modal-footer{
    background: rgb(238,238,238);
}
.shopupUL_modal .ant-modal-footer .ant-btn{
    background: rgb(235,237,239);
    border: 1px solid rgb(132,106,41);
}
.shopupUL_modal .ant-modal-footer .ant-btn-primary{
    background: rgb(240,195,81);
    border: 1px solid rgb(132,106,41);
}


