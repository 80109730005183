* {
	margin: 0;
	padding: 0;
}

.gg01_container {
	width: 100%;
	height: 100%;
	display: flex;
	min-width: 1100px;
}

.gg01_sideBar {
	width: 35px;
	height: 2900px;
	background-color: #232f3e;
}

.sideBar img {
	width: 15px;
	height: 15px;
	margin: 20px 0 0 12px;

}

.gg01_main {
	flex: 1;
	width: 100%;
	overflow: hidden;

}

.gg01_header {
	width: 100%;
	height: 55px;
	line-height: 55px;
	color: #ffffff;
	background-color: #005a95;
	overflow: hidden;
	padding-left: 2%;
	position: fixed;
}

.gg01_header .gg01_header_bg {
	float: left;
	padding-left: 16px;

}

.gg01_header .gg01_header_gj {
	float: right;
	height: 55px;
	line-height: 55px;
	line-height: 45px;

}

.gg01_header .gg01_header_gj_s {
	font-size: 12px;
	width: 120px;
	border: none;
	background: none;
	outline: none;
	color: #e1ecf3;
	white-space: pre-wrap;
	height: 30px;
}

.gg01_search {
	height: 50px;
    line-height: 50px;
    border: 1px solid #e7e7e7;
    margin: 0 20px 0 58px;
    border-bottom: none;
    margin-top: 4%;
	display: flex;
	justify-content: space-between;
}

.gg01_search .gg01_search_b {
	width: 73px;
	height: 30px;
	border: none;
	background-color: #f5be44;
	border-radius: 5px;
	margin-left: 10px;
	outline: none;
    color: #111111;
    line-height: 0;
}

.gg01_search .gg01_search_i {
	width: 285px;
	height: 30px;
	margin-left: 10px;
	border: 1px solid #bfbfbf;
	color: #767676;
	background: none;
	border-radius: 5px;
	outline: none;
}

.gg01_search .gg01_search_b1 {
	width: 35px;
	height: 30px;
	border: 1px solid #cccccc;
	background: #e7e7e7;
	border-radius: 5px;
	float: right;
	margin: 10px;
	outline: none;
    color: #111111;
    line-height: 0;
	position: relative;
}
.gg01_search_b1II{
	position: absolute;
	right:66px;
	top: 89px;
	background: #fff;
	border: 1px solid #7d7d7d;
	border-radius: 4px;
	padding: 10px;
	display: none;
}
.gg01_search_b1II div{
	height: 30px;
}
.gg01_search_b1Box:hover .gg01_search_b1II{
	display: block;
}
.gg01_tablebox {
	border: 1px solid #e7e7e7;
	margin: 0 20px 0 20px;
	border-top: none;
	font-size: 10px;
	color: #7d7d7d;
	padding: 0 0 0 2%;
}

.gg01_tablebox table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

.gg01_tablebox table tr {
	height: 50px;
}

.gg01_tablebox table tr td {
	border: 1px solid #e7e7e7;
	color: #4a4a4a;
	padding: 12px;
}

.gg01_tablebox table tr:nth-of-type(1) {
	background-color: #f3f3f3;
	height: 30px;
}

.gg01_tablebox table tr:nth-of-type(1) td {
	padding: 10px 0 0 15px;
}

.gg01_tablebox table tr:nth-of-type(1) td:nth-of-type(3) {
	text-align: left;
}

.gg01_tablebox table tr:nth-of-type(1) td:nth-of-type(4) {
	text-align: left;
}

.gg01_tablebox table tr:nth-of-type(1) td:nth-of-type(5) {
	text-align: left;
	color: #4a4a4a;
}

.gg01_tablebox table tr td {
	height: 55px;
}

.gg01_tablebox table tr td:nth-of-type(1) {
	width: 165px;
}

.gg01_tablebox table tr td:nth-of-type(2) {
	width: 115px;
}

.gg01_tablebox table tr td:nth-of-type(3) {
	text-align: center;
	width: 85px;
}

.gg01_tablebox table tr td:nth-of-type(4) {
	text-align: center;
	width: 115px;
}

.gg01_tablebox table tr td:nth-of-type(5) {
	width: 530px;
	color: #1070c4;
}

.gg01_tablebox table tr td:nth-of-type(6) {
	width: 165px;
}

.gg01_tablebox table tr td:nth-of-type(7) {
	width: 165px;
}

.gg01_tablebox table tr td:nth-of-type(8) {
	width: 280px;
}

.gg01_tablebox table tr td:nth-of-type(9) {
	width: 100px;
}

.gg01_tablebox table tr td:nth-of-type(10) {
	width: 87px;
}
.gg01_caozuo{
	position: relative;
	width: 60px;
}
.gg01_caozuo_btn button{
	width: 60px;
	height: 25px;
}
.gg01_win{
	background: #fff;
	padding: 6px;
	height: 90px;
	width: 80px;
	border: 1px solid #7d7d7d;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: -9px;
	display: none;
}
.gg01_caozuo:hover .gg01_win{
	display: block;
	z-index: 3;
}
.gg01_win div{
	margin: 4px 0;
	padding: 0 9px;
}
.gg01_win a{
	color: #000;
}
.gg01_win div:hover{
	background: #ddd;
}
.gg01_delete{
	margin-top: 8px !important;
	padding-top: 4px !important;
	border-top: 1px solid #7d7d7d;
}
.gg01_tablebox table tr td:nth-of-type(10) select {
	font-size: 12px;
	margin-left: 10px;
	outline: none;
	height: 30px;
	border: 1px solid #cccccc;
	background: #e7e7e7;
	border-radius: 5px;
	color: #303030;

}

.gg01_bnt {
	float: right;
	width: 20px;
	height: 8px;
	border: 1px solid #111111;
	display: flex;
	justify-content: space-around;
	margin: -30px -10px 0 0;
	border-radius: 3px;
}

.gg01_bnt div:nth-of-type(1) {
	width: 50%;
	height: 100%;
	background-color: #005A95;
}

.gg01_bnt div:nth-of-type(2) {
	width: 50%;
	height: 100%;
	background-color: #ffffff;
}

.gg01_footer {
	width: 100%;
	height: 115px;
	color: #111111;
	font-size: 14px;
}

.gg01_footer_a {
	height: 50px;
	line-height: 50px;
	color: #181818;
}

.gg01_footer_a .gg01_footer_a_con {
	float: right;
}

.gg01_footer_a .gg01_footer_a_con span:nth-of-type(1) {
	margin-right: 10px;

}

.gg01_footer_a .gg01_footer_a_con input {
	width: 30px;
	height: 30px;
	text-align: center;
	outline: none;
	background: no-repeat;
	border: 1px solid #cccccc;
	margin-right: 10px;
	border-radius: 5px;
}

.gg01_footer_a .gg01_footer_a_con button {
	outline: none;
	background: #f3f3f3;
	border: 1px solid #cccccc;
	width: 30px;
	height: 30px;
	border-radius: 3px;
    clear: both;
    line-height: 0;
}

.gg01_footer_a .gg01_footer_a_con button:nth-of-type(2) {
	background-color: #e5e5e5;
	margin-right: 10px;
}

.gg01_footer_a .gg01_footer_a_con span:nth-of-type(2) {
	margin-right: 10px;
}

.gg01_footer_a .gg01_footer_a_con .gg01_footer_a_con_a {
	margin: 10px 20px 0 0;
	border-radius: 5px;
	background-color: #e9e9e9;
	height: 30px;
	width: 145px;
	line-height: 30px;
	border: 1px solid #cccccc;
	float: right;
}

.gg01_footer_a .gg01_footer_a_con .gg01_footer_a_con_a span {
	padding-left: 5px;
}

.gg01_footer_a .gg01_footer_a_con .gg01_footer_a_con_a select {
	background: none;
	outline: none;
	border: none;
	text-align: center;
}

.gg01_footer .gg01_footer_b {
	height: 15px;
	line-height: 15px;
	margin: 10px 0 0 20px;
	font-size: 12px;
	color: #898989;
	padding: 0 42px;
}

.gg01_footer .gg01_footer_b img {
	width: 15px;
	height: 15px;
	vertical-align: middle;
	margin-top: -3px;
}

.gg01_footer .gg01_footer_b span {
	color: #287dc9;
}

.gg01_footer .gg01_footer_c {
	color: #898989;
	margin: 10px 0 0 0;
	text-align: center;
	font-size: 12px;
}

.gg01_footer .gg01_footer_c span {
	margin-left: 20px;
}

.gg01_icn {
	width: 20px;
	height: 20px;
	margin: 17px 0 0 7px;
}

.gg01_icn1 {
	width: 20px;
	height: 20px;
	vertical-align: middle;
	margin-top: 1px;
}

.gg01_icn2 {
	width: 25px;
	height: 25px;
	vertical-align: middle;
	margin-top: 1px;
}

.gg01_icn3 {
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin-right: 20px;
	margin-top: 2px;
}

.gg01_icn4 {
	width: 16px;
	height: 16px;
}
