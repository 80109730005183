.manageYourExperiments-box {
  font-size: 12px;
  padding: 30px 225px;
}
.manageYourExperiments-head {
  padding: 10px 20px;
  background-color: #e5f2f4;
  font-size: 12px;
  font-weight: 600;
}
.manageYourExperiments-head span {
  margin-right: 10px;
}
.manageYourExperiments-body {
  padding: 30px 10px;
  display: flex;
}
.manageYourExperiments-body a{
    color: #008296;
  }
.manageYourExperiments-body img {
  width: 360px;
  height: 420px;
  margin-bottom: 25px;
}
.manageYourExperiments-body span {
  font-size: 15px;
  font-weight: 600;
}
.manageYourExperiments-body-left {
  width: 600px;
  margin-right: 30px;
}
.manageYourExperiments-body ul {
  padding: 18px 18px;
  margin-left: 18px;
}
.manageYourExperiments-body li {
  list-style: disc;
}
