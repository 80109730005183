

.DraftIncompleteBox .order_body{
    width: 100%;
    padding: 0 1%;
}
.order_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DraftIncompleteBox .order_headI{
    min-width: 13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order_headII{
    display: flex;
    align-items: center;
}
.order_headII a:hover{
    color: #ff8c00;
    text-decoration: underline;
}
.order_headII span{
    margin: 0 10px;
}
.order_headR{
    width: 40%;
    display: flex;
    align-items: center;
}
.order_headR_lab{
    margin-right: 8px;
}
.order_headR_lab select{
    padding: 2px;
    background-color: rgb(236,238,240);

}
.order_headR_inp input{
    height: 27px;
    width: 92%;
    border: 0;
    outline-style: none;
}
.order_headR_inp  button{
    width: 50px;
    height: 27px;
    background: #000;
    color: #ffffff;
    outline-style: none;
}
.order_headR_inp {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    padding-left: 10px;
}

.order_headR_tab{
    position: relative;
}
.order_headR_tabR{
    position: absolute;
    top: 7px;
    right: 25px;
}
.order_headR_tabR a {
    font-size: 16px;
    color: #0066C0;
}

.order_headR_tab .ant-tabs-tabpane{
    padding-top: 0!important;
}
.order_headR_tab .ant-tabs-tab-active{
    border-bottom: 1px solid #e77600 !important;
    border-top: 0!important;
}
.ManageOrdersBox .order_headR_lab table td:nth-child(6){
    min-width: 100px;
}
.DraftIncompleteBox .bolds{
    font-weight: bold;
}