
.Custom_body{
    width: 100%;
    
}
.Custom_titelBox{
    padding: 0 20px;
}
.Custom_titel{
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
}