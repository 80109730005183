.kc32_content{
    width: 96%;
    margin: 0 auto;
}
.kc32_content .kc32_cont_tab{
    border-bottom: 5px solid #999999;
    margin: 0;
}
.kc32_content .kc32_cont_tab ul{
    width: 400px;
    height: 45px;
    line-height: 45px;
    font-size: 19px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0;

}
.kc32_content .kc32_cont_tab ul li:nth-of-type(1){
    height: 45px;
    line-height: 43px;
    position: relative;
    top: 5px;
    color: #cf7e2d;
    border-bottom: 5px solid #cf7e2d;
}
.kc32_content .kc32_cont_tab ul li:nth-of-type(1)::before{
    content: "";
    position: absolute;
    left: 30px;
    top: 29px;
    width: 0;
    height: 0;
    border: 6px solid #cc6600;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}
.kc32_content_brief{
    width: 100%;
}
.kc32_content_brief h2{
    height: 65px;
    line-height: 65px;
	font-size: 21px;
	margin-top: 25px;
}
.kc32_content_brief>div{
    height: 65px;
    line-height: 65px;
}
.kc32_content_brief ul {
    margin-left: 65px;
}
.kc32_content_brief ul li{
    list-style: disc;
    padding: 4px 0;
}
.kc32_content-next{
    width: 100%;
    margin-top: 35px;
    margin-bottom: 45px;
}
.kc32_content-next h2{
    font-size: 16px;
    color: rgb(13, 116, 194);
	line-height: 24px;
}
.kc32_content-next h2 img{
	vertical-align: middle;
}
.kc32_content-next div{
    display: flex;
    justify-content: space-between;
}
.kc32_content-next div button{    
    background: rgb(241, 200, 97);
    padding: 3px;
    border-radius: 4px;
    outline-style: none;
    border: 1px solid #3d3d3d
}
.kc32_content-next p span{
    color: rgb(13, 116, 194);
    font-weight: 500;
}
.kc32_feedback{
    width: 80px;
    height: 26px;
    color: #ffff;
    line-height: 30px;
    padding: 0 7px;
	padding-left: 11px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #008296;
    position: absolute;
    bottom: 0;
    left: 35px;
}
.kc32_feedback span{
	margin-left: 7px;
}
.kc32_feedback img{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 3px;
}